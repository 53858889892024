import { baseURL } from '../store/root-saga';
import { _token } from '../store/local-storage';
import { getCurrentRole } from './role';

/**
 * Create a fake anchor and triggers a download of file
 * @param href
 * @param target
 */
export function downloadFile(href: string, target = '_self') {
  const a = document.createElement('a');
  a.setAttribute('href', href);
  a.setAttribute('download', '');
  a.setAttribute('target', target);
  a.click();
}

// Here it is as a function that accepts (URL, Record<string, string>)
// From https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams
export const addSearchParams = (url: URL, params = {}): URL =>
  new URL(
    // @ts-ignore
    `${url.origin}${url.pathname}?${new URLSearchParams([
      ...Array.from(url.searchParams.entries()),
      ...Object.entries(params),
    ])}`,
  );

/* Adds role and auth token to URL */
export function getHref(location: string): string {
  let url = new URL(`${baseURL}${location}`);

  const addParams = {
    role_id: getCurrentRole()?.id,
    access_token: _token,
  };

  url = addSearchParams(url, addParams);

  return url.toString();
}

/**
 * Create a fake form in order to make a POST request to download files.
 * @param ids
 * @param location
 */
export function downloadWithIds(ids: number[], location: string): void {
  // Create form
  const form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', getHref(location));

  // Create inputs
  let input = document.createElement('input');
  input.setAttribute('type', 'text');
  input.setAttribute('name', 'ids');
  input.setAttribute('value', JSON.stringify(ids));
  form.appendChild(input);

  input = document.createElement('input');
  input.setAttribute('type', 'submit');
  input.setAttribute('value', 'Submit');
  form.appendChild(input);

  document.body.appendChild(form); // This is needed

  // Simulate a user click
  const submit = form.querySelector("input[type='submit']");
  // @ts-ignore
  submit.click();

  // Remove the form from the DOM
  if (document.body.lastElementChild) {
    document.body.removeChild(document.body.lastElementChild);
  }
}
