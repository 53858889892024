import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { AppState } from '../../store/state';
import { selectFormState } from '../../store/selectors';
import { Form, FormDTO } from '../../store/form/types';
import formSlice from '../../store/form/slice';
import FormBuilder from '../../components/FormBuilder/FormBuilder';
import { FormAdapter, sortForms } from '../../helpers/form';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { AddButton } from '../../components/ui/inputs/AddButton/AddButton';
import FormCard from '../../components/FormCard/FormCard';
import tw from 'twin.macro';
import AddFormDialog from '../../components/dialogs/AddFormDialog/AddFormDialog';

interface Props {
  formById: { [key: number]: Form };
  createFormAction: (data: FormDTO) => void;
  deleteFormAction: (formId: number) => void;
}

// Tabs: I declare the type 'tabOption' limited to three values
type tabOption = 'forms' | 'edit-form';

const SettingsFormsPage: React.FC<Props> = ({ formById, createFormAction, deleteFormAction }) => {
  const theme = useTheme();
  const [selected, setSelected] = useState<number | undefined>(undefined);
  const [dialog, setDialog] = React.useState<JSX.Element | undefined>(undefined);
  const [tab, setTab] = React.useState<tabOption>('forms');

  const handleCreateForm = (form: FormDTO) => {
    // Trigger redux action
    createFormAction(form);
  };

  // Function to handle change edit tab page
  const handleTab = (formId: number, tabName: tabOption) => {
    setSelected(formId);
    setTab(tabName);
  };

  // Function to handle the delete form dialog
  const handleDeleteForm = (attachedForm: Form) => {
    setDialog(
      <SimpleDialog
        open={true}
        handleClose={() => setDialog(undefined)}
        handleOk={() => {
          deleteFormAction(attachedForm.id);
          setSelected(undefined);
        }}
        title="Remove form?"
      >
        <p className="text-sm mb-2">
          {`Are you sure you want to remove ${attachedForm.name} form? Take into account that this action can't be undone.`}
        </p>
      </SimpleDialog>,
    );
  };

  // Tabs: I declare the variable for the content of the return
  let content;

  // Tabs: I define the main and secondary pages as a tabs
  switch (tab) {
    case 'forms':
      content = (
        <>
          {/* SUBMISSION FORMS */}
          <div className="text-sm w-full max-w-screen-2xl mb-14">
            {/* SUBMISSION FORMS HEADER */}
            <div className="flex mb-8 items-center">
              <h2 className="font-bold text-xl">Submission</h2>

              <AddButton
                label="New form"
                onClick={() => {
                  setDialog(
                    <AddFormDialog
                      type="Submission"
                      handleFormOk={(newForm) => {
                        handleCreateForm(newForm);
                      }}
                      handleClose={() => setDialog(undefined)}
                    />,
                  );
                }}
              />
            </div>

            {/* SUBMISSION FORM CARDS LIST */}
            <StyledCardsWrapper>
              {sortForms(Object.values(formById).filter((form) => form.type == 'Submission')).map(
                (attachedForm, index) => {
                  return (
                    <FormCard
                      key={index}
                      form={attachedForm}
                      handleEdit={() => handleTab(attachedForm.id, 'edit-form')}
                      handleDeleteForm={() => handleDeleteForm(attachedForm)}
                    />
                  );
                },
              )}
            </StyledCardsWrapper>
          </div>

          {/* REVIEW FORMS */}
          <div className="text-sm w-full max-w-screen-2xl mb-14">
            {/* REVIEW FORMS HEADER */}
            <div className="flex mb-8 items-center">
              <h2 className="font-bold text-xl">Review</h2>

              <AddButton
                label="New review form"
                onClick={() => {
                  setDialog(
                    <AddFormDialog
                      type="Review"
                      handleFormOk={(newForm) => {
                        handleCreateForm(newForm);
                      }}
                      handleClose={() => setDialog(undefined)}
                    />,
                  );
                }}
              />
            </div>

            {/* REVIEW FORM CARDS LIST */}
            <StyledCardsWrapper>
              {Object.values(formById).filter((form) => form.type == 'Review').length > 0 ? (
                sortForms(Object.values(formById).filter((form) => form.type == 'Review')).map((reviewForm, index) => {
                  return (
                    <FormCard
                      key={index}
                      form={reviewForm}
                      handleEdit={() => handleTab(reviewForm.id, 'edit-form')}
                      handleDeleteForm={() => handleDeleteForm(reviewForm)}
                    />
                  );
                })
              ) : (
                <p>There are no forms yet.</p>
              )}
            </StyledCardsWrapper>
          </div>

          {dialog}
        </>
      );
      break;
    case 'edit-form':
      content = (
        <div className="flex flex-grow h-full flex-col">
          {selected && (
            <FormBuilder
              initFormData={new FormAdapter().transform(formById[selected])}
              onCancel={() => {
                setTab('forms');
              }}
            />
          )}
        </div>
      );
      break;
  }

  return <ThemeProvider theme={theme}>{content}</ThemeProvider>;
};

const mapStateToProps = (state: AppState) => ({
  formById: selectFormState(state).formById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createFormAction: (data: FormDTO) => dispatch(formSlice.actions.CREATE_FORM(data)),
  deleteFormAction: (formId: number) => dispatch(formSlice.actions.DELETE_FORM(formId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsFormsPage);

const StyledCardsWrapper = styled.div`
  ${tw`grid gap-5 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5`}

  .draft-card {
    background-color: #fdfbe6;
    border-color: #fbeb00;
  }

  .card-header {
    border-bottom: 1px dashed rgb(214, 216, 217);
    margin-bottom: 0.75rem;
    display: flex;
    justify-content: space-between;

    h3 {
      border: none;
      margin-bottom: 0;
      align-self: end;
    }

    .draft-label {
      height: max-content;
      background-color: #fbeb02;
      padding: 2px 8px;
      border-radius: 12px;
      margin-bottom: 0.2rem;
    }
  }
`;
