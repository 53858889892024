import React, { useState } from 'react';
import { BasicInvitationFormFields } from '../../store/recruitment/types';
import { validationErrorTransformer } from '../../store/api';
import { Button, InputText, Select, SelectRadioButton } from '../ui';
import FileUploadUpdater from '../FileUploadUpdater/FileUploadUpdater';
import { validateFile, validateInvitationFormFields } from '../../store/recruitment/sagas';
import { Role } from '../../store/conference/types';
import { ValidatedField } from '../../store/api.d';
import styled from 'styled-components';
import { getUserByEmail } from '../../store/user/sagas';

export type INVITATION_INPUT_TYPE = 'single' | 'many' | 'ids';

const initFields = {
  role: '',
  email: '',
  first_name: '',
  last_name: '',
};

interface Props {
  roleById: { [key: string]: Role };
  onValid: (input: BasicInvitationFormFields[], type: INVITATION_INPUT_TYPE) => void;
  onClose: () => void;
}

const InvitationRecipientPicker: React.FC<Props> = ({ roleById, onValid, onClose }) => {
  const [fields, setFields] = useState<BasicInvitationFormFields[]>([initFields]);
  const [mode, setMode] = useState<INVITATION_INPUT_TYPE | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useState<ValidatedField[]>([]);

  const errors = validationErrors ? validationErrorTransformer(validationErrors) : {};

  const [showNext, setShowNext] = useState<boolean>(false);

  const [showExtraFields, setShowExtraFields] = useState<boolean>(false);

  let content = undefined;

  if (mode === 'single') {
    content = (
      <div>
        <StyledSelectWrapper className="selectWrapper">
          <Select
            label="Role"
            required
            onChange={(value) => setFields([{ ...fields[0], role: value }])}
            options={Object.values(roleById)
              .filter((role) => role.type === 'reviewer' || role.type === 'chair')
              .map((role) => ({ id: role.description, label: role.description }))}
            error={!!errors.role}
            helperText={errors.role}
          />
        </StyledSelectWrapper>

        <StyledInputWrapper>
          <InputText
            label="Email"
            required
            onChange={(event) => {
              setShowExtraFields(false);
              setFields([{ ...fields[0], email: event.target.value, first_name: '', last_name: '' }]);
            }}
            error={!!errors.email}
            helperText={errors.email}
            className="inputWrapper"
          />
        </StyledInputWrapper>

        {showExtraFields && (
          <React.Fragment>
            <StyledInputWrapper>
              <InputText
                required
                label="First name"
                onChange={(event) => {
                  setFields([{ ...fields[0], first_name: event.target.value }]);
                }}
                error={!!errors.first_name}
                helperText={errors.first_name}
                className="inputWrapper"
              />
            </StyledInputWrapper>

            <StyledInputWrapper>
              <InputText
                required
                label="Last name"
                onChange={(event) => {
                  setFields([{ ...fields[0], last_name: event.target.value }]);
                }}
                error={!!errors.last_name}
                helperText={errors.last_name}
                className="inputWrapper"
              />
            </StyledInputWrapper>
          </React.Fragment>
        )}
      </div>
    );
  } else if (mode === 'many') {
    content = (
      <FileUploadUpdater
        doPreview={(file) => {
          return validateFile(file);
        }}
        templateName="invitations"
        error={errors.file}
        onFeedbackHandler={(data) => {
          const filtered = data.data.rows.filter((value) => !value.has_error);
          setShowNext(filtered.length > 0);
          setFields(
            filtered.map((value) => {
              return {
                email: value.values[0],
                role: value.values[1],
                first_name: value.values[2],
                last_name: value.values[3],
              };
            }),
          );
        }}
      />
    );
  } else {
    // mode === 'ids'
    content = undefined;
  }

  return (
    <>
      <StyledFieldsetWrapper>
        <fieldset className={`text-sm`}>
          <StyledSelectRadioButton
            label="How many invitations do you want to create? "
            options={[
              { id: 'single', label: 'Just one' },
              { id: 'many', label: 'Many' },
            ]}
            value={mode}
            onChange={(value) => {
              setMode(value as INVITATION_INPUT_TYPE);
              setValidationErrors([]);
              setShowExtraFields(false);
            }}
          />

          {/*          <hr className="my-4 border-dashed border-gray-300" />

          <div className={`mt-3 ${ids && ids.length > 0 ? '' : 'disabled'}`}>
            <StyledSelectRadioButton
              label="Resend table selected invitations? "
              options={[{ id: 'ids', label: 'Use selected rows' }]}
              onChange={(value) => {
                setMode(value as INVITATION_INPUT_TYPE);
                setShowNext(true);
              }}
              className="text-sm"
              value={ids && ids.length > 0 && mode === 'ids' ? 'ids' : ''}
            />
          </div>*/}
        </fieldset>
      </StyledFieldsetWrapper>

      {content}

      <StyledButtons>
        <StyledCancel onClick={onClose} autoFocus variant="text">
          Cancel
        </StyledCancel>

        {((mode === 'many' && showNext) || mode === 'single' || mode === 'ids') && content && (
          <Button
            onClick={async () => {
              if (mode === 'single') {
                let tempFields: BasicInvitationFormFields = {
                  ...fields[0],
                };
                const res = await getUserByEmail(fields[0].email);
                switch (res.type) {
                  case 'ok':
                    const { data } = res.value;
                    if (data.length > 0) {
                      // Case user found
                      tempFields = {
                        ...tempFields,
                        first_name: data[0].first_name,
                        last_name: data[0].last_name,
                      };
                    } else {
                      // Case user not found. Ask for extra fields
                      setShowExtraFields(true);
                    }
                    break;
                  case 'validation-error':
                    break;
                  case 'error':
                    break;
                }

                // Call API here to validate input
                validateInvitationFormFields(tempFields).then((resp) => {
                  switch (resp.type) {
                    case 'ok':
                      if (mode) {
                        setValidationErrors([]);
                        onValid([tempFields], mode);
                      }
                      break;
                    case 'validation-error':
                      setValidationErrors(Object.values(resp.value.validation));
                      break;
                  }
                });
              } else if (mode === 'many') {
                onValid(fields, mode);
              }
            }}
          >
            Next
          </Button>
        )}
      </StyledButtons>
    </>
  );
};

const StyledFieldsetWrapper = styled.div`
  fieldset {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 2rem auto 1rem auto;
    padding: 1rem;
  }
  .disabled {
    > div {
      label {
        color: #ccc;
        pointer-events: none;
      }
      > div {
        input {
          border-color: #ccc;
          pointer-events: none;
        }
      }
    }
  }
`;

const StyledSelectRadioButton = styled(SelectRadioButton)`
  label {
    margin-right: 1rem;
    vertical-align: text-bottom;
  }
`;

const StyledButtons = styled.div`
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 2px dotted #e3e5e6;
  padding: 1rem 0 0 0;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
  padding-bottom: 1.5rem;
`;

const StyledCancel = styled(Button)`
  color: ${(props) => props.theme.palette.error.main};
  font-weight: 700;
`;

const StyledSelectWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;

  .labelSelect {
    margin-right: 0.8rem;
    width: 10.3rem;
    align-self: flex-start;
    margin-top: 0.8rem;
  }
  > span:nth-of-type(1) {
    width: 28%;
    margin-right: 0.5rem;
  }
  > span:nth-of-type(2) {
    margin-bottom: 0;
    margin-right: 0;
  }
  .dropdown-wrapper {
    width: 100%;
  }
  .helperText {
    margin-top: 0.2rem;
    margin-bottom: 0;
  }
`;

const StyledInputWrapper = styled.div`
  margin-bottom: 1rem;
  .inputWrapper {
    display: flex;
    flex-direction: row;

    label {
      margin-right: 1rem;
      width: 7.3rem;
      align-self: flex-start;
      margin-top: 0.8rem;
    }
    > div {
      width: 74%;
    }
    .helperText {
      margin-top: 0;
    }
  }
`;

export default InvitationRecipientPicker;
