import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faFileCircleMinus,
  faUsers,
  faEarthAmericas,
  faCircleXmark,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import '../../components/Dashboard/Dashboard.styles.css';
import { ResponsiveBar } from '@nivo/bar';
import { Select } from '../../components/ui';
import Map from '../../components/ui/surfaces/Map/Map';
import { TableDataStateChangeDTO } from '../../store/table/types';
import { State } from '@progress/kendo-data-query';
import history from '../../store/history';
import { getRouteByName } from '../../router/routes';
import { fillRoutePath } from '../../helpers/path';

interface DashboardSubmissionsProps {
  dashboardState: DashboardState;
  keywordById: { [key: number]: Keyword };
  dataStateChangeAction: (data: TableDataStateChangeDTO) => void;
}

const DashboardSubmissions: React.FC<DashboardSubmissionsProps> = ({
  dashboardState,
  keywordById,
  dataStateChangeAction,
}) => {
  const [selectedAreaId, setSelectedAreaId] = useState<any>(0);
  const [areaIdForPaperStatus, setAreaIdForPaperStatus] = useState<any>('');

  const totalSubmissionsPerArea = dashboardState.data.submissions_per_keyword.reduce(
    (sum: number, area: { value: number }) => sum + area.value,
    0,
  );

  const submissionsWithoutCountry = dashboardState.data.submissions_per_country
    .find((item: { id: string; value: number }) => item.id === 'UNKNOWN')
    ?.value.toFixed(2);

  const authorsWithoutCountry = dashboardState.data.authors_per_country
    .find((item: { id: string; value: number }) => item.id === 'UNKNOWN')
    ?.value.toFixed(2);

  const created_submissions_days = dashboardState.data.submissions_per_day.find(
    (item: { id: string; data: [] }) => item.id === 'Created',
  )?.data.length;

  const submissions_per_status = dashboardState.data.submissions_per_status[areaIdForPaperStatus || 'all'] ?? [];

  const goTofilteredSubmissions = (status: string) => {
    const submissionsState: State = {
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'validation_status',
            operator: 'contains',
            value: status == 'Completed' ? 'Complete' : '',
          },
        ],
      },
    };

    dataStateChangeAction({
      dataState: submissionsState,
      friendlyName: 'submissions',
    });

    history.push(fillRoutePath(getRouteByName('RouteAllSubmissions').path));
  };

  return (
    <>
      <div className="flex flex-row justify-between w-full" style={{ height: '35rem', minWidth: 540 }}>
        {/* SUBMISSIONS PER DAY CHART */}
        <div className="bg-white rounded-lg shadow-md p-8 w-9/12">
          <h2 className="font-bold text-xl mb-4 text-center">Submissions per day</h2>
          {Object.keys(dashboardState.data.submissions_per_day).length > 0 ? (
            <ResponsiveLine
              isInteractive={true}
              data={dashboardState.data.submissions_per_day}
              defs={[
                {
                  colors: [
                    {
                      color: 'inherit',
                      offset: 80,
                    },
                    {
                      color: 'inherit',
                      offset: 100,
                      opacity: 0,
                    },
                  ],
                  id: 'gradientA',
                  type: 'linearGradient',
                },
              ]}
              fill={[
                {
                  id: 'gradientA',
                  match: '*',
                },
              ]}
              curve="monotoneX"
              xFormat="time:%Y-%m-%d"
              xScale={{
                format: '%Y-%m-%d',
                precision: 'day',
                type: 'time',
                useUTC: false,
              }}
              yScale={{
                type: 'linear',
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                format: '%b %d',
                legend: '',
                legendOffset: -12,
                tickValues: created_submissions_days > 7 ? 'every week' : 'every day',
                tickRotation: -34,
              }}
              axisLeft={{
                legend: 'papers',
                legendOffset: -64,
              }}
              margin={{ top: 20, right: 150, bottom: 100, left: 80 }}
              colors={['#0044f0', '#f9a80e']} //colors={{ scheme: 'nivo' }}
              lineWidth={3}
              pointSize={10}
              pointBorderWidth={3}
              pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.8]],
              }}
              pointLabelYOffset={-20}
              enableArea={true}
              enablePointLabel={false}
              enableSlices={false} // Only is available interactivity (mouse events) if enableSlices is false
              useMesh={true}
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 118,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                  // onClick: (data, event) => {
                  //   goTofilteredSubmissions(data.label);
                  // },
                },
              ]}
              // onClick={(data) => {
              //   goTofilteredSubmissions(data.serieId.toString());
              // }}
            />
          ) : (
            <div className="text-center mt-36">
              <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
              <p className="text-center mt-4 mb-8">There are no data yet.</p>
            </div>
          )}
        </div>

        {/* CARDS WITH TOTALS */}
        <div className="dashboardCardWrapper flex-col justify-around mb-0">
          <div className="dashboardCard">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faFile} className="text-xl" />
            </div>
            <span>{dashboardState.data.total_submissions}</span>
            <br />
            <span className="legend">
              completed {dashboardState.data.total_authors > 1 ? 'submissions' : 'submission'}
            </span>
          </div>

          <div className="dashboardCard">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faFileCircleMinus} className="text-xl" />
            </div>
            <span>{dashboardState.data.total_pending_submissions}</span>
            <br />
            <span className="legend">
              {dashboardState.data.total_authors > 1 ? 'incompleted submissions' : 'incompleted submission'}
            </span>
          </div>

          <div className="dashboardCard">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faUsers} className="text-xl" />
            </div>
            <span>{dashboardState.data.total_authors}</span>
            <br />
            <span className="legend">{dashboardState.data.total_authors > 1 ? 'authors' : 'author'}</span>
          </div>

          <div className="dashboardCard">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faEarthAmericas} className="text-xl" />
            </div>
            <span>{dashboardState.data.total_countries}</span>
            <br />
            <span className="legend">{dashboardState.data.total_countries > 1 ? 'countries' : 'country'}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between mt-12 w-full" style={{ height: '32rem' }}>
        {/* SUBMISSIONS PER STATUS */}
        <div className="bg-white rounded-lg shadow-md mr-4 p-8 w-6/12" style={{ minWidth: 540 }}>
          <h2 className="font-bold text-xl text-center">Total submissions per status</h2>

          <StyledSelectWrapper className="flex flex-row justify-end items-baseline mt-6 mb-4">
            <Select
              label="Area"
              value={areaIdForPaperStatus}
              options={[{ id: '', label: 'Not selected' }].concat(
                Object.values(keywordById)
                  .filter((keyword) => keyword.parent_id == null)
                  .map((keyword) => ({ id: keyword.id.toString(), label: keyword.name })),
              )}
              onChange={(value) => setAreaIdForPaperStatus(value)}
            />
          </StyledSelectWrapper>

          {Object.keys(submissions_per_status).length > 0 ? (
            <ResponsiveBar
              data={submissions_per_status}
              keys={['frequency']}
              indexBy="paper_status"
              margin={{ top: 20, right: 50, bottom: 150, left: 60 }}
              padding={0.6}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors="#63b3ff"
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'number of papers',
                legendPosition: 'middle',
                legendOffset: -50,
              }}
              labelSkipHeight={15}
              tooltip={(point) => {
                return (
                  <div className="text-sm flex flex-row">
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: point.color,
                        marginTop: '4px',
                        marginRight: '8px',
                      }}
                    ></div>
                    <div>
                      {point.indexValue}: <strong>{point.value}</strong>
                    </div>
                  </div>
                );
              }}
            />
          ) : (
            <div className="text-center mt-28">
              <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
              <p className="text-center mt-4 mb-8">There are no data yet.</p>
            </div>
          )}
        </div>

        {/* SUBMISSIONS PER COUNTRY */}
        <div className="bg-white mb-0 p-8 rounded-lg shadow-md w-6/12" style={{ maxWidth: 1200 }}>
          {submissionsWithoutCountry ? (
            <Map
              id="mapSubmissionsPerCountry"
              mapData={dashboardState.data.authors_per_country}
              width="100%"
              height="24rem"
              label="Submissions"
              totalWithoutCountry={submissionsWithoutCountry}
            />
          ) : (
            <>
              <h2 className="font-bold text-xl mb-1 mr-5 text-center">% Submissions per country</h2>
              <div className="text-center mt-36">
                <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
                <p className="text-center mt-4 mb-8">There are no data yet.</p>
              </div>
            </>
          )}
        </div>
      </div>

      {/* SUBMISSIONS PER KEYWORDS */}
      {Object.keys(keywordById).length > 0 && (
        <div className="flex flex-row w-full mt-12 justify-center mx-auto" style={{ height: '40rem', minWidth: 540 }}>
          <div className="relative my-0 bg-white rounded-lg shadow-md mr-4 pt-8 px-4 w-6/12">
            <h2 className="font-bold text-xl text-center">Submissions per area</h2>
            {Object.keys(dashboardState.data.submissions_per_keyword).length > 0 ? (
              <>
                <p className="text-center text-sm mt-2 mb-4">
                  Number of submissions considering the keyword in the first position (the main keyword).
                </p>

                <ResponsivePie
                  data={dashboardState.data.submissions_per_keyword}
                  sortByValue={true}
                  margin={{ top: 50, right: 80, bottom: 220, left: 80 }}
                  innerRadius={0.65}
                  padAngle={1}
                  cornerRadius={1}
                  colors={[
                    '#3a71bd',
                    '#417cc8',
                    '#4887d4',
                    '#4f91e0',
                    '#569cee',
                    '#5ca8f9',
                    '#63b3ff',
                    '#6abdfb',
                    '#71c7f7',
                    '#78d2f3',
                    '#7fdcff',
                    '#86e6fb',
                    '#8deff7',
                    '#94f9f3',
                    '#9bffef',
                    '#a2f9fb',
                    '#a9f5ff',
                  ]}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.5]],
                  }}
                  arcLinkLabel="label"
                  arcLinkLabelsSkipAngle={15}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  tooltip={(point) => {
                    return (
                      <div className="text-sm bg-white px-2 py-1 shadow-md flex flex-row">
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: point.datum.color,
                            marginTop: '4px',
                            marginRight: '8px',
                          }}
                        ></div>
                        <div>
                          {point.datum.label}: <strong>{point.datum.value}</strong>
                        </div>
                      </div>
                    );
                  }}
                  layers={['arcs', 'arcLabels', 'arcLinkLabels']}
                  onClick={(data, event) => {
                    setSelectedAreaId(data.id);
                  }}
                />

                <StyledTotal>
                  <span className="title">{totalSubmissionsPerArea}</span>
                  <span className="text">submissions with keywords</span>
                </StyledTotal>
              </>
            ) : (
              <div className="text-center mt-48">
                <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
                <p className="text-center mt-4 mb-10">There are no data yet.</p>
              </div>
            )}
          </div>

          <div className="relative my-0 bg-white rounded-lg shadow-md pt-8 px-4 w-6/12">
            {selectedAreaId > 0 && (
              <>
                <div
                  onClick={() => setSelectedAreaId(0)}
                  className="absolute top-3 right-3 text-3xl text-blue-600 cursor-pointer"
                >
                  <FontAwesomeIcon icon={faCircleXmark} />
                </div>
                <h2 className="font-bold text-xl text-center">
                  {
                    dashboardState.data.submissions_per_keyword.find(
                      (dades: { id: any }) => dades.id === selectedAreaId,
                    )?.label
                  }
                  : submissions per topic
                </h2>
                <p className="text-center text-sm mt-2 mb-4">
                  Number of submissions considering the keyword in the first position (the main keyword).
                </p>

                <ResponsivePie
                  data={
                    dashboardState.data.submissions_per_keyword.find(
                      (dades: { id: any }) => dades.id === selectedAreaId,
                    )?.topics
                  }
                  sortByValue={true}
                  margin={{ top: 50, right: 80, bottom: 220, left: 80 }}
                  innerRadius={0.65}
                  padAngle={1}
                  cornerRadius={1}
                  colors={[
                    '#3a71bd',
                    '#417cc8',
                    '#4887d4',
                    '#4f91e0',
                    '#569cee',
                    '#5ca8f9',
                    '#63b3ff',
                    '#6abdfb',
                    '#71c7f7',
                    '#78d2f3',
                    '#7fdcff',
                    '#86e6fb',
                    '#8deff7',
                    '#94f9f3',
                    '#9bffef',
                    '#a2f9fb',
                    '#a9f5ff',
                  ]}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  arcLinkLabel="label"
                  arcLinkLabelsSkipAngle={9}
                  arcLinkLabelsOffset={0}
                  arcLinkLabelsStraightLength={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={6}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  tooltip={(point) => {
                    return (
                      <div className="text-sm bg-white px-2 py-1 shadow-md flex flex-row">
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: point.datum.color,
                            marginTop: '4px',
                            marginRight: '8px',
                          }}
                        ></div>
                        <div>
                          {point.datum.label}: <strong>{point.datum.value}</strong>
                        </div>
                      </div>
                    );
                  }}
                  layers={['arcs', 'arcLabels', 'arcLinkLabels']}
                />

                <StyledTotal>
                  <span className="title">
                    {dashboardState.data.submissions_per_keyword
                      .find((dades: { id: any }) => dades.id === selectedAreaId)
                      ?.topics.reduce((sum: number, area: any) => sum + area.value, 0)}
                  </span>
                  <span className="text">submissions in this area</span>
                </StyledTotal>
              </>
            )}

            {!(Object.keys(dashboardState.data.submissions_per_keyword).length > 0) && (
              <div className="text-center mt-56">
                <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
                <p className="text-center mt-4 mb-10">There are no data yet.</p>
              </div>
            )}

            {Object.keys(dashboardState.data.submissions_per_keyword).length > 0 && !selectedAreaId && (
              <div className="flex flex-col justify-center h-full pb-8">
                <p className="text-md font-semibold self-center">Select an area from Submissions per Area</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* AUTHORS PER COUNTRY */}
      <div className="bg-white mt-12 mx-auto mb-4 w-full p-8 rounded-lg shadow-md" style={{ height: '35rem' }}>
        {authorsWithoutCountry ? (
          <Map
            id="mapAuthorsPerCountry"
            mapData={dashboardState.data.authors_per_country}
            width="100%"
            height="27rem"
            margin="0 auto"
            maxWidth={1920}
            zoom={1.6}
            label="Authors"
            totalWithoutCountry={authorsWithoutCountry}
          />
        ) : (
          <>
            <h2 className="font-bold text-xl mb-1 mr-5 text-center">% Authors per country</h2>
            <div className="text-center mt-36">
              <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
              <p className="text-center mt-4 mb-8">There are no data yet.</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const StyledTotal = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 1.1;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  color: #333333;

  .title {
    font-size: 2rem;
    margin-right: 0.5rem;
  }
  .text {
    font-size: 0.775rem;
  }
`;

const StyledSelectWrapper = styled.div`
  > div {
    margin-right: 0.85rem;
    min-width: inherit;
  }
`;

export default DashboardSubmissions;
