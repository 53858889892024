import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import { EmailTemplate, PlaceholderByRecipientType } from '../../../store/email/types';
import { InputText } from '../../ui';
import emailSlice from '../../../store/email/slice';
import { selectEmailState } from '../../../store/selectors';
import EmailForm from '../../EmailForm/EmailForm';

interface Props extends SimpleDialogProps {
  initialValue: EmailTemplate;
  placeholdersByRecipientType: PlaceholderByRecipientType;
  updateEmailTemplateAction: (data: EmailTemplate) => void;
}

const EmailTemplateDialog: React.FC<Props> = ({
  initialValue,
  placeholdersByRecipientType,
  updateEmailTemplateAction,
  ...rest
}) => {
  const [data, setData] = useState<EmailTemplate>(initialValue);

  return (
    <SimpleDialog
      {...rest}
      okButtonText="Save"
      handleOk={() => {
        updateEmailTemplateAction(data);
      }}
      disableOkButton={!data.name}
    >
      <div className="flex flex-col">
        <InputText
          label="Name"
          required
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          className="mb-4"
        />

        <EmailForm
          onChange={(value) => setData({ ...data, body: value.body, subject: value.subject })}
          placeholders={placeholdersByRecipientType['people']} // TODO
          value={{ subject: data.subject, body: data.body }}
          templatesById={{}}
          hideTemplates={true}
        />
      </div>
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  placeholdersByRecipientType: selectEmailState(state).placeholdersByRecipientType,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateEmailTemplateAction: (data: EmailTemplate) => dispatch(emailSlice.actions['TEMPLATES:UPDATE'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateDialog);
