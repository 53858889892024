import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import {
  deleteSubmissionsResponse,
  getSignedURLRequest,
  getSignedURLResponse,
  getSubmissionsResponse,
  updateSubmissionResponse,
  postSubmissionResponse,
  Submission,
  getSubmissionsRequest,
  SubmissionState,
} from './types';
import { put, select, takeLatest } from '@redux-saga/core/effects';
import submissionSlice from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import errorSlice from '../error/slice';
import { selectConference, selectCurrentRole, selectSubmissionState } from '../selectors';
import formSlice from '../form/slice';
import { baseURL } from '../root-saga';
import conferenceSlice from '../conference/slice';
import { updatePaperStatusBulkDTO, updatePaperStatusBulkResponse } from '../info/types';
import history from '../history';
import { fillRoutePath } from '../../helpers/path';
import { getRouteByName } from '../../router/routes';
import { ConferenceState, Role } from '../conference/types';
import labelSlice from '../label/slice';
import tableSlice from '../table/slice';
import { goBackFromSubmissionDetail } from '../../components/SubmissionDetail/SubmissionDetail';
import { getUserRoles } from '../../helpers/role';

export const getSignedURL = async (
  data: getSignedURLRequest,
): Promise<Result<getSignedURLResponse, APIValidationError>> => {
  const { command, ...rest } = data;
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };

  return apiCall<getSignedURLResponse>(`${baseURL}/api/s3/sign/${command}`, init);
};

export const putObjectS3 = async (signedURL: string, file: File): Promise<Response> => {
  const init = {
    method: 'PUT',
    body: file,
  };

  return fetch(signedURL, init);
};

const postSubmission = async (data: Submission): Promise<Result<postSubmissionResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };
  return apiCall<postSubmissionResponse>(`${baseURL}/api/submissions`, init);
};

function* createSubmissionSaga(action: PayloadAction<Submission>) {
  const result = (yield postSubmission(action.payload)) as Await<ReturnType<typeof postSubmission>>;

  switch (result.type) {
    case 'ok':
      const { answerById, submissionsById } = result.value.data;
      yield put(formSlice.actions['GET:ANSWERS:OK']({ answerById }));
      const submission = Object.values(submissionsById)[0];
      yield put(errorSlice.actions['CLEAR:VALIDATION_ERRORS']());

      // Add author role into userRolesByTrack
      const conferenceState: ConferenceState = yield select(selectConference);
      const roles = getUserRoles(submission.track_id, conferenceState.userRolesByTrack, conferenceState.roleById);
      yield put(
        conferenceSlice.actions['UPDATE:USER_ROLES']({
          trackId: submission.track_id,
          roles: roles.map((r) => ({ id: r.id })),
        }),
      );

      const role: Role | null = yield select(selectCurrentRole);
      if (role) {
        goBackFromSubmissionDetail(role.type); // yield call(goBackFromSubmissionDetail, role.type);
      }
      yield put(submissionSlice.actions['CREATE_SUBMISSION:OK'](submission));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: `Submission #${submission.external_id} created`,
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(errorSlice.actions['SET:VALIDATION_ERRORS'](Object.values(result.value.validation)));
      yield put(submissionSlice.actions['CREATE_SUBMISSION:KO']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: 'Found errors in the submission. Please check them out before save.',
          severity: 'error',
        }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const updateSubmission = async (data: Submission): Promise<Result<updateSubmissionResponse, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<updateSubmissionResponse>(`${baseURL}/api/submissions/${data.id}`, init);
};

function* updateSubmissionSaga(action: PayloadAction<Submission>) {
  const result = (yield updateSubmission(action.payload)) as Await<ReturnType<typeof updateSubmission>>;

  switch (result.type) {
    case 'ok':
      const { answerById, submissionsById } = result.value.data;
      yield put(formSlice.actions['GET:ANSWERS:OK']({ answerById }));
      const submission = Object.values(submissionsById)[0];
      yield put(errorSlice.actions['CLEAR:VALIDATION_ERRORS']());
      const role: Role = yield select(selectCurrentRole);
      if (role.type == 'chair') {
        yield put(formSlice.actions.GET_FORMS()); // Need to do in order to have synchronized whether form is editable
      }
      if (role) {
        goBackFromSubmissionDetail(role.type); // yield call(goBackFromSubmissionDetail, role.type);
      }
      yield put(submissionSlice.actions['UPDATE_SUBMISSION:OK'](submission));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: `Submission #${submission.external_id} edited`,
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(errorSlice.actions['SET:VALIDATION_ERRORS'](Object.values(result.value.validation)));
      yield put(submissionSlice.actions['UPDATE_SUBMISSION:KO']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: 'Found errors in the submission. Please check them out before save.',
          severity: 'error',
        }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const getSubmissions = async (
  data: getSubmissionsRequest,
): Promise<Result<getSubmissionsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  const queryString = data.friendlyName ? `table_friendly_name=${data.friendlyName}` : '';

  return apiCall<getSubmissionsResponse>(`${baseURL}/api/submissions?${queryString}`, init);
};

function* getSubmissionsSaga(action: PayloadAction<getSubmissionsRequest>) {
  const result = (yield getSubmissions(action.payload)) as Await<ReturnType<typeof getSubmissions>>;
  const { friendlyName } = action.payload;

  switch (result.type) {
    case 'ok':
      const { answerById, labelablesById, customColumnsById, submissionsById } = result.value.data;
      yield put(formSlice.actions['GET:ANSWERS:OK']({ answerById }));
      if (friendlyName && labelablesById) {
        yield put(
          labelSlice.actions['LABELABLES:GET:OK']({
            tableFriendlyName: friendlyName,
            labelablesById: labelablesById,
          }),
        );
      }
      if (friendlyName && customColumnsById) {
        yield put(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET:OK']({ friendlyName, byId: customColumnsById }));
      }
      yield put(submissionSlice.actions['GET_SUBMISSIONS:OK']({ submissionsById }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const deleteSubmission = async (
  submissionId: number,
): Promise<Result<deleteSubmissionsResponse, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };
  return apiCall<deleteSubmissionsResponse>(`${baseURL}/api/submissions/${submissionId}`, init);
};

function* deleteSubmissionSaga(action: PayloadAction<number>) {
  const submissionId = action.payload;
  const result = (yield deleteSubmission(submissionId)) as Await<ReturnType<typeof deleteSubmission>>;

  switch (result.type) {
    case 'ok':
      const state: SubmissionState = yield select(selectSubmissionState);
      const submission = state.submissionsById[submissionId];

      // Remove submission
      yield put(submissionSlice.actions['REMOVE_SUBMISSION:OK'](action.payload));

      const role: Role = yield select(selectCurrentRole);
      if (role.type == 'chair') {
        yield put(formSlice.actions.GET_FORMS()); // Need to do in order to have synchronized whether form is editable
      }
      switch (role.type) {
        case 'chair':
          history.push(fillRoutePath(getRouteByName('RouteAllSubmissions').path));
          break;
        case 'author':
          history.push(fillRoutePath(getRouteByName('RouteConferenceHome').path));
          break;
      }
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: `Submission #${submission.external_id} deleted`,
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const updateSubmissionBulk = async (
  data: updatePaperStatusBulkDTO,
): Promise<Result<updatePaperStatusBulkResponse, APIValidationError>> => {
  const { submissionIds, ...rest } = data;
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify({ ...rest, ids: submissionIds }),
  };

  return apiCall<updatePaperStatusBulkResponse>(`${baseURL}/api/submissions/paper-status`, init);
};

function* updateSubmissionBulkSaga(action: PayloadAction<updatePaperStatusBulkDTO>) {
  const result = (yield updateSubmissionBulk(action.payload)) as Await<ReturnType<typeof updateSubmissionBulk>>;

  switch (result.type) {
    case 'ok':
      yield put(submissionSlice.actions['UPDATE:BULK:OK'](action.payload));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(submissionSlice.actions.CREATE_SUBMISSION, createSubmissionSaga),
  takeLatest(submissionSlice.actions.UPDATE_SUBMISSION, updateSubmissionSaga),
  takeLatest(submissionSlice.actions.GET_SUBMISSIONS, getSubmissionsSaga),
  takeLatest(submissionSlice.actions.REMOVE_SUBMISSION, deleteSubmissionSaga),
  takeLatest(submissionSlice.actions['UPDATE:BULK'], updateSubmissionBulkSaga),
];
