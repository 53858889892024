import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';

interface Props extends GridCellProps {
  handleOnClick?: () => void;
}

const IdCell: React.FC<Props> = ({ handleOnClick, ...props }) => {
  const field = props.field || '';
  const value = props.dataItem[field] || '';

  return (
    <StyledTdId
      colSpan={props.colSpan}
      className={`${props.className} cursor-pointer`}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
      onClick={handleOnClick}
    >
      <a>
        <div className="flex items-start">
          {value}
          <div className="view ml-4 px-1 rounded border text-xs invisible" style={{ borderColor: '#0044F0' }}>
            View
          </div>
        </div>
      </a>
    </StyledTdId>
  );
};

export default IdCell;

const StyledTdId = styled.td`
  :hover {
    .view {
      visibility: visible;
    }
  }
`;
