import {
  Answer,
  Answerable,
  DefaultQuestions,
  Form,
  FormDTO,
  FormState,
  FormType,
  FormVisibilityOption,
  Question,
  QuestionDTO,
  QuestionStyle,
  QuestionType,
  ValidationStatus,
} from '../store/form/types';
import { store } from '../store/store';
import { selectFormState, selectTrackRoles } from '../store/selectors';
import {
  FData,
  FTypeEnum,
  FTypeOptions,
  OptionDatas,
  OptionsData,
  QuestionData,
} from '../components/FormBuilder/FormBuilder';
import { FUploadOption } from '../components/FormBuilderInput/UploadFileComponent';
import { FormTypeEnum } from '../store/form/types.d';
import { Review } from '../store/review/types';
import { Submission } from '../store/submission/types';
import { getCompositeAnswerId } from './answers';
import { parseNumber, parsePositiveNumber } from './validation';

const getAuthorsOfAnswerable = (answerable: Answerable, formType: FormType): { id: number; is_primary: boolean }[] => {
  let authors: { id: number; is_primary: boolean }[] = [];
  if (answerable) {
    switch (formType) {
      case FormTypeEnum.Submission:
        authors = (answerable as Submission).authors.map((author) => ({
          id: author.id,
          is_primary: author.is_primary,
        }));
        break;
      case FormTypeEnum.Review:
        authors = [{ id: (answerable as Review).person_id, is_primary: true }];
        break;
    }
  }
  return authors;
};

export const isEmptyAnswer = (answer: Answer): boolean => {
  /* 
  Here empty means {answer: null} or {answer: value: ''}}
  Take into account the following case: {answer: value: 0}!
   */
  return answer.answer === null || (typeof answer.answer.value == 'string' && !answer.answer.value);
};

/**
 * Checks if there's any form with pending questions
 */
const computePendingQuestions = (questions: Question[], answers: Answer[]): number => {
  const requiredQuestions = questions.filter((data) => data.required).map((data) => data.id);

  const answeredRequiredQuestions = answers.filter(
    (answer) => requiredQuestions.includes(answer.question_id) && !isEmptyAnswer(answer),
  );

  return requiredQuestions.length - answeredRequiredQuestions.length;
};

/**
 * Returns the number of pending questions.
 * @param form
 * @param questions
 * @param answers
 * @param answerable
 */
export const formPendingQuestions = (
  form: Form,
  questions: Question[],
  answers: Answer[],
  answerable: Answerable,
): number => {
  let pending = 0;
  if (form.visibility === 'write') {
    pending = computePendingQuestions(questions, answers);
    if (form.type === FormTypeEnum.Submission && form.is_default) {
      // Check submission specific fields like title, abstract etc...
      const submission = answerable as Submission;
      const paperUploadRequired = !!form.default_questions?.paper_upload.required;

      if (!submission.title) pending++;
      if (!submission.abstract) pending++;
      if (submission.authors.length === 0) pending++;
      if (paperUploadRequired && !submission.file_upload) pending++;
    }
  }
  return pending;
};

/*
    Check whether forms are completed
 */
export const isCompleted = (
  formsData: { form: Form; questions: Question[]; answers: Answer[] }[],
  answerable: Answerable,
): boolean => {
  const pendingForms = formsData.filter(
    ({ form, questions, answers }) => formPendingQuestions(form, questions, answers, answerable) > 0,
  );
  return !pendingForms.length;
};

/* Here is where we get forms taking into account the current role */
export const getForms = (
  formType: FormType,
  visibilities: FormVisibilityOption[],
  userRoleId: number,
  authorshipRoleId: number | null,
  answerable: Answerable,
): Form[] => {
  const state = store.getState();
  const formState = selectFormState(state);
  const userRole = selectTrackRoles(state)[userRoleId];

  const { formById, visibilityByFormId } = formState;

  return Object.values(formById)
    .filter((form) => form.type == formType)
    .filter((form) => {
      /* Filter whether the form has ever been presented to the owner role */
      const find = visibilityByFormId[form.id][authorshipRoleId ?? userRoleId].find(
        (value) => value.to_role_id === null,
      );
      return userRole.type == 'chair' ? !!find : !!find?.first_used_at;
    })
    .map((form) => {
      /* Add visibility information with respect of the current role */
      const toRoleId = userRole.type == 'chair' ? null : authorshipRoleId;
      const find = visibilityByFormId[form.id][userRoleId].find((value) => value.to_role_id == toRoleId);
      return {
        ...form,
        visibility: find?.visibility ?? 'hidden',
      };
    })
    .filter((form) => visibilities.includes(form.visibility))
    .filter((form) => {
      /* Filter by paper status selected in the form definition */
      let filter;
      if (form.type === FormTypeEnum.Submission && form.paper_status_ids.length > 0) {
        filter = form.paper_status_ids.includes((answerable as Submission).paper_status_id);
      } else {
        filter = true;
      }
      return filter;
    });
};

const getFormData = (
  form: Form,
  roleId?: number,
  personId?: number,
  answerable?: Answerable,
): { form: Form; questions: Question[]; answers: Answer[] } => {
  const state = store.getState();
  const formState: FormState = selectFormState(state);

  const authors = answerable ? getAuthorsOfAnswerable(answerable, form.type) : [];

  let questions = Object.values(formState.questionById).filter((value) => value.form_id === form.id);

  // Filter questions to visibility specified by role and authorship
  if (roleId !== undefined && personId !== undefined) {
    questions = questions.filter((question) => {
      const { can_read, can_read_others } = question.role_visibility[roleId];
      const skip = !can_read || (!can_read_others && !authors.map((author) => author.id).includes(personId));
      return !skip;
    });
  }
  const questionById: { [key: number]: Question } = {};
  questions.forEach((question) => (questionById[question.id] = question));

  // Get the answers for each question
  const answers: Answer[] = [];
  if (answerable) {
    Object.keys(questionById).forEach((questionId) => {
      const answer = formState.answerById[getCompositeAnswerId(parseInt(questionId), answerable.id)];
      if (answer) {
        answers.push(answer);
      }
    });
  }

  questions = Object.values(questionById);

  return {
    form: form,
    questions: questions,
    answers,
  };
};

export const getFormsData = (
  formType: FormType,
  visibilities: FormVisibilityOption[],
  userRoleId: number,
  authorshipRoleId: number | null,
  personId: number,
  answerable: Answerable,
): {
  answerable: Answerable;
  formsData: { form: Form; questions: Question[]; answers: Answer[] }[];
} => {
  const forms = sortForms(getForms(formType, visibilities, userRoleId, authorshipRoleId, answerable));

  const formsData = forms.map((form) => getFormData(form, userRoleId, personId, answerable));

  // Decide new value for validation status
  const validationStatus: ValidationStatus = isCompleted(formsData, answerable) ? 'Complete' : 'Pending';

  return {
    answerable: { ...answerable, validation_status: validationStatus }, // Overwrite validation status
    formsData: formsData,
  };
};

export const toKendoGridValue = (question: Question, answer: Answer): string | undefined => {
  let value;
  switch (question.type) {
    case 'Select':
    case 'LikertScale':
      const option = question.options.find((option: { id: string }) => option.id == answer.answer?.value);
      value = option?.label;
      break;
    case 'MultiSelect':
      const options: { id: string; label: string }[] = question.options.filter(
        (option: { id: string; label: string }) => answer.answer?.value.includes(option.id),
      );
      value = options.map((option) => option.label).join(';');
      break;
    case 'FileUpload':
      value = answer.answer?.value.filename_original;
      break;
    default:
      value = answer.answer?.value;
      break;
  }
  return value;
};

export const emptyAnswer = (questionId: number): Answer => {
  return {
    id: 0,
    answer: null,
    question_id: questionId,
    answerable_id: 0,
  };
};

export const sortForms = (forms: Form[]): Form[] => {
  return forms
    .sort((a, b) => a.position - b.position)
    .sort((a, b) => {
      // Sort default form first
      if (a.is_default) {
        return -1;
      }
      if (b.is_default) {
        return 1;
      }
      return 0;
    });
};

export class FormAdapter {
  protected transformQuestionType(type: QuestionType, style: QuestionStyle | null): string {
    switch (type) {
      case 'MultiSelect':
        return FTypeEnum.MultipleSelect;
      case 'Select':
        return FTypeEnum.Select;
      case 'Numeric':
        return FTypeEnum.Numeric;
      case 'Comment':
        return style === 'InputText' ? FTypeEnum.ShortText : FTypeEnum.Text;
      case 'FileUpload':
        return FTypeEnum.File;
      case 'LinearScale':
        return FTypeEnum.LinearScale;
      case 'LikertScale':
        return FTypeEnum.LikertScale;
    }
  }

  protected transformQuestionOptions(
    type: QuestionType,
    style: QuestionStyle | null,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    options: any,
  ): { Options?: OptionsData[]; Option?: OptionDatas } | undefined {
    switch (type) {
      case 'FileUpload':
        return {
          Option: {
            fileSize: options.max_size,
            fileType: options.extensions.map((value: string) =>
              FUploadOption.find((option: OptionsData) => option.label == value),
            ),
            pdfFile: options.max_pages,
          },
        };
      case 'Numeric':
      case 'LinearScale':
        return {
          Option: {
            max: options.max_bound !== null ? options.max_bound.toString() : null,
            min: options.min_bound !== null ? options.min_bound.toString() : null,
            num_decimals: type == 'Numeric' ? options.num_decimals.toString() : undefined,
          },
        };
      case 'Select':
      case 'LikertScale':
        const opt: { Options?: OptionsData[]; Option?: OptionDatas } = { Options: options };
        switch (style) {
          case 'Select':
            opt['Option'] = { asSelect: true };
            break;
          case 'SelectRadioButton':
            opt['Option'] = { asRadioButton: true };
        }
        return opt;
      case 'MultiSelect':
        return { Options: options };
      case 'Comment':
        return { Option: { textLength: options.max_length, latex: style === 'TextAreaLatex' } };
      default:
        return undefined;
    }
  }

  protected transformQuestion(question: Question): QuestionData {
    const hiddenRoles: string[] = [];
    Object.entries(question.role_visibility).forEach(([roleId, { can_read, can_read_others }]) => {
      if (!can_read) {
        hiddenRoles.push(roleId);
      }
      if (!can_read_others) {
        hiddenRoles.push(`${roleId}:other`);
      }
    });
    return {
      id: question.id,
      questionTitle: question.title ?? '',
      questionDescriptionOption: !!question.text,
      questionDescription: question.text ?? undefined,
      questionType: FTypeOptions.find(
        (option) => option.label === this.transformQuestionType(question.type, question.style),
      ),
      required: question.required,
      ...this.transformQuestionOptions(question.type, question.style, question.options),
      visibilityOption: hiddenRoles.length > 0,
      visibility: hiddenRoles.map((roleId) => ({ id: roleId, label: '' })),
    };
  }

  transform(form: Form): FData {
    const formData = getFormData(form);
    return {
      id: form.id,
      title: form.name,
      description: form.description ?? undefined,
      type: form.type,
      questions: formData.questions
        .sort((a, b) => a.position - b.position)
        .map((question) => this.transformQuestion(question)),
      paper_status_ids: form.paper_status_ids,
      is_default: form.is_default,
      default_questions: form.default_questions,
      has_answers: form.has_answers,
    };
  }

  reverse(formData: FData): FormDTO {
    return {
      id: formData.id,
      name: formData.title,
      description: formData.description ?? '',
      type: formData.type,
      questions: formData.questions.map((questionData, index) => {
        return this.reverseQuestion(questionData, index + 1, formData.id);
      }),
      paper_status_ids: formData.paper_status_ids,
      default_questions: formData.default_questions
        ? this.reverseDefaultQuestions(formData.default_questions)
        : undefined,
    };
  }

  protected reverseQuestionType(type: string): QuestionType | undefined {
    switch (type) {
      case FTypeEnum.MultipleSelect:
        return 'MultiSelect';
      case FTypeEnum.Select:
        return 'Select';
      case FTypeEnum.Numeric:
        return 'Numeric';
      case FTypeEnum.Text:
      case FTypeEnum.ShortText:
        return 'Comment';
      case FTypeEnum.File:
        return 'FileUpload';
      case FTypeEnum.LinearScale:
        return 'LinearScale';
      case FTypeEnum.LikertScale:
        return 'LikertScale';
      default:
        return undefined;
    }
  }

  protected reverseQuestionOptions(questionData: QuestionData): { options: any; style: QuestionStyle | null } {
    const opt: { options: any; style: QuestionStyle | null } = { options: null, style: null };
    switch (questionData.questionType?.label) {
      case FTypeEnum.MultipleSelect:
        opt.options = questionData.Options;
        break;
      case FTypeEnum.Select:
      case FTypeEnum.LikertScale:
        opt.options = questionData.Options;
        opt.style = questionData.Option?.asSelect
          ? 'Select'
          : questionData.Option?.asRadioButton
          ? 'SelectRadioButton'
          : null;
        break;
      case FTypeEnum.Numeric:
        const decimals = questionData.Option?.num_decimals ? parseInt(questionData.Option?.num_decimals) : 0;

        opt.options = {
          max_bound: questionData.Option?.max ? parseNumber(questionData.Option.max, decimals) : null,
          min_bound: questionData.Option?.min ? parseNumber(questionData.Option.min, decimals) : null,
          num_decimals: decimals,
        };
        break;
      case FTypeEnum.Text:
        opt.options = {
          max_length: questionData.Option?.textLength ? parseInt(questionData.Option.textLength) : null,
        };
        opt.style = questionData.Option?.latex ? 'TextAreaLatex' : 'TextArea';
        break;
      case FTypeEnum.ShortText:
        opt.options = {
          max_length: questionData.Option?.textLength ? parseInt(questionData.Option.textLength) : null,
        };
        opt.style = 'InputText';
        break;
      case FTypeEnum.File:
        opt.options = {
          max_size: parsePositiveNumber(questionData.Option?.fileSize ?? '') ?? 10,
          extensions: questionData.Option?.fileType?.map((value) => value.label),
          max_pages: parsePositiveNumber(questionData.Option?.pdfFile ?? ''),
        };
        break;
      case FTypeEnum.LinearScale:
        opt.options = {
          max_bound: questionData.Option?.max ? parseInt(questionData.Option.max) : null,
          min_bound: questionData.Option?.min ? parseInt(questionData.Option.min) : null,
        };
        break;
    }
    return opt;
  }

  protected reverseQuestion(questionData: QuestionData, position: number, formId: number): QuestionDTO {
    const reversedType = this.reverseQuestionType(questionData.questionType?.label || '');
    if (!reversedType) throw new Error('Question type not recognized.');
    return {
      id: questionData.id,
      form_id: formId,
      title: questionData.questionTitle ?? '',
      text: questionData.questionDescription ?? '',
      required: questionData.required ?? false,
      type: reversedType,
      ...this.reverseQuestionOptions(questionData),
      position: position,
      role_ids: (questionData.visibility ?? []).map((value) => value.id),
    };
  }

  protected reverseDefaultQuestions(defaultQuestionData: DefaultQuestions): DefaultQuestions {
    return {
      ...defaultQuestionData,
      paper_upload: {
        ...defaultQuestionData.paper_upload,
        max_size: parsePositiveNumber(defaultQuestionData.paper_upload.max_size.toString() ?? '') ?? 10,
        max_pages: parsePositiveNumber(defaultQuestionData.paper_upload.max_pages?.toString() ?? ''),
      },
    };
  }
}
