import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppState } from '../store/state';
import {
  selectCurrentRole,
  selectCurrentUser,
  selectRoleRelations,
  selectSubmissionState,
  selectTrackRoles,
} from '../store/selectors';
import { connect } from 'react-redux';
import { AppDispatch } from '../store/store';
import { getSubmissionsRequest, Submission } from '../store/submission/types';
import Loading from '../components/Loading/Loading';
import { Role } from '../store/conference/types';
import { Person, User } from '../store/user/types';
import { Link } from '../components/ui';
import TextAreaLatex, { emptyAnswer } from '../components/TextAreaLatex/TextAreaLatex';
import { Message, MessageDTO } from '../store/discussion/types';
import { deleteMessage, editMessage, getDiscussion, postMessage, subscribe } from '../store/discussion/sagas';
import { Review } from '../store/review/types';
import { AnswerJson } from '../store/form/types';
import submissionSlice from '../store/submission/slice';
import styled, { ThemeProvider } from 'styled-components';
import { Button } from '../components/ui';
import history from '../store/history';
import { fillRoutePath } from '../helpers/path';
import { getRouteByName } from '../router/routes';
import useTheme from '@material-ui/core/styles/useTheme';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import moment from 'moment';
import { getUserOffset } from '../helpers/timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faPaperPlane,
  faRectangleXmark,
  faSquareCaretUp,
  faFloppyDisk,
} from '@fortawesome/free-solid-svg-icons';
import { can, SubmissionPermissionManager } from '../helpers/permissions';
import TextAreaDisplay from '../components/TextAreaDisplay/TextAreaDisplay';
import { OpenSnackBarDTO } from '../store/error/types';
import errorSlice from '../store/error/slice';

interface Props {
  loading: boolean;
  submissionsById: { [key: number]: Submission };
  roleById: { [key: string]: Role };
  getSubmissionsAction: (data: getSubmissionsRequest) => void;
  user: User;
  timezone: string | null;
  roleIds: number[];
  roleId?: number;
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
}

interface Path {
  nodeId: number;
  depth: number;
}

const ROOT_NODE_ID = 0;

class Graph {
  adjacencyList: { [key: number]: number[] } = {};

  constructor(messages: Message[]) {
    messages.forEach((message) => {
      const parentId = message.reply_to_id ?? ROOT_NODE_ID;
      if (!(parentId in this.adjacencyList)) {
        this.adjacencyList[parentId] = [];
      }
      this.adjacencyList[parentId].push(message.id);
    });
  }

  public dfs() {
    const path: Path[] = [];

    this._dfs(ROOT_NODE_ID, 0, path);

    path.shift(); // Remove first element
    return path;
  }

  protected _dfs(nodeId: number, depth = 0, path: any[]) {
    path.push({ nodeId, depth });
    this.neighbors(nodeId).forEach((nodeId) => this._dfs(nodeId, depth + 1, path));
  }

  protected neighbors(nodeId: number): number[] {
    return this.adjacencyList[nodeId] ?? [];
  }
}

const DiscussionPage: React.FC<Props> = ({
  loading,
  submissionsById,
  roleById,
  getSubmissionsAction,
  user,
  timezone,
  roleIds,
  roleId,
  openSnackBarAction,
}: Props) => {
  const [messageLoading, setMessageLoading] = useState<boolean>(false);
  const [reviewsById, setReviewsById] = useState<{ [key: number]: Review }>({});
  const [peopleById, setPeopleById] = useState<{ [key: string]: Person }>({});
  const [messages, setMessages] = useState<Message[]>([]);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [selectedNodeId, setSelectedNodeId] = useState<number | undefined>(undefined);
  const [body, setBody] = useState<AnswerJson>(emptyAnswer);
  const [newMessageBody, setNewMessageBody] = useState<AnswerJson>(emptyAnswer);
  const [editMode, setEditMode] = useState<boolean>(false);

  const params: any = useParams();
  const { submissionId: submission_external_id } = params;

  const theme = useTheme();

  const isChair = roleId ? roleById[roleId].type == 'chair' : false;

  useEffect(() => {
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction({ friendlyName: 'submissions' });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(submissionsById).length > 0) {
      const submission = Object.values(submissionsById).find(
        (submission) => submission.external_id == submission_external_id,
      );

      if (submission) {
        setMessageLoading(true);
        getDiscussion(submission.id).then((response) => {
          if (response.type == 'ok') {
            setReviewsById(response.value.data.reviewsById);
            setMessages(response.value.data.messages);
            setPeopleById({ ...response.value.data.peopleById, [user.person.id]: user.person }); // Add current user
            setIsSubscribed(response.value.data.subscribed);

            setMessageLoading(false);
            scrollTo('bottom');
          }
        });
      }
    }
  }, [Object.keys(submissionsById).length]);

  useEffect(() => {
    if (selectedNodeId) {
      scrollTo('message');
    }
  }, [selectedNodeId]);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const messagesStartRef = useRef<null | HTMLDivElement>(null);
  const messageRef = useRef<null | HTMLDivElement>(null);

  /**
   * behavior: smooth | instant | auto
   * block (vertical alignment, defaults to start): start | center | end | nearest
   * inline (horizontal alignment, defaults to nearest): start | center | end | nearest
   */
  const scrollTo = (position: string) => {
    switch (position) {
      case 'bottom':
        if (messagesEndRef.current != null) {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
        break;

      case 'message':
        if (messageRef.current != null) {
          messageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        break;

      case 'top':
      default:
        if (messagesStartRef.current != null) {
          messagesStartRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
    }
  };

  const saveHandler = () => {
    const isNewMessage = newMessageBody.value.length > 0 ? true : false;

    if ((newMessageBody.value.length > 0 || body.value.length > 0) && submission) {
      const data: MessageDTO = {
        id: 0,
        submission_id: submission.id,
        reply_to_id: selectedNodeId == ROOT_NODE_ID || selectedNodeId == undefined ? null : selectedNodeId,
        body: isNewMessage ? newMessageBody : body,
      };
      postMessage(data).then((response) => {
        if (response.type == 'ok') {
          const newMessage = response.value.data;
          setMessages([...messages, newMessage]);
          setSelectedNodeId(undefined);
          isNewMessage ? setNewMessageBody(emptyAnswer()) : setBody(emptyAnswer());

          if (!selectedNodeId && !editMode) {
            scrollTo('bottom'); // Just scroll to bottom if it a message entered via bottom text area
          }
        }
      });
    }
  };

  const editHandler = () => {
    if (selectedNodeId) {
      editMessage({
        id: selectedNodeId,
        submission_id: 0,
        reply_to_id: null,
        body,
      }).then((response) => {
        if (response.type == 'ok') {
          setMessages(
            messages.map((message) => {
              return {
                ...message,
                body: selectedNodeId == message.id ? body : message.body,
                updated_at: selectedNodeId == message.id ? new Date().toISOString() : message.updated_at,
              };
            }),
          );
          setEditMode(false);
          setBody(emptyAnswer());
          setSelectedNodeId(undefined);
        }
      });
    }
  };

  if (loading || messageLoading || !roleId) {
    return <Loading />;
  }

  const submission = Object.values(submissionsById).find(
    (submission) => submission.external_id == submission_external_id,
  );

  if (!submission) {
    return <div>Submission #{submission_external_id} not found</div>;
  }

  // Visibility permissions
  const role = roleById[roleId];
  const spm = new SubmissionPermissionManager(submission);
  const canViewDiscussion = spm.canViewDiscussion(role);
  const discussionIsOpen = spm.canEditDiscussion(role);
  const canModerate = can('IS_MODERATOR_OF_DISCUSSION') || isChair;
  const canViewReviewers = !!roleIds.map((roleId) => roleById[roleId]).find((role) => role.type === 'reviewer');

  /*
    If you are a reviewer and you are not assigned to the paper of the discussion that you want to access, appears the message "Submission not found".
    So, it's not necessary additional control to access related with this topic like "isReviewerAssigned" in the SubmissionDetailPage.
  */

  if (!canViewDiscussion) {
    return <div>This discussion is not active.</div>;
  }

  const reviews = Object.values(reviewsById).filter((r) => r.submission_id == submission.id);

  const closeButton = (
    <>
      {/* FOOTER BUTTONS */}
      <StyledButtonsWrapper>
        <Button
          variant="text"
          className="cursor-pointer"
          onClick={() => {
            history.push(
              fillRoutePath(getRouteByName('RouteDetailSubmission').path, {
                id: submission.external_id.toString(),
              }),
            );
          }}
        >
          <span className=" font-bold text-red-600 ">Close</span>
        </Button>
      </StyledButtonsWrapper>
    </>
  );

  if (!reviews) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <div className="h-full">This submissions does not have assignments yet.</div>
          {closeButton}
        </ThemeProvider>
      </>
    );
  }

  const graph = new Graph(messages);

  const messageById: { [key: number]: Message } = {};
  messages.forEach((message) => {
    messageById[message.id] = message;
  });

  // Textarea that it's showed when you REPLIES a message
  const textArea = (
    <div ref={messageRef}>
      <TextAreaLatex value={body?.value} format={body?.format} onChange={(data) => setBody(data)} rows={2} />
      <div className="mt-1 text-right">
        <Link
          className="cursor-pointer text-sm"
          onClick={() => {
            setSelectedNodeId(undefined);
            setBody(emptyAnswer());
          }}
        >
          <FontAwesomeIcon icon={faRectangleXmark} size="xl" />
          <span className="ml-1">Cancel</span>
        </Link>
        <Link className="ml-3 cursor-pointer text-sm" onClick={() => saveHandler()}>
          <FontAwesomeIcon icon={faPaperPlane} size="lg" />
          <span className="ml-1">Send</span>
        </Link>
      </div>
    </div>
  );

  const messagesByPerson: Message[] = messages.filter((message) => message.person_id == user.person.id);
  const lasteMessageByPersonId: Message | undefined = messagesByPerson[messagesByPerson.length - 1];

  return (
    <ThemeProvider theme={theme}>
      <StyledDiscussionContent ref={messagesEndRef}>
        <div className="flex flex-row discussion mb-14">
          <div className="w-8/12">
            {/** PAGE HEADER */}
            <h2 className="text-xl mb-8 font-bold text-gray-700">
              Discussion for Paper #{submission.external_id}: {submission.title}
            </h2>

            {reviews.length > 0 ? (
              <div>
                <span className="mr-2 font-semibold">Reviewers:</span>

                {reviews.map((review, index) => {
                  const person = peopleById[review.person_id];
                  const role = roleById[review.role_id];
                  return (
                    <div key={index} className="mr-2">
                      <div key={index} className="flex">
                        <div>
                          {/**
                           * PAPER REVIEWERS HEADER
                           * Show paper reviewers.
                           * Name of chair always visible except if is current user.
                           * Name of reviewer is visible if current user has privileges.
                           * Name of current user not showed, only "You"
                           * */}
                          <span className="mr-1">Reviewer #{review.external_id}</span>
                          <span className="mr-1">
                            {(role.type == 'chair' || (canViewReviewers && person?.id != user.person.id)) &&
                              person?.full_name}
                            {person?.id == user.person.id && `You`}
                          </span>
                          ({role.description})
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>There aren't any reviewers in this discussion.</div>
            )}

            {reviews.length > 0 && (
              <div className="flex h-full flex-col mt-5" ref={messagesStartRef}>
                {graph.dfs().length > 0 ? (
                  <div className="mt-3 ml-2 grow h-full">
                    {graph.dfs().map((path) => {
                      const { nodeId, depth } = path;
                      const { person_id, role_id, body: messageBody, updated_at, created_at } = messageById[nodeId];
                      const person = peopleById[person_id];
                      const role = roleById[role_id];
                      const isSelectedNode = selectedNodeId === nodeId;

                      const utcOffset = getUserOffset(timezone);
                      const reverse = (date: Date) => {
                        const dateStr = moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) + utcOffset;
                        return new Date(dateStr).toLocaleString();
                      };
                      const dateCreatedUtcOffset = reverse(new Date(created_at));
                      const dateUpdatedUtcOffset = reverse(new Date(updated_at));

                      const editLastMessage = discussionIsOpen && lasteMessageByPersonId?.id == nodeId;
                      const showReplyTextArea = discussionIsOpen && isSelectedNode && !editMode;
                      const readOnly = !(editMode && lasteMessageByPersonId?.id == nodeId);
                      const showFormatButtons = editMode;
                      const modifiedMessage = dateCreatedUtcOffset !== dateUpdatedUtcOffset;

                      const handleSelect = (e: MenuSelectEvent) => {
                        if (e.item.text == 'Reply') {
                          setEditMode(false);
                          setBody(emptyAnswer());
                          setSelectedNodeId(nodeId);
                        }

                        if (e.item.text == 'Delete') {
                          deleteMessage(nodeId).then((response) => {
                            if (response.type == 'ok') {
                              setMessages(messages.filter((message) => message.id != nodeId));
                            }
                          });
                        }
                      };

                      const reviewer: Review | undefined = reviews.find(
                        (review) => review.person && review.person_id === person_id,
                      );

                      const renderFullName = <span className="mr-1">{person?.full_name}</span>;

                      return (
                        <div className={`relative ${depth - 1 == 0 ? 'pt-4' : ''}`} key={nodeId}>
                          {depth - 1 == 0 && <StyledHr className="absolute top-0" />}
                          <div className={`ml-${(depth - 1) * 12}`}>
                            <StyledMessage
                              className={`max-w-full w-max p-3 rounded-xl p-6 min-h-20 shadow-lg shadow-black border-gray-400 ${
                                user.person.id == person_id ? 'bg-blue-300' : 'bg-gray-200'
                              }`}
                            >
                              {/**
                               * MESSAGE HEADER
                               * Show only ReviewerId if sender of message is not Chair
                               * Name of chair always visible.
                               * Name of sender is visible if is current user or has privileges.
                               * */}
                              <p className="text-xs mb-2">
                                {dateUpdatedUtcOffset} {modifiedMessage && <span className="ml-1">[edited]</span>}
                              </p>
                              <p className="font-bold mb-2">
                                {/* MESSAGE TITLE */}
                                {reviewer && person_id != user.person.id && role.type != 'chair' && (
                                  <span className="mr-1">Reviewer #{reviewer.external_id} </span>
                                )}
                                {/* NAME AND ROLE DESCRIPTION */}
                                {(person_id != user.person.id && canViewReviewers) ||
                                (person_id != user.person.id && role.type == 'chair')
                                  ? renderFullName
                                  : null}
                                {person_id == user.person.id && <span className="mr-1">You</span>}(
                                {role && role.description})
                              </p>

                              <StyledTextAreaLatex>
                                {/** DROPDOWN ACTIONS MENU */}
                                <div className="kmenu">
                                  <Menu onSelect={handleSelect}>
                                    <MenuItem icon="more-vertical">
                                      {discussionIsOpen && <MenuItem text="Reply" />}
                                      {canModerate && <MenuItem text="Delete" />}
                                    </MenuItem>
                                  </Menu>
                                </div>

                                {/**
                                 * MESSAGE CONTENT
                                 * By default (when it's not edit mode ) disabled is true
                                 * and showFormatButtons is false
                                 */}
                                {readOnly ? (
                                  <TextAreaDisplay value={messageBody.value} format={messageBody.format ?? 'plain'} />
                                ) : (
                                  <div ref={messageRef}>
                                    {/**
                                     * Textarea that it's showed when you EDIT a message
                                     * */}
                                    <TextAreaLatex
                                      value={body?.value}
                                      format={body?.format}
                                      showFormatButtons={showFormatButtons}
                                      onChange={(data) => setBody(data)}
                                      rows={5}
                                    />
                                  </div>
                                )}

                                {/** EDIT BUTTON IN LAST MESSAGE USER */}
                                {discussionIsOpen && editLastMessage && !editMode && (
                                  <Link
                                    className="cursor-pointer text-sm block absolute right-3 bottom-4"
                                    onClick={() => {
                                      setBody(messageBody);
                                      setSelectedNodeId(nodeId);
                                      setEditMode(true);
                                    }}
                                  >
                                    <StyledEditButton>
                                      <FontAwesomeIcon icon={faPenToSquare} />
                                    </StyledEditButton>
                                    <span className="ml-1">Edit</span>
                                  </Link>
                                )}

                                {/** EDITION BUTTONS */}
                                {editLastMessage && editMode && (
                                  <div className="mt-1 text-right">
                                    <Link
                                      className="cursor-pointer text-sm"
                                      onClick={() => {
                                        setEditMode(false);
                                        setBody(emptyAnswer());
                                        setSelectedNodeId(undefined);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faRectangleXmark} size="xl" />
                                      <span className="ml-1">Cancel</span>
                                    </Link>
                                    <Link
                                      className="ml-3 cursor-pointer text-sm"
                                      onClick={() => {
                                        editHandler();
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faFloppyDisk} size="xl" />
                                      <span className="ml-1">Save</span>
                                    </Link>
                                  </div>
                                )}
                              </StyledTextAreaLatex>
                            </StyledMessage>

                            {showReplyTextArea && textArea}
                            <div className="h-5"></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="relative">
                    <StyledHr className="absolute top-0" />
                    <div className="pt-4">There are no messages in this discussion yet.</div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* SUBMENU */}
          <StyledSubmenu>
            <>
              <div>
                <h2>Actions</h2>
                <ul>
                  <li>
                    <Link
                      onClick={() =>
                        subscribe(submission.id).then((response) => {
                          if (response.type == 'ok') {
                            const { subscribed } = response.value.data;
                            setIsSubscribed(subscribed);
                            openSnackBarAction({
                              message: `${subscribed ? 'Subscribed' : 'Unsubscribed'} with success.`,
                              severity: 'success',
                            });
                          }
                        })
                      }
                    >
                      {isSubscribed ? 'Unsubscribe from' : 'Subscribe to'} this discussion
                    </Link>
                  </li>
                </ul>
              </div>
            </>
          </StyledSubmenu>
        </div>

        {/* TEXTAREA NEW MESSAGE */}
        <div className="new-message relative">
          {discussionIsOpen && (
            <>
              <div className={`${selectedNodeId ? 'opacityLayer' : ''}`}></div>
              {/**
               * Textarea that it's showed when you CREATES A NEW MESSSAGE
               */}
              <TextAreaLatex
                value={newMessageBody?.value}
                format={newMessageBody?.format}
                onChange={(data) => setNewMessageBody(data)}
                rows={4}
              />
              <div className="mt-1 text-right">
                <Link
                  className="text-sm cursor-pointer block mb-2"
                  onClick={() => {
                    if (!selectedNodeId) {
                      saveHandler();
                    }
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={faPaperPlane} size="lg" />
                    <span className="pl-2">Send</span>
                  </div>
                </Link>
              </div>
              <div className="text-sm absolute top-1 right-0.5">
                <Link className="cursor-pointer" onClick={() => scrollTo('top')} title="Go to top">
                  <FontAwesomeIcon icon={faSquareCaretUp} size="2xl" />
                </Link>
              </div>
            </>
          )}
          {closeButton}
        </div>
      </StyledDiscussionContent>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectSubmissionState(state).loading,
  submissionsById: selectSubmissionState(state).submissionsById,
  roleById: selectTrackRoles(state),
  user: selectCurrentUser(state),
  timezone: selectCurrentUser(state).person.timezone,
  roleIds: selectRoleRelations(state, 'identity'),
  roleId: selectCurrentRole(state)?.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSubmissionsAction: (data: getSubmissionsRequest) => dispatch(submissionSlice.actions.GET_SUBMISSIONS(data)),
  openSnackBarAction: (data: OpenSnackBarDTO) => dispatch(errorSlice.actions['OPEN:SNACKBAR'](data)),
});

const StyledHr = styled.hr`
  border-bottom: 2px dotted #d2d4d7;
  top: 0;
  width: 100%;
  z-index: 0;
  border-top: 0;
  position: absolute;
`;

const StyledTextAreaLatex = styled.div`
  > div {
    p {
      background-color: transparent !important;
    }
  }
  .kmenu .k-menu:not(.k-context-menu) > .k-item {
    color: #000;
  }
  .kmenu .k-menu-expand-arrow {
    margin: 10px 0 0 0;
  }
  .kmenu .k-i-caret-alt-down {
    display: none;
  }
  .kmenu .k-i-caret-alt-down::before {
    color: #000;
    content: '';
  }
  .kmenu {
    position: absolute;
    top: 10px;
    right: -5px;
  }
`;

const StyledDiscussionContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .discussion {
    flex-grow: 1;
  }

  .textAreaContent {
    background-color: transparent !important;
    padding: 0;
  }

  .textAreaContentWrapper {
    background-color: rgb(229 231 235);
    padding: 1rem;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .new-message {
    position: sticky;
    background-color: #edf3ff;
    bottom: 0;
    z-index: 10;
    width: 100%;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    padding-top: 0.5rem;
  }

  .opacityLayer {
    width: 100%;
    height: 75%;
    opacity: 0.7;
    position: absolute;
    background-color: #edf3ff;
    z-index: 1000;
    top: 0;
    left: 0;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  padding: 0.5rem 0;
  border-top: 1px dashed ${(props) => props.theme.palette.secondary.dark};
`;

const StyledMessage = styled.div`
  margin-bottom: 0.5rem;
  position: relative;
  min-width: 15rem;
`;

const StyledSubmenu = styled.section`
  position: sticky;
  top: 0;
  align-self: flex-start;
  padding-right: 2rem;
  padding-left: 8rem;

  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.75rem;
  }

  ul {
    color: ${(props) => props.theme.palette.text.hint};

    li {
      margin-bottom: 0.5rem;

      a {
        cursor: pointer;
      }
    }
  }

  > ul {
    border-left: 2px solid ${(props) => props.theme.palette.text.hint};
    padding-left: 1rem;

    ul {
      margin-top: 0.5rem;
      padding-left: 1.875rem;
    }
  }
`;

const StyledEditButton = styled.span`
  padding: 0.2rem 0.3rem 0.2rem 0.4rem;
  background-color: rgb(0, 68, 240);
  border-radius: 1rem;
  color: #fff;
`;

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionPage);
