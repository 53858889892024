import React from 'react';
import HiddenIcon from '../../icons/Hidden';
import EditIcon from '../../icons/Edit';
import ViewIcon from '../../icons/View';
import { Role } from '../../store/conference/types';
import { Form, FormVisibilityOption } from '../../store/form/types';
import { capitalizeFirstLetter } from '../../helpers/string';
import useTheme from '@material-ui/core/styles/useTheme';

interface Props {
  role: Role;
  roleById: { [key: string]: Role };
  permissionsData: {
    to_role_id: number | null;
    visibility: FormVisibilityOption;
  };
  form: Form;
  renderIcons?: boolean;
}

const FormRolePermission: React.FC<Props> = ({ role, roleById, permissionsData, form, renderIcons = false }) => {
  const theme = useTheme();
  const { visibility, to_role_id } = permissionsData;
  const roleName = capitalizeFirstLetter(role.description);
  let icon, iconText;
  switch (visibility) {
    case 'read':
      icon = (
        <span className="ml-1.5 mr-1 inline-block mt-0.5 align-top w-5">
          <ViewIcon />
        </span>
      );
      iconText = 'can read-only';
      break;
    case 'write':
      icon = (
        <span className="ml-1.5 mr-1 inline-block mt-0.5 align-top w-5">
          <EditIcon color={theme.palette.primary.main} className="mr-1" />
        </span>
      );
      iconText = 'can read/write';
      break;
    case 'hidden':
      icon = (
        <span className="ml-1.5 mr-1 inline-block mt-0.5 align-top w-5">
          <HiddenIcon color="rgb(156 163 175)" />
        </span>
      );
      iconText = "can't view";
      break;
  }

  const isOwn = to_role_id ? false : true;
  const destRole = to_role_id ? roleById[to_role_id].description : '';

  let owner = '';
  if (form.type == 'Review' && role.type == 'reviewer') {
    owner = isOwn ? ' of his own' : ' from other ';
  } else if (
    (form.type == 'Submission' && role.type == 'reviewer') ||
    (form.type == 'Review' && role.type == 'author')
  ) {
    owner = ' from ';
  }
  return (
    <div className="mb-1 pr-2">
      {renderIcons && icon} {roleName} {iconText} answers {owner}
      {destRole}
      {isOwn ? '' : 's'}
    </div>
  );
};

export default FormRolePermission;
