import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppDispatch } from '../store/store';
import { AppState } from '../store/state';
import { Role } from '../store/conference/types';
import { Form, FormVisibilityOption } from '../store/form/types';
import { selectCurrentUser, selectFormState, selectPhaseState, selectTrackRoles } from '../store/selectors';
import phaseSlice from '../store/phase/slice';
import { Link } from './ui';
import { getUserOffset } from '../helpers/timezone';
import useTheme from '@material-ui/core/styles/useTheme';
import styled, { ThemeProvider } from 'styled-components';
import PhaseSettingsDialog from './dialogs/PhaseSettingsDialog/PhaseSettingsDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import SettingsIcon from '../icons/Settings';
import { AddButton } from './ui/inputs/AddButton/AddButton';
import { OpenSnackBarDTO } from '../store/error/types';
import errorSlice from '../store/error/slice';
import Tooltip from '@material-ui/core/Tooltip';
import SimpleDialog from './dialogs/SimpleDialog/SimpleDialog';
import EventSidePanel from './EditEventSidePanel/EventSidePanel';
import PhaseEventComponent from './PhaseEvent/PhaseEvent';
import ResultingState from './ResultingState/ResultingState';
import SidePanel from './ui/utils/SidePanel/SidePanel';
import { intersection } from '../helpers/set';

/**
 *   // Sort events start date ascending
 * @param a
 * @param b
 */
export function compareEventsFn(a: PhaseEvent, b: PhaseEvent): number {
  // Turn your strings into dates, and then subtract them
  // to get a value that is either negative, positive, or zero.
  const valA = a.starts_at ? new Date(a.starts_at) : Infinity;
  const valB = b.starts_at ? new Date(b.starts_at) : Infinity;
  // @ts-ignore
  return valA - valB;
}

/**
 * Check existence of date range overlapping as specified in https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
 * @param startA
 * @param endA
 * @param startB
 * @param endB
 */
const isOverlap = (startA: Date, endA: Date, startB: Date, endB: Date): boolean => {
  return startA <= endB && endA >= startB;
};

const getEncodedPermissions = (event: PhaseEvent) => {
  const encoded: string[] = [];
  event.actions
    .filter((action) => action.type == 'form_permission_change')
    .forEach((action) => {
      const { form_id, permissions: permissions_ } = action.body.params;
      const permissions = permissions_ as {
        [key: string]: {
          visibility: FormVisibilityOption;
          to_role_id: number | null;
        }[];
      };
      Object.keys(permissions).forEach((fromRoleId) => {
        permissions[fromRoleId].forEach((value) => {
          encoded.push(`${form_id}-${fromRoleId}`);
        });
      });
    });
  return encoded;
};

const computePermissionConflicts = (phase: Phase, phaseEvents: PhaseEvent[]): PermissionConflict[] => {
  /* Group events by phase */
  const allEventsByPhaseId: { [key: string]: PhaseEvent[] } = {};
  phaseEvents.forEach((phaseEvent) => {
    if (!(phaseEvent.phase_id in allEventsByPhaseId)) {
      allEventsByPhaseId[phaseEvent.phase_id] = [];
    }
    allEventsByPhaseId[phaseEvent.phase_id].push(phaseEvent);
  });

  /* Detect which phases overlap in time */
  const startA = allEventsByPhaseId[phase.id].find((event) => event.type == 'start')?.starts_at;
  const endA = allEventsByPhaseId[phase.id].find((event) => event.type == 'end')?.starts_at;
  const overlap = Object.keys(allEventsByPhaseId)
    .filter((phaseId) => parseInt(phaseId) != phase.id)
    .filter((phaseId) => {
      const startB = allEventsByPhaseId[phaseId].find((event) => event.type == 'start')?.starts_at;
      const endB = allEventsByPhaseId[phaseId].find((event) => event.type == 'end')?.starts_at;

      return isOverlap(new Date(startA ?? ''), new Date(endA ?? ''), new Date(startB ?? ''), new Date(endB ?? ''));
    });

  /* Analyze overlapping phases */
  const permissionsA = new Set();
  allEventsByPhaseId[phase.id].map((event) => {
    getEncodedPermissions(event).forEach((permission) => permissionsA.add(permission));
  });
  const conflicts: { [key: string]: number[] } = {};
  overlap.forEach((phaseId) => {
    const permissionsB = new Set();
    allEventsByPhaseId[phaseId].map((event) => {
      getEncodedPermissions(event).forEach((permission) => permissionsB.add(permission));
    });

    // Conflicts are those elements that interest
    intersection(permissionsA, permissionsB).forEach((code) => {
      if (!(code in conflicts)) {
        conflicts[code] = [];
      }
      conflicts[code].push(parseInt(phaseId));
    });
  });

  return Object.keys(conflicts).map((code) => {
    const split = code.split('-');
    return { form_id: parseInt(split[0]), role_id: parseInt(split[1]), phase_ids: conflicts[code] };
  });
};

interface Props {
  phase: Phase;
  eventById: { [key: number]: PhaseEvent };
  actionDefinitions: { [key in PhaseType]: { [key: string]: ActionDefinition } };
  onBack: () => void;
  roleById: { [key: string]: Role };
  formById: { [key: number]: Form };
  timezone: string | null;
  dateFormat: string | null;
  updatePhaseAction: (data: PhaseUpdateDTO) => void;
  createPhaseEventAction: (data: PhaseEventDTO) => void;
  updatePhaseEventAction: (data: PhaseEventDTO) => void;
  deletePhaseEventAction: (id: number) => void;
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
  getPhaseName?: (phaseId: number) => void;
}

const Phase: React.FC<Props> = ({
  phase,
  eventById,
  actionDefinitions,
  onBack,
  roleById,
  formById,
  timezone,
  dateFormat,
  updatePhaseAction,
  createPhaseEventAction,
  updatePhaseEventAction,
  deletePhaseEventAction,
  openSnackBarAction,
  getPhaseName,
}) => {
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);
  const [sidePanel, setSidePanel] = useState<JSX.Element | undefined>(undefined);

  const theme = useTheme();
  const utcOffset = getUserOffset(timezone);

  const events = Object.values(eventById).filter((event) => event.phase_id == phase.id);

  // Sort events ascending
  events.sort(compareEventsFn);

  // Sort all events of all phases ascending
  const allPhasesEvents = Object.values(eventById).sort(compareEventsFn);

  // Compute permission conflicts with other events occurring at the same time
  const conflicts = computePermissionConflicts(phase, allPhasesEvents);

  /*
    RESULTING STATE FOR ACTIONS
  */

  const state: { [key: string]: boolean } = {};

  const computeUpdatedActionsState = (
    initialState: { [key: string]: boolean },
    eventStartsAt: string | null,
  ): { [key: string]: boolean } => {
    const state = { ...initialState };

    // Events sorted until the date of the clicked event
    events
      .filter((data) => data.starts_at && eventStartsAt && data.starts_at <= eventStartsAt)
      .forEach((event) => {
        event.actions.forEach((action: PhaseAction) => {
          const {
            type,
            body: {
              params: { value },
            },
          } = action;

          state[type] = value;
        });
      });

    return state;
  };

  let phaseLongDescription = '';

  switch (phase.type) {
    case 'submission':
      phaseLongDescription = `This is a submission phase. Researchers submit their academic papers for consideration, peer review, and potential inclusion 
        in the conference program. You can add events, edit events and view the resulting state after each event. 
        The past events are marked with a green background.`;
      break;
    case 'bidding':
      phaseLongDescription = `This is a bidding phase. Reviewers place bids and express their interest in reviewing different papers. You can add events, 
        edit events and view the resulting state after each event. The past events are marked with a green background.`;
      break;
    case 'review':
      phaseLongDescription = `This is a review phase. Submitted content is carefully examined and evaluated by reviewers. You can add events, edit 
        events and view the resulting state after each event. The past events are marked with a green background.`;
      break;
    case 'discussion':
      phaseLongDescription = `This is a discussion phase. This phase involves open discussion among reviewers. You can add events, edit events and 
        view the resulting state after each event. The past events are marked with a green background.`;
      break;
    case 'author_notification':
      phaseLongDescription = `This is an author notification phase. Authors receive notifications regarding the status of their submissions. You can add 
        events, edit events and view the resulting state after each event. The past events are marked with a green background.`;
      break;
    case 'custom':
      phaseLongDescription = `This is a personalized phase where custom actions are established. You can add events, edit events and view the 
        resulting state after each event. The past events are marked with a green background.`;
      break;

    default:
      phaseLongDescription = `Here give users a bit of an explanation about what this is. Let's say it simple terms.`;
      break;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="flex h-auto flex-col">
        <div className="pb-4 gap-5 grid grid-cols-1 justify-items-start max-w-5xl">
          <div className="flex mb-2 items-center w-full">
            <button onClick={onBack} className="bg-blue-700 text-white px-1.5 py-0.5 h-6 leading-none">
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>

            <h2 className="font-bold text-xl ml-8">{phase.name}</h2>

            {phase.role_id && (
              <p className="ml-4 mr-2">
                Role: {Object.values(roleById).find((role) => role.id == phase.role_id)?.description}
              </p>
            )}

            <Tooltip title="The UTC offset can be adjusted from the user settings screen." placement="top">
              <StyledDateInfo className="text-sm ml-4 cursor-default">Dates in UTC{utcOffset}</StyledDateInfo>
            </Tooltip>

            <Link
              onClick={() =>
                setDialog(
                  <PhaseSettingsDialog
                    open={true}
                    handleClose={() => setDialog(undefined)}
                    phase={phase}
                    updatePhaseAction={updatePhaseAction}
                  />,
                )
              }
              className="cursor-pointer flex items-center ml-8"
            >
              <SettingsIcon stroke={'#0e4ff0'} />
              <span className="ml-2 font-medium text-sm">Settings</span>
            </Link>
          </div>

          <p className="text-sm max-w-3xl">{phaseLongDescription}</p>
        </div>
      </div>

      <StyledPhaseEventWrapper>
        {events.map((event) => {
          const isEndEvent = event.type == 'end';

          if (event.type == 'start') {
            event = { ...event, name: `Open ${phase.name.toLowerCase()}` };
          } else if (event.type == 'end') {
            event = { ...event, name: `Close ${phase.name.toLowerCase()}` };
          }

          return (
            <div key={event.id}>
              <PhaseEventComponent
                roleById={roleById}
                formById={formById}
                initEvent={event}
                actionDefinitions={actionDefinitions[phase.type]}
                openSnackBarAction={openSnackBarAction}
                onEditClick={() => {
                  /* EDIT EVENT */
                  setSidePanel(
                    <EventSidePanel
                      key={event.id}
                      title="Edit"
                      onClose={() => setSidePanel(undefined)}
                      onSave={updatePhaseEventAction}
                      onDelete={() => {
                        setDialog(
                          <SimpleDialog
                            open={true}
                            handleClose={() => setDialog(undefined)}
                            handleOk={() => deletePhaseEventAction(event.id)}
                            title="Remove event?"
                          >
                            <p className="text-sm mb-2">{`Are you sure you want to remove ${event.name}? This action can't be undone.`}</p>
                          </SimpleDialog>,
                        );
                      }}
                      roleById={roleById}
                      formById={formById}
                      initEvent={event}
                      actionDefinitions={actionDefinitions[phase.type]}
                      utcOffset={utcOffset}
                      dateFormat={dateFormat ? dateFormat : 'M/d/y h:mm a'}
                      phase={phase}
                    />,
                  );
                }}
                onViewResultingClick={() => {
                  /* VIEW RESULTING STATE */
                  setSidePanel(
                    <SidePanel open={true} key={event.id} onClose={() => setSidePanel(undefined)} cancelLabel="Close">
                      <header
                        className="w-full py-3.5 px-8 flex justify-between sticky top-0 z-50"
                        style={{ backgroundColor: '#EFF3FA' }}
                      >
                        <h2 className="font-bold text-xl">Resulting state</h2>
                      </header>
                      <ResultingState
                        roleById={roleById}
                        formById={formById}
                        initEvent={event}
                        actionDefinitions={actionDefinitions[phase.type]}
                        phaseEvents={events}
                        actionsState={computeUpdatedActionsState(state, event.starts_at)}
                        allPhasesEvents={allPhasesEvents}
                      />
                    </SidePanel>,
                  );
                }}
                onConflictClick={(formId) => {
                  /* VIEW CONFLICTS */
                  const infoConflictText: JSX.Element[] = [];

                  // Filter by clicked id conflict form and prepare info text to show in side panel
                  conflicts
                    .filter((formConflict) => formConflict.form_id == formId)
                    .forEach((formConflict) => {
                      const conflictFormName = Object.values(formById).find(
                        (form) => form.id === formConflict.form_id,
                      )?.name;

                      const conflictRoleName = Object.values(roleById).find(
                        (role) => role.id === formConflict.role_id,
                      )?.description;

                      const conflictPhaseNames = formConflict?.phase_ids
                        .map((phasetId) => getPhaseName?.(phasetId))
                        .join(', ');

                      infoConflictText.push(
                        <p className="mb-4 mr-1">
                          The form <span style={{ fontWeight: 500 }}>{conflictFormName}</span> has conflict for role{' '}
                          <span style={{ fontWeight: 500 }}>{conflictRoleName}</span> in this phase
                          {formConflict?.phase_ids.length > 1 ? 's: ' : ': '}
                          <span style={{ fontWeight: 500 }}>{conflictPhaseNames}</span>.
                        </p>,
                      );
                    });

                  /* CONFLICTS PANEL */
                  setSidePanel(
                    <SidePanel open={true} key={event.id} onClose={() => setSidePanel(undefined)} cancelLabel="Close">
                      <header
                        className="w-full py-3.5 px-8 flex justify-between items-center sticky top-0 z-50"
                        style={{ backgroundColor: '#EFF3FA' }}
                      >
                        <h2 className="font-bold text-xl">Form conflicts</h2>

                        <span className="font-semibold px-2 rounded-full text-sm text-black bg-yellow-300">!</span>
                      </header>

                      <div>
                        <div className="mb-4">{infoConflictText}</div>

                        <p className="mb-4 text-xs">
                          This means that there are two or more phases overlapping in time, both modifying permissions
                          for the same form and may cause an inconsistency.
                        </p>

                        <p className="mb-4 text-xs">
                          Please, check the form permissions in the specified phases to avoid unexpected effects.
                        </p>
                      </div>
                    </SidePanel>,
                  );
                }}
                permissionConflicts={conflicts}
              />
              {!isEndEvent && (
                <StyledAddButtonWrapper>
                  <hr className="timeLine" />
                  <div>
                    <AddButton
                      label="Add event"
                      className="font-medium text-sm timeLineState"
                      iconWidth="small-icon"
                      onClick={() => {
                        /* ADD EVENT */
                        setSidePanel(
                          <EventSidePanel
                            key="0"
                            title="Add event"
                            onClose={() => setSidePanel(undefined)}
                            onSave={createPhaseEventAction}
                            roleById={roleById}
                            formById={formById}
                            initEvent={undefined}
                            actionDefinitions={actionDefinitions[phase.type]}
                            utcOffset={utcOffset}
                            dateFormat={dateFormat ? dateFormat : 'M/d/y h:mm a'}
                            phase={phase}
                          />,
                        );
                      }}
                    />
                  </div>
                  <hr className="timeLine" />
                </StyledAddButtonWrapper>
              )}
            </div>
          );
        })}
      </StyledPhaseEventWrapper>

      {dialog}
      {sidePanel}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  eventById: selectPhaseState(state).eventById,
  actionDefinitions: selectPhaseState(state).actionDefinitions,
  roleById: selectTrackRoles(state),
  formById: selectFormState(state).formById,
  timezone: selectCurrentUser(state).person.timezone,
  dateFormat: selectCurrentUser(state).person.date_format,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updatePhaseAction: (data: PhaseUpdateDTO) => dispatch(phaseSlice.actions.UPDATE(data)),
  createPhaseEventAction: (data: PhaseEventDTO) => dispatch(phaseSlice.actions['CREATE:EVENT'](data)),
  updatePhaseEventAction: (data: PhaseEventDTO) => dispatch(phaseSlice.actions['UPDATE:EVENT'](data)),
  deletePhaseEventAction: (id: number) => dispatch(phaseSlice.actions['DELETE:EVENT'](id)),
  openSnackBarAction: (data: OpenSnackBarDTO) => dispatch(errorSlice.actions['OPEN:SNACKBAR'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Phase);

const StyledPhaseEventWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 4rem;
  max-width: 780px;
`;

const StyledDateInfo = styled.div`
  background-color: transparent;
  padding: 0 10px;
  color: #000;
  border-radius: 13px;
  font-size: 0.87rem;
  font-weight: 500;
  border: 1px solid #000;
`;

const StyledAddButtonWrapper = styled.div`
  button {
    margin: 0 auto;

    .plus-icon-wrapper {
      margin-left: 4.4rem;
    }
  }
  svg {
    width: 1.2rem;
  }
  .timeLine {
    border-left-style: dashed;
    border-left-color: #b1c1ea;
    height: 1.5rem;
    margin: 0 auto;
    border-left-width: 2px;
    width: 2px;
  }
  .timeLineEnd {
    margin-bottom: 0;
    height: 1rem;
  }
  .timeLineState {
    margin: 0 auto 0 0.5rem;
  }
  .timeLineEndArrow {
    width: max-content;
    margin: -3px auto 0 auto;
    color: #b1c1ea;
    font-size: 19px;
  }
`;
