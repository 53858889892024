import React from 'react';
import * as ReactDOM from 'react-dom';
import { Link } from '../ui';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { DataResult, State, toODataString } from '@progress/kendo-data-query';
import ActionsCell from '../../components/ActionsCell/ActionsCell';
import EmailDialog from '../../components/dialogs/EmailDialog/EmailDialog';
import { getEmails } from '../../store/email/sagas';
import styled from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';

interface EmailsLoaderProps {
  dataState: State;
  onDataReceived: (emails: DataResult) => void;
}

const EmailsLoader = (props: EmailsLoaderProps) => {
  const lastSuccess = React.useRef<string>('');
  const pending = React.useRef<string>('');

  const requestDataIfNeeded = () => {
    if (pending.current || toODataString(props.dataState) === lastSuccess.current) {
      return;
    }
    pending.current = toODataString(props.dataState);
    getEmails(pending).then((r) => {
      if (r.type === 'ok') {
        const json = r.value;
        lastSuccess.current = pending.current;
        pending.current = '';
        if (toODataString(props.dataState) === lastSuccess.current) {
          props.onDataReceived.call(undefined, {
            data: json.value,
            total: json['@count'],
          });
        } else {
          requestDataIfNeeded();
        }
      }
    });
  };

  requestDataIfNeeded();
  return pending.current ? <LoadingPanel /> : null;
};

const LoadingPanel = () => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );
  const gridContent = document && document.querySelector('.k-grid-content');
  return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
};

interface Props {
  dateFormat: string | null;
}

const EmailHistory: React.FC<Props> = ({ dateFormat }) => {
  const [emails, setEmails] = React.useState<DataResult>({
    data: [],
    total: 0,
  });
  const initialDataState = {
    take: 20,
    skip: 0,
  };
  const [dataState, setDataState] = React.useState<State>(initialDataState);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [emailHtml, setEmailHtml] = React.useState<string | undefined>(undefined);

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const dataReceived = (emails: DataResult) => {
    // Convert dates
    const data = emails.data.map((value) => ({
      ...value,
      sent_at_date: new Date(value.sent_at_date),
      body: (
        <Link
          className="flex items-center cursor-pointer"
          onClick={() => {
            setEmailHtml(value.html);
            setShowDialog(true);
          }}
        >
          View
        </Link>
      ),
    }));
    setEmails({ ...emails, data });
  };
  const theme = useTheme();

  const format = `{0:${dateFormat ? dateFormat.split(' ')[0] : 'dd/MM/y'}}`;

  return (
    <StyledWrapper>
      <Grid
        filterable={true}
        sortable={true}
        pageable={{
          buttonCount: 8,
          pageSizes: [10, 20, 50, 100, 500, 1000],
        }}
        {...dataState}
        data={emails}
        onDataStateChange={dataStateChange}
      >
        {/* Check date format here: https://github.com/telerik/kendo-intl/blob/master/docs/date-formatting/index.md */}
        <Column field="sent_at_date" title="Sent at" filter="date" format={format} />
        <Column field="from_name" title="From name" />
        <Column field="from" title="From address" />
        <Column field="recipient_name" title="Recipient name" />
        <Column field="recipient_address" title="Recipient address" />
        <Column field="subject" title="Subject" />
        <Column field="body" title="Body" cell={ActionsCell} filterable={true} />
        <Column field="token" title="Token" />
      </Grid>
      <EmailsLoader dataState={dataState} onDataReceived={dataReceived} />
      {emailHtml && <EmailDialog show={showDialog} onClose={() => setShowDialog(false)} html={emailHtml} />}
    </StyledWrapper>
  );
};

export default EmailHistory;

const StyledWrapper = styled.div`
  height: 100%;

  .k-grid .k-grid-norecords td,
  .k-grid tr.k-alt td {
    background-color: #fafafa !important;
  }
  .k-grid tr.k-alt:hover td {
    background-color: #e4ebfd !important;
  }
  .k-grid td {
    border-color: #f4f5f9 !important;
    background-color: #fff;
  }
`;
