import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import emailSlice from '../../store/email/slice';
import { TableFriendlyName, updateUserRolesBulkDTO } from '../../store/info/types';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import tableSlice from '../../store/table/slice';
import LabelDialog, { DialogMode, LabelDialogFields } from '../dialogs/LabelDialog/LabelDialog';
import MyGridCore from '../MyGridCore/MyGridCore';
import { gridSettingsProps } from '../MyGridCore/types';
import { TableSettings } from './types';
import { Label } from '../../store/label/types';
import labelSlice from '../../store/label/slice';
import { EmailRecipientType } from '../../store/email/types';
import GridTabLayout from '../../layouts/GridTabLayout';
import ManageLabels from '../ManageLabels/ManageLabels';
import { downloadFile, downloadWithIds, getHref } from '../../helpers/download';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import LabelPicker from '../LabelPicker/LabelPicker';
import { intersection, union } from '../../helpers/set';
import {
  GridCellProps,
  GridColumnProps,
  GridDetailRowProps,
  GridExpandChangeEvent,
  GridFilterCellProps,
} from '@progress/kendo-react-grid';
import LabelsCell from '../MyGridCore/LabelsCell';
import {
  selectCurrentTrack,
  selectCurrentUser,
  selectFormState,
  selectLabelState,
  selectTable,
  selectKeywordState,
} from '../../store/selectors';
import { OpenSnackBarDTO } from '../../store/error/types';
import errorSlice from '../../store/error/slice';
import IdCell from '../IdCell/IdCell';
import { Track } from '../../store/conference/types';
import { getRouteByName } from '../../router/routes';
import { fillRoutePath } from '../../helpers/path';
import { Answer, Form, FormType, Question } from '../../store/form/types';
import { FormTypeEnum } from '../../store/form/types.d';
import { toKendoGridValue } from '../../helpers/form';
import QuestionCell from '../QuestionCell/QuestionCell';
import {
  CustomColumn,
  CustomColumnsUpdate,
  FilterBox,
  TableClearFiltersDTO,
  TableSaveAsSettingsDTO,
  TableDataStateChangeDTO,
  TableDeleteSettingsDTO,
  TableFilterBoxChangeDTO,
  TablePatchSettingsDTO,
  TableSwitchFilterDTO,
} from '../../store/table/types';
import { ImpersonateRequest } from '../../store/auth/types';
import authSlice from '../../store/auth/slice';
import PaperStatusChangeDialog from '../dialogs/PaperStatusChangeDialog/PaperStatusChangeDialog';
import RolesDialog from '../dialogs/RolesDialog/RolesDialog';
import infoSlice from '../../store/info/slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import history from '../../store/history';
import ActionsKmenu from '../ActionsKmenu/ActionsKmenu';
import ColumnSelectionDialog from '../dialogs/ColumnSelectionDialog/ColumnSelectionDialog';
import ExportTableDialog from '../dialogs/ExportTableDialog/ExportTableDialog';
import FileUploadCell from '../FileUploadCell/FileUploadCell';
import styled, { ThemeProvider } from 'styled-components';
import SimpleDialog from '../dialogs/SimpleDialog/SimpleDialog';
import recruitmentSlice from '../../store/recruitment/slice';
import { getter } from '@progress/kendo-react-common';
import InvitationStepper from '../InvitationStepper/InvitationStepper';
import { Dialog } from '@progress/kendo-react-dialogs';
import BodyCell from '../BodyCell/BodyCell';
import { Invitation, RespondInvitationDTO } from '../../store/recruitment/types';
import MarkdownEditor from '../ui/inputs/MarkdownEditor/MarkdownEditor';
import RespondInvitationDialog from '../dialogs/RespondInvitationDialog/RespondInvitationDialog';
import AddPersonDialog from '../dialogs/AddPersonDialog/AddPersonDialog';
import { Action, actionOption, RowAction } from '../types';
import KeywordsCell from '../KeywordsCell/KeywordsCell';
import { Submission } from '../../store/submission/types';
import { ReviewerTableRow } from '../../store/review/types';
import EditAssignmentSubmissionDialog from '../dialogs/EditAssignmentSubmissionDialog/EditAssignmentSubmissionDialog';
import EditAssignmentReviewerDialog from '../dialogs/EditAssignmentReviewerDialog/EditAssignmentReviewerDialog';
import DownloadSubmissionsDialog from '../dialogs/DownloadSubmissionsDialog/DownloadSubmissionsDialog';
import { MultiSelectFilterCell } from '../MultiSelectFilterCell/MultiSelectFilterCell';
import AggregateColumnDialog from '../dialogs/AggregateColumnDialog/AggregateColumnDialog';
import { Kmenu } from '../ui/navigation/Kmenu/Kmenu';
import { faEye, faDatabase, faFilterCircleXmark, faSync, faTableColumns } from '@fortawesome/free-solid-svg-icons';
import { MenuItem, MenuItemLink } from '@progress/kendo-react-layout';
import SaveTableSettingsDialog from '../dialogs/SaveTableSettingsDialog/SaveTableSettingsDialog';
import useTheme from '@material-ui/core/styles/useTheme';
import { renderMenuIconIfApplicable } from '../ActionsKmenu/ActionsKmenu';
import TitleCell from '../TitleCell/TitleCell';
import { getCompositeAnswerId } from '../../helpers/answers';
import ChairDeleteSubmissionDialog from '../dialogs/ChairDeleteSubmissionDialog/ChairDeleteSubmissionDialog';
import { isQuestionColumn } from '../../helpers/table';
import SendEmailDialog from '../dialogs/SendEmailDialog/SendEmailDialog';

export const LabelDefaultColors = {
  backgroundColor: '#E0E0E0',
  textColor: '#232323',
};

const gridForms: { [Property in TableFriendlyName]: { [key: string]: FormType[] } } = {
  users: {
    single: [],
    many: [],
  },
  invitations: {
    single: [],
    many: [],
  },
  submissions: {
    single: [FormTypeEnum.Submission],
    many: [FormTypeEnum.Review],
  },
  reviewers_bids: {
    single: [],
    many: [],
  },
  submissions_bids: {
    single: [FormTypeEnum.Submission],
    many: [FormTypeEnum.Review],
  },
  submissions_to_review: {
    single: [],
    many: [],
  },
  papers_to_reviews: {
    single: [FormTypeEnum.Submission],
    many: [FormTypeEnum.Review],
  },
  reviews_to_papers: {
    single: [],
    many: [],
  },
};

export const DEFAULT_FONT_SIZE = 14;

interface Props {
  inputData: Record<string, unknown>[];
  friendlyName: TableFriendlyName;
  initTableSettings?: TableSettings;
  tablesSettings: { [key: string]: TableSettings };
  otherTablesSettings: { [key: string]: TableSettings[] };
  setGridSettingsAction: (data: TablePatchSettingsDTO) => void;
  resetSettingsAction: (friendlyName: TableFriendlyName) => void;
  saveSettingsAction: (friendlyName: TableFriendlyName) => void;
  saveAsSettingsAction: (data: TableSaveAsSettingsDTO) => void;
  loadSettingsAction: (personalSettingsId: number) => void;
  deleteSettingsAction: (data: TableDeleteSettingsDTO) => void;
  pickLabelAction: (labelsByRowId: { [key: number]: number[] }, tableFriendlyName: TableFriendlyName) => void;
  unpickLabelAction: (recordId: number, labelId: number, tableFriendlyName: TableFriendlyName) => void;
  currentTrack: Track | null;
  createLabelAction: (
    data: LabelDialogFields,
    tableFriendlyName: TableFriendlyName,
    currentTrackId: number,
    rowIds?: number[],
  ) => void;
  updateLabelAction: (labelId: number, data: LabelDialogFields) => void;
  deleteLabelAction: (labelId: number, tableFriendlyName: TableFriendlyName) => void;
  getInfoAction?: () => void;
  labelsById: { [key: number]: Label };
  labelables: { [key: string]: { [key: number]: number[] } };
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
  removeEmailAction: () => void;
  removePreviewAction: () => void;
  questionById: { [key: number]: Question };
  answerById: { [key: string]: Answer };
  formById: { [key: number]: Form };
  dataStateChangeAction: (data: TableDataStateChangeDTO) => void;
  filterBoxChangeAction: (data: TableFilterBoxChangeDTO) => void;
  switchFilterAction: (data: TableSwitchFilterDTO) => void;
  clearFiltersAction: (data: TableClearFiltersDTO) => void;
  impersonateAction: (data: ImpersonateRequest) => void;
  updateUserRolesBulkAction: (data: updateUserRolesBulkDTO) => void;
  initRowActions?: { [key: string]: RowAction[] };
  dateFormat: string | null;
  clearValidationErrorsAction: () => void;
  deleteInvitationsAction: (ids: number[]) => void;
  updateSelectionAction: (data: number[], tableFriendlyName: TableFriendlyName) => void;
  updateExpandedAction: (data: number[], tableFriendlyName: TableFriendlyName) => void;
  clearSelectionAction: () => void;
  respondInvitationAction: (data: RespondInvitationDTO) => void;
  action: actionOption;
  setTableAction: (actionOpt: actionOption) => void;
  detail?: React.ComponentType<GridDetailRowProps> | null;
  selectable?: boolean;
  keywordById: { [key: number]: Keyword };
  customColumnsData: { [key: string]: { [key: number]: { [key: string]: any } } };
  customColumnsById: { [key: string]: CustomColumn };
  initialSelectedRowIds: { [key in TableFriendlyName]: number[] };
  initialExpandedRowIds: { [key in TableFriendlyName]: number[] };
  updateCustomColumnsAction: (data: CustomColumnsUpdate) => void;
}

const MyGridRedux: React.FC<Props> = ({
  inputData,
  friendlyName,
  initTableSettings,
  tablesSettings,
  otherTablesSettings,
  setGridSettingsAction,
  resetSettingsAction,
  saveSettingsAction,
  saveAsSettingsAction,
  loadSettingsAction,
  deleteSettingsAction,
  pickLabelAction,
  unpickLabelAction,
  currentTrack,
  createLabelAction,
  updateLabelAction,
  deleteLabelAction,
  getInfoAction,
  labelsById,
  labelables,
  openSnackBarAction,
  removeEmailAction,
  removePreviewAction,
  questionById,
  answerById,
  formById,
  dataStateChangeAction,
  filterBoxChangeAction,
  switchFilterAction,
  clearFiltersAction,
  impersonateAction,
  updateUserRolesBulkAction,
  initRowActions,
  dateFormat,
  clearValidationErrorsAction,
  deleteInvitationsAction,
  updateSelectionAction,
  updateExpandedAction,
  respondInvitationAction,
  action,
  setTableAction,
  detail,
  selectable,
  keywordById,
  customColumnsData,
  customColumnsById,
  initialSelectedRowIds,
  initialExpandedRowIds,
  updateCustomColumnsAction,
}) => {
  const gridSettingsChange = (gridSettings: gridSettingsProps) => {
    gridSettings.gridColumnProps = gridSettings.gridColumnProps.map((col) => {
      const { cell, ...rest } = col;
      return rest;
    });

    setGridSettingsAction({ gridSettings, friendlyName });
  };

  const _getSelectedRowIds = (selectedState: { [id: string]: boolean | number[] }): number[] => {
    return Object.entries(selectedState)
      .filter(([, selected]) => selected)
      .map(([rowId]) => parseInt(rowId));
  };

  const _getExpandedRowIds = (expandedState: { [id: string]: boolean | number[] }): number[] => {
    return Object.entries(expandedState)
      .filter(([, expanded]) => expanded)
      .map(([rowId]) => parseInt(rowId));
  };

  /**
   * Returns an array containing the IDs of the selected rows in the table.
   */
  const getSelectedRowIds = (): number[] => {
    return _getSelectedRowIds(selectedState);
  };

  const getSelectedRows = (): Record<string, unknown>[] => {
    const rowIds = getSelectedRowIds();
    return allGridData.filter((row) => rowIds.includes(idGetter(row) as number));
  };

  const handleSelectRow = (newState: { [id: string]: boolean | number[] }) => {
    // Update local state...
    setSelectedState(newState);

    // Store selected ids in redux...
    updateSelectionAction(_getSelectedRowIds(newState), friendlyName);
  };

  const handleExpandedRow = (newState: { [id: string]: boolean | number[] }) => {
    // Update local state...
    setExpandedState(newState);

    // Store expanded ids in redux...
    updateExpandedAction(_getExpandedRowIds(newState), friendlyName);
  };

  /* Label button */
  const onClickApplyHandler = (checked: number[], indeterminate: number[]) => {
    const checkedSet = new Set(checked);
    const indeterminateSet = new Set(indeterminate);

    // Compute new label state for each selected row
    const labelsByRowId: { [key: number]: number[] } = {};
    selectedRows.forEach((row) => {
      const labels = row.label_ids as number[];
      const labelSet = new Set(labels);
      const newLabelSet = union(intersection(labelSet, indeterminateSet), checkedSet); // Tricky set operation to fast determinate new labels based on the checkbox selection
      const rowId = labelableIdGetter(row) as number;
      labelsByRowId[rowId] = Array.from(newLabelSet).sort();
    });

    if (Object.keys(labelsByRowId).length > 0) {
      pickLabelAction(labelsByRowId, friendlyName);
    }
  };

  const onClickLabelHandler = (labelId: number) => {
    const labelsByRowId: { [key: number]: number[] } = {};

    selectedRows.forEach((row) => {
      const labels = row.label_ids as number[];
      const labelSet = new Set(labels);

      labelSet.has(labelId) ? labelSet.delete(labelId) : labelSet.add(labelId);

      const newLabelArr = Array.from(labelSet);
      const rowId = labelableIdGetter(row) as number;
      labelsByRowId[rowId] = newLabelArr.sort();
    });

    if (Object.keys(labelsByRowId).length > 0) {
      pickLabelAction(labelsByRowId, friendlyName);
    }
  };

  const handleLabelsPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLabelsPopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLabelDialogOpen = (data: LabelDialogFields, labelId: null | number, mode: DialogMode) => {
    setLabelFields(data);
    setLabelId(labelId);
    setModeLabelDialog(mode);
    setOpenLabelDialog(true);
  };

  const handleLabelDialogClose = () => {
    setOpenLabelDialog(false);
  };

  const handleOnCreateClick = (data: LabelDialogFields) => {
    const set = new Set(selectedRows.map((row: any) => labelableIdGetter(row)));
    currentTrack && createLabelAction(data, friendlyName, currentTrack.id, Array.from(set));
    handleLabelDialogClose();
  };

  const handleOnUpdateClick = (labelId: number, data: LabelDialogFields) => {
    updateLabelAction(labelId, data);
    handleLabelDialogClose();
  };

  const handleOnDeleteClick = (labelId: number) => {
    deleteLabelAction(labelId, friendlyName);
    handleLabelDialogClose();
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    handleExpandedRow({ ...expandedState, [idGetter(event.dataItem)]: event.value });
  };

  const getLabelsCount = () => {
    const labelIds = selectedRows.map((row) => row.label_ids) as number[][];

    // Count occurrences of labels across rows
    const counts: { [key: number]: number } = {};
    labelIds.forEach((labels) => {
      for (const labelId of labels) {
        if (!(labelId in counts)) counts[labelId] = 0;
        counts[labelId]++;
      }
    });

    return counts;
  };

  const parseQuestionId = (fieldId: string): number => {
    return parseInt(fieldId.split('_')[1]);
  };

  /**
   * Decides the component to render in a cell of a grid based on the fieldId
   * @param fieldId
   */
  const getCustomCell = (fieldId: string): Function | undefined => {
    let MyCustomCell;

    if (fieldId === 'labels') {
      MyCustomCell = (props: GridCellProps) => (
        <LabelsCell
          {...props}
          unpickLabelAction={unpickLabelAction}
          labelsById={labelsById}
          tableFriendlyName={friendlyName}
          labelableIdKey={gridSettings.LABELABLE_ITEM_KEY}
        />
      );
    } else if (fieldId == 'title') {
      if (['submissions', 'submissions_to_review', 'papers_to_reviews', 'submissions_bids'].includes(friendlyName)) {
        MyCustomCell = (props: GridCellProps) => <TitleCell {...props} />;
      }
    } else if (
      fieldId == 'id' &&
      ['submissions', 'submissions_to_review', 'papers_to_reviews', 'submissions_bids'].includes(friendlyName)
    ) {
      MyCustomCell = (props: GridCellProps) => (
        <IdCell
          {...props}
          handleOnClick={() => {
            if (
              ['submissions', 'submissions_to_review', 'papers_to_reviews', 'submissions_bids'].includes(friendlyName)
            ) {
              handleSelectRow({ [idGetter(props.dataItem)]: true });
              history.push(
                fillRoutePath(getRouteByName('RouteDetailSubmission').path, {
                  id: props.dataItem[props.field || ''], // This is the external id
                }),
              );
            }
          }}
        />
      );
    } else if (fieldId == 'file_upload') {
      MyCustomCell = (props: GridCellProps) => <FileUploadCell {...props} />;
    } else if (friendlyName === 'invitations' && fieldId === 'body') {
      MyCustomCell = (props: GridCellProps) => (
        <BodyCell
          {...props}
          handleViewClick={(rowId) => {
            const row = allGridData.find((row) => rowId == idGetter(row)) as unknown as Invitation;
            setDialog(
              <SimpleDialog title="Body preview" open={true} handleClose={() => setDialog(undefined)}>
                <StyledSimpleDialog>
                  <MarkdownEditor defaultValue={row.body} editable={false} />
                </StyledSimpleDialog>
              </SimpleDialog>,
            );
          }}
        />
      );
    } else if (isQuestionColumn(fieldId)) {
      MyCustomCell = (props: GridCellProps) => (
        <QuestionCell
          {...props}
          questionId={parseQuestionId(fieldId)}
          questionById={questionById}
          answerById={answerById}
        />
      );
    } else if (fieldId == 'keywords') {
      MyCustomCell = (props: GridCellProps) => <KeywordsCell {...props} keywordById={keywordById} />;
    }
    return MyCustomCell;
  };

  const getCustomFilterCell = (columnProps: GridColumnProps) => {
    const colId = columnProps.id ?? '';
    /* Determine whether we need to use a custom filter cell */
    let filterCell;
    if (
      [
        'labels',
        'main_area',
        'validation_status',
        'paper_status',
        'keywords',
        'roles',
        'role',
        'country',
        'status',
      ].includes(colId) ||
      (isQuestionColumn(colId) &&
        ['Select', 'MultiSelect', 'LikertScale'].includes(questionById[parseQuestionId(colId)].type))
    ) {
      filterCell = (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={allGridData} />;
    }
    return filterCell;
  };

  function hasSelectedRows(): boolean {
    if (selectedRows.length > 0) {
      return true;
    } else {
      openSnackBarAction({ message: 'You need to select at least one row', severity: 'warning' });
      return false;
    }
  }

  /**
   * Determines whether selected rows are applicable to the target action
   * @param actionOption
   */
  function areSelectedRowsApplicable(actionOption: actionOption): boolean {
    for (const row of getSelectedRows()) {
      const actions = getRowActions(row).map((action) => action.id);
      if (!actions.includes(actionOption)) {
        openSnackBarAction({
          message:
            actionOption == 'send-email:invitation'
              ? "You can't resend invitations that are already responded. Please unselect those rows that are either Accepted or Declined."
              : 'There are selected rows that are not applicable for this action',
          severity: 'warning',
        });
        return false;
      }
    }
    return true;
  }

  const getBulkActions = (): Action[] => {
    return bulkActions[friendlyName] || [];
  };

  const getRowActions = (newRegister: Record<string, unknown>): RowAction[] => {
    let rowActionsForTable = rowActions[friendlyName] ?? [];

    if (initRowActions) {
      rowActionsForTable = rowActionsForTable.concat(initRowActions[idGetter(newRegister)] ?? []);
    }

    switch (friendlyName) {
      case 'invitations':
        const row = newRegister as unknown as Invitation;
        return rowActionsForTable.filter((rowAction) => {
          let filter = true;
          // Skip resend, respond and delete actions if invitation is already responded.
          if (
            ['send-email:invitation', 'respond:invitation', 'delete:invitation'].includes(rowAction.id) &&
            ['Accepted', 'Declined'].includes(row.status)
          ) {
            filter = false;
          }
          return filter;
        });
      default:
        return rowActionsForTable;
    }
  };

  const addLabels = (newRegister: Record<string, unknown>) => {
    if (hasLabelsColumn) {
      const modelClass = tableSettings.model;
      // @ts-ignore
      newRegister.label_ids = labelables[modelClass][labelableIdGetter(newRegister)] ?? [];
      // @ts-ignore
      newRegister.labels = newRegister.label_ids.map((id) => labelsByIdForTable[id].label).join(';');
    }
  };

  const initData = () => {
    /* Initialize form questions */
    const questionColumnProps = gridSettings.gridColumnProps.filter((e) => isQuestionColumn(e.id));

    /* Arrange data for Kendo Grid */
    const newData: Record<string, unknown>[] = [];
    inputData.forEach((register, index) => {
      let newRegister = { ...register };

      /* Initialize labels */
      addLabels(newRegister);

      /* Set selected to false */
      newRegister[gridSettings.SELECTED_FIELD] = false;
      newRegister['table_friendly_name'] = friendlyName;

      /* Initialize row actions fields */
      newRegister['row_actions'] = (
        <div>
          <ActionsKmenu actions={getRowActions(newRegister)} record={newRegister} index={index.toString()} />
        </div>
      );

      /* Create form questions fields */
      questionColumnProps.forEach((columnProp) => {
        const questionId = parseQuestionId(columnProp.id);
        const question = questionById[questionId];

        const id = getCompositeAnswerId(question.id, idGetter(newRegister));
        const answer = answerById[id];
        newRegister[columnProp.field] = answer ? toKendoGridValue(question, answer) : undefined;
      });

      /* Add custom columns fields */
      if (customColumnsData[friendlyName]) {
        const customColumns = customColumnsData[friendlyName][idGetter(newRegister)] ?? {};
        newRegister = { ...newRegister, ...customColumns };
      }

      newData.push(newRegister);
    });

    return newData;
  };

  const initSelectedState = () => {
    const selectedFromRedux: { [key: string]: boolean } = {};

    Object.values(initialSelectedRowIds[friendlyName]).forEach((id) => {
      selectedFromRedux[id.toString()] = true;
    });

    return selectedFromRedux;
  };

  const initExpandedState = () => {
    const expandedFromRedux: { [key: string]: boolean } = {};

    Object.values(initialExpandedRowIds[friendlyName]).forEach((id) => {
      expandedFromRedux[id.toString()] = true;
    });

    return expandedFromRedux;
  };

  const getSendEmailDialog = (action: actionOption) => {
    let dialogTitle = '';
    switch (action) {
      case 'send-email:author':
        dialogTitle = 'Send email to authors';
        break;
      case 'send-email:contact_author':
        dialogTitle = 'Send email to contact authors';
        break;
      case 'send-email:people':
        dialogTitle = 'Send email to people';
        break;
    }
    const rowIds: number[] = selectedRowIds;
    const recipientType: EmailRecipientType = action.split(':')[1] as EmailRecipientType;

    return (
      <SendEmailDialog
        modelIds={rowIds}
        recipientType={recipientType}
        title={dialogTitle}
        handleClose={() => {
          setTableAction('');
          setDialog(undefined);
        }}
      />
    );
  };

  const getAssignmentsBidsDialog = (rowId: number) => {
    const row = allGridData.find((row) => rowId == idGetter(row));
    let dialog;
    if (friendlyName == 'papers_to_reviews' || friendlyName == 'submissions_bids') {
      const submission = row as unknown as Submission;
      if (submission) {
        dialog = (
          <EditAssignmentSubmissionDialog
            submission={submission}
            outerFriendlyName={friendlyName}
            handleClose={() => setDialog(undefined)}
          />
        );
      }
    } else if (friendlyName == 'reviews_to_papers' || friendlyName == 'reviewers_bids') {
      const reviewer = row as unknown as ReviewerTableRow;
      if (reviewer) {
        dialog = (
          <EditAssignmentReviewerDialog
            reviewer={reviewer}
            outerFriendlyName={friendlyName}
            handleClose={() => setDialog(undefined)}
          />
        );
      }
    }
    return dialog;
  };

  const getExportDialog = () => {
    return (
      <ExportTableDialog
        open={true}
        handleClose={() => setDialog(undefined)}
        handleOk={(columnsOption, rowsOption) => {
          const ids = rowsOption == 'selected' ? getSelectedRowIds() : allGridData.map((row) => idGetter(row));
          downloadWithIds(ids, `/tables/${friendlyName}/export?columns=${columnsOption}`);
        }}
        title="Export data"
        closeOnOk={true}
        numSelected={selectedRowIds.length}
      />
    );
  };

  const getMenuItems = (): JSX.Element[] => {
    // Get current grid settings
    const currentSettings = tableSettings;

    // Get the others
    const restSettings = otherTablesSettings[friendlyName] ?? [];

    // Compute columns menu items
    const menuItems = restSettings.map((value, index) => (
      <MenuItem
        key={index}
        text={value.name}
        cssStyle={value.name == currentSettings.name ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}
      />
    ));
    menuItems.push(
      <MenuItem
        key={menuItems.length}
        text=""
        disabled={true}
        cssStyle={{ borderTop: '1px dashed #707073', marginTop: '5px' }}
      />,
    );
    /* Here start the actions */
    if (currentSettings.touched) {
      menuItems.push(
        <MenuItem
          key={menuItems.length}
          text={`Restore '${currentSettings.name}'`}
          cssStyle={{ fontSize: '0.75rem' }}
        />,
      );
    }
    const source = restSettings.find((value) => value.id == currentSettings.source_id);
    if (source && !source.read_only && currentSettings.touched) {
      menuItems.push(<MenuItem key={menuItems.length} text="Save" cssStyle={{ fontSize: '0.75rem' }} />);
    }
    menuItems.push(<MenuItem key={menuItems.length} text="Save as" cssStyle={{ fontSize: '0.75rem' }} />);
    if (source && !source.read_only) {
      menuItems.push(
        <MenuItem
          key={menuItems.length}
          text={`Delete '${currentSettings.name}'`}
          cssStyle={{ fontSize: '0.75rem' }}
        />,
      );
    }
    return menuItems;
  };

  const resizeFont = (action: 'increase' | 'decrease' | 'reset') => {
    let gridSettingsProps: gridSettingsProps;
    switch (action) {
      case 'increase':
        gridSettingsProps = { ...gridSettings, fontSize: gridSettings.fontSize + 1 };
        break;
      case 'decrease':
        gridSettingsProps = { ...gridSettings, fontSize: gridSettings.fontSize - 1 };
        break;
      case 'reset':
        gridSettingsProps = { ...gridSettings, fontSize: DEFAULT_FONT_SIZE };
        break;
    }
    gridSettingsChange(gridSettingsProps);
  };

  /**
   * Compute whether filters currently used have changed with respect to the filters in redux
   */
  const haveFiltersChanged = (): boolean => {
    const current = dataState.filter;
    const original = gridSettings.dataState.filter;
    return JSON.stringify(current) !== JSON.stringify(original);
  };

  // Initialize gridSettings...
  const tableSettings = initTableSettings ?? tablesSettings[friendlyName];
  const { gridSettings } = tableSettings;
  const { dataState, filterBox } = tableSettings;

  const idGetter = getter(gridSettings.DATA_ITEM_KEY);
  const labelableIdGetter = getter(gridSettings.LABELABLE_ITEM_KEY);

  /* Initialize labels things... */
  const hasLabelsColumn = gridSettings.gridColumnProps.find((e) => e.id === 'labels') !== undefined;
  const labelsByIdForTable: { [key: number]: Label } = {};
  if (hasLabelsColumn) {
    Object.values(labelsById)
      .filter((label) => label.model === tableSettings.model)
      .forEach((label) => {
        labelsByIdForTable[label.key] = label;
      });
  }

  /* Define table bulk actions */
  const bulkActions: { [key: string]: Action[] } = {
    submissions: [
      {
        id: 'download-submissions',
        label: 'Download submissions',
        callback: () => {
          if (hasSelectedRows()) {
            setDialog(
              <DownloadSubmissionsDialog
                open={true}
                handleClose={() => setDialog(undefined)}
                handleOk={(uploadOptions: number[]) => {
                  const queryString = uploadOptions
                    .map((uploadOptions) => `upload_options[]=${uploadOptions}`)
                    .join('&');
                  downloadWithIds(selectedRowIds, `/submissions/download?${queryString}`);
                }}
              />,
            );
          }
        },
      },
      {
        id: 'send-email:author',
        label: 'Send email to authors',
        callback: () => {
          if (hasSelectedRows()) {
            setDialog(getSendEmailDialog('send-email:author'));
          }
        },
      },
      {
        id: 'send-email:contact_author',
        label: 'Send email to contact authors',
        callback: () => {
          if (hasSelectedRows()) {
            setDialog(getSendEmailDialog('send-email:contact_author'));
          }
        },
      },
      {
        id: 'edit-paper-status',
        label: 'Edit paper status',
        callback: () => {
          if (hasSelectedRows()) {
            const rows = getSelectedRows();
            setDialog(
              <PaperStatusChangeDialog
                initialOption={rows.length == 1 ? (rows[0].paper_status_id as string) : undefined}
                submissionIds={getSelectedRowIds()}
                open={true}
                handleClose={() => setDialog(undefined)}
              />,
            );
          }
        },
      },
      {
        id: 'export',
        label: 'Export',
        callback: () => {
          setDialog(getExportDialog());
        },
      },
    ],
    submissions_to_review: [],
    users: [
      {
        id: 'send-email:people',
        label: 'Send email',
        callback: () => {
          if (hasSelectedRows()) {
            setDialog(getSendEmailDialog('send-email:people'));
          }
        },
      },
      {
        id: 'export',
        label: 'Export',
        callback: () => {
          setDialog(getExportDialog());
        },
      },
      {
        id: 'add-person',
        label: 'Add person',
        callback: () => {
          setDialog(
            <AddPersonDialog
              handleClose={() => {
                setDialog(undefined);
                clearValidationErrorsAction();
              }}
            />,
          );
        },
      },
    ],
    invitations: [
      {
        id: 'send-email:invitation',
        label: 'Resend invitation',
        callback: () => {
          if (hasSelectedRows() && areSelectedRowsApplicable('send-email:invitation')) {
            // TO DO: use SimpleDialog instead of Dialog?
            setDialog(
              <StyledDialog className="custom-dialog">
                <InvitationStepper
                  initType="ids"
                  initInput={selectedRowIds}
                  initStep={1}
                  onClose={(formState) => {
                    removeEmailAction();
                    removePreviewAction();
                    clearValidationErrorsAction();
                    setDialog(undefined);
                    if (formState == 'SENDING') {
                      getInfoAction?.(); // Refresh data
                    }
                  }}
                />
              </StyledDialog>,
            );
          }
        },
      },
    ],
    reviewers_bids: [
      {
        id: 'export',
        label: 'Export bids',
        callback: () => {
          downloadFile(getHref('/bids/export'));
        },
      },
    ],
    submissions_bids: [
      {
        id: 'export',
        label: 'Export bids',
        callback: () => {
          downloadFile(getHref('/bids/export'));
        },
      },
    ],
  };
  const bulkActionsForTable = getBulkActions();

  /* Define row actions */
  const rowActions: { [key: string]: RowAction[] } = {
    users: [
      {
        id: 'impersonate',
        label: 'Impersonate',
        rowIdKey: 'user_id',
        callback: (rowId) => impersonateAction({ user_id: rowId }),
      },
      {
        id: 'edit-roles',
        label: 'Edit roles',
        rowIdKey: 'id',
        callback: (rowId) => {
          const rows = allGridData.filter((row) => rowId == idGetter(row));
          let initialOption: string[];
          if (rows.length == 1) {
            const roleIds = rows[0].role_ids as number[];
            initialOption = roleIds.map((value) => value.toString());
          } else {
            initialOption = [];
          }
          setDialog(
            <RolesDialog
              title="Edit roles"
              initialOption={initialOption}
              open={true}
              handleClose={() => setDialog(undefined)}
              doOkClick={(selectedOption: string[]) => {
                const data: { [key: number]: number[] } = {};
                [rowId].forEach((personId) => {
                  data[personId] = selectedOption.map((value) => parseInt(value));
                });
                updateUserRolesBulkAction({ data });
              }}
            />,
          );
        },
      },
    ],
    submissions: [
      {
        id: 'view-submission',
        label: 'View submission',
        rowIdKey: 'id',
        callback: (rowId) => {
          handleSelectRow({ [rowId]: true });
          const selectedSubmission = Object.values(inputData).find(({ id }) => id === rowId) as unknown as Submission;
          selectedSubmission &&
            history.push(
              fillRoutePath(getRouteByName('RouteDetailSubmission').path, {
                id: selectedSubmission.external_id.toString(),
              }),
            );
        },
      },
      {
        id: 'edit-submission',
        label: 'Edit submission',
        rowIdKey: 'id',
        callback: (rowId) => {
          handleSelectRow({ [rowId]: true });
          const selectedSubmission = Object.values(inputData).find(({ id }) => id === rowId) as unknown as Submission;
          selectedSubmission &&
            history.push(
              fillRoutePath(getRouteByName('RouteEditSubmission').path, {
                id: selectedSubmission.external_id.toString(),
              }),
            );
        },
      },
      {
        id: 'delete-submission',
        label: 'Delete submission',
        rowIdKey: 'id',
        callback: (rowId) => {
          const selectedSubmission = Object.values(inputData).find(({ id }) => id === rowId) as unknown as Submission;
          selectedSubmission &&
            setDialog(
              <ChairDeleteSubmissionDialog
                submission={selectedSubmission}
                open={true}
                handleClose={() => setDialog(undefined)}
              />,
            );
        },
      },
      {
        id: 'edit-paper-status',
        label: 'Edit paper status',
        rowIdKey: 'id',
        callback: (rowId) => {
          const rows = allGridData.filter((row) => rowId == idGetter(row));
          setDialog(
            <PaperStatusChangeDialog
              initialOption={rows.length == 1 ? (rows[0].paper_status_id as string) : undefined}
              submissionIds={[rowId]}
              open={true}
              handleClose={() => setDialog(undefined)}
            />,
          );
        },
      },
    ],
    submissions_to_review: [],
    invitations: [
      {
        id: 'send-email:invitation',
        label: 'Resend invitation',
        rowIdKey: 'id',
        callback: (rowId) => {
          const rowIds = [rowId];
          // TO DO: use SimpleDialog instead of Dialog?
          setDialog(
            <StyledDialog className="custom-dialog">
              <InvitationStepper
                initType="ids"
                initInput={rowIds}
                initStep={1}
                onClose={(formState) => {
                  removeEmailAction();
                  removePreviewAction();
                  clearValidationErrorsAction();
                  setDialog(undefined);
                  if (formState == 'SENDING') {
                    getInfoAction?.(); // Refresh data
                  }
                }}
              />
            </StyledDialog>,
          );
        },
      },
      {
        id: 'delete:invitation',
        label: 'Delete invitation',
        rowIdKey: 'id',
        callback: (rowId) => {
          setDialog(
            <SimpleDialog
              open={true}
              handleClose={() => setDialog(undefined)}
              handleOk={() => deleteInvitationsAction([rowId])}
              title="Delete invitation?"
            >
              <p>
                Are you sure you want to remove the current invitation? Take into account that this action can't be
                undone.
              </p>
            </SimpleDialog>,
          );
        },
      },
      {
        id: 'respond:invitation',
        label: 'Respond invitation',
        rowIdKey: 'id',
        callback: (rowId) => {
          setDialog(
            <RespondInvitationDialog
              open={true}
              handleClose={() => setDialog(undefined)}
              handleOk={(data) => respondInvitationAction({ ...data, id: rowId })}
            />,
          );
        },
      },
    ],
    papers_to_reviews: [
      {
        id: 'edit-assignments',
        label: 'Edit assignments',
        rowIdKey: 'id',
        callback: (rowId) => {
          setDialog(getAssignmentsBidsDialog(rowId));
        },
      },
    ],
    reviews_to_papers: [
      {
        id: 'edit-assignments',
        label: 'Edit assignments',
        rowIdKey: 'person_role_id',
        callback: (rowId) => {
          setDialog(getAssignmentsBidsDialog(rowId));
        },
      },
    ],
    reviewers_bids: [
      {
        id: 'view-bids',
        label: 'View bids',
        rowIdKey: 'person_role_id',
        callback: (rowId) => {
          setDialog(getAssignmentsBidsDialog(rowId));
        },
      },
    ],
    submissions_bids: [
      {
        id: 'view-bids',
        label: 'View bids',
        rowIdKey: 'id',
        callback: (rowId) => {
          setDialog(getAssignmentsBidsDialog(rowId));
        },
      },
    ],
  };

  // useState hooks
  const [selectedState, setSelectedState] = React.useState<{
    [id: string]: boolean | number[];
  }>(() => initSelectedState()); // Stores which rows are selected
  const [expandedState, setExpandedState] = React.useState<{
    [id: string]: boolean | number[];
  }>(() => initExpandedState()); // Stores which rows are expanded

  /* labels button */
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openLabelDialog, setOpenLabelDialog] = React.useState(false);
  const [labelId, setLabelId] = React.useState<number | null>(null);
  const [labelFields, setLabelFields] = React.useState<LabelDialogFields>({
    backgroundColor: '',
    name: '',
    textColor: '',
  });
  const [modeLabelDialog, setModeLabelDialog] = React.useState<DialogMode>('create');

  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);

  /* Here we'll make use of useMemo so that we can cache the data initialization process result as it can be time-consuming when data volume increases to thousands of records.
  This process will be re-triggered whenever row labels, aggregated columns or input data changes.
  */
  const allGridData = useMemo(() => initData(), [labelables, customColumnsData[friendlyName], inputData]);

  const selectedRows = getSelectedRows();
  const selectedRowIds: number[] = selectedRows.map((row) => idGetter(row));

  const theme = useTheme();

  /* Initialize labels things... */
  let labelDialog;
  let popLabel;
  if (hasLabelsColumn) {
    labelDialog = (
      <LabelDialog
        open={openLabelDialog}
        onClose={handleLabelDialogClose}
        onCreate={handleOnCreateClick}
        onUpdate={handleOnUpdateClick}
        onDelete={handleOnDeleteClick}
        labelId={labelId}
        initLabelFields={labelFields}
        mode={modeLabelDialog}
      />
    );

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    popLabel = (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleLabelsPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <LabelPicker
          allLabels={Object.values(labelsByIdForTable)}
          getCountsByLabelId={getLabelsCount}
          onClickApply={onClickApplyHandler}
          closePopOver={handleLabelsPopoverClose}
          numSelectedRows={selectedRowIds.length}
          openCreateLabelDialog={handleLabelDialogOpen}
          onManageClick={() => setTableAction('manage-labels')}
          onClickLabel={onClickLabelHandler}
        />
      </Popover>
    );
  }

  switch (action) {
    case 'manage-labels':
      return (
        <GridTabLayout onBackClick={() => setTableAction('')} backButton={false}>
          <ManageLabels
            labelsById={labelsByIdForTable}
            openLabelDialog={handleLabelDialogOpen}
            onBackClick={() => setTableAction('')}
          />
          {labelDialog}
        </GridTabLayout>
      );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="h-full flex flex-col">
          <div className="flex w-full rounded-lg mb-4 justify-between">
            {/* TOOLBOX 1 */}
            <StyledTools className="flex items-center" style={{ flexGrow: 1 }}>
              {/* CONFIGS */}
              <div className="tool-box rounded-md mr-2 p-1 flex">
                <div className="flex pl-1.5 items-center">
                  <FontAwesomeIcon icon={faEye} className="text-white" />
                  <div className="ml-2 text-sm text-white">Selected view</div>
                </div>
                <StyledKmenu
                  onSelect={(event) => {
                    if (!event.item.text) return;
                    const itemName = event.item.text.split("'")[0].trim(); // Parses something like "Delete 'No ID'" => "Delete"
                    switch (itemName) {
                      case 'Restore':
                        resetSettingsAction(friendlyName);
                        break;
                      case 'Save':
                        saveSettingsAction(friendlyName);
                        break;
                      case 'Save as':
                        setDialog(
                          <SaveTableSettingsDialog
                            open={true}
                            handleClose={() => setDialog(undefined)}
                            onSaveAs={(name: string) =>
                              saveAsSettingsAction({
                                friendlyName,
                                name,
                                gridSettings: {
                                  ...gridSettings,
                                  dataState: { filter: dataState.filter },
                                },
                              })
                            }
                          />,
                        );
                        break;
                      case 'Delete':
                        deleteSettingsAction({ friendlyName });
                        break;
                      default:
                        // case Load
                        const ts = (otherTablesSettings[friendlyName] ?? []).find(
                          (value) => value.name == event.item.text,
                        );
                        if (ts && ts.name != tableSettings.name) {
                          loadSettingsAction(ts.id);
                        }
                        break;
                    }
                  }}
                >
                  <MenuItem text={tableSettings.touched ? tableSettings.name + ' (modified)' : tableSettings.name}>
                    {getMenuItems()}
                  </MenuItem>
                </StyledKmenu>
              </div>

              {/* SYNC */}
              {getInfoAction && (
                <StyledButtonWrapper>
                  <Button
                    variant="outlined"
                    aria-label="sync table"
                    onClick={() => {
                      getInfoAction();
                    }}
                    startIcon={<FontAwesomeIcon icon={faSync} />}
                    disableRipple
                    style={{ backgroundColor: '#ffffff', borderColor: '#ffffff' }}
                  >
                    Refresh
                  </Button>
                </StyledButtonWrapper>
              )}
            </StyledTools>

            {/* TOOLBOX 2 */}
            <StyledTools className="flex">
              {/* FONT RESIZE */}
              <div className="flex items-center">
                <span style={{ color: '#0044F0', fontWeight: 600, display: 'flex', alignItems: 'center' }}>Aa</span>
                <button
                  onClick={() => resizeFont('reset')}
                  className="px-1 py-0.5 border rounded-sm text-md leading-none ml-2 hover:opacity-70"
                  style={{ color: '#0044F0', borderColor: '#0044F0' }}
                >
                  <FontAwesomeIcon icon={faSync} style={{ fontSize: '12px' }} />
                </button>
                <button
                  onClick={() => resizeFont('decrease')}
                  className="px-2 py-0.5 border rounded-sm text-md leading-none ml-2 hover:opacity-70"
                  style={{ color: '#0044F0', borderColor: '#0044F0' }}
                >
                  -
                </button>
                <button
                  onClick={() => resizeFont('increase')}
                  className="px-1.5 py-0.5 border rounded-sm text-md leading-none ml-2 hover:opacity-70"
                  style={{ color: '#0044F0', borderColor: '#0044F0' }}
                >
                  +
                </button>
              </div>

              {/* MANAGE COLUMNS */}
              <StyledButtonWrapper>
                <Button
                  variant="outlined"
                  aria-label="manage columns table"
                  onClick={() =>
                    setDialog(
                      <ColumnSelectionDialog
                        show={true}
                        tablesSettings={[tableSettings].concat(otherTablesSettings[friendlyName] ?? [])}
                        onGridSettingsChange={gridSettingsChange}
                        onClose={() => setDialog(undefined)}
                        questions={Object.values(formById)
                          .filter((form) => gridForms[friendlyName].many.includes(form.type))
                          .map((form) => Object.values(questionById).filter((question) => question.form_id == form.id))
                          .flat()}
                      />,
                    )
                  }
                  startIcon={<FontAwesomeIcon icon={faTableColumns} />}
                  disableRipple
                >
                  Manage columns
                </Button>
              </StyledButtonWrapper>

              {/* AGGREGATE COLUMNS */}
              {gridForms[friendlyName].many.length > 0 && (
                <StyledButtonWrapper>
                  <Button
                    variant="outlined"
                    aria-label="aggregate columns"
                    onClick={() => {
                      const forms = Object.values(formById).filter((form) =>
                        gridForms[friendlyName].many.includes(form.type),
                      );
                      setDialog(
                        <AggregateColumnDialog
                          onClose={() => setDialog(undefined)}
                          forms={forms}
                          questions={forms
                            .map((form) =>
                              Object.values(questionById).filter((question) => question.form_id == form.id),
                            )
                            .flat()}
                          initCustomColumns={Object.values(customColumnsById).filter(
                            (customColumn) => customColumn.tableFriendlyName == friendlyName,
                          )}
                          handleOk={(customColumns) => {
                            updateCustomColumnsAction({ friendlyName: friendlyName, customColumns });
                          }}
                        />,
                      );
                    }}
                    startIcon={<FontAwesomeIcon icon={faDatabase} />}
                    disableRipple
                  >
                    Aggregate
                  </Button>
                </StyledButtonWrapper>
              )}

              {/* LABELS */}
              {hasLabelsColumn && (
                <StyledButtonWrapper>
                  <Button
                    variant="outlined"
                    aria-label="add"
                    onClick={handleLabelsPopoverClick}
                    startIcon={<FontAwesomeIcon icon={faTags} />}
                    disableRipple
                  >
                    Labels
                  </Button>
                </StyledButtonWrapper>
              )}

              {/* ADVANCED FILTER */}
              {/*  
              <StyledButtonWrapper>
                <Button
                  variant="outlined"
                  aria-label="switch filters"
                  onClick={() => onSwitchFilters()}
                  startIcon={<FontAwesomeIcon icon={faFilter} />}
                  disableRipple
                >
                  Advanced Filter
                </Button>
              </StyledButtonWrapper>
              */}

              {/* RESET FILTERS */}
              <StyledButtonWrapper>
                <Button
                  variant="outlined"
                  aria-label="reset filters"
                  onClick={() => clearFiltersAction({ friendlyName })}
                  startIcon={<FontAwesomeIcon icon={faFilterCircleXmark} />}
                  disableRipple
                  disabled={!haveFiltersChanged()}
                >
                  Reset Filters
                </Button>
              </StyledButtonWrapper>
            </StyledTools>

            {bulkActionsForTable.length > 0 && (
              <StyledActions className="flex items-center justify-end">
                <div className="bulk-actions ml-2.5">
                  <Kmenu
                    linkRender={(props: any) => {
                      return (
                        <MenuItemLink url={props.item.url} opened={props.opened}>
                          {[renderMenuIconIfApplicable(props, 'bulkActionsForTable'), props.item.text]}
                        </MenuItemLink>
                      );
                    }}
                    items={[
                      {
                        //text: 'Actions',
                        icon: 'more-vertical',
                        items: bulkActionsForTable.map((x) => {
                          return { text: x.label };
                        }),
                      },
                    ]}
                    onSelect={(event) => {
                      const action = bulkActionsForTable.find((x) => x.label == event.item.text);
                      if (action && action.callback) {
                        action.callback();
                      }
                    }}
                  />
                </div>
              </StyledActions>
            )}
          </div>

          <MyGridCore
            allGridData={allGridData}
            gridSettings={{
              /* Here we decide if the column must be rendered with a custom components.*/
              ...gridSettings,
              gridColumnProps: gridSettings.gridColumnProps.map((props) => ({
                ...props,
                cell: props.id ? getCustomCell(props.id) : undefined,
                filterCell: getCustomFilterCell(props),
              })),
            }}
            onGridSettingsChange={gridSettingsChange}
            dataStateValue={dataState}
            filterBoxValue={filterBox}
            selectedState={selectedState}
            handleSelectRow={handleSelectRow}
            onDataStateChange={(dataState) => dataStateChangeAction({ friendlyName, dataState })}
            onFilterBoxChange={(filterBox: FilterBox) => filterBoxChangeAction({ friendlyName, filterBox })}
            dateFormat={dateFormat}
            detail={detail}
            expandedState={expandedState}
            onExpandChange={detail ? expandChange : undefined}
            selectable={selectable ?? true}
            scrollToSelectedRow={selectedRowIds.length > 0 ? selectedRowIds[0] : undefined}
            showActionsColumn={true}
          />
        </div>

        {labelDialog}
        {dialog}
        {popLabel}
      </ThemeProvider>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  tablesSettings: selectTable(state).tablesSettings,
  otherTablesSettings: selectTable(state).otherTablesSettings,
  currentTrack: selectCurrentTrack(state),
  labelsById: selectLabelState(state).labelsById,
  labelables: selectLabelState(state).labelables,
  questionById: selectFormState(state).questionById,
  answerById: selectFormState(state).answerById,
  formById: selectFormState(state).formById,
  dateFormat: selectCurrentUser(state).person.date_format,
  action: selectTable(state).action,
  keywordById: selectKeywordState(state).keywordById,
  customColumnsData: selectTable(state).customColumnsData,
  customColumnsById: selectTable(state).customColumnsById,
  initialSelectedRowIds: selectTable(state).selectedRowIds,
  initialExpandedRowIds: selectTable(state).expandedRowIds,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setGridSettingsAction: (data: TablePatchSettingsDTO) => dispatch(tableSlice.actions['SETTINGS:PATCH'](data)),
  resetSettingsAction: (friendlyName: TableFriendlyName) =>
    dispatch(tableSlice.actions['SETTINGS:RESET']({ friendlyName })),
  saveSettingsAction: (friendlyName: TableFriendlyName) =>
    dispatch(tableSlice.actions['SETTINGS:SAVE']({ friendlyName })),
  saveAsSettingsAction: (data: TableSaveAsSettingsDTO) => dispatch(tableSlice.actions['SETTINGS:SAVE_AS'](data)),
  loadSettingsAction: (personalSettingsId: number) => dispatch(tableSlice.actions['SETTINGS:LOAD'](personalSettingsId)),
  deleteSettingsAction: (data: TableDeleteSettingsDTO) => dispatch(tableSlice.actions['SETTINGS:DELETE'](data)),
  pickLabelAction: (labelsByRowId: { [key: number]: number[] }, tableFriendlyName: TableFriendlyName) =>
    dispatch(labelSlice.actions['LABEL:PICK']({ labelsByRowId, tableFriendlyName })),
  unpickLabelAction: (recordId: number, labelId: number, tableFriendlyName: TableFriendlyName) =>
    dispatch(labelSlice.actions['LABEL:UNPICK']({ recordId, labelId, tableFriendlyName })),
  createLabelAction: (
    data: LabelDialogFields,
    tableFriendlyName: TableFriendlyName,
    currentTrackId: number,
    rowIds?: number[],
  ) => dispatch(labelSlice.actions['LABEL:CREATE']({ data, tableFriendlyName, currentTrackId, rowIds })),
  updateLabelAction: (labelId: number, data: LabelDialogFields) =>
    dispatch(labelSlice.actions['LABEL:UPDATE']({ labelId, data })),
  deleteLabelAction: (labelId: number, tableFriendlyName: TableFriendlyName) =>
    dispatch(labelSlice.actions['LABEL:DELETE']({ labelId, tableFriendlyName })),
  openSnackBarAction: (data: OpenSnackBarDTO) => dispatch(errorSlice.actions['OPEN:SNACKBAR'](data)),
  removeEmailAction: () => dispatch(emailSlice.actions['REMOVE:EMAIL']()),
  removePreviewAction: () => dispatch(emailSlice.actions['REMOVE:PREVIEW']()),
  dataStateChangeAction: (data: TableDataStateChangeDTO) => dispatch(tableSlice.actions['DATASTATE:CHANGE'](data)),
  filterBoxChangeAction: (data: TableFilterBoxChangeDTO) => dispatch(tableSlice.actions['FILTERBOX:CHANGE'](data)),
  switchFilterAction: (data: TableSwitchFilterDTO) => dispatch(tableSlice.actions.SWITCH_FILTER(data)),
  clearFiltersAction: (data: TableClearFiltersDTO) => dispatch(tableSlice.actions.CLEAR_FILTERS(data)),
  impersonateAction: (data: ImpersonateRequest) => dispatch(authSlice.actions.IMPERSONATE(data)),
  updateUserRolesBulkAction: (data: updateUserRolesBulkDTO) =>
    dispatch(infoSlice.actions['USER:UPDATE:ROLES:BULK'](data)),
  clearValidationErrorsAction: () => dispatch(errorSlice.actions['CLEAR:VALIDATION_ERRORS']()),
  deleteInvitationsAction: (ids: number[]) => dispatch(recruitmentSlice.actions['DELETE_INVITATIONS'](ids)),
  clearSelectionAction: () => dispatch(tableSlice.actions['SELECTION:CLEAR']()),
  respondInvitationAction: (data: RespondInvitationDTO) => dispatch(recruitmentSlice.actions.RESPOND_INVITATION(data)),
  setTableAction: (actionOpt: actionOption) => dispatch(tableSlice.actions['ACTION:SET'](actionOpt)),
  updateSelectionAction: (rowIds: number[], tableFriendlyName: TableFriendlyName) =>
    dispatch(tableSlice.actions['SELECTION:UPDATE']({ rowIds, tableFriendlyName })),
  updateExpandedAction: (rowIds: number[], tableFriendlyName: TableFriendlyName) =>
    dispatch(tableSlice.actions['EXPANDED:UPDATE']({ rowIds, tableFriendlyName })),
  updateCustomColumnsAction: (data: CustomColumnsUpdate) => dispatch(tableSlice.actions['CUSTOMCOLUMNS:UPDATE'](data)),
});

const StyledSimpleDialog = styled.div`
  > div {
    width: 35rem;
  }
  .k-editor .k-editor-content {
    /* target the content area */
    height: 25rem;
  }
  .k-editor-content > .k-iframe {
    height: 100% !important;
  }
`;

const StyledTools = styled.div`
  padding: 7px 0;
  border-radius: 5px;

  .k-link {
    border: none;
  }

  .tool-box {
    background-color: ${(props) => props.theme.palette.primary.main};
  }

  .sync-table:hover,
  .manage-columns:hover,
  .aggregate-columns:hover,
  .labels:hover,
  .reset-filters:hover {
    .MuiIconButton-root {
      background-color: transparent; /* rgba(0, 0, 0, 0.04) */
    }
    opacity: 0.7;
  }
`;

const StyledActions = styled.div`
  background-color: #edf3ff;

  .k-link {
    color: ${(props) => props.theme.palette.primary.main};
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.palette.primary.main};

    .k-icon {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  .k-menu-link {
    padding: 4px 3px;
  }
`;

const StyledKmenu = styled(Kmenu)`
  margin-left: 1rem;
  .k-link {
    color: ${(props) => props.theme.palette.text.secondary};
    border-radius: 4px;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => props.theme.palette.text.secondary};
    min-width: 6.3rem;

    .k-icon {
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }
`;

const StyledButtonWrapper = styled.div`
  margin-left: 10px;

  button {
    border-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.main};
    height: 42px;

    span {
      font-weight: normal;
      text-transform: none;
    }
  }

  button:hover {
    opacity: 0.7;
    background-color: transparent;
  }

  .MuiButton-outlined.Mui-disabled {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
  .MuiButton-root.Mui-disabled {
    color: ${(props) => props.theme.palette.primary.main};
  }
  .MuiButton-outlined.Mui-disabled,
  .MuiButton-root.Mui-disabled {
    opacity: 0.26;
  }
`;

const StyledDialog = styled(Dialog)`
  .k-grid {
    min-height: 22rem;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(MyGridRedux);
