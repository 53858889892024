import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store, runSaga } from './store/store';
import App from './router/App';
import * as serviceWorker from './serviceWorker';
import rootSaga from './store/root-saga';
import '@progress/kendo-theme-default/dist/all.css';
import './layouts/custom-all.css';
import './index.css';

// Run the saga
runSaga(rootSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
