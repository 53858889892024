import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import { DateFormatEnum, User } from '../../store/user/types.d';
import { Button, InputText, Select } from '../ui';
import countries, { countryToFlag } from '../../helpers/countries';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import useTheme from '@material-ui/core/styles/useTheme';
import { formatDate } from '@progress/kendo-intl';
import { InputValidationError } from '../types';
import styled from 'styled-components';

export const dateFormatOptions = [
  { id: '', label: 'System default' },
  { id: DateFormatEnum.DMY12, label: 'day/month/year 12h' },
  { id: DateFormatEnum.DMY24, label: 'day/month/year 24h' },
  { id: DateFormatEnum.MDY12, label: 'month/day/year 12h' },
  { id: DateFormatEnum.MDY24, label: 'month/day/year 24h' },
  { id: DateFormatEnum.YMD12, label: 'year/month/day 12h' },
  { id: DateFormatEnum.YMD24, label: 'year/month/day 24h' },
]; // https://github.com/telerik/kendo-intl/blob/master/docs/date-formatting/index.md#standard

export interface ProfileFormFields {
  first_name: string;
  middle_name: string;
  last_name: string;
  country: string;
  timezone: string;
  date_format: string;
  organization: string;
  dblp_key: string;
  google_scholar_id: string;
  semantic_scholar_id: string;
  orcid_id: string;
}

export interface ProfileFormProps {
  validation_error?: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    country?: string;
    timezone?: string;
    date_format?: string;
    organization: string;
    dblp_key?: string;
    google_scholar_id?: string;
    semantic_scholar_id?: string;
    orcid_id?: string;
  };
  save_handler: (form: ProfileFormFields) => void;
  user: User;
  onCancelClick: () => void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({ save_handler, user, validation_error, onCancelClick }) => {
  const theme = useTheme();
  const { handleSubmit, errors, control, setValue, clearErrors } = useForm<ProfileFormFields>();

  let firstNameProps: InputValidationError = {};
  let middleNameProps: InputValidationError = {};
  let lastNameProps: InputValidationError = {};
  let countryProps: InputValidationError = {};
  let timezoneProps: InputValidationError = {};
  let dateFormatProps: InputValidationError = {};
  let organizationProps: InputValidationError = {};
  let dblpKeyProps: InputValidationError = {};
  let googleScholarIdProps: InputValidationError = {};
  let semanticScholarIdProps: InputValidationError = {};
  let orcidIdProps: InputValidationError = {};

  const frontend_validation_rules = {
    first_name: { required: 'Enter your first name' },
    middle_name: { required: false },
    last_name: { required: 'Enter your last name' },
    country: { required: false },
    timezone: { required: false },
    date_format: { required: false },
    organization: { required: false },
    dblp_key: { required: false },
    google_scholar_id: { required: false },
    semantic_scholar_id: { required: false },
    orcid_id: { required: false },
  };
  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.first_name) {
      firstNameProps = {
        ...firstNameProps,
        error: true,
        helperText: errors.first_name.message,
      };
    }
    if (errors.middle_name) {
      middleNameProps = {
        ...middleNameProps,
        error: true,
        helperText: errors.middle_name.message,
      };
    }
    if (errors.last_name) {
      lastNameProps = {
        ...lastNameProps,
        error: true,
        helperText: errors.last_name.message,
      };
    }
    if (errors.country) {
      countryProps = {
        ...countryProps,
        error: true,
        helperText: errors.country.message,
      };
    }
    if (errors.timezone) {
      timezoneProps = {
        ...timezoneProps,
        error: true,
        helperText: errors.timezone.message,
      };
    }
    if (errors.timezone) {
      dateFormatProps = {
        ...timezoneProps,
        error: true,
        helperText: errors.timezone.message,
      };
    }
    if (errors.organization) {
      organizationProps = {
        ...organizationProps,
        error: true,
        helperText: errors.organization.message,
      };
    }
    if (errors.dblp_key) {
      dblpKeyProps = {
        ...dblpKeyProps,
        error: true,
        helperText: errors.dblp_key.message,
      };
    }
    if (errors.google_scholar_id) {
      googleScholarIdProps = {
        ...googleScholarIdProps,
        error: true,
        helperText: errors.google_scholar_id.message,
      };
    }
    if (errors.semantic_scholar_id) {
      semanticScholarIdProps = {
        ...semanticScholarIdProps,
        error: true,
        helperText: errors.semantic_scholar_id.message,
      };
    }
    if (errors.orcid_id) {
      orcidIdProps = {
        ...orcidIdProps,
        error: true,
        helperText: errors.orcid_id.message,
      };
    }
  } else {
    // Backend Validation
    if (validation_error) {
      if (validation_error.first_name) {
        firstNameProps = {
          ...firstNameProps,
          error: true,
          helperText: validation_error.first_name,
        };
      }
      if (validation_error.middle_name) {
        middleNameProps = {
          ...middleNameProps,
          error: true,
          helperText: validation_error.middle_name,
        };
      }
      if (validation_error.last_name) {
        lastNameProps = {
          ...lastNameProps,
          error: true,
          helperText: validation_error.last_name,
        };
      }
      if (validation_error.country) {
        countryProps = {
          ...countryProps,
          error: true,
          helperText: validation_error.country,
        };
      }
      if (validation_error.timezone) {
        timezoneProps = {
          ...timezoneProps,
          error: true,
          helperText: validation_error.timezone,
        };
      }
      if (validation_error.date_format) {
        timezoneProps = {
          ...timezoneProps,
          error: true,
          helperText: validation_error.date_format,
        };
      }
      if (validation_error.organization) {
        organizationProps = {
          ...organizationProps,
          error: true,
          helperText: validation_error.organization,
        };
      }
      if (validation_error.dblp_key) {
        dblpKeyProps = {
          ...dblpKeyProps,
          error: true,
          helperText: validation_error.dblp_key,
        };
      }
      if (validation_error.google_scholar_id) {
        googleScholarIdProps = {
          ...googleScholarIdProps,
          error: true,
          helperText: validation_error.google_scholar_id,
        };
      }
      if (validation_error.semantic_scholar_id) {
        semanticScholarIdProps = {
          ...semanticScholarIdProps,
          error: true,
          helperText: validation_error.semantic_scholar_id,
        };
      }
      if (validation_error.orcid_id) {
        orcidIdProps = {
          ...orcidIdProps,
          error: true,
          helperText: validation_error.orcid_id,
        };
      }
    }
  }

  const clearForm = () => {
    setValue('first_name', user.person.first_name, {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('middle_name', user.person.middle_name ?? '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('last_name', user.person.last_name ?? '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('country', user.person.country ?? '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('timezone', user.person.timezone ?? '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('date_format', user.person.date_format ?? '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('organization', user.person.organization ?? '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('dblp_key', user.person.dblp_key ?? '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('google_scholar_id', user.person.google_scholar_id ?? '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('semantic_scholar_id', user.person.semantic_scholar_id ?? '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('orcid_id', user.person.orcid_id ?? '', {
      shouldValidate: false,
      shouldDirty: false,
    });
    clearErrors();
  };

  let options = countries
    .sort(function (a, b) {
      const nameA = a.label.toUpperCase(); // ignore upper and lowercase
      const nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
    .map((country) => {
      const label = countryToFlag(country.code) + ' ' + country.label;
      return { id: country.code, label };
    });

  options = [{ id: '', label: 'None' }].concat(options);

  return (
    <>
      <StyledProfileContentWrapper>
        <p className="text-2xl mb-3 text-center font-semibold text-gray-600">Welcome {user.person.full_name}</p>
        <p className="mb-9 text-center text-gray-600">Personal info and options to manage it.</p>
        <form onSubmit={handleSubmit(save_handler)} className="flex flex-col">
          <StyledFormContent>
            <Controller
              name="first_name"
              control={control}
              defaultValue={user.person.first_name}
              rules={frontend_validation_rules.first_name}
              render={({ onChange, value }) => (
                <InputText
                  label="First name"
                  autoFocus
                  value={value}
                  onChange={onChange}
                  className="mb-3 w-96"
                  error={firstNameProps.error}
                  helperText={firstNameProps.helperText}
                />
              )}
            />
            <Controller
              name="middle_name"
              control={control}
              defaultValue={user.person.middle_name ?? ''}
              rules={frontend_validation_rules.middle_name}
              render={({ onChange, value }) => (
                <InputText
                  label="Middle name"
                  value={value}
                  onChange={onChange}
                  className="mb-3 w-96"
                  error={middleNameProps.error}
                  helperText={middleNameProps.helperText}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              defaultValue={user.person.last_name}
              rules={frontend_validation_rules.last_name}
              render={({ onChange, value }) => (
                <InputText
                  label="Last name"
                  value={value}
                  onChange={onChange}
                  className="mb-3 w-96"
                  error={lastNameProps.error}
                  helperText={lastNameProps.helperText}
                />
              )}
            />
            <Controller
              name="country"
              control={control}
              defaultValue={user.person.country ?? ''}
              rules={frontend_validation_rules.country}
              render={({ onChange, value }) => (
                <Select
                  label="Country"
                  value={value}
                  options={options}
                  onChange={onChange}
                  className="mb-3 w-96"
                  error={countryProps.error}
                  helperText={countryProps.helperText}
                />
              )}
            />
            <Controller
              name="timezone"
              control={control}
              defaultValue={user.person.timezone}
              rules={frontend_validation_rules.timezone}
              render={({ onChange, value }) => (
                <div className="mt-3 mb-3 w-96">
                  <div className="mb-1.5 ml-0.5 font-normal text-sm" color={theme.palette.text.primary}>
                    Timezone
                  </div>
                  <TimezoneSelect
                    value={value}
                    onChange={(timezone) => {
                      onChange(timezone.value);
                    }}
                    timezones={{ ...allTimezones, 'etc/gmt+12': 'Anywhere on Earth' }}
                  />
                </div>
              )}
            />
            <Controller
              name="date_format"
              control={control}
              defaultValue={user.person.date_format ?? ''}
              rules={frontend_validation_rules.date_format}
              render={({ onChange, value }) => (
                <Select
                  label={'Date format'}
                  value={value}
                  options={dateFormatOptions.map(({ id, label }) => ({
                    id,
                    label: id ? `${label} (${formatDate(new Date(), id)})` : label,
                  }))}
                  onChange={(dateFormat) => onChange(dateFormat)}
                />
              )}
            />

            <Controller
              name="organization"
              control={control}
              defaultValue={user.person.organization}
              rules={frontend_validation_rules.organization}
              render={({ onChange, value }) => (
                <InputText
                  label="Organization"
                  value={value}
                  onChange={onChange}
                  className="mt-3 mb-3 w-96"
                  error={organizationProps.error}
                  helperText={organizationProps.helperText}
                />
              )}
            />

            <Controller
              name="dblp_key"
              control={control}
              defaultValue={user.person.dblp_key ?? ''}
              rules={frontend_validation_rules.dblp_key}
              render={({ onChange, value }) => (
                <InputText
                  label="DBLP Key"
                  value={value}
                  onChange={onChange}
                  className="mb-3 w-96"
                  error={dblpKeyProps.error}
                  helperText={dblpKeyProps.helperText}
                />
              )}
            />
            <Controller
              name="google_scholar_id"
              control={control}
              defaultValue={user.person.google_scholar_id ?? ''}
              rules={frontend_validation_rules.google_scholar_id}
              render={({ onChange, value }) => (
                <InputText
                  label="Google Scholar Identifier"
                  value={value}
                  onChange={onChange}
                  className="mb-3 w-96"
                  error={googleScholarIdProps.error}
                  helperText={googleScholarIdProps.helperText}
                />
              )}
            />
            <Controller
              name="semantic_scholar_id"
              control={control}
              defaultValue={user.person.semantic_scholar_id ?? ''}
              rules={frontend_validation_rules.semantic_scholar_id}
              render={({ onChange, value }) => (
                <InputText
                  label="Semantic Scholar Identifier"
                  value={value}
                  onChange={onChange}
                  className="mb-3 w-96"
                  error={semanticScholarIdProps.error}
                  helperText={semanticScholarIdProps.helperText}
                />
              )}
            />
            <Controller
              name="orcid_id"
              control={control}
              defaultValue={user.person.orcid_id ?? ''}
              rules={frontend_validation_rules.orcid_id}
              render={({ onChange, value }) => (
                <InputText
                  label="ORCID"
                  value={value}
                  onChange={onChange}
                  className="mb-3 w-96"
                  error={orcidIdProps.error}
                  helperText={orcidIdProps.helperText}
                />
              )}
            />
          </StyledFormContent>

          <StyledButtons>
            <StyledCancel
              onClick={(e) => {
                e.preventDefault();
                clearForm();
                onCancelClick();
              }}
              autoFocus
              variant="text"
            >
              Cancel
            </StyledCancel>

            <Button type="submit" className="w-48 place-self-center">
              <span className="px-14">Save</span>
            </Button>
          </StyledButtons>
        </form>
      </StyledProfileContentWrapper>
    </>
  );
};

const StyledButtons = styled.div`
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 2px dotted #e3e5e6;
  padding: 1rem 0;
  position: sticky;
  bottom: 0;
  background-color: rgb(237, 243, 255);
  z-index: 10;
`;

const StyledCancel = styled(Button)`
  color: rgb(220 38 38);
  font-weight: 700;
`;

const StyledProfileContentWrapper = styled.div`
  flex-grow: 1;
`;

const StyledFormContent = styled.div`
  max-width: 385px;
  margin: 0 auto 4% auto;

  .k-widget.k-dropdown {
    width: 24rem;
  }
`;

export default ProfileForm;
