import React from 'react';
import { OptionsData } from '../FormBuilder/FormBuilder';
import { Checkbox } from '../ui';
import SelectOptions from './SelectOptions';
import styled from 'styled-components';

interface OptionData {
  asSelect?: boolean;
  asRadioButton?: boolean;
}
interface Props {
  editOption: (index: number, option: OptionsData) => void;
  option: OptionsData[];
  addOption: (index: number, option: OptionsData) => void;
  deleteOptionHandler: (OptionIndex: number, index: number | undefined) => void;
  value?: OptionData;
  index: number;
  checkboxHandler: (event: React.ChangeEvent<HTMLInputElement>, index: number | undefined) => void;
  withNumericalValue?: boolean;
  disabled?: boolean;
}

const InputSelectComponent: React.FC<Props> = ({
  editOption,
  option,
  index,
  value,
  addOption,
  deleteOptionHandler,
  checkboxHandler,
  withNumericalValue,
  disabled,
}) => {
  const asSelect = value?.asSelect ?? false;
  const asRadioButton = value?.asRadioButton ?? false;

  return (
    <StyledSelectWrapper className="flex flex-col items-start py-2 select-wrapper mb-4">
      <span className="mb-3">Show options as:</span>

      <div className="checkbox-wrapper mb-2">
        <Checkbox
          label="Drop-down list"
          onChange={(e) => !asSelect && checkboxHandler(e, index)}
          checked={asSelect}
          name="asSelect"
        />
        <span className="bullet"></span>
      </div>

      <div className="checkbox-wrapper mb-4">
        <Checkbox
          label="Radio button group"
          onChange={(e) => !asRadioButton && checkboxHandler(e, index)}
          checked={asRadioButton}
          name="asRadioButton"
        />
        <span className="bullet"></span>
      </div>

      <SelectOptions
        editOption={editOption}
        option={option}
        addOption={addOption}
        deleteOptionHandler={deleteOptionHandler}
        index={index}
        withNumericalValue={withNumericalValue}
        disabled={disabled}
      />
    </StyledSelectWrapper>
  );
};

export default InputSelectComponent;

const StyledSelectWrapper = styled.div`
  input:checked ~ .checkmark:after {
    display: none;
  }

  .checkbox-wrapper {
    position: relative;

    input {
      width: 16px;
      height: 16px;
    }

    .bullet {
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: #fff;
      width: 6px;
      height: 6px;
      border-radius: 10px;
    }

    /* Show checkbox as radio */
    .checkmark {
      height: 16px;
      width: 16px;
      border-radius: 12px;
      top: 0;
      left: 0;
    }
  }
`;
