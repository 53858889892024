import React, { FormEvent } from 'react';
import { Invitation, InvitationStatus, RespondInvitationRequest } from '../../store/auth/types';
import { Button, RadioButton, TextArea } from '../ui';

export interface InvitationFormProps {
  token: string;
  invitation: Invitation;
  sendHandler: (data: RespondInvitationRequest) => void;
}

const InvitationForm: React.FC<InvitationFormProps> = ({ token, invitation, sendHandler }) => {
  const [radioValue, setRadioValue] = React.useState<InvitationStatus>('Accepted');
  const [textValue, setTextValue] = React.useState('');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value as InvitationStatus);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendHandler({
      token: token,
      status: radioValue,
      comment: textValue,
    });
  };

  return (
    <div className="text-justify">
      <p className="mb-5">Dear {invitation.full_name},</p>
      <p className="mb-5">
        You've been invited to participate in the <b>{invitation.conference_name}</b>.
      </p>
      <p className="mb-5">
        This invitation applies to the track <span className="italic">{invitation.track_name}</span> with a role of{' '}
        <span className="italic">{invitation.role_description}</span>.
      </p>
      <p className="mb-5">Choose whether you want to accept or decline the invitation.</p>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="flex flex-col mb-5">
          <RadioButton
            name="options"
            label="Accept"
            checked={radioValue === 'Accepted'}
            value="Accepted"
            onChange={handleRadioChange}
            className="mb-3"
          />
          <RadioButton
            name="options"
            label="Decline"
            checked={radioValue === 'Declined'}
            value="Declined"
            onChange={handleRadioChange}
          />
        </div>
        <TextArea label="Write a comment about yout decision." value={textValue} onChange={handleTextChange} />
        <Button type="submit" className="mt-5 bg-blue-700">
          <span className="text-white">Send</span>
        </Button>
      </form>
    </div>
  );
};

export default InvitationForm;
