import { InputText } from '../ui';
import React from 'react';

interface Props {
  textHandler: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number | undefined) => void;
  index?: number;
  value?: OptionDatas;
}
interface OptionDatas {
  min?: number;
  max?: number;
}
const InputLinearScaleComponent: React.FC<Props> = ({ textHandler, index, value }) => {
  return (
    <div className="flex flex-col mt-2 linear-scale">
      <InputText
        type="number"
        label="Min Value"
        defaultValue={value?.min}
        name="min"
        onChange={(e) => textHandler(e, index)}
      />
      <InputText
        type="number"
        label="Max Value"
        defaultValue={value?.max}
        name="max"
        onChange={(e) => textHandler(e, index)}
      />
    </div>
  );
};

export default InputLinearScaleComponent;
