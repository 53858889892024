import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { BidDTO, BidOption } from '../../store/bid/types';
import { Select } from '../ui';

interface Props extends GridCellProps {
  bidOptionsById: { [key: number]: BidOption };
  createBid: (data: BidDTO) => void;
}

const BidCell: React.FC<Props> = (props) => {
  const field = props.field || '';
  const value = props.dataItem[field] || '';
  const { submission_id } = props.dataItem;

  const options = Object.values(props.bidOptionsById)
    .map((value) => ({ id: value.id.toString(), label: value.name }))
    .concat([{ id: '', label: 'None' }]);

  return (
    <td
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      <Select
        value={value}
        options={options}
        onChange={(newValue) => {
          if (newValue === value) return;
          const bidOptionId = parseInt(newValue) || null;
          props.createBid({ submission_id, bid_option_id: bidOptionId });
        }}
      />
    </td>
  );
};

export default BidCell;
