import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { InputText, Select } from '../../ui';
import React, { useState } from 'react';
import { ValidatedField } from '../../../store/api.d';
import { validationErrorTransformer } from '../../../store/api';
import { Role } from '../../../store/conference/types';
import { AppState } from '../../../store/state';
import { selectErrorState, selectTrackRoles } from '../../../store/selectors';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import infoSlice from '../../../store/info/slice';
import styled from 'styled-components';
import { getUserByEmail } from '../../../store/user/sagas';

export interface AddPersonFormFields {
  first_name: string;
  last_name: string;
  email: string;
  target_role_id: number;
}

interface Props {
  handleClose: () => void;
  validationErrors?: ValidatedField[];
  roleById: { [key: string]: Role };
  addPersonAction: (data: AddPersonFormFields) => void;
}

const AddPersonDialog: React.FC<Props> = ({ handleClose, validationErrors, roleById, addPersonAction }) => {
  const [FData, setFData] = useState<AddPersonFormFields>({
    first_name: '',
    last_name: '',
    email: '',
    target_role_id: 0,
  });
  const [showExtraFields, setShowExtraFields] = useState<boolean>(false);

  const errors = validationErrors ? validationErrorTransformer(validationErrors) : {};

  const handleOk = async () => {
    const res = await getUserByEmail(FData.email);
    switch (res.type) {
      case 'ok':
        const { data } = res.value;
        if (data.length > 0 || showExtraFields) {
          // Case user found
          addPersonAction(FData);
        } else {
          // Case user not found. Ask for extra fields
          setShowExtraFields(true);
        }
        break;
      case 'validation-error':
        break;
      case 'error':
        break;
    }
  };

  return (
    <SimpleDialog
      title="Add person"
      open={true}
      handleClose={handleClose}
      handleOk={handleOk}
      closeOnOk={false}
      disableOkButton={
        !FData.email || !FData.target_role_id || (showExtraFields && (!FData.first_name || !FData.last_name))
      }
    >
      <StyledDialogContent>
        {showExtraFields && (
          <React.Fragment>
            <InputText
              required
              label="First name"
              error={!!errors.first_name}
              helperText={errors.first_name}
              onChange={(e) => setFData({ ...FData, first_name: e.target.value })}
              className="mb-4"
            />
            <InputText
              required
              label="Last name"
              error={!!errors.last_name}
              helperText={errors.last_name}
              onChange={(e) => setFData({ ...FData, last_name: e.target.value })}
              className="mb-4"
            />
          </React.Fragment>
        )}
        <InputText
          required
          label="Email"
          error={!!errors.email}
          helperText={errors.email}
          onChange={(e) => setFData({ ...FData, email: e.target.value })}
          className="mb-4"
        />
        <Select
          label="Role"
          required
          options={Object.values(roleById)
            .filter((role) => role.type !== 'author')
            .map((role) => ({ id: role.id.toString(), label: role.description }))}
          error={!!errors.target_role_id}
          helperText={errors.target_role_id}
          onChange={(value) => setFData({ ...FData, target_role_id: parseInt(value) })}
        />
      </StyledDialogContent>
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  roleById: selectTrackRoles(state),
  validationErrors: selectErrorState(state).validationErrors,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addPersonAction: (data: AddPersonFormFields) => dispatch(infoSlice.actions['USER:ADD'](data)),
});

const StyledDialogContent = styled.div`
  flex-direction: column;
  display: flex;

  .MuiInputBase-input {
    width: 25rem;
  }

  span.k-dropdown-wrap {
    border-left: 1px solid rgba(0, 0, 0, 0.33);
    border-top: 1px solid rgba(0, 0, 0, 0.33);
    border-bottom: 1px solid rgba(0, 0, 0, 0.33);
    border-right: 1px solid rgba(0, 0, 0, 0.33);
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(AddPersonDialog);
