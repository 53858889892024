import React, { useEffect, useState } from 'react';
import { Checkbox, Tabs, TabsItem } from '../ui';
import { AppState } from '../../store/state';
import {
  selectConference,
  selectPaperStatusState,
  selectPermission,
  selectScreenState,
  selectTrackRoles,
} from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import Loading from '../../components/Loading/Loading';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { PaperStatus } from '../../store/paper-status/types.d';
import { Role } from '../../store/conference/types';
import { capitalizeFirstLetter } from '../../helpers/string';
import { PermissionString } from '../../store/permission/types';
import PaperStatusPicker from '../PaperStatusPicker/PaperStatusPicker';

interface Props {
  loading: boolean;
  roleById: { [key: string]: Role };
  permissionsByRoleId: { [key: number]: PermissionString[] };
  screenByName: { [key: string]: Screen };
  paperStatusById: { [key: number]: PaperStatus };
  onChange?: (settings: DiscussionSettings) => void;
}

const PhaseSettingsDiscussion: React.FC<Props> = ({
  loading,
  roleById,
  permissionsByRoleId,
  screenByName,
  paperStatusById,
  onChange,
}) => {
  const theme = useTheme();
  const reviewerRoles = Object.values(roleById).filter((role) => role.type === 'reviewer');

  const initSettings = (): DiscussionSettings => {
    const byRoleId: { [key: number]: { is_moderator: boolean } } = {};
    reviewerRoles.forEach((role) => {
      byRoleId[role.id] = {
        is_moderator: permissionsByRoleId[role.id]?.includes('IS_MODERATOR_OF_DISCUSSION'),
      };
    });
    return { paper_status_ids: screenByName[`Discussion`].settings.paper_statuses, by_role_id: byRoleId };
  };

  const [selectedRoleId, setSelectedRoleId] = React.useState<number>(
    reviewerRoles.length > 0 ? reviewerRoles[0].id : 0,
  );
  const [settings, setSettings] = useState<DiscussionSettings>(initSettings);

  useEffect(() => {
    onChange?.(settings); // Just a silly way of take out settings to outside at the very beginning
  }, []);

  const settingsChangeHandler = (settings: DiscussionSettings) => {
    setSettings(settings);
    onChange?.(settings); // Call external callback
  };

  if (loading) {
    return <Loading />;
  }

  /* Discussion screen settings */
  const discussion = (
    <fieldset className="border border-solid pt-2 pb-6 px-4 border-gray-300 rounded-md">
      <PaperStatusPicker
        paperStatusIds={settings.paper_status_ids}
        paperStatusById={paperStatusById}
        onChange={(paperStatusIds) => settingsChangeHandler({ ...settings, paper_status_ids: paperStatusIds })}
        phaseType="discussion"
      />
      <StyledHeader className="relative ml-2">
        <Tabs className="flex-grow">
          {reviewerRoles.map((role, index) => (
            <TabsItem
              key={index}
              label={`${role.description}`}
              selected={selectedRoleId == role.id}
              onClick={() => setSelectedRoleId(role.id)}
            />
          ))}
        </Tabs>
      </StyledHeader>
      {selectedRoleId && (
        <div className="flex flex-col items-start mt-3 ml-4 mt-7">
          <Checkbox
            label={'IS_MODERATOR_OF_DISCUSSION'
              .toLowerCase()
              .split('_')
              .map((split) => capitalizeFirstLetter(split))
              .join(' ')}
            checked={settings.by_role_id[selectedRoleId].is_moderator}
            onChange={(e) => {
              settingsChangeHandler({
                ...settings,
                by_role_id: {
                  ...settings.by_role_id,
                  [selectedRoleId]: { ...settings.by_role_id[selectedRoleId], is_moderator: e.target.checked },
                },
              });
            }}
          />
        </div>
      )}
    </fieldset>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className="text-sm max-w-4xl">
        <div className="mt-8 mb-5">{discussion}</div>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectConference(state).loading,
  roleById: selectTrackRoles(state),
  permissionsByRoleId: selectPermission(state).byRoleId,
  screenByName: selectScreenState(state).screenByName,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PhaseSettingsDiscussion);

const StyledHeader = styled.header`
  background-color: ${(props) => props.theme.palette.secondary.dark};
  margin-top: 2rem;
`;
