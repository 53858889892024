import React from 'react';
import tw from 'twin.macro';
import cn from 'classnames';
import { Chip } from '../../display/Chip';
import { Selector } from './Selector';
import ChevronDownIcon from '../../icons/ChevronDown';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';

export type MultiSelectFieldProps = {
  label?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
  options?: { id: string; label: string }[];
  placeholder?: string;
  className?: string;
};

export const MultiSelect = ({
  label,
  required,
  fullWidth,
  error,
  helperText,
  value = [],
  onChange,
  options = [],
  placeholder,
  className,
}: MultiSelectFieldProps): JSX.Element => {
  const [selectorOpen, setSelectorOpen] = React.useState(false);
  const wrapperRef = React.useRef<Element | null>() as React.MutableRefObject<HTMLInputElement | null>;

  const onOpenSelector = (event: React.SyntheticEvent) => {
    if (event.target == event.currentTarget) {
      setSelectorOpen(true);
    }
  };

  const onSave = (ids: string[]) => {
    onChange?.(ids);
    setSelectorOpen(false);
  };

  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledDiv className={cn(className, { error })}>
        {label && <label>{required ? `${label} *` : label}</label>}
        <div ref={wrapperRef} className={cn('--button', { fullWidth })}>
          <div onClick={onOpenSelector} className="flex-grow flex flex-wrap">
            {value.length == 0 && <Chip className="bg-transparent text-gray-300">{placeholder}</Chip>}
            {options
              .filter((option) => value.includes(option.id))
              .map((option) => (
                <div key={option.id} className="mr-2">
                  <Chip onClose={() => onChange?.(value.filter((id) => id != option.id))}>{option.label}</Chip>
                </div>
              ))}
          </div>
          <button onClick={() => setSelectorOpen(true)} className="align-middle self-stretch cursor-default px-2">
            <ChevronDownIcon />
          </button>
          <Selector
            ids={value}
            onSave={onSave}
            options={options}
            open={selectorOpen}
            onClose={() => setSelectorOpen(false)}
            anchorEl={wrapperRef?.current ?? undefined}
            gap={0}
          />
        </div>
        <span className={cn('helperText', { error })}>{helperText}</span>
      </StyledDiv>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  label {
    ${tw`block mb-1.5 ml-0.5`}
  }

  .--button {
    ${tw`inline-flex items-center p-1 bg-white rounded border border-gray-400`}
    min-height: 2.75rem;

    &.fullWidth {
      ${tw`flex`}
    }
  }

  .helperText {
    ${tw`block text-sm text-gray-600 m-0.5 mb-1 leading-4 h-4`}

    &.error {
      color: ${(props) => props.theme.palette.error.main};
    }
  }

  &.error {
    label {
      color: ${(props) => props.theme.palette.error.main};
    }

    .--button {
      ${tw`rounded border-2`}
      border-color: ${(props) => props.theme.palette.error.main};
    }
  }
`;
