import { FetchOptions, Result, ValidatedField } from './api.d';
import { store } from './store';
import { Action } from 'redux';
import { _token, setToken } from './local-storage';
import errorSlice from './error/slice';
import { getCurrentRole } from '../helpers/role';

const processOptions = (options?: FetchOptions): FetchOptions => {
  if (!options) {
    return {};
  }
  let opts = {
    ...options,
  };
  // defaultContentType is true, no set 'Content-Type'
  if (!opts.defaultContentType && opts.method) {
    opts = {
      ...opts,
      headers: {
        ...opts.headers,
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
      },
    };
  }
  if (opts.auth && _token) {
    opts = {
      ...opts,
      headers: {
        ...opts.headers,
        Authorization: `Bearer ${_token}`,
      },
    };
  }
  // remove flag defaultContentType, used when upload files
  delete opts.defaultContentType;
  return opts;
};

const processInput = (input: RequestInfo, options?: FetchOptions): RequestInfo => {
  if (!options) {
    return input;
  }

  let urlStr = input.toString();

  const currentRole = getCurrentRole();

  if (options.role && currentRole) {
    const url = new URL(urlStr);
    url.searchParams.set('role_id', String(currentRole.id));
    urlStr = url.toString();
  }
  return urlStr;
};

export const validationErrorTransformer = (errors: ValidatedField[]): { [k: string]: string } => {
  let ret: { [k: string]: string } = {};
  errors.forEach((item) => {
    ret = { ...ret, [item.name]: item.message };
  });
  return ret;
};

export class APIValidationError extends Error {
  validation: ValidatedField[];
  constructor(obj: ValidatedField[]) {
    super('Validation Error');
    this.name = 'ValidationError';
    this.message = 'Validation Error';
    this.validation = obj;
  }
}

const _apiCall = async (input: RequestInfo, init: RequestInit) => {
  const response = await fetch(processInput(input, init), processOptions(init));
  if (response.status === 200) {
    return response;
  }
  if (response.status === 500) {
    throw new Error('Network error');
  }
  if (response.status === 401) {
    const { data } = await response.json();
    if (data.code === '104') {
      // Code 104 means Invalid token - delete from client
      setToken(null);
      window.location.replace('/');
    }
    throw new Error(data.message || 'Unauthorized');
  }
  if (response.status === 403) {
    const { data } = await response.json();
    throw new Error(data.message || 'Forbidden');
  }
  if (response.status === 404) {
    const { data } = await response.json();
    throw new Error(data.message || 'Not found');
  }
  if (response.status === 422) {
    const { data } = await response.json();
    throw new APIValidationError(data.validation);
  }
  if (response.status === 400) {
    const { data } = await response.json();
    throw new Error(data.message || data || 'Invalid request');
  }
  if (response.status === 409) {
    const { data } = await response.json();
    throw new Error(data.message || data || 'Resource already exists');
  }
  if (response.status === 4000) {
    const { data } = await response.json();
    throw new Error(data.message || data || 'Bulk request error');
  }
  const data = await response.json();
  throw new Error(data.errors);
};

export const apiCall2 = async <T>(input: RequestInfo, init: RequestInit): Promise<T> => {
  const response = await _apiCall(input, init);
  return response.json();
};

export const apiCall = async <T>(input: RequestInfo, init: RequestInit): Promise<Result<T, APIValidationError>> => {
  try {
    const response = await apiCall2<T>(input, init);
    return {
      type: 'ok',
      value: response,
    };
  } catch (e: any) {
    if (e instanceof APIValidationError) {
      return {
        type: 'validation-error',
        value: e,
      };
    }
    if (e instanceof Error) {
      const action = errorSlice.actions['OPEN:SNACKBAR']({ message: e.message, severity: 'error' }) as Action;
      store.dispatch(action);
      return {
        type: 'error',
        value: e.message,
      };
    }
    return e;
  }
};
