import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IconProps {
  color: string;
}

function Icon({ color = 'currentColor' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
      <g id="Grupo_1863" data-name="Grupo 1863" transform="translate(-28 -229.041)">
        <g id="Grupo_1862" data-name="Grupo 1862">
          <path
            id="Trazado_899"
            data-name="Trazado 899"
            d="M42.4,455.39V444.08H44v11.31a.838.838,0,0,0,.8.87H59.2a.838.838,0,0,0,.8-.87V444.08h1.6v11.31A2.512,2.512,0,0,1,59.2,458H44.8A2.512,2.512,0,0,1,42.4,455.39Z"
            transform="translate(-12.4 -204.959)"
            fill={color}
            fillRule="evenodd"
          />
        </g>
        <path
          id="Trazado_901"
          data-name="Trazado 901"
          d="M50.869,434.51a1.51,1.51,0,0,1,2.263,0l10.634,11.565a.924.924,0,0,1,0,1.23.755.755,0,0,1-1.131,0L52,435.74,41.366,447.3a.755.755,0,0,1-1.131,0,.924.924,0,0,1,0-1.23Z"
          transform="translate(-12 -204.959)"
          fill={color}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default Icon;
