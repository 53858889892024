import React from 'react';
import tw from 'twin.macro';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';

export type SearchTextProps = React.InputHTMLAttributes<HTMLInputElement> & {
  ref?: any;
};

export const SearchText = ({ placeholder = 'Search by text...', ...inputProps }: SearchTextProps): JSX.Element => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <StyledDiv>
        <input {...inputProps} placeholder={placeholder} type="text" />
      </StyledDiv>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  ${tw`flex flex-col`}

  input {
    ${tw`rounded  border-transparent focus:ring-0 `}
    background-color: ${(props) => props.theme.palette.background.default};

    &.focus {
      border-color: ${(props) => props.theme.palette.secondary.light};
      background-color: ${(props) => props.theme.palette.background.default};
    }

    &.placeholder {
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;
