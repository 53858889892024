import React from 'react';
import { Tooltip } from '@material-ui/core';

interface Props {
  label: string;
  validationStatus: string;
}

const StatusLabel: React.FC<Props> = ({ label, validationStatus }) => {
  return (
    <div className="inline-flex">
      <p className="font-semibold mr-2">{label}:</p>

      <div className="flex">
        <span className={`flex font-normal ${validationStatus === 'Pending' ? 'text-red-600' : 'text-green-600'}`}>
          {validationStatus === 'Pending' ? 'Incomplete' : validationStatus}
        </span>

        {validationStatus === 'Pending' && (
          <Tooltip title="Compulsory questions to answer." placement="right">
            <span
              className="font-semibold px-1.5 rounded-full text-sm ml-1.5 text-white"
              style={{ backgroundColor: '#2563eb' }}
            >
              ?
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default StatusLabel;
