import { put, select, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import infoSlice from './slice';
import { getInfoResponse, InfoGetDTO, updateUserRolesBulkDTO, updateUserRolesBulkResponse } from './types';
import { baseURL } from '../root-saga';
import errorSlice from '../error/slice';
import { selectCurrentTrack, selectCurrentUser } from '../selectors';
import { Track } from '../conference/types';
import { AddPersonFormFields } from '../../components/dialogs/AddPersonDialog/AddPersonDialog';
import tableSlice from '../table/slice';
import labelSlice from '../label/slice';
import conferenceSlice from '../conference/slice';
import { User } from '../user/types';

const getInfo = async (data: InfoGetDTO): Promise<Result<getInfoResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  const { friendlyName, queryParams } = data;

  let queryString = '';
  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      queryString += `${key}=${value}&`;
    }
  }

  return apiCall<getInfoResponse>(`${baseURL}/api/info/${friendlyName}?${queryString}`, init);
};

function* getInfoSaga(action: PayloadAction<InfoGetDTO>) {
  const result = (yield getInfo(action.payload)) as Await<ReturnType<typeof getInfo>>;

  switch (result.type) {
    case 'ok':
      const { friendlyName } = action.payload;
      yield put(
        labelSlice.actions['LABELABLES:GET:OK']({
          tableFriendlyName: friendlyName,
          labelablesById: result.value.data.labelablesById,
        }),
      );
      yield put(
        tableSlice.actions['CUSTOMCOLUMNS:DATA:GET:OK']({ friendlyName, byId: result.value.data.customColumnsById }),
      );
      yield put(infoSlice.actions['GET:OK']({ ...result.value.data, friendlyName }));
      break;
    case 'validation-error':
      yield put(infoSlice.actions['GET:KO']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const updateUserRolesBulk = async (
  data: updateUserRolesBulkDTO,
): Promise<Result<updateUserRolesBulkResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<updateUserRolesBulkResponse>(`${baseURL}/api/users/roles`, init);
};

function* updateUserRolesBulkSaga(action: PayloadAction<updateUserRolesBulkDTO>) {
  const result = (yield updateUserRolesBulk(action.payload)) as Await<ReturnType<typeof updateUserRolesBulk>>;

  switch (result.type) {
    case 'ok':
      // Reorder roles by rank
      const data: { [key: number]: { person_role_ids: number[]; role_ids: number[] } } = {};
      const user: User = yield select(selectCurrentUser);
      let currentUserTouched = false;
      Object.entries(result.value.data).forEach(([idStr, values]) => {
        const personId = parseInt(idStr);
        currentUserTouched = user.person.id == personId;
        data[personId] = { person_role_ids: [], role_ids: [] };
        values.role_ids
          .map((roleId, index) => ({
            roleId: roleId,
            personRoleId: values.person_role_ids[index],
          }))
          .forEach((value) => {
            data[personId].role_ids.push(value.roleId);
            data[personId].person_role_ids.push(value.personRoleId);
          });
      });
      yield put(infoSlice.actions['USER:UPDATE:ROLES:BULK:OK']({ data }));

      /* If we change current user role, we need to sync redux state */
      if (currentUserTouched) {
        const track: Track | null = yield select(selectCurrentTrack);
        const roles = data[user.person.id].role_ids.map((roleId) => ({ id: roleId }));
        yield put(conferenceSlice.actions['UPDATE:USER_ROLES']({ trackId: track?.id ?? 0, roles }));
      }
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const addPerson = async (data: AddPersonFormFields): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: any }>(`${baseURL}/api/users`, init);
};

function* addPersonSaga(action: PayloadAction<AddPersonFormFields>) {
  const result = (yield addPerson(action.payload)) as Await<ReturnType<typeof addPerson>>;

  switch (result.type) {
    case 'ok':
      yield put(tableSlice.actions['ACTION:SET'](''));
      yield put(infoSlice.actions.GET({ friendlyName: 'users' }));
      break;
    case 'validation-error':
      yield put(errorSlice.actions['SET:VALIDATION_ERRORS'](Object.values(result.value.validation)));
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(infoSlice.actions.GET, getInfoSaga),
  takeLatest(infoSlice.actions['USER:UPDATE:ROLES:BULK'], updateUserRolesBulkSaga),
  takeLatest(infoSlice.actions['USER:ADD'], addPersonSaga),
];
