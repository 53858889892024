import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { InputText } from '../../ui';
import PhaseSettingsBidding from '../../PhaseSettingsBidding/PhaseSettingsBidding';
import PhaseSettingsDiscussion from '../../PhaseSettingsDiscussion/PhaseSettingsDiscussion';

interface Props extends SimpleDialogProps {
  phase: Phase;
  updatePhaseAction: (data: PhaseUpdateDTO) => void;
}

const PhaseSettingsDialog: React.FC<Props> = ({ phase, updatePhaseAction, ...rest }) => {
  const [name, setName] = useState<string>(phase.name);
  const [settings, setSettings] = useState<BidSettings | DiscussionSettings | undefined>(undefined);

  const isDisabled = () => {
    return !name || (['bidding', 'discussion'].includes(phase.type) && !settings);
  };

  let settingsEl: JSX.Element | undefined;
  switch (phase.type) {
    case 'bidding':
      settingsEl = phase.role_id ? (
        <PhaseSettingsBidding roleId={phase.role_id} onChange={(settings) => setSettings(settings)} />
      ) : undefined;
      break;
    case 'discussion':
      settingsEl = <PhaseSettingsDiscussion onChange={(settings) => setSettings(settings)} />;
      break;
    default:
      settingsEl = undefined;
  }

  return (
    <SimpleDialog
      {...rest}
      title="Phase settings"
      handleOk={() => updatePhaseAction({ id: phase.id, name, settings })}
      okButtonText="Save"
      disableOkButton={isDisabled()}
    >
      <div>
        <InputText
          label="Name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mb-3"
          fullWidth={true}
        />
        {settingsEl}
      </div>
    </SimpleDialog>
  );
};

export default PhaseSettingsDialog;
