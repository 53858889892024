import React from 'react';
import { DashboardState } from '../../store/dashboard/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import '../../components/Dashboard/Dashboard.styles.css';
import { ResponsiveBar } from '@nivo/bar';
import { Select } from '../../components/ui';
import { Role } from '../../store/conference/types';
import { Keyword } from '../../store/keyword/types';
import { Switch } from '@progress/kendo-react-inputs';
import { PaperStatus } from '../../store/paper-status/types';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';

interface DashboardSubmissionsProps {
  dashboardState: DashboardState;
  roleById: { [key: string]: Role };
  keywordById: { [key: number]: Keyword };
  paperStatusById: { [key: number]: PaperStatus };
  handleChangeTargetRole: (targetRoleId: string, areaId: string, paperStatusId: string) => void;
  roleId: string;
  areaId: string;
  paperStatusId: string;
}

const DashboardSubmissions: React.FC<DashboardSubmissionsProps> = ({
  dashboardState,
  roleById,
  keywordById,
  paperStatusById,
  handleChangeTargetRole,
  roleId,
  areaId,
  paperStatusId,
}) => {
  const [inverseRatio, setInverseRatio] = React.useState<boolean>(false);
  const [scaleCheckSubmissions, setScaleCheckSubmissions] = React.useState<boolean>(false);
  const [scaleCheckReviewers, setScaleCheckReviewers] = React.useState<boolean>(false);
  const [scaleCheckRatios, setScaleCheckRatios] = React.useState<boolean>(false);

  const assignmentsPerReviewer = dashboardState.data.assignments_per_reviewer ?? [];
  const assignmentsPerSubmission = dashboardState.data.assignments_per_submission ?? [];
  const reviews_per_status = dashboardState.data.reviews_per_status ?? [];
  const submissions_and_reviewers = dashboardState.data.submissions_and_reviewers ?? [];

  const totalReviews = reviews_per_status.reduce((sum: number, status: { value: number }) => sum + status.value, 0);

  /*
  const submissionsAndReviewers = dashboardState.data.submissions_and_reviewers.map((item: any) => ({
    ...item,
    total_reviewers: Math.floor(Math.random() * 600) + 1,
  }));
  */

  const submissionsAndReviewersWithRatios = submissions_and_reviewers.map((item: any) => ({
    ...item,
    ratio:
      !inverseRatio && item.total_reviewers > 0
        ? item.total_submissions / item.total_reviewers
        : item.total_reviewers / item.total_submissions,
  }));

  return (
    <>
      <div className="flex flex-row justify-end mb-12 w-full">
        <StyledSelectWrapper className="flex flex-row justify-end items-baseline">
          <Select
            label="Role"
            value={roleId}
            options={Object.values(roleById)
              .filter((role) => role.type == 'reviewer')
              .map((role) => ({ id: role.id.toString(), label: role.description }))}
            onChange={(value) => {
              handleChangeTargetRole(value, areaId, paperStatusId);
            }}
          />
        </StyledSelectWrapper>

        <StyledSelectWrapper className="flex flex-row justify-end ml-5 items-baseline">
          <Select
            label="Area"
            value={areaId}
            options={[{ id: '', label: 'Not selected' }].concat(
              Object.values(keywordById)
                .filter((keyword) => keyword.parent_id == null)
                .map((keyword) => ({ id: keyword.id.toString(), label: keyword.name })),
            )}
            onChange={(value) => {
              handleChangeTargetRole(roleId, value, paperStatusId);
            }}
          />
        </StyledSelectWrapper>

        <StyledSelectWrapper className="flex flex-row justify-end ml-5 items-baseline">
          <Select
            label="Paper status"
            value={paperStatusId}
            options={[{ id: '', label: 'Not selected' }].concat(
              Object.values(paperStatusById).map((status) => ({ id: status.id.toString(), label: status.name })),
            )}
            onChange={(value) => {
              handleChangeTargetRole(roleId, areaId, value);
            }}
          />
        </StyledSelectWrapper>
      </div>
      <div className="flex flex-row justify-between w-full" style={{ height: '32rem', minWidth: 540 }}>
        {/* ASSIGNMENTS PER STATUS */}
        <div className="relative bg-white rounded-lg shadow-md mr-4 p-8 w-6/12">
          <h2 className="font-bold text-xl text-center">Total reviews per status</h2>

          {Object.keys(reviews_per_status).length > 0 ? (
            <>
              <ResponsivePie
                data={reviews_per_status}
                sortByValue={true}
                margin={{ top: 50, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.65}
                padAngle={1}
                cornerRadius={1}
                colors={['#5ca8f9', '#f9a80e', '#86e6fb']}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.5]],
                }}
                arcLinkLabel="label"
                arcLinkLabelsSkipAngle={15}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]],
                }}
                tooltip={(point) => {
                  return (
                    <div className="text-sm bg-white px-2 py-1 shadow-md flex flex-row">
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: point.datum.color,
                          marginTop: '4px',
                          marginRight: '8px',
                        }}
                      ></div>
                      <div>
                        {point.datum.label}: <strong>{point.datum.value}</strong>
                      </div>
                    </div>
                  );
                }}
                layers={['arcs', 'arcLabels', 'arcLinkLabels']}
              />

              <StyledTotal>
                <span className="title">{totalReviews}</span>
                <span className="text">reviews</span>
              </StyledTotal>
            </>
          ) : (
            <div className="text-center mt-36">
              <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
              <p className="text-center mt-4 mb-8">There are no data yet.</p>
            </div>
          )}
        </div>

        {/* ASSIGNMENTS PER SUBMISSION */}
        <div className="bg-white rounded-lg shadow-md p-8 w-6/12">
          <h2 className="font-bold text-xl text-center">Assignments per submission</h2>
          <div className="flex flex-row items-center justify-end">
            <Switch
              onChange={() => setScaleCheckSubmissions(!scaleCheckSubmissions)}
              onLabel={''}
              offLabel={''}
              defaultChecked={true}
            />
            <p className="ml-4 text-sm">Log scale</p>
          </div>
          {Object.keys(assignmentsPerSubmission).length > 0 ? (
            <ResponsiveBar
              data={assignmentsPerSubmission}
              keys={['frequency']}
              indexBy="total_assignments"
              margin={{ top: 20, right: 50, bottom: 170, left: 60 }}
              padding={0.6}
              layout="vertical"
              valueScale={{ type: scaleCheckSubmissions ? 'linear' : 'symlog' }}
              indexScale={{ type: 'band', round: true }}
              colors="#63b3ff"
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisBottom={{ format: (v) => `${v} ${v > 1 ? 'assignments' : 'assignment'}`, tickRotation: -34 }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
                tickValues: 4,
                legend: 'submissions',
                legendPosition: 'middle',
                legendOffset: -50,
              }}
              labelSkipHeight={15}
              enableLabel={true}
              tooltip={(point) => {
                return (
                  <div className="text-sm flex flex-row">
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: point.color,
                        marginTop: '4px',
                        marginRight: '8px',
                      }}
                    ></div>
                    <div>
                      {point.value} {point.value > 1 ? 'submissions' : 'submission'} with {point.indexValue}
                      {Number(point.indexValue) > 1 ? ' assignments' : ' assignment'}
                    </div>
                  </div>
                );
              }}
            />
          ) : (
            <div className="text-center mt-10">
              <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
              <p className="text-center mt-4 mb-8">There are no data yet.</p>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-row justify-between mt-12 w-full mb-4" style={{ height: '32rem', minWidth: 540 }}>
        {/* ASSIGNMENTS PER REVIEWER */}
        <div className="bg-white rounded-lg shadow-md p-8 w-full">
          <h2 className="font-bold text-xl text-center">Assignments per reviewer</h2>
          <div className="flex flex-row items-center justify-end">
            <Switch
              onChange={() => setScaleCheckReviewers(!scaleCheckReviewers)}
              onLabel={''}
              offLabel={''}
              defaultChecked={true}
            />
            <p className="ml-4 text-sm">Log scale</p>
          </div>
          {Object.keys(assignmentsPerReviewer).length > 0 ? (
            <ResponsiveBar
              data={assignmentsPerReviewer}
              keys={['frequency']}
              indexBy="total_assignments"
              margin={{ top: 20, right: 50, bottom: 150, left: 60 }}
              padding={0.6}
              valueScale={{ type: scaleCheckReviewers ? 'linear' : 'symlog' }}
              indexScale={{ type: 'band', round: true }}
              colors="#f9a80e"
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{ format: (v) => `${v} ${v > 1 ? 'assignments' : 'assignment'}`, tickRotation: -34 }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
                tickValues: 4,
                legend: 'reviewers',
                legendPosition: 'middle',
                legendOffset: -50,
              }}
              labelSkipHeight={15}
              enableLabel={true}
              tooltip={(point) => {
                return (
                  <div className="text-sm flex flex-row">
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: point.color,
                        marginTop: '4px',
                        marginRight: '8px',
                      }}
                    ></div>
                    <div>
                      {point.value} {point.value > 1 ? 'reviewers' : 'reviewer'} with {point.indexValue}
                      {Number(point.indexValue) > 1 ? ' assignments' : ' assignment'}
                    </div>
                  </div>
                );
              }}
            />
          ) : (
            <div className="text-center mt-36">
              <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
              <p className="text-center mt-4 mb-8">There are no data yet.</p>
            </div>
          )}
        </div>
      </div>

      {Object.keys(keywordById).length > 0 && (
        <div
          className="flex flex-row justify-around gap-4 mt-8 mb-12 w-full"
          style={{ height: '36rem', minWidth: 540 }}
        >
          {/* SUBMISSIONS AND REVIEWERS RATIOS */}
          <div className="bg-white rounded-lg shadow-md p-8 w-full">
            <h2 className="font-bold text-xl text-center mb-1">Submissions and reviewers ratios</h2>
            <div className="flex flex-row items-center gap-4 justify-end">
              <Switch
                onChange={() => setInverseRatio(!inverseRatio)}
                onLabel={''}
                offLabel={''}
                defaultChecked={true}
              />
              <p className="text-sm mr-6">
                {!inverseRatio ? 'Ratio: submissions / reviewers' : 'Ratio: reviewers / submissions'}
              </p>

              <Switch
                onChange={() => setScaleCheckRatios(!scaleCheckRatios)}
                onLabel={''}
                offLabel={''}
                defaultChecked={true}
              />
              <p className="text-sm">Log scale</p>
            </div>
            {Object.keys(submissionsAndReviewersWithRatios).length > 0 ? (
              <ResponsiveBar
                data={submissionsAndReviewersWithRatios}
                keys={['ratio', 'total_submissions', 'total_reviewers']}
                indexBy="areaId"
                margin={{ top: 20, right: 150, bottom: 200, left: 60 }}
                padding={0.6}
                layout="vertical"
                valueScale={{ type: scaleCheckRatios ? 'linear' : 'symlog' }}
                indexScale={{ type: 'band', round: true }}
                colors={['#ec6f6f', '#5ca8f9', '#86e6fb']}
                groupMode="grouped"
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisBottom={{
                  format: (v) => {
                    const text = Object.values(keywordById)
                      .filter((keyword) => keyword.id == v)
                      .map((keyword) => ({ id: keyword.id.toString(), label: keyword.name }.label.slice(0, 35)));

                    return text;
                  },
                  tickRotation: -34,
                }}
                axisLeft={{
                  tickSize: 0,
                  tickPadding: 0,
                  tickRotation: 0,
                  tickValues: 4,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -50,
                }}
                labelSkipHeight={15}
                enableLabel={false}
                tooltip={(point) => {
                  return (
                    <div className="text-sm flex flex-row">
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: point.color,
                          marginTop: '4px',
                          marginRight: '8px',
                        }}
                      ></div>
                      <div>
                        {point.value} {point.id}
                      </div>
                    </div>
                  );
                }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            ) : (
              <div className="text-center mt-36">
                <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
                <p className="text-center mt-4 mb-8">There are no data yet.</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardSubmissions;

const StyledSelectWrapper = styled.div`
  > div {
    margin-right: 0.85rem;
    min-width: inherit;
  }
`;

const StyledTotal = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 1.1;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  color: #333333;

  .title {
    font-size: 2rem;
    margin-right: 0.5rem;
  }
  .text {
    font-size: 0.775rem;
  }
`;
