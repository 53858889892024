import React from 'react';
import { Card, Link } from '../../components/ui';
import EditIcon from '../../icons/Edit';
import TrashCan from '../../icons/TrashCan';
import { Role } from '../../store/conference/types';
import useTheme from '@material-ui/core/styles/useTheme';

interface Props {
  role: Role;
  handleEdit: () => void;
  handleDeleteRole?: () => void;
}

const RoleCard: React.FC<Props> = ({ role, handleEdit, handleDeleteRole }) => {
  const theme = useTheme();

  return (
    <Card>
      <h3 className="font-medium text-base">
        <Link className="flex items-center cursor-pointer capitalize" onClick={handleEdit}>
          {role.description}
        </Link>
      </h3>

      <ul style={{ minHeight: '71px' }}></ul>

      <div className="flex justify-between w-full left-0 px-5 text-sm absolute bottom-4">
        <Link className="flex items-center cursor-pointer" onClick={handleEdit}>
          <EditIcon color={theme.palette.primary.main} className="mr-1" />
          Edit
        </Link>

        {handleDeleteRole && (
          <div className="cursor-pointer w-4" onClick={handleDeleteRole}>
            <TrashCan stroke={theme.palette.primary.main} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default RoleCard;
