import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MIN_PASSWORD_LENGTH, passwordValidationRule } from '../../helpers/password';
import { InputText, Button, Link } from '../ui';
import { Checkbox } from './Checkbox';
import PrivacyCheckbox from '../PrivacyCheckbox/PrivacyCheckbox';
import { InputValidationError } from '../types';

export interface SignUpForm {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export interface SignUpProps {
  validation_error?: {
    first_name?: string;
    last_name?: string;
    email?: string;
    password?: string;
  };
  login_handler?: () => void;
  signup_handler: (form: SignUpForm) => void;
  on_change_handler: () => void;
}

const SignUp: React.FC<SignUpProps> = (props) => {
  const { handleSubmit, errors, control } = useForm<SignUpForm>();
  const { login_handler, validation_error } = props;
  const [modalOptions, setModalOptions] = React.useState({
    isOpen: false,
    title: '',
    text: '',
    // eslint-disable-next-line prettier/prettier
    callback: () => {},
    hideCancel: false,
  });

  let firstNameProps: InputValidationError = {};
  let lastNameProps: InputValidationError = {};
  let emailProps: InputValidationError = {};
  let passwordProps: InputValidationError = {};

  const frontend_validation_rules = {
    first_name: { required: 'Enter your first name' },
    last_name: { required: 'Enter your last name' },
    email: {
      required: 'Enter your email address',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
    password: passwordValidationRule,
    privacy: { required: 'required' },
  };
  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.first_name) {
      firstNameProps = {
        ...firstNameProps,
        error: true,
        helperText: errors.first_name.message,
      };
    }
    if (errors.last_name) {
      lastNameProps = {
        ...lastNameProps,
        error: true,
        helperText: errors.last_name.message,
      };
    }
    if (errors.password) {
      let message;
      switch (errors.password.type) {
        case 'minLength': {
          message = `The password must be at least ${MIN_PASSWORD_LENGTH.toString()} characters.`;
          break;
        }
        default: {
          message = errors.password.message;
        }
      }
      passwordProps = {
        ...passwordProps,
        error: true,
        helperText: message,
      };
    }
    if (errors.email) {
      emailProps = {
        ...emailProps,
        error: true,
        helperText: errors.email.message,
      };
    }
  } else {
    // Backend Validation
    if (validation_error) {
      if (validation_error.first_name) {
        firstNameProps = {
          ...firstNameProps,
          error: true,
          helperText: validation_error.first_name,
        };
      }
      if (validation_error.last_name) {
        lastNameProps = {
          ...lastNameProps,
          error: true,
          helperText: validation_error.last_name,
        };
      }
      if (validation_error.email) {
        emailProps = {
          ...emailProps,
          error: true,
          helperText: validation_error.email,
        };
      }
      if (validation_error.password) {
        passwordProps = {
          ...passwordProps,
          error: true,
          helperText: validation_error.password,
        };
      }
    }
  }

  return (
    <div>
      <h2 className="text-2xl mb-9 font-semibold text-gray-600">Create account</h2>
      <form onSubmit={handleSubmit(props.signup_handler)} className="flex flex-col">
        <Controller
          name="first_name"
          control={control}
          defaultValue=""
          rules={frontend_validation_rules.first_name}
          render={({ onChange, value }) => (
            <InputText
              label="First name"
              autoFocus
              value={value}
              onChange={onChange}
              className="mb-3"
              error={firstNameProps.error}
              helperText={firstNameProps.helperText}
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          defaultValue=""
          rules={frontend_validation_rules.last_name}
          render={({ onChange, value }) => (
            <InputText
              label="Last name"
              value={value}
              onChange={onChange}
              className="mb-3"
              error={lastNameProps.error}
              helperText={lastNameProps.helperText}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={frontend_validation_rules.email}
          render={({ onChange, value }) => (
            <InputText
              label="E-mail"
              value={value}
              onChange={onChange}
              className="mb-7"
              error={emailProps.error}
              helperText={emailProps.helperText}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={frontend_validation_rules.password}
          render={({ onChange, value }) => (
            <InputText
              type="password"
              label="Password"
              onChange={onChange}
              value={value}
              className="mb-10"
              error={passwordProps.error}
              helperText={passwordProps.helperText}
            />
          )}
        />
        <div className="flex">
          <div>
            <Checkbox type="checkbox" label="" className="mb-10" required />
          </div>
          <div className="ml-2">
            {' '}
            I accept the{' '}
            <span
              className="cursor-pointer underline"
              onClick={() => setModalOptions({ ...modalOptions, isOpen: true })}
            >
              privacy policy
            </span>{' '}
            *
          </div>
        </div>
        <Button type="submit" className="mb-5 bg-blue-700">
          <span className="text-white">Sign up</span>
        </Button>
      </form>
      <Link href="javascript:void(0)" onClick={login_handler} className="font-semibold text-xs mb-5">
        Already have an account? Sign in
      </Link>
      <PrivacyCheckbox
        isOpen={modalOptions.isOpen}
        closehandler={() => setModalOptions({ ...modalOptions, isOpen: false })}
        btn1handler={() => setModalOptions({ ...modalOptions, isOpen: false })}
      />
    </div>
  );
};

export default SignUp;
