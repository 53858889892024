import React from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { InputText } from '../../ui';
import { FormDTO } from '../../../store/form/types';

interface Props extends SimpleDialogProps {
  type: 'Submission' | 'Review';
  handleFormOk: (newForm: FormDTO) => void;
}

const AddFormDialog: React.FC<Props> = ({ type, handleFormOk, handleClose, ...rest }) => {
  const initForm: FormDTO = {
    id: 0,
    form_id: null,
    name: '',
    type: type,
  };

  const [newForm, setNewForm] = React.useState<FormDTO>(initForm);

  return (
    <SimpleDialog
      {...rest}
      title={`New ${newForm.type} Form`}
      handleOk={() => {
        handleFormOk(newForm);
      }}
      handleClose={handleClose}
      disableOkButton={!newForm.name}
    >
      <InputText
        placeholder="Enter name"
        label="Name"
        value={newForm.name}
        onChange={(e) => {
          setNewForm({ ...newForm, name: e.target.value });
        }}
        className="text-sm mb-4"
      />
    </SimpleDialog>
  );
};

export default AddFormDialog;
