import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { selectInfo, selectKeywordState, selectTrackRoles } from '../../store/selectors';
import { Role } from '../../store/conference/types';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import infoSlice from '../../store/info/slice';
import Loading from '../../components/Loading/Loading';
import { formatKeywordColumn } from '../../helpers/table';

interface Props {
  getInfoAction: (data: InfoGetDTO) => void;
  info: Info;
  loading: boolean;
  roleById: { [key: string]: Role };
  keywordById: { [key: number]: Keyword };
}

const AllReviewersBiddingPage: React.FC<Props> = ({ getInfoAction, info, loading, roleById, keywordById }) => {
  const friendlyName: TableFriendlyName = 'reviewers_bids';

  useEffect(() => {
    if (Object.keys(info[friendlyName].byId).length === 0) {
      getInfoAction({ friendlyName });
    }
  }, []);

  const chairInfo = info as ChairInfo;

  const { byId } = chairInfo.reviewers_bids;
  const inputData: unknown[] = [];
  Object.values(byId).forEach((register) => {
    const newRegister = { ...register };

    // Add Role description
    newRegister.role = roleById[newRegister.role_id].description;

    formatKeywordColumn(newRegister, keywordById);

    inputData.push(newRegister);
  });

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-xl mb-8 font-bold text-gray-700">Reviewers bids</h2>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={() => getInfoAction({ friendlyName })}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  info: state.info.info,
  loading: selectInfo(state).loading,
  roleById: selectTrackRoles(state),
  keywordById: selectKeywordState(state).keywordById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllReviewersBiddingPage);
