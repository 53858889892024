import React from 'react';
import { connect } from 'react-redux';
import ChangePasswordForm, { ChangePasswordFormFields } from '../components/ChangePasswordForm/ChangePasswordForm';
import Loading from '../components/Loading/Loading';
import ProfileForm, { ProfileFormFields } from '../components/ProfileForm/ProfileForm';
import { selectUser } from '../store/selectors';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import userSlice from '../store/user/slice';
import {
  PostChangePasswordDTO,
  UpdateMainAliasDTO,
  UpdateUserAvatarDTO,
  UpdateUserDTO,
  UserState,
} from '../store/user/types';
import AvatarForm from '../components/AvatarForm/AvatarForm';
import MainEmailForm from '../components/MainEmailForm/MainEmailForm';
import { Tabs, TabsItem } from '../components/ui';
import styled from 'styled-components';
import history from '../store/history';

interface SettingsPageProps {
  userState: UserState;
  updateUserAction: (data: UpdateUserDTO) => void;
  updateUserAvatarAction: (data: UpdateUserAvatarDTO) => void;
  deleteUserAvatarAction: () => void;
  changeUserPasswordAction: (data: PostChangePasswordDTO) => void;
  updateMainAliasAction: (data: UpdateMainAliasDTO) => void;
}

type SectionName = 'personal-info' | 'security' | 'email' | 'payments' | 'keywords';

const SettingsUserPage: React.FC<SettingsPageProps> = ({
  userState,
  updateUserAction,
  updateUserAvatarAction,
  deleteUserAvatarAction,
  changeUserPasswordAction,
  updateMainAliasAction,
}) => {
  const [section, setSection] = React.useState<SectionName>('personal-info');

  if (userState.loading) return <Loading />;

  const user = userState.data;

  let content;
  switch (section) {
    case 'personal-info':
      content = (
        <div className="w-full h-full flex flex-col">
          <AvatarForm
            user={user}
            save_handler={(avatar: File) => updateUserAvatarAction({ avatar })}
            delete_handler={() => deleteUserAvatarAction()}
          />
          <ProfileForm
            save_handler={(form: ProfileFormFields) => {
              const data = {
                ...form,
                personId: userState.data.person.id,
                country: form.country ?? '',
              };
              updateUserAction(data);
            }}
            user={user}
            onCancelClick={() => history.goBack()}
          />
        </div>
      );
      break;
    case 'security':
      content = (
        <div className="w-full h-full flex flex-col">
          <ChangePasswordForm
            save_handler={(form: ChangePasswordFormFields) => changeUserPasswordAction(form)}
            password_set={user.password_set}
            onCancelClick={() => history.goBack()}
          />
        </div>
      );
      break;
    case 'email':
      content = (
        <div className="w-full h-full flex flex-col">
          <MainEmailForm
            user_alias_by_id={user.user_alias_by_id}
            main_user_id={user.id}
            save_handler={(user_id: number) => updateMainAliasAction({ user_id })}
            onCancelClick={() => history.goBack()}
          />
        </div>
      );
      break;
    case 'payments':
      break;
  }

  return (
    <div className="h-full">
      <header className="relative">
        <Tabs className="flex-grow">
          <TabsItem
            label="Personal info"
            selected={section == 'personal-info'}
            onClick={() => setSection('personal-info')}
          />
          <TabsItem label="Security" selected={section == 'security'} onClick={() => setSection('security')} />
          <TabsItem label="E-mail" selected={section == 'email'} onClick={() => setSection('email')} />
          {/*<TabsItem label="Payments" selected={section == 'payments'} onClick={() => setSection('payments')} />*/}
        </Tabs>
      </header>
      <div className="h-full">{content}</div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  userState: selectUser(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateUserAction: (data: UpdateUserDTO) => dispatch(userSlice.actions.UPDATE(data)),
  updateUserAvatarAction: (data: UpdateUserAvatarDTO) => dispatch(userSlice.actions['AVATAR:UPDATE'](data)),
  deleteUserAvatarAction: () => dispatch(userSlice.actions['AVATAR:DELETE']()),
  changeUserPasswordAction: (data: PostChangePasswordDTO) => dispatch(userSlice.actions['CHANGE_PASSWORD'](data)),
  updateMainAliasAction: (data: UpdateMainAliasDTO) => dispatch(userSlice.actions['UPDATE_MAIN_ALIAS'](data)),
});

const StyledKeywordsWrapper = styled.div`
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 0.75rem;
    text-align: center;
  }

  > div > div > div > div {
    text-align: center;
    width: 100%;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(SettingsUserPage);
