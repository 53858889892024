import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import SendEmailForm from '../../SendEmailForm/SendEmailForm';
import {
  EmailFormState,
  EmailRecipientType,
  EmailSendDTO,
  EmailTemplate,
  PlaceholderByRecipientType,
  PreviewRow,
} from '../../../store/email/types';
import { selectEmailState } from '../../../store/selectors';
import emailSlice from '../../../store/email/slice';
import errorSlice from '../../../store/error/slice';

interface Props extends SimpleDialogProps {
  modelIds: number[];
  recipientType: EmailRecipientType;
  recipientIds?: number[];
  sendEmailAction: (data: EmailSendDTO) => void;
  clearValidationErrorsAction: () => void;
  removeEmailAction: () => void;
  removePreviewAction: () => void;
  formState: EmailFormState;
  emailRedux?: EmailSendDTO;
  preview?: PreviewRow[];
  placeholdersByRecipientType: PlaceholderByRecipientType;
  templatesById: { [key: number]: EmailTemplate };
  updateEmailAction: (data: EmailSendDTO) => void;
}

const SendEmailDialog: React.FC<Props> = ({
  modelIds,
  recipientType,
  recipientIds,
  sendEmailAction,
  clearValidationErrorsAction,
  removeEmailAction,
  removePreviewAction,
  formState,
  emailRedux,
  preview,
  placeholdersByRecipientType,
  templatesById,
  updateEmailAction,
  ...rest
}) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const email = emailRedux ?? {
    ids: modelIds,
    subject: '',
    body: '',
    dry_run: true,
    recipient_type: recipientType,
    recipient_ids: recipientIds,
    template_id: undefined,
  };

  const onCloseHandler = () => {
    removeEmailAction();
    removePreviewAction();
    clearValidationErrorsAction();
    rest.handleClose();
  };

  const getStep = () => {
    switch (formState) {
      case 'COMPOSE':
        return {
          okButtonText: 'Preview',
          handleOk: () => sendEmailAction(email),
        };
      case 'PREVIEW':
        return {
          previousButtonText: 'Continue editing',
          handlePrevious: () => removePreviewAction(),
          okButtonText: 'Send',
          handleOk: () => {
            if (email) {
              sendEmailAction({ ...email, dry_run: false });
            }
          },
        };
      case 'SENDING':
        return {
          okButtonText: 'Close',
          handleOk: onCloseHandler,
        };
    }
  };

  const step = getStep();

  return (
    <SimpleDialog
      {...rest}
      previousButtonText={step?.previousButtonText}
      handlePrevious={step?.handlePrevious}
      okButtonText={step?.okButtonText}
      handleOk={step?.handleOk}
      disableOkButton={!isValid}
      closeOnOk={formState == 'SENDING'}
      hideCancel={formState == 'SENDING'}
      handleClose={formState != 'SENDING' ? onCloseHandler : () => {}} // In sending step, we don't want users to close modal in a way that is not clicking the Close button
    >
      <SendEmailForm
        placeholders={placeholdersByRecipientType[recipientType]}
        formState={formState}
        preview={preview}
        email={email}
        onChange={(value) => {
          setIsValid(!!value.subject && !!value.body);
          updateEmailAction({ ...email, ...value });
        }}
        templatesById={templatesById}
      />
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  formState: selectEmailState(state).formState,
  emailRedux: selectEmailState(state).email as EmailSendDTO | undefined,
  preview: selectEmailState(state).preview,
  placeholdersByRecipientType: selectEmailState(state).placeholdersByRecipientType,
  templatesById: selectEmailState(state).templatesById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendEmailAction: (data: EmailSendDTO) => dispatch(emailSlice.actions['SEND'](data)),
  updateEmailAction: (data: EmailSendDTO) => dispatch(emailSlice.actions['UPDATE:EMAIL'](data)),
  clearValidationErrorsAction: () => dispatch(errorSlice.actions['CLEAR:VALIDATION_ERRORS']()),
  removeEmailAction: () => dispatch(emailSlice.actions['REMOVE:EMAIL']()),
  removePreviewAction: () => dispatch(emailSlice.actions['REMOVE:PREVIEW']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailDialog);
