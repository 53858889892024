import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { RadioButton, TextArea } from '../../ui';
import React from 'react';
import { InvitationStatus } from '../../../store/auth/types';
import styled from 'styled-components';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleOk: (data: { status: InvitationStatus; comment: string }) => void;
}

const RespondInvitationDialog: React.FC<Props> = ({ open, handleClose, handleOk }) => {
  const [radioValue, setRadioValue] = React.useState<InvitationStatus>('Accepted');
  const [textValue, setTextValue] = React.useState('');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value as InvitationStatus);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
  };

  return (
    <SimpleDialog
      title="Respond invitation"
      open={open}
      handleClose={handleClose}
      handleOk={() => handleOk({ status: radioValue, comment: textValue })}
    >
      <StyledDialog className="flex flex-col mb-5">
        <RadioButton
          name="options"
          label="Accept"
          checked={radioValue === 'Accepted'}
          value="Accepted"
          onChange={handleRadioChange}
          className="mb-2"
        />
        <RadioButton
          name="options"
          label="Decline"
          checked={radioValue === 'Declined'}
          value="Declined"
          onChange={handleRadioChange}
          className="mb-2"
        />
        <TextArea
          label="Write a comment about yout decision."
          value={textValue}
          onChange={handleTextChange}
          className="mt-1"
        />
      </StyledDialog>
    </SimpleDialog>
  );
};

export default RespondInvitationDialog;

const StyledDialog = styled.div`
  > div {
    margin-bottom: 0.5rem;
  }
  input {
    margin-bottom: 0;
  }
`;
