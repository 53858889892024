import React, { useState } from 'react';
import PaperIcon from '../icons/Paper';
import SettingsIcon from '../icons/Settings';
import UsersIcon from '../icons/Users';
import EmailIcon from '../icons/Email';
import { connect } from 'react-redux';
import { can } from '../helpers/permissions';
import authSlice from '../store/auth/slice';
import { Conference, ConferenceState, Role, Track } from '../store/conference/types';
import {
  selectAuth,
  selectCurrentConference,
  selectCurrentRole,
  selectCurrentTrack,
  selectSideMenuState,
  selectUser,
  selectKeywordState,
} from '../store/selectors';
import sidemenuSlice from '../store/sidemenu/slice';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { appRoutes, getRouteByName, getRouteByPath } from './routes';
import { generatePath } from 'react-router';
import history from '../store/history';
import MainLayout from '../layouts/MainLayout';
import { Link } from '../components/ui';
import { UserState } from '../store/user/types';
import conferenceSlice from '../store/conference/slice';
import { MainItems, MenuItems } from '../layouts/types';
import { useTheme } from '@material-ui/core/styles';
import Bidding from '../icons/Bidding';
import Assignment from '../icons/Assignment';
import styled from 'styled-components';
import ConversationIcon from '../icons/Conversation';
import Dashboard from '../icons/Dashboard';
import { ReactComponent as LogoMini } from '../images/logo-mini.svg';
import { ReactComponent as Logo } from '../images/logo.svg';
import ChevronDownIcon from '../icons/ChevronDown';
import { SelectRadioButton } from '../components/ui';
import { Link as RouterLink } from 'react-router-dom';
import { addSearchParams } from '../helpers/download';
import { getUserRoles } from '../helpers/role';

interface LayoutCtrlProps {
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
  menuclick: (item: string) => void;
  children: React.ReactNode;
  currentTrack: Track | null;
  currentRole: Role | null;
  currentConference: Conference | null;
  user: UserState;
  conferenceState: ConferenceState;
  selectRoleAction: (trackId: number, roleId: number) => void;
  skipPadding?: boolean;
  isImpersonated: boolean;
  leaveImpersonationAction: () => void;
  keywordState: KeywordState;
}
const main_items = (conference: Conference, track: Track, currentRole: Role | null): MainItems => {
  switch (currentRole?.type) {
    case 'author':
      return [
        {
          name: 'Submissions',
          route_name: 'RouteConferenceHome',
          icon: PaperIcon,
          params: { conference: conference.slug, track: track.slug },
        },
        {
          name: 'User Keywords',
          route_name: 'RouteKeywords',
          icon: SettingsIcon,
          params: { conference: conference.slug, track: track.slug },
        },
      ];
    case 'reviewer':
      return [
        {
          name: 'Submissions',
          route_name: 'RouteConferenceHome',
          icon: PaperIcon,
          params: { conference: conference.slug, track: track.slug },
        },
        {
          name: 'User Keywords',
          route_name: 'RouteKeywords',
          icon: SettingsIcon,
          params: { conference: conference.slug, track: track.slug },
        },
        {
          name: 'Bidding',
          route_name: 'RouteBidding',
          icon: Bidding,
          permission: 'ACCESS_BIDDING',
          params: { conference: conference.slug, track: track.slug },
        },
      ];
    case 'chair':
      return [
        {
          name: 'Dashboard',
          route_name: 'RouteConferenceHome',
          icon: Dashboard,
          params: { conference: conference.slug, track: track.slug },
        },
        {
          name: 'People',
          route_name: 'RoutePeople',
          icon: UsersIcon,
          permission: 'ACCESS_PEOPLE',
          params: { conference: conference.slug, track: track.slug },
        },
        {
          name: 'Submissions',
          route_name: 'RouteAllSubmissions',
          icon: PaperIcon,
          permission: 'ACCESS_ALL_SUBMISSIONS',
          params: { conference: conference.slug, track: track.slug },
        },
        {
          name: 'Bidding',
          route_name: 'RouteAllBidding',
          icon: Bidding,
          permission: 'ACCESS_ALL_BIDDING',
          params: { conference: conference.slug, track: track.slug },
          subitems: [
            {
              name: 'Reviewers bids',
              route_name: 'RouteAllReviewersBids',
              icon: Bidding,
              permission: 'ACCESS_ALL_BIDDING',
              params: { conference: conference.slug, track: track.slug },
            },
            {
              name: 'Submissions bids',
              route_name: 'RouteAllSubmissionsBids',
              icon: Bidding,
              permission: 'ACCESS_ALL_BIDDING',
              params: { conference: conference.slug, track: track.slug },
            },
          ],
        },
        {
          name: 'Assignments',
          route_name: 'RouteAssignments',
          icon: Assignment,
          permission: 'ACCESS_ALL_REVIEWS',
          params: { conference: conference.slug, track: track.slug },
          subitems: [
            {
              name: 'Bulk import',
              route_name: 'RouteAssigmentBulkImport',
              icon: Assignment,
              permission: 'ACCESS_ALL_REVIEWS',
              params: { conference: conference.slug, track: track.slug },
            },
            {
              name: 'Reviewers to papers',
              route_name: 'RouteAssigmentReviewerToPaper',
              icon: Assignment,
              permission: 'ACCESS_ALL_REVIEWS',
              params: { conference: conference.slug, track: track.slug },
            },
            {
              name: 'Papers to reviewers',
              route_name: 'RouteAssigmentPaperToReviewer',
              icon: Assignment,
              permission: 'ACCESS_ALL_REVIEWS',
              params: { conference: conference.slug, track: track.slug },
            },
          ],
        },
        {
          name: 'Communications',
          route_name: 'RouteRecruitmentPage',
          icon: EmailIcon,
          permission: 'ACCESS_COMMUNICATIONS',
          params: { conference: conference.slug, track: track.slug },
          subitems: [
            {
              name: 'Email templates',
              route_name: 'RouteEmailTemplatesPage',
              icon: EmailIcon,
              permission: 'ACCESS_COMMUNICATIONS',
              params: { conference: conference.slug, track: track.slug },
            },
            {
              name: 'Email history',
              route_name: 'RouteEmailHistoryPage',
              icon: EmailIcon,
              permission: 'ACCESS_COMMUNICATIONS',
              params: { conference: conference.slug, track: track.slug },
            },
            {
              name: 'Recruitment',
              route_name: 'RouteRecruitmentPage',
              icon: EmailIcon,
              permission: 'ACCESS_COMMUNICATIONS',
              params: { conference: conference.slug, track: track.slug },
            },
          ],
        },
        {
          name: 'Chair settings',
          icon: SettingsIcon,
          route_name: 'RouteSettingsRoles',
          permission: 'ACCESS_TRACK_SETTINGS',
          params: { conference: conference.slug, track: track.slug },
          subitems: [
            {
              name: 'Roles',
              icon: SettingsIcon,
              route_name: 'RouteSettingsRoles',
              permission: 'ACCESS_TRACK_SETTINGS',
              params: { conference: conference.slug, track: track.slug },
            },
            {
              name: 'Keywords',
              icon: SettingsIcon,
              route_name: 'RouteSettingsKeywords',
              permission: 'ACCESS_TRACK_SETTINGS',
              params: { conference: conference.slug, track: track.slug },
            },

            {
              name: 'Paper status',
              icon: SettingsIcon,
              route_name: 'RouteSettingsPaperStatus',
              permission: 'ACCESS_TRACK_SETTINGS',
              params: { conference: conference.slug, track: track.slug },
            },

            {
              name: 'Forms',
              icon: SettingsIcon,
              route_name: 'RouteSettingsForms',
              permission: 'ACCESS_TRACK_SETTINGS',
              params: { conference: conference.slug, track: track.slug },
            },
            {
              name: 'Planning',
              icon: SettingsIcon,
              route_name: 'RouteSettingsPlanning',
              permission: 'ACCESS_TRACK_SETTINGS',
              params: { conference: conference.slug, track: track.slug },
            },
            {
              name: 'Announcements',
              icon: SettingsIcon,
              route_name: 'RouteSettingsAnnouncements',
              permission: 'ACCESS_TRACK_SETTINGS',
              params: { conference: conference.slug, track: track.slug },
            },
            {
              name: 'Conference',
              icon: SettingsIcon,
              route_name: 'RouteSettingsConference',
              permission: 'ACCESS_TRACK_SETTINGS',
              params: { conference: conference.slug, track: track.slug },
            },
          ],
        },
      ];
    default:
      return [];
  }
};

const LayoutCtrl: React.FC<LayoutCtrlProps> = ({
  menuOpen,
  setMenuOpen,
  menuclick,
  currentTrack,
  currentRole,
  currentConference,
  user,
  conferenceState,
  selectRoleAction,
  children,
  isImpersonated,
  leaveImpersonationAction,
  keywordState,
}) => {
  const [roleValue, setRole] = React.useState<string | undefined>(currentRole?.id.toString());
  const [profileMenu, setProfileMenu] = useState(false);

  const route = getRouteByPath(window.location.pathname);

  const selectedOption = route.main_menu_option || '';
  const theme = useTheme();

  const itemClicked = (item: string) => {
    menuclick(item);
    let menuItem = undefined;
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].name === item) {
        menuItem = menuItems[i];
        break;
      }
      if (menuItems[i].subitems) {
        const num_subitems = menuItems[i].subitems?.length ?? 0;
        for (let j = 0; j < num_subitems; j++) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (menuItems[i].subitems[j].name === item) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            menuItem = menuItems[i].subitems[j];
          }
        }
      }
    }
    const route_name = menuItem?.route_name || '';
    const findroute = appRoutes.filter((element) => element.name === route_name);
    if (findroute.length > 0) {
      const path = menuItem?.params ? generatePath(findroute[0].path, menuItem.params) : findroute[0].path;
      history.push(path);
    }
  };

  const doClick = (item: MenuItems, isMenuSubitem: boolean) => {
    if (item.name === 'Hide Menu') {
      setMenuOpen(!menuOpen);
    } else if (isMenuSubitem) {
      itemClicked(item.name);
    } else if (item.subitems) {
      setMenuOpen(false);
    } else {
      itemClicked(item.name);
    }
  };

  const handleRoleSelectChange = (role: string) => {
    if (currentTrack) {
      selectRoleAction(currentTrack.id, parseInt(role));
    }
  };

  const handleAvatarClick = () => {
    history.push(getRouteByName('RouteSettingsUser').path);
  };

  let menuItems: MainItems = [];
  let header;
  const userName = user.data.person.full_name;

  // Build selectors options
  const roleOptions: {
    id: string;
    label: string;
  }[] = [];

  let logo = (
    <Link className={`${!isImpersonated ? 'cursor-pointer' : 'cursor-default'}`}>
      <StyledBeta>BETA</StyledBeta>
      {!menuOpen ? <Logo title="Home" /> : <LogoMini title="Home" />}
    </Link>
  );
  {
    /* Logo is clickable only if the user is not impersonated */
  }
  if (!isImpersonated) {
    logo = <RouterLink to={getRouteByName('RouteExitConference').path}>{logo}</RouterLink>;
  }

  let url = new URL(window.location.origin + getRouteByName('RouteLogout').path);
  url = addSearchParams(url, { next: window.location.pathname });

  const logoutEl = (
    <RouterLink to={url.pathname + url.search}>
      <Link className="cursor-pointer flex">
        <span style={{ flexGrow: 1 }}>Log out</span>
      </Link>
    </RouterLink>
  );

  if (route.name === 'RouteConferences' || !currentConference) {
    header = (
      <>
        {/* LOGO WRAPPER */}
        <div className="relative" style={{ marginLeft: '-8px' }}>
          {logo}
        </div>

        <div className="flex justify-between">
          {/* PROFILE BUTTON */}
          <div className="flex flex-col relative">
            <div
              className={`flex justify-center my-3 self-center cursor-pointer`}
              onClick={() => setProfileMenu(!profileMenu)}
              style={{ color: theme.palette.primary.main }}
            >
              <div title={!menuOpen ? '' : 'Profile'}>
                {user.data.person.avatar_url ? (
                  <img
                    className="inline object-cover w-12 h-12 rounded-full"
                    src={user.data.person.avatar_url}
                    alt="profile"
                  />
                ) : (
                  <UsersIcon width="40" height="40" />
                )}
              </div>
              <div className="self-center">
                <p className="font-medium text-base ml-2 mr-2">{userName}</p>
              </div>
              <div className="self-center ml-2">
                <ChevronDownIcon />
              </div>
            </div>

            {profileMenu && (
              <StyledProfileMenu className="border-t border-gray-200 rounded-b-md shadow-md">
                <div className="flex mb-4 mt-1">
                  <Link className="cursor-pointer" onClick={handleAvatarClick}>
                    <span className="ml-3">Profile</span>
                  </Link>
                </div>

                <hr className="divide-y divide-gray-950 mt-2 mb-4"></hr>

                <div className="flex my-1 ml-2">{logoutEl}</div>
              </StyledProfileMenu>
            )}
          </div>
        </div>
      </>
    );
  } else {
    const conference_info = (
      <div className="flex items-center font-bold text-base align-baseline">
        <ConversationIcon />
        {currentConference && <span className="ml-4">{currentConference.name}</span>}
      </div>
    );

    if (currentConference && currentTrack && currentRole) {
      // Filter menu items based on the user permissions
      menuItems = main_items(currentConference, currentTrack, currentRole).filter((value) => {
        return value.permission ? can(value.permission) : true;
      });

      // Filter menu items when there's no keywords
      if (Object.keys(keywordState.keywordById).length === 0) {
        menuItems = menuItems.filter((menuItem) => menuItem.route_name !== 'RouteKeywords');
      }

      const roles = getUserRoles(currentTrack.id, conferenceState.userRolesByTrack, conferenceState.roleById);
      roles.forEach((role) => {
        roleOptions.push({ id: role.id.toString(), label: role.description });
      });
    }

    // Define header
    header = (
      <div className="flex justify-between w-full">
        {conference_info}

        {/* PROFILE BUTTON */}
        <div className="flex flex-col relative">
          <div
            className={`flex justify-center my-3 self-center cursor-pointer`}
            onClick={() => setProfileMenu(!profileMenu)}
            style={{ color: theme.palette.primary.main }}
          >
            <div title={!menuOpen ? '' : 'Profile'}>
              {user.data.person.avatar_url ? (
                <img
                  className="inline object-cover w-12 h-12 rounded-full"
                  src={user.data.person.avatar_url}
                  alt="profile"
                />
              ) : (
                <UsersIcon width="40" height="40" />
              )}
            </div>
            <div className="self-center">
              <p className="font-medium text-base ml-2 mr-2">{userName}</p>
              <p className="text-xs text-gray-500 ml-2">Role: {currentRole?.description}</p>
            </div>
            <div className="self-center ml-2">
              <ChevronDownIcon />
            </div>
          </div>

          {profileMenu && (
            <StyledProfileMenu className="border-t border-gray-200 rounded-b-md shadow-md">
              <div className="flex flex-wrap">
                <span style={{ flexGrow: 1 }}>Your role</span>

                <SelectRadioButton
                  value={roleValue}
                  options={roleOptions ?? roleOptions}
                  onChange={roleOptions ? handleRoleSelectChange : undefined}
                  className="w-full flex flex-col ml-3 mt-1"
                />
              </div>

              {!isImpersonated && (
                <>
                  <hr className="divide-y divide-gray-950 my-2"></hr>

                  <div className="flex my-4">
                    <Link className="cursor-pointer flex" onClick={handleAvatarClick}>
                      <span style={{ flexGrow: 1 }}>Profile</span>
                    </Link>
                  </div>
                </>
              )}

              <hr className="divide-y divide-gray-950 mt-2 mb-4"></hr>

              {!isImpersonated && (
                <div className="flex my-2">
                  <RouterLink to={getRouteByName('RouteExitConference').path}>
                    <Link className="flex items-center 'cursor-pointer" title={menuOpen ? '' : 'Exit conference'}>
                      <span style={{ flexGrow: 1 }}>Exit conference</span>
                    </Link>
                  </RouterLink>
                </div>
              )}
              <div className="flex my-2">{logoutEl}</div>
            </StyledProfileMenu>
          )}
        </div>
      </div>
    );
  }

  const uiprops = {
    main_items: menuItems,
    selectedOption,
    doClick,
    logo,
    header,
    menuOpen,
    handleAvatarClick: isImpersonated ? undefined : handleAvatarClick,
    userName: userName,
    children,
    isImpersonated,
    currentConference,
    leaveImpersonationAction,
    currentTrack,
  };

  return (
    <>
      <MainLayout {...uiprops} />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  menuOpen: selectSideMenuState(state).menu_open,
  currentTrack: selectCurrentTrack(state),
  currentRole: selectCurrentRole(state),
  currentConference: selectCurrentConference(state),
  user: selectUser(state),
  conferenceState: state.conference,
  isImpersonated: !!selectAuth(state).impersonatedTrackId,
  role: selectCurrentRole(state),
  keywordState: selectKeywordState(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setMenuOpen: (menuOpen: boolean) => dispatch(sidemenuSlice.actions.SIDEMENU_SET_MENU_OPEN(menuOpen)),
  menuclick: (item: string) => dispatch(sidemenuSlice.actions.SIDEMENU_CLICK(item)),
  selectRoleAction: (trackId: number, roleId: number) =>
    dispatch(conferenceSlice.actions.SELECT_ROLE({ trackId, roleId, doRedirect: true })),
  leaveImpersonationAction: () => dispatch(authSlice.actions.LEAVE_IMPERSONATION()),
});

const StyledBeta = styled.div`
  font-weight: bold;
  background-color: rgb(0, 68, 240);
  width: max-content;
  padding: 1px 20px;
  color: #fff;
  font-size: 0.6rem;
  position: absolute;
  top: -8px;
  left: -43px;
  opacity: 1;
  transform: rotate(310deg);
  box-shadow: rgba(0, 0, 0, 0.4) -2px 2px 4px 0px;
`;

const StyledProfileMenu = styled.div`
  position: absolute;
  top: 4rem;
  z-index: 1000;
  background-color: #fafafa;
  padding: 1.5rem 2rem;
  min-width: 14rem;
  right: -1rem;

  div > div {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(LayoutCtrl);
