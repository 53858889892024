import React from 'react';
import { UserAlias } from '../../store/user/types';
import { Button, Link, RadioButton } from '../ui';
import styled from 'styled-components';

export interface MainEmailFormProps {
  user_alias_by_id: { [key: number]: UserAlias };
  main_user_id: number;
  save_handler: (user_id: number) => void;
  onCancelClick: () => void;
}

const MainEmailForm: React.FC<MainEmailFormProps> = ({
  user_alias_by_id,
  main_user_id,
  save_handler,
  onCancelClick,
}) => {
  const [checked, setChecked] = React.useState<number>(main_user_id);

  const listItems = Object.values(user_alias_by_id).map((userAlias, index) => {
    const { id: value, email } = userAlias;
    return (
      <div key={index} className="mb-3">
        <RadioButton
          name="options"
          label={email}
          checked={value === checked}
          value={value}
          onChange={(e) => setChecked(parseInt(e.target.value))}
        />
      </div>
    );
  });

  return (
    <>
      <StyledProfileContentWrapper>
        <p className="text-2xl mt-14 mb-3 text-center font-semibold text-gray-600">E-mail</p>
        <p className="mb-9 text-center text-gray-600">Change main email.</p>
        <div className="flex flex-col mb-3">{listItems}</div>
      </StyledProfileContentWrapper>

      <StyledButtons>
        <StyledCancel
          onClick={(e) => {
            setChecked(main_user_id);
            onCancelClick();
          }}
          autoFocus
          variant="text"
        >
          Cancel
        </StyledCancel>

        <Button className="w-48 place-self-center" onClick={() => save_handler(checked)}>
          <span className="px-14">Save</span>
        </Button>
      </StyledButtons>
    </>
  );
};

const StyledButtons = styled.div`
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 2px dotted #e3e5e6;
  padding: 1rem 0;
  position: sticky;
  bottom: 0;
  background-color: rgb(237, 243, 255);
  z-index: 10;
`;

const StyledCancel = styled(Button)`
  color: rgb(220 38 38);
  font-weight: 700;
`;

const StyledProfileContentWrapper = styled.div`
  max-width: 384px;
  margin: 0 auto;
  flex-grow: 1;
`;

export default MainEmailForm;
