import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ValidatedField } from '../api.d';
import {
  AuthState,
  ForgotPasswordDTO,
  LoginOAuthDTO,
  LoginRequest,
  OAuthFields,
  ResetPasswordDTO,
  SignUpDTO,
  VerifyEmailDTO,
  RespondInvitationRequest,
  GetInvitationRequest,
  Invitation,
  SignUpInvitationRequest,
  RespondInvitationOkDTO,
  ImpersonateRequest,
} from './types';

export const initialState: AuthState = {
  loggedIn: false,
  impersonatedTrackId: null,
  isSuperAdmin: false,
  loading: false,
};

const authSlice = createSlice({
  name: 'AUTH',
  initialState,
  reducers: {
    ['LOADING']: (state) => ({
      ...state,
      loading: true,
    }),
    ['LOGIN']: (state, _action: PayloadAction<LoginRequest>) => ({
      ...state,
      loading: true,
    }),
    ['LOGIN:OK']: (state) => ({
      ...state,
      loggedIn: true,
      loading: false,
    }),
    ['LOGIN:KO']: (state, action: PayloadAction<ValidatedField[] | undefined>) => ({
      ...state,
      loggedIn: false,
      loading: false,
      validationError: action.payload ? { ...action.payload } : undefined,
    }),
    ['LOGOUT']: (state) => ({
      ...state,
      loading: true,
    }),
    ['LOGOUT:OK']: (state) => ({
      ...state,
      loggedIn: false,
      loading: false,
    }),
    ['LOGOUT:KO']: (state) => ({
      ...state,
      loggedIn: true,
      loading: false,
    }),
    ['SIGNUP']: (state, _action: PayloadAction<SignUpDTO>) => ({
      ...state,
    }),
    ['SIGNUP:OK']: (state) => ({
      ...state,
      signup: initialState.signup,
    }),
    ['SIGNUP:KO']: (state, action: PayloadAction<ValidatedField[] | undefined>) => {
      return action.payload ? { ...state, validationError: { ...action.payload } } : { ...state };
    },
    ['CLEAR:VALIDATION']: (state) => {
      const new_state_clear: AuthState = {
        ...state,
      };
      delete new_state_clear.validationError;
      return new_state_clear;
    },
    ['SIGNUP_FILL_FORM']: (state, action: PayloadAction<OAuthFields>) => ({
      ...state,
      loading: false,
      signup: action.payload,
    }),
    ['SIGNUP_OAUTH']: (state, _action: PayloadAction<OAuthFields>) => ({
      ...state,
    }),
    ['SIGNUP_OAUTH:KO']: (state, _action: PayloadAction<ValidatedField[]>) => ({
      ...state,
    }),
    ['LOGIN_OAUTH']: (state, _action: PayloadAction<LoginOAuthDTO>) => ({
      ...state,
      loading: true,
    }),
    ['FORGOT_PASSWORD']: (state, _action: PayloadAction<ForgotPasswordDTO>) => ({
      ...state,
    }),
    ['RESET_PASSWORD']: (state, _action: PayloadAction<ResetPasswordDTO>) => ({
      ...state,
    }),
    ['SEND_VERIFICATION_EMAIL']: (state) => ({
      ...state,
    }),
    ['VERIFY_EMAIL']: (state, _action: PayloadAction<VerifyEmailDTO>) => ({
      ...state,
      loading: true,
    }),
    ['RESPOND_INVITATION']: (state, _action: PayloadAction<RespondInvitationRequest>) => ({
      ...state,
    }),
    ['RESPOND_INVITATION:OK']: (state, _action: PayloadAction<RespondInvitationOkDTO>) => {
      const newState = { ...state };
      if (state.invitation) {
        newState.invitation = { ...state.invitation, ..._action.payload };
      }
      return newState;
    },
    ['RESPOND_INVITATION:KO']: (state, _action: PayloadAction<ValidatedField[]>) => ({
      ...state,
    }),
    ['SIGNUP_INVITATION']: (state, _action: PayloadAction<SignUpInvitationRequest>) => ({
      ...state,
      loading: true,
    }),
    ['SIGNUP_INVITATION:OK']: (state) => ({
      ...state,
      invitation: initialState.invitation,
    }),
    ['SIGNUP_INVITATION:KO']: (state, _action: PayloadAction<ValidatedField[]>) => ({
      ...state,
      loading: false,
    }),
    ['GET_INVITATION']: (state, _action: PayloadAction<GetInvitationRequest>) => ({
      ...state,
      invitationLoading: true,
    }),
    ['GET_INVITATION:OK']: (state, _action: PayloadAction<Invitation>) => ({
      ...state,
      invitation: _action.payload,
      invitationLoading: false,
    }),
    ['GET_INVITATION:KO']: (state, action: PayloadAction<ValidatedField[] | undefined>) => ({
      ...state,
      invitationLoading: false,
      validationError: action.payload,
    }),
    ['IMPERSONATE']: (state, action: PayloadAction<ImpersonateRequest>) => ({ ...state, loading: true }),
    ['IMPERSONATE:KO']: (state) => ({ ...state, loading: false }),
    ['LEAVE_IMPERSONATION']: (state) => ({ ...state, loading: true }),
    ['SET_EXTRA_FIELDS']: (state, action: PayloadAction<{ impersonated: number | null; is_superadmin: boolean }>) => ({
      ...state,
      impersonatedTrackId: action.payload.impersonated,
      isSuperAdmin: action.payload.is_superadmin,
    }),
  },
});

export default authSlice;
