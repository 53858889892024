import { Theme } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { FilterDescriptor, process, State } from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import {
  getSelectedState,
  Grid,
  GridColumn,
  GridColumnProps,
  GridColumnReorderEvent,
  GridColumnResizeEvent,
  GridDataStateChangeEvent,
  GridDetailRowProps,
  GridExpandChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridRowProps,
  GridSelectionChangeEvent,
} from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import 'react-filter-box/lib/react-filter-box.css';
import { Option } from 'react-filter-box/src/GridDataAutoCompleteHandler';
import styled, { ThemeProvider } from 'styled-components';
import { FilterBox } from '../../store/table/types';
import ActionsCell from '../ActionsCell/ActionsCell';
import { Link } from '../ui';
import MyGridColumnMenu from './MyGridColumnMenu';
import './MyGridCore.styles.css';
import { gridSettingsProps } from './types';
// @ts-ignore
import ReactFilterBox, { Expression, SimpleResultProcessing } from 'react-filter-box';
import { getInitialDataState, getInitialFilterBox } from '../../store/table/slice';

/**
 * Build options dynamically for react-filter-box based on grid settings.
 */
const initOptions = (gridSettings: gridSettingsProps): Option[] => {
  return gridSettings.gridColumnProps
    .filter((props) => {
      return 'filterable' in props ? props.filterable : true;
    })
    .map((props) => {
      let type = 'text';
      if (props.filter && props.filter == 'numeric') {
        type = 'numeric';
      }
      return {
        columnField: props.field,
        columnText: props.title ? props.title : props.field,
        type,
      };
    });
};

const EXPANDED_FIELD = 'expanded';

export interface Props {
  allGridData: Record<string, unknown>[];
  gridSettings: gridSettingsProps;
  onGridSettingsChange?: (gridSettings: gridSettingsProps) => void;
  dataStateValue?: State;
  filterBoxValue?: FilterBox;
  selectedState: {
    [id: string]: boolean | number[];
  };
  handleSelectRow?: (data: { [p: string]: boolean | number[] }) => void;
  onDataStateChange?: (dataState: State) => void;
  onFilterBoxChange?: (filterBox: FilterBox) => void;
  dateFormat: string | null;
  detail?: React.ComponentType<GridDetailRowProps> | null;
  expandedState: {
    [id: string]: boolean | number[];
  };
  onExpandChange?: (event: GridExpandChangeEvent) => void;
  selectable: boolean;
  scrollToSelectedRow?: number;
  showActionsColumn: boolean;
}

const MyGridCore: React.FC<Props> = ({
  allGridData,
  gridSettings,
  onGridSettingsChange,
  dataStateValue,
  filterBoxValue,
  selectedState,
  handleSelectRow,
  onDataStateChange,
  onFilterBoxChange,
  dateFormat,
  detail,
  expandedState,
  onExpandChange,
  selectable,
  scrollToSelectedRow,
  showActionsColumn,
}) => {
  const scrollTo = (rowIndex: number) => {
    if (gridRef.current) {
      gridRef.current.scrollIntoView({
        rowIndex: rowIndex,
      });
    }
  };

  /* hooks */
  const [internalDataState, setInternalDataState] = useState<State>(() => dataStateValue ?? getInitialDataState());
  const [options] = useState<Option[]>(() => initOptions(gridSettings));
  const gridRef = React.useRef<any>(null);

  const isControlled = dataStateValue !== undefined;
  const dataState = isControlled ? dataStateValue : internalDataState;
  const filterBox = filterBoxValue ?? getInitialFilterBox();

  useEffect(() => {
    if (scrollToSelectedRow) {
      // Find the position in the array of the given ID and add 1 to the position
      const rowIndex =
        dataResult && dataResult.data.length > 0
          ? dataResult.data.findIndex((element) => idGetter(element) === scrollToSelectedRow) + 1
          : 0;
      scrollTo(rowIndex);
    }
  }, []);

  const handleDataStateChange = (newDataState: State) => {
    if (isControlled) {
      // Sync redux externally
      onDataStateChange?.(newDataState);
    } else {
      // Update internally
      setInternalDataState(newDataState);
    }
  };

  const isArrayColumn = (fieldName: string) => {
    return gridSettings.arrayFields.includes(fieldName);
  };

  /* Prepare filter operators */
  /**
   * Generic operator targeted to compare multi-valued strings separated by a token (;)
   * @param value
   * @param pattern
   * @param operator
   */
  const myArrayOperator = (operator: string) => (value: string, pattern: string) => {
    const result = value.split(';').filter((str) => {
      switch (operator) {
        case 'contains':
          return str.includes(pattern);
        case 'doesnotcontain':
          return !str.includes(pattern);
        case 'eq':
          return str === pattern;
        case 'neq':
          return str !== pattern;
        case 'startswith':
          return str.startsWith(pattern);
        case 'endswith':
          return str.endsWith(pattern);
        case 'isempty':
          return !str;
        case 'isnotempty':
          return !!str;
      }
    });
    return result.length > 0;
  };
  const myIsEmptyOperator = (value: string, pattern: string) => {
    return !value;
  };
  const myIsNotEmptyOperator = (value: string, pattern: string) => {
    return !!value;
  };

  /**
   * Applies filters on data
   * @param expressions
   * @param dataState
   */
  const processData = (expressions: Expression[], dataState: State) => {
    const newData = new SimpleResultProcessing(options).process(allGridData, expressions);
    let newDataState;

    // Swap filter operator to array specific for array fields
    if (dataState.filter) {
      newDataState = { ...dataState, filter: { ...dataState.filter, filters: [...dataState.filter.filters] } };
      newDataState.filter.filters = newDataState.filter.filters.map((filter) => {
        const f = { ...filter } as FilterDescriptor;
        if (typeof f.field === 'string' && typeof f.operator === 'string' && isArrayColumn(f.field)) {
          f.operator = myArrayOperator(f.operator);
        }
        return f;
      });
    } else {
      newDataState = dataState;
    }
    return process(newData, newDataState);
  };

  const columnReorderChange = (event: GridColumnReorderEvent) => {
    const orderIndexByColumnId: { [key: string]: number } = {};
    event.columns.forEach((c) => {
      // @ts-ignore
      orderIndexByColumnId[c.id] = c.orderIndex;
    });

    const newGridColumnProps: GridColumnProps[] = gridSettings.gridColumnProps.map((c) => {
      return {
        ...c,
        orderIndex: orderIndexByColumnId[c.id] ?? c.orderIndex,
      };
    });

    const newGridSettings = {
      ...gridSettings,
      gridColumnProps: newGridColumnProps,
    };

    onGridSettingsChange?.(newGridSettings);
  };

  const columnResizeChange = (event: GridColumnResizeEvent) => {
    if (!event.end) return;

    const newGridColumnProps = gridSettings.gridColumnProps.map((column) => {
      if (column.id === event.targetColumnId) {
        return { ...column, width: event.newWidth + 'px' };
      } else {
        return column;
      }
    });

    const newGridSettings = {
      ...gridSettings,
      gridColumnProps: newGridColumnProps,
    };

    onGridSettingsChange?.(newGridSettings);
  };

  const onLockedColumnChange = (value: string[]) => {
    const newGridColumnProps = gridSettings.gridColumnProps.map((c) => {
      return {
        ...c,
        locked: value.includes(c.id),
      };
    });

    const newGridSettings = {
      ...gridSettings,
      gridColumnProps: newGridColumnProps,
    };

    onGridSettingsChange?.(newGridSettings);
  };

  const onHiddenColumnChange = (columnId: string) => {
    const newGridColumnProps = gridSettings.gridColumnProps.map((c) => {
      if (c.id === columnId) {
        const show = !c.show;
        return { ...c, show, orderIndex: show ? c.orderIndex : 0 };
      } else {
        return c;
      }
    });

    const newGridSettings = {
      ...gridSettings,
      gridColumnProps: newGridColumnProps,
    };

    onGridSettingsChange?.(newGridSettings);
  };

  function onParseOk(expressions: Expression[]) {
    // Set skip to 0 in order to move pagination to the first page
    const newDataState = {
      ...dataState,
      skip: 0,
    };
    const newFilterBox = { ...filterBox, expressions };
    onFilterBoxChange?.(newFilterBox);
    handleDataStateChange(newDataState);
  }

  const selectRowsBulk = (rowIds: number[]) => {
    const newSelectedState = { ...selectedState };
    rowIds.forEach((id) => {
      newSelectedState[id] = true;
    });
    handleSelectRow?.(newSelectedState);
  };

  function getGridColumnsFromProps() {
    return gridSettings.gridColumnProps
      .filter((props) => ('show' in props ? props.show : true))
      .map((props) => {
        const newProps = { ...props };

        // Decide here what format to use when displaying the date field.
        if (newProps.filter === 'date') {
          newProps.format = `{0:${dateFormat || 'dd/MM/y HH:mm'}}`;
        }

        return (
          <GridColumn
            key={props.id}
            {...newProps}
            columnMenu={
              onGridSettingsChange
                ? (columnMenuProps) =>
                    MyGridColumnMenu(columnMenuProps, onHiddenColumnChange, lockColumn, props.locked, props.id)
                : undefined
            }
            className="gcgrid"
          />
        );
      });
  }

  const lockColumn = (colId: string) => {
    const locked = gridSettings.gridColumnProps
      .filter((c) => {
        if (c.id == colId) {
          return !c.locked;
        }
        return c.locked;
      })
      .map((c) => c.id);
    onLockedColumnChange(locked);
  };

  const getMaxOrderIndex = (): number => {
    return Math.max(...gridSettings.gridColumnProps.map((columnProp) => columnProp.orderIndex));
  };

  const dataResult = processData(filterBox.expressions, dataState);

  // Default Grid filter operators:
  const filterOperators = {
    text: [
      { text: 'grid.filterContainsOperator', operator: 'contains' },
      { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
      { text: 'grid.filterEqOperator', operator: 'eq' },
      { text: 'grid.filterNotEqOperator', operator: 'neq' },
      { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
      { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
      { text: 'grid.filterIsEmptyOperator', operator: myIsEmptyOperator },
      { text: 'grid.filterIsNotEmptyOperator', operator: myIsNotEmptyOperator },
    ],
    numeric: [
      { text: 'grid.filterEqOperator', operator: 'eq' },
      { text: 'grid.filterNotEqOperator', operator: 'neq' },
      { text: 'grid.filterGteOperator', operator: 'gte' },
      { text: 'grid.filterGtOperator', operator: 'gt' },
      { text: 'grid.filterLteOperator', operator: 'lte' },
      { text: 'grid.filterLtOperator', operator: 'lt' },
      { text: 'grid.filterIsNullOperator', operator: 'isnull' },
      { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' },
    ],
    date: [
      { text: 'grid.filterEqOperator', operator: 'eq' },
      { text: 'grid.filterNotEqOperator', operator: 'neq' },
      { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
      { text: 'grid.filterAfterOperator', operator: 'gt' },
      { text: 'grid.filterBeforeOperator', operator: 'lt' },
      { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
      { text: 'grid.filterIsNullOperator', operator: 'isnull' },
      { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' },
    ],
    boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
  };

  const idGetter = getter(gridSettings.DATA_ITEM_KEY);

  const selectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: gridSettings.DATA_ITEM_KEY,
      });
      handleSelectRow?.(newSelectedState);
    },
    [selectedState],
  );

  const headerSelectionChange = React.useCallback((event: GridHeaderSelectionChangeEvent) => {
    const checkboxElement: any = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};

    event.dataItems.forEach((item) => {
      // @ts-ignore
      newSelectedState[idGetter(item)] = checked;
    });
    handleSelectRow?.(newSelectedState);
  }, []);

  const headerSelectionValue = dataResult.data.findIndex((item) => !selectedState[idGetter(item)]) === -1;

  let selectAllRowsButton;
  if (
    headerSelectionValue &&
    dataState.take &&
    dataResult.total > dataState.take &&
    Object.keys(selectedState).length !== dataResult.total
  ) {
    selectAllRowsButton = (
      <div className="flex">
        <p className="">
          All <b>{dataState.take}</b> rows on this page are selected.&nbsp;
        </p>
        <Link
          className="cursor-pointer"
          onClick={() => {
            const { skip, take, ...rest } = dataState;
            const result = processData(filterBox.expressions, rest);
            const rowIds = result.data.map((item) => idGetter(item));
            selectRowsBulk(rowIds);
          }}
        >
          Select all {dataResult.total} rows in the table
        </Link>
      </div>
    );
  }

  /* Dynamically create grid columns based on the given configuration */
  let gridColumns = [];
  let selectableProps = {};
  if (selectable) {
    selectableProps = {
      selectedField: gridSettings.SELECTED_FIELD,
      selectable: { enabled: true, mode: 'multiple', drag: false, cell: false },
    };
    gridColumns.push(
      <GridColumn
        field={gridSettings.SELECTED_FIELD}
        width="50px"
        headerSelectionValue={headerSelectionValue}
        filterable={false}
        reorderable={false}
        resizable={false}
        key="selected"
        orderIndex={0}
        locked={true}
        className="gcgrid"
      />,
    );
  }
  gridColumns = gridColumns.concat(getGridColumnsFromProps()); // TODO Maybe this needs to be done outsisde...
  // Add row actions column
  if (showActionsColumn) {
    gridColumns = gridColumns.concat([
      <GridColumn
        key="row_actions"
        id="row_actions"
        field="row_actions"
        width="100px"
        title="Actions"
        orderIndex={getMaxOrderIndex() + 1}
        cell={ActionsCell}
        filterable={false}
        reorderable={false}
        resizable={false}
        locked={true}
        className="gcgrid"
      />,
    ]);
  }

  /*
   * Prepare data for Grid.
   */
  const data = dataResult.data.map((item, index) => ({
    ...item,
    [gridSettings.SELECTED_FIELD]: selectedState[idGetter(item)],
    [EXPANDED_FIELD]: expandedState[idGetter(item)] ?? false,
  }));

  const theme = useTheme();

  const grid = (
    <StyledGridWrapper className="flex h-full">
      <StyledGrid className="k-grid" style={{ height: '100%', flexGrow: 1, flexShrink: 1, border: 0 }}>
        <Grid
          sortable={true}
          filterable={true} //{!gridSettings.advancedFilter}
          filterOperators={filterOperators}
          groupable={false}
          reorderable={true}
          resizable={true}
          total={dataResult.total}
          pageable={{
            buttonCount: 8,
            pageSizes: [10, 20, 50, 100, 500, 1000],
          }}
          data={data}
          detail={detail}
          expandField={EXPANDED_FIELD}
          onExpandChange={onExpandChange}
          style={{
            height: '10vh',
            flexGrow: 1,
            flexShrink: 1,
            border: 0,
            fontSize: `${gridSettings.fontSize}px`,
          }}
          dataItemKey={gridSettings.DATA_ITEM_KEY}
          {...selectableProps}
          {...dataState}
          onSelectionChange={selectionChange}
          onHeaderSelectionChange={headerSelectionChange}
          onDataStateChange={(event: GridDataStateChangeEvent) => handleDataStateChange(event.dataState)}
          onColumnReorder={columnReorderChange}
          onColumnResize={columnResizeChange}
          rowRender={rowRender(theme)}
          className="sggrid"
          ref={gridRef}
        >
          {gridColumns}
        </Grid>
      </StyledGrid>
    </StyledGridWrapper>
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="flex flex-col h-full z-40" style={{ flexGrow: 1 }}>
          {selectAllRowsButton}
          {gridSettings.advancedFilter && (
            <div>
              <span>Advanced Filter</span>
              <ReactFilterBox
                query={filterBox.query}
                options={options}
                onParseOk={onParseOk}
                onChange={(query: string) => onFilterBoxChange?.({ ...filterBox, query })}
              />
            </div>
          )}
          {grid}
        </div>
      </ThemeProvider>
    </>
  );
};

const grid_bg = (props: any) => props.theme.palette.background.default;
const grid_alt_bg = (props: any) => props.theme.palette.primary.main;
const grid_border = (props: any) => props.theme.palette.primary.main;
const grid_header_border = (props: any) => props.theme.palette.secondary.light;
const grid_header_text = (props: any) => props.theme.palette.text.primary;
const grid_header_background = (props: any) => props.theme.palette.secondary.dark;
const grid_sticky_header_text = (props: any) => props.theme.palette.text.primary;
const grid_sticky_header_bg = (props: any) => props.theme.palette.secondary.dark;
const grid_sticky_header_border = (props: any) => props.theme.palette.secondary.light;

const grid_grouping_row_text = (props: any) => props.theme.palette.primary.main;
const grid_grouping_row_bg = (props: any) => props.theme.palette.primary.main;
const grid_text = (props: any) => props.theme.palette.primary.main;
const grid_hovered_text = (props: any) => props.theme.palette.primary.main;
const grid_selected_text = (props: any) => props.theme.palette.secondary.main;
const grid_selected_bg = (props: any) => props.theme.palette.secondary.light;
const grid_focused_shadow = (props: any) => props.theme.palette.primary.main;
const grid_sticky_border = (props: any) => 'rgba(0, 0, 0, 0.08);'; //props.theme.palette.background.paper; //

const grid_hovered_bg = (props: any) => props.theme.palette.primary.light;
const grid_sticky_hovered_bg = (props: any) => props.theme.palette.primary.light;
const grid_sticky_bg = (props: any) => props.theme.palette.background.paper;

const grid_sticky_alt_bg = (props: any) => props.theme.palette.background.paper;
const grid_sticky_selected_bg = (props: any) => props.theme.palette.secondary.light;

const grid_sticky_footer_bg = (props: any) => props.theme.palette.primary.main;
const grid_sticky_selected_alt_bg = (props: any) => props.theme.palette.secondary.light;
const grid_sticky_selected_hovered_bg = (props: any) => props.theme.palette.primary.light;
const grid_sorted_bg = (props: any) => props.theme.palette.secondary.dark;
const grid_sorted_text = (props: any) => props.theme.palette.primary.main;
const grid_sorting_indicator_text = (props: any) => props.theme.palette.text.primary;
const button_hovered_text = (props: any) => props.theme.palette.primary.main;
const button_hovered_bg = (props: any) => props.theme.palette.primary.main;
const selected_text = (props: any) => props.theme.palette.primary.main;
const selected_bg = (props: any) => props.theme.palette.primary.main;

const grid_footer_text = (props: any) => props.theme.palette.primary.main;
const grid_footer_bg = (props: any) => props.theme.palette.secondary.main;
const grid_footer_border = (props: any) => props.theme.palette.primary.main;

const list_item_text = (props: any) => props.theme.palette.primary.main;
const list_item_bg = (props: any) => props.theme.palette.primary.main;
const list_item_hovered_text = (props: any) => props.theme.palette.primary.main;
const list_item_hovered_bg = (props: any) => props.theme.palette.primary.main;
const list_item_focused_shadow = (props: any) => props.theme.palette.primary.main;
const list_item_selected_text = (props: any) => props.theme.palette.primary.main;
const list_item_selected_bg = (props: any) => props.theme.palette.primary.main;

const popup_text = (props: any) => props.theme.palette.primary.main;
const popup_bg = (props: any) => props.theme.palette.primary.main;
const popup_border = (props: any) => props.theme.palette.primary.main;

const grid_column_menu_group_header_text = (props: any) => props.theme.palette.primary.main;
const grid_column_menu_group_header_bg = (props: any) => props.theme.palette.primary.main;
const grid_column_menu_group_header_border = (props: any) => props.theme.palette.primary.main;

const rowRender = (theme: Theme) => (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
  const selected = props.dataItem.selected;
  const alt = false; // trElement.props.className.includes('k-alt');

  const bgcolor = alt ? theme.palette.background.default : theme.palette.background.paper;
  let mystyle = {};
  if (selected) {
    mystyle = {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.light,
    };
  } else {
    mystyle = { color: theme.palette.text.primary, backgroundColor: bgcolor };
  }

  const trProps: any = {
    style: mystyle,
  };
  return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
};

export const StyledGrid = styled.div`
  .k-grid td.k-state-selected,
  .k-grid tr.k-state-selected > td.gcgrid {
    /*background-color: yellow;*/
  }

  .k-grid-header,
  .k-header,
  th.k-header,
  .k-grid-header-wrap,
  .k-grouping-header,
  .k-grouping-header .k-group-indicator,
  .k-grid td,
  .k-grid-footer,
  .k-grid-footer-wrap,
  .k-grid-content-locked,
  .k-grid-footer-locked,
  .k-grid-header-locked,
  .k-filter-row > td,
  .k-filter-row > th {
    border-color: ${(props) => grid_header_border(props)};
  }

  .k-grid-header,
  .k-grouping-header,
  .k-grid-add-row,
  .k-grid-footer {
    color: ${(props) => grid_header_text(props)};
    background-color: ${(props) => grid_header_background(props)};
    font-weight: bold;
  }

  .k-grid-content {
    // setting this background color resolves glitches in iOS
    background-color: ${(props) => grid_bg(props)};
  }

  .k-group-footer td,
  .k-grouping-row td,
  tbody .k-group-cell {
    color: ${(props) => grid_grouping_row_text(props)};
    background-color: ${(props) => grid_grouping_row_bg(props)};
  }

  .k-grouping-dropclue {
    &::before {
      border-color: ${(props) => grid_header_text(props)} transparent transparent;
    }

    &::after {
      background-color: ${(props) => grid_header_text(props)};
    }
  }

  .k-grid-table .k-grid tbody tr:hover,
  .k-grid-table .k-grid tbody tr.k-state-hover {
    background-color: red;
  }

  .k-grid-table td a {
    color: ${(props) => grid_text(props)};
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    /* padding: 1rem 0; */
    display: block;
    align-content: center;
  }
  .k-grid {
    color: ${(props) => grid_text(props)};
    background-color: ${(props) => grid_bg(props)};

    tr.k-alt {
      background-color: ${(props) => grid_alt_bg(props)};
    }

    tbody tr:hover,
    tbody tr.k-state-hover {
      color: ${(props) => grid_hovered_text(props)};
      background-color: '#ff0000'; //${(props) => grid_hovered_bg(props)};
    }

    td.k-state-selected,
    tr.k-state-selected > td {
      color: ${(props) => grid_selected_text(props)};
      background-color: ${(props) => grid_selected_bg(props)};
    }

    // Focused state
    td.k-state-focused,
    th.k-state-focused,
    th:focus,
    .k-master-row > td:focus,
    .k-grouping-row > td:focus,
    .k-detail-row > td:focus,
    .k-group-footer > td:focus,
    .k-grid-pager.k-state-focused {
      box-shadow: ${(props) => grid_focused_shadow(props)};
    }

    .k-grid-filter,
    .k-header-column-menu,
    .k-hierarchy-cell .k-icon {
      color: ${(props) => grid_header_text(props)};
    }

    .k-grouping-row {
      background-color: ${(props) => grid_grouping_row_bg(props)};

      .k-icon {
        color: ${(props) => grid_header_text(props)};
      }

      .k-grid-content-sticky {
        border-color: ${(props) => grid_sticky_border(props)};
        border-top-color: ${(props) => grid_header_border(props)};
      }
    }
    // Locked columns
    .k-grid-header-locked,
    .k-grid-content-locked,
    .k-grid-header-locked .k-header,
    .k-grid-content-locked td {
      border-color: ${(props) => grid_sticky_header_border(props)};
    }

    .k-grid-content-locked {
      .k-group-footer td,
      .k-group-cell {
        border-color: ${(props) => grid_header_border(props)};
      }

      .k-grouping-row + tr td {
        border-top-color: ${(props) => grid_header_border(props)};
      }

      // Selected state
      .k-state-selected td {
        background-color: ${(props) => grid_sticky_selected_bg(props)};
      }

      .k-state-selected.k-alt td {
        background-color: ${(props) => grid_sticky_selected_alt_bg(props)};
      }

      // Hovered state
      .k-state-hover td,
      tr:hover td {
        background-color: ${(props) => grid_sticky_hovered_bg(props)};
      }

      // Selected hover
      .k-state-selected:hover td,
      .k-state-selected.k-state-hover td {
        background-color: ${(props) => grid_sticky_selected_hovered_bg(props)};
      }
    }
    .k-grid-header-locked .k-header {
      border-bottom-color: ${(props) => grid_header_border(props)};
    }
  }
  col.k-sorted,
  th.k-sorted {
    background-color: ${(props) => grid_sorted_bg(props)};
    color: ${(props) => grid_sorted_text(props)};
    .k-icon {
      color: ${(props) => grid_header_text(props)};
    }
  }
  [type='checkbox']:checked {
    background-image: none;
    background-color: ${(props) => selected_text(props)};
    appearance: none;
  }
  [type='checkbox']:focus {
    box-shadow: none;
  }

  // Grid header
  .k-grid-header {
    .k-i-sort-asc-sm,
    .k-i-sort-desc-sm,
    .k-sort-order {
      color: ${(props) => grid_sorting_indicator_text(props)};
    }

    .k-grid-filter,
    .k-header-column-menu,
    .k-hierarchy-cell .k-icon {
      &:hover {
        color: ${(props) => button_hovered_text(props)};
        background-color: ${(props) => button_hovered_bg(props)};
      }
      &:focus,
      &.k-state-focus,
      &.k-state-border-down {
        box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
      }
      &.k-state-active {
        color: ${(props) => selected_text(props)};
        background-color: ${(props) => selected_bg(props)};
      }
    }

    th.k-grid-header-sticky,
    td.k-grid-header-sticky {
      color: ${(props) => grid_sticky_header_text(props)};
      background-color: ${(props) => grid_sticky_header_bg(props)};

      border-right-color: ${(props) => grid_sticky_header_border(props)};
      border-left-color: ${(props) => grid_sticky_header_border(props)};
    }

    .k-grid-header-sticky.k-sorted {
      color: ${(props) => grid_sorted_text(props)};
      background-color: ${(props) => grid_sticky_header_bg(props)};

      border-right-color: ${(props) => grid_sticky_header_border(props)};
      border-left-color: ${(props) => grid_sticky_header_border(props)};
    }
  }

  // Grid footer
  .k-grid-footer {
    color: ${(props) => grid_footer_text(props)};
    background-color: ${(props) => grid_footer_bg(props)};
    border-color: ${(props) => grid_footer_border(props)};

    .k-grid-footer-sticky {
      border-left-color: ${(props) => grid_sticky_border(props)};
      border-right-color: ${(props) => grid_sticky_border(props)};
      background-color: ${(props) => grid_sticky_footer_bg(props)};
    }
  }

  .k-master-row {
    .k-grid-content-sticky {
      border-color: ${(props) => grid_sticky_border(props)};
      border-top-color: ${(props) => grid_header_border(props)};
      background-color: ${(props) => grid_sticky_bg(props)};
    }

    .k-grid-row-sticky {
      border-top-color: ${(props) => grid_sticky_border(props)};
      border-bottom-color: ${(props) => grid_sticky_border(props)};
      background-color: ${(props) => grid_sticky_bg(props)};
    }

    &.k-alt {
      .k-grid-content-sticky,
      .k-grid-row-sticky {
        background-color: ${(props) => grid_sticky_alt_bg(props)};
      }
    }

    // Selected state
    &.k-state-selected .k-grid-content-sticky,
    &.k-state-selected .k-grid-row-sticky,
    td.k-grid-content-sticky.k-state-selected {
      background-color: ${(props) => grid_sticky_selected_bg(props)};
    }

    &.k-state-selected.k-alt .k-grid-content-sticky,
    &.k-state-selected.k-alt .k-grid-row-sticky,
    &.k-alt td.k-grid-content-sticky.k-state-selected {
      background-color: ${(props) => grid_sticky_selected_alt_bg(props)};
    }

    // Hovered state
    &:hover td.gcgrid,
    &:hover .k-grid-content-sticky,
    &:hover .k-grid-row-sticky,
    &.k-state-hover .k-grid-content-sticky,
    &.k-state-hover .k-grid-row-sticky {
      background-color: ${(props) => grid_sticky_hovered_bg(props)};
    }

    // Selected hover
    &.k-state-selected:hover .k-grid-content-sticky,
    &.k-state-selected:hover .k-grid-row-sticky,
    &.k-state-selected.k-state-hover .k-grid-content-sticky,
    &.k-state-selected.k-state-hover .k-grid-row-sticky,
    &:hover td.k-grid-content-sticky.k-state-selected,
    &.k-state-hover td.k-grid-content-sticky.k-state-selected {
      background-color: ${(props) => grid_sticky_selected_hovered_bg(props)};
    }
  }

  kendo-grid {
    .k-grid-content-sticky {
      border-top-color: ${(props) => grid_header_border(props)};
      border-left-color: ${(props) => grid_sticky_border(props)};
      border-right-color: ${(props) => grid_sticky_border(props)};
      background-color: ${(props) => grid_sticky_bg(props)};

      &:hover,
      &.k-state-hover {
        background-color: ${(props) => grid_sticky_hovered_bg(props)};
      }
    }

    .k-grid-row-sticky {
      td {
        border-top-color: ${(props) => grid_sticky_border(props)};
        border-bottom-color: ${(props) => grid_sticky_border(props)};
        background-color: ${(props) => grid_sticky_bg(props)};
      }

      &:hover td,
      &.k-state-hover td {
        background-color: ${(props) => grid_sticky_hovered_bg(props)};
      }
    }

    .k-alt .k-grid-content-sticky,
    .k-grid-row-sticky.k-alt td {
      background-color: ${(props) => grid_sticky_alt_bg(props)};
    }

    // Selected state
    tr.k-state-selected .k-grid-content-sticky,
    .k-state-selected.k-grid-row-sticky td,
    .k-grid-row-sticky td.k-state-selected,
    .k-state-selected.k-grid-content-sticky {
      @include fill($bg: $grid-sticky-selected-bg);
      background-color: ${(props) => grid_sticky_selected_bg(props)};
    }

    tr.k-state-selected.k-alt .k-grid-content-sticky,
    .k-state-selected.k-alt.k-grid-row-sticky td,
    .k-alt .k-state-selected.k-grid-content-sticky {
      background-color: ${(props) => grid_sticky_selected_alt_bg(props)};
    }

    // Hover state
    tr:hover .k-grid-content-sticky,
    tr.k-state-hover .k-grid-content-sticky,
    .k-grid-row-sticky:hover td,
    .k-grid-row-sticky.k-state-hover td,
    .k-grid-row-sticky.k-alt:hover td,
    .k-grid-row-sticky.k-alt.k-state-hover td,
    .k-alt:hover .k-grid-content-sticky,
    .k-alt.k-state-hover .k-grid-content-sticky {
      background-color: ${(props) => grid_sticky_hovered_bg(props)};
    }

    // Selected + Hover
    tr.k-state-selected:hover .k-grid-content-sticky,
    tr.k-state-selected.k-state-hover .k-grid-content-sticky,
    .k-state-selected.k-grid-row-sticky:hover td,
    .k-state-selected.k-grid-row-sticky.k-state-hover td,
    .k-state-selected.k-alt.k-grid-row-sticky:hover td,
    .k-state-selected.k-alt.k-grid-row-sticky.k-state-hover td,
    tr.k-state-selected.k-alt:hover .k-grid-content-sticky,
    tr.k-state-selected.k-alt.k-state-hover .k-grid-content-sticky,
    .k-grid-row-sticky:hover td.k-state-selected,
    .k-grid-row-sticky.k-state-hover td.k-state-selected,
    tr:hover .k-grid-content-sticky.k-state-selected,
    tr.k-state-hover .k-grid-content-sticky.k-state-selected {
      background-color: ${(props) => grid_sticky_selected_hovered_bg(props)};
    }
  }

  .k-grouping-row {
    .k-grid-content-sticky {
      background-color: ${(props) => grid_sticky_header_bg(props)};
    }

    &:hover .k-grid-content-sticky,
    &.k-state-hover .k-grid-content-sticky {
      background-color: ${(props) => grid_sticky_hovered_bg(props)};
    }
  }

  .k-column-list-item:hover,
  .k-columnmenu-item:hover {
    color: ${(props) => list_item_hovered_text(props)};
    background-color: ${(props) => list_item_hovered_bg(props)};
  }
  .k-columnmenu-item:focus,
  .k-columnmenu-item.k-state-focus {
    color: ${(props) => list_item_focused_shadow(props)};
  }

  .k-columnmenu-item {
    &.k-state-selected {
      color: ${(props) => list_item_selected_text(props)};
      background-color: ${(props) => list_item_selected_bg(props)};
    }
  }

  .k-column-menu {
    .k-menu:not(.k-context-menu) {
      color: ${(props) => popup_text(props)};
      background-color: ${(props) => popup_bg(props)};
      border-color: ${(props) => popup_border(props)};

      .k-item {
        color: ${(props) => list_item_text(props)};
        background-color: ${(props) => list_item_bg(props)};

        &:hover,
        &.k-state-hover {
          color: ${(props) => list_item_hovered_text(props)};
          background-color: ${(props) => list_item_hovered_bg(props)};
        }

        &.k-state-selected {
          color: ${(props) => list_item_selected_text(props)};
          background-color: ${(props) => list_item_selected_bg(props)};
        }

        &:focus,
        &.k-state-focused {
          /* @include box-shadow($list-item-focused-shadow); */
        }
      }
    }
  }

  .k-column-menu-group-header-text {
    color: ${(props) => grid_column_menu_group_header_text(props)};
    background-color: ${(props) => grid_column_menu_group_header_bg(props)};
    border-color: ${(props) => grid_column_menu_group_header_border(props)};
  }

  .k-check-all-wrap {
    border-color: ${(props) => popup_border(props)};
  }

  .k-grid-norecords-template {
    background-color: ${(props) => grid_bg(props)};
    border-color: ${(props) => grid_border(props)};
  }

  .k-pager-wrap {
    background-color: ${(props) => grid_bg(props)};
    color: ${(props) => grid_header_text(props)};
    border-top: none;
  }

  .k-pager-numbers .k-link {
    color: ${(props) => grid_header_text(props)};
    background-color: ${(props) => grid_bg(props)};
  }

  .k-pager-numbers .k-link:focus {
    box-shadow: none;
  }
  .k-pager-numbers .k-link.k-state-selected {
    color: ${(props) => grid_text(props)};

    background-color: ${(props) => props.theme.palette.primary.light};
  }

  .tooltip_icon .k-icon {
    color: ${(props) => grid_text(props)};
  }
  .k-menu-item {
    margin: auto;
  }
`;

export const StyledGridWrapper = styled.div`
  /** 
 * RE-STYLING 
 * */

  .k-grid {
    border-radius: 5px;
    overflow: hidden;
  }
  .k-header {
    background-color: ${(props) => props.theme.palette.secondary.dark};

    > div {
      > div {
        top: 12px;
      }
    }
  }
  .k-grid-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    border: 1px solid ${(props) => props.theme.palette.secondary.dark};
  }
  .k-grid-header-sticky {
    background-color: ${(props) => props.theme.palette.secondary.dark};
  }
  .k-grid .k-grid-header-wrap {
    background-color: ${(props) => props.theme.palette.secondary.dark};
  }
  .k-filter-row th,
  .k-grid-header .k-header > .k-link {
    padding: 13px 16px;
  }
  .k-menu-horizontal {
    background-color: transparent;
  }
  .k-menu-horizontal .k-link {
    padding: inherit;
    border: none;
  }
  .tooltip_icon .k-icon {
    color: ${(props) => props.theme.palette.text.primary};
  }
  td.k-grid-content-sticky:last-child,
  th.k-grid-header-sticky:last-child,
  th.k-header:last-child {
    /* border-left: 3px double ${(props) => props.theme.palette.text.secondary};*/
  }
  th.k-header:last-child span {
    /*display: none;*/
  }
  .k-grid-header th.k-grid-header-sticky {
    /*border-left-color: ${(props) => props.theme.palette.text.secondary};*/
  }
  .k-numerictextbox {
    height: 30px;
    overflow: hidden;
  }
  .k-numeric-wrap {
    border-right: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 6px;
    overflow: hidden;
  }
  span.k-dropdown-wrap {
    background: ${(props) => props.theme.palette.background.paper};
    border-top: 1px solid ${(props) => props.theme.palette.secondary.dark};
    border-bottom: 1px solid ${(props) => props.theme.palette.secondary.dark};
    border-right: 1px solid ${(props) => props.theme.palette.secondary.dark};
    border-left: 1px solid ${(props) => props.theme.palette.secondary.dark};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .k-textbox {
    border-right: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 6px;
  }
  .k-filtercell-operator {
    width: 1.9rem;
    margin-left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 0;
  }
  .k-menu-link {
    border: 1px solid red;
    border-radius: 4px;
  }
  svg:not(:root).svg-inline--fa,
  svg:not(:host).svg-inline--fa {
    color: ${(props) => props.theme.palette.background.paper};
  }
  .k-pager-wrap {
    border-top: 1px solid ${(props) => props.theme.palette.secondary.dark};
  }
  .k-pager-numbers li {
    border-top: 1px solid ${(props) => props.theme.palette.secondary.dark};
    border-bottom: 1px solid ${(props) => props.theme.palette.secondary.dark};
    border-left: 1px solid ${(props) => props.theme.palette.secondary.dark};
  }
  .k-pager-numbers li:last-child {
    border-right: 1px solid ${(props) => props.theme.palette.secondary.dark};
  }
  .k-pager-numbers .k-link {
    background-color: ${(props) => props.theme.palette.background.paper};
  }
  .k-icon {
    color: ${(props) => props.theme.palette.text.hint};
  }
  th.k-grid-header-sticky {
    vertical-align: middle;
  }
  .k-master-row.k-alt .k-grid-content-sticky,
  table.k-grid-table tbody tr:nth-child(even) td {
    background-color: ${(props) => props.theme.palette.background.default};
  }
  .k-master-row.k-state-selected td.gcgrid,
  .k-master-row.k-state-selected .k-grid-content-sticky,
  .k-master-row.k-state-selected.k-alt .gcgrid {
    background-color: ${(props) => props.theme.palette.primary.light};
  }

  .k-textbox:focus-within {
    border-color: ${(props) => props.theme.palette.secondary.dark};
    border-right-color: ${(props) => props.theme.palette.secondary.dark};
    box-shadow: none;
  }
  .k-textbox:focus-visible {
    outline: ${(props) => props.theme.palette.secondary.dark};
    border-width: 1px;
  }

  /* Detail subgrid */
  .k-detail-row .k-hierarchy-cell,
  .k-detail-row .k-detail-cell {
    background-color: #adc3f9 !important;
  }
  .k-detail-row {
    .k-detail-cell {
      .k-grid {
        border-color: #4d78e6;
        border-radius: 0;
        margin-bottom: 0.8rem;
      }
    }
  }
  .k-detail-row {
    .k-detail-cell {
      .k-grid {
        .k-grid-header {
          border-radius: 0;
          border: none;
        }
      }
    }
  }
  .k-detail-row {
    .k-grid-header-wrap {
      border-width: 0px;
    }
  }
  .k-detail-row {
    .k-grid-header-wrap {
      table {
        thead {
          tr > th {
            background-color: #4d78e6;
            color: #fff;
          }
        }
      }
    }
  }
  .k-detail-row {
    .k-grid-header-wrap {
      table {
        thead {
          tr {
            > .k-header span {
              padding-top: 0.2rem;
              padding-bottom: 0.2rem;
            }
          }
        }
      }
    }
  }
  .k-detail-row {
    .k-detail-cell {
      .k-grid {
        .k-grid-container {
          border-radius: 0;
          border: none;
        }
      }
    }
  }
  .k-detail-row {
    .k-detail-cell {
      .k-grid {
        .k-grid-container {
          .k-grid-content {
            div {
              .k-grid-table {
                tbody {
                  tr {
                    td {
                      padding: 5px 12px;
                      color: rgba(0, 0, 0);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .k-master-row.k-alt .k-grid-content-sticky:hover,
  .k-grid-table tbody tr:nth-child(2n) td:hover,
  .k-grid-table .k-grid tbody tr:hover td {
    background-color: #e4ebfd;
  }
  .k-button.k-button-icon {
    visibility: visible !important;
    opacity: 0.5;
  }
  .k-filtercell {
    width: calc(100% - 30px);
  }
  .k-button-icon.k-clear-button-visible {
    opacity: 1;
  }
  .k-grid tr.k-state-selected > td {
    color: inherit;
  }

  .k-filtercell-operator span.k-dropdown-wrap {
    border-left: 1px solid ${(props) => props.theme.palette.background.paper};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .k-grid,
  .k-grid tr:hover {
    color: inherit !important;
  }
  .k-grid .k-grid-container tr:hover,
  .k-grid .k-grid-container tr:hover td {
    background-color: #e4ebfd;
  }
  .k-grid-table tbody tr:nth-child(2n) td {
    background-color: ${(props) => props.theme.palette.secondary.light};
  }
  .k-detail-row,
  .k-detail-row .detail-grid {
    .k-grid .k-grid-container tr td {
      background-color: #fff;
    }
    .k-grid .k-grid-container tr:hover,
    .k-grid .k-grid-container tr:hover td {
      background-color: #e4ebfd;
    }
  }
`;

export default MyGridCore;
