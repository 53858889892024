import { InputText, Select } from '../ui';
import React from 'react';
import { Tooltip } from '@material-ui/core';

interface Props {
  textHandler: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number | undefined) => void;
  index?: number;
  value?: OptionDatas;
  values?: OptionsData;
  disabled?: boolean;
  tootltipMessage?: string;
  selectNumberHandler: (event: string, index: number | undefined, optionName: string) => void;
}
interface OptionDatas {
  num_decimals?: string;
  min?: number;
  max?: number;
}
interface OptionsData {
  id?: string;
  label?: string;
}
const InputNumericComponent: React.FC<Props> = ({
  textHandler,
  index,
  value,
  disabled,
  tootltipMessage,
  selectNumberHandler,
  values,
}) => {
  const options = [
    {
      id: '0',
      label: 'Integer',
    },
    {
      id: '2',
      label: 'Decimal',
    },
  ];

  return (
    <Tooltip title={`${disabled ? tootltipMessage : ''}`} placement="top-start">
      <div className="flex flex-col mt-2 input-numeric">
        <div className={`flex flex-row`}>
          <Select
            fullWidth
            label="Number Type"
            options={options}
            value={value?.num_decimals}
            onChange={(e) => {
              return selectNumberHandler(e, index, 'num_decimals');
            }}
            disabled={disabled}
          />
        </div>

        <InputText
          type="number"
          label="Min Value"
          defaultValue={value?.min}
          name="min"
          onChange={(e) => textHandler(e, index)}
          disabled={disabled}
        />
        <InputText
          type="number"
          label="Max Value"
          defaultValue={value?.max}
          name="max"
          onChange={(e) => textHandler(e, index)}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );
};

export default InputNumericComponent;
