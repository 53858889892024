import { store } from '../store/store';
import { selectCurrentRole } from '../store/selectors';
import { Role, RoleRelationType } from '../store/conference/types';

export const getCurrentRole = (): Role | null => selectCurrentRole(store.getState());

export const hasRelation = (
  type: RoleRelationType,
  fromId: number,
  toId: number,
  roleRelationsById: { [key: string]: { [key: string]: number[] } },
): boolean => {
  return roleRelationsById[type] && roleRelationsById[type][fromId] && roleRelationsById[type][fromId].includes(toId);
};

const sortRoles = (roles: Role[]): Role[] => {
  return roles.sort((a, b) => {
    return b.rank - a.rank; // Sort descending by role priority
  });
};

export const getUserRoles = (
  trackId: number,
  userRolesByTrack: { [key: string]: { id: number }[] },
  roleById: { [key: string]: Role },
): Role[] => {
  const authorRole = Object.values(roleById).find((role) => role.type == 'author' && role.track_id == trackId);
  const userRolesIds = [...(userRolesByTrack[trackId] ?? []), { id: authorRole?.id ?? 0 }].map((r) => r.id); // Artificially add author role as everyone can enter with it
  const set = new Set(userRolesIds);
  const userRoles = Array.from(set).map((roleId) => roleById[roleId]);
  return sortRoles(userRoles); // Sort
};
