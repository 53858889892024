import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, InputText, Link } from '../ui';
import { InputValidationError } from '../types';

export interface ForgotPasswordForm {
  email: string;
}

export interface ForgotPasswordProps {
  fogot_password_handler: (form: ForgotPasswordForm) => void;
  login_handler: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ fogot_password_handler, login_handler }) => {
  const { handleSubmit, errors, control } = useForm<ForgotPasswordForm>();

  let emailProps: InputValidationError = {};

  const frontend_validation_rules = {
    email: {
      required: 'Enter your email address',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  };

  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.email) {
      emailProps = {
        ...emailProps,
        error: true,
        helperText: errors.email.message,
      };
    }
  }

  return (
    <div>
      <h2 className="text-2xl mb-9 font-semibold text-gray-600">Forgot password</h2>
      <p className="mb-5">
        If you have forgotten your password, simply enter your email address and we will send you a link to reset your
        password.
      </p>
      <form onSubmit={handleSubmit(fogot_password_handler)} className="flex flex-col">
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={frontend_validation_rules.email}
          render={({ onChange, value }) => (
            <InputText
              label="E-mail"
              autoFocus
              onChange={onChange}
              value={value}
              className="mb-7"
              error={emailProps.error}
              helperText={emailProps.helperText}
            />
          )}
        />

        <Button type="submit" className="mb-5 bg-blue-700">
          <span className="text-white">Send link</span>
        </Button>
      </form>
      <Link href="javascript:void(0)" onClick={login_handler} className="font-semibold text-xs mb-5">
        Back to login
      </Link>
    </div>
  );
};

export default ForgotPassword;
