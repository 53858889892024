import React from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Form } from '../../store/form/types';
import { difference } from '../../helpers/set';
import SquaredAddButton from '../ui/inputs/SquaredAddButton/SquaredAddButton';
import { Role } from '../../store/conference/types';
import styled from 'styled-components';
import { Switch } from '@progress/kendo-react-inputs';
import TrashCan from '../../icons/TrashCan';
import { InputText } from '../ui';
import DateTimePickerWithOffset from '../DateTimePickerWithOffset/DateTimePickerWithOffset';
import FormPermissionPicker from '../FormPermissionPicker/FormPermissionPicker';
import { addAction, removeAction } from '../../helpers/phase';

interface Props {
  roleById: { [key: string]: Role };
  formById: { [key: number]: Form };
  event: PhaseEvent;
  actionDefinitions: { [key: string]: ActionDefinition };
  utcOffset: string;
  dateFormat: string | null;
  phase: Phase;
  onChange: (event: PhaseEvent) => void;
}

const EventForm: React.FC<Props> = ({
  roleById,
  formById,
  event,
  actionDefinitions,
  utcOffset,
  dateFormat,
  phase,
  onChange,
}) => {
  const defaultAddActionValue = { text: 'Choose action' };
  const [addActionValue, setAddActionValue] = React.useState<{
    id?: PhaseActionType;
    text: string;
  }>(defaultAddActionValue);

  /* Compute which actions to display in the dropdown */
  const computeActionOptions = () => {
    const allAction = new Set(
      Object.entries(actionDefinitions)
        .filter(([actionType]) => actionType != 'form_permission_change')
        .map(([actionType]) => actionType),
    );
    const actionsInUse = new Set(
      event.actions.filter((action) => action.type != 'form_permission_change').map((action) => action.type),
    );
    const diff = difference(allAction, actionsInUse);
    return Array.from(diff).map((actionType: PhaseActionType) => {
      return { text: actionDefinitions[actionType].display_name, id: actionType };
    });
  };

  /* Map actions to its display component */
  const isEndEvent = event.type == 'end';
  const switchActions: JSX.Element[] = [];

  event.actions.forEach((action, index) => {
    if (action.type != 'form_permission_change') {
      const { display_name } = actionDefinitions[action.type];
      const disabled = false; //isEndEvent;
      switchActions.push(
        <StyledSwitchWrapper key={index} className="flex items-center justify-between mb-3">
          <div className="flex items-center justify-content-start switch-wrapper">
            <Switch
              disabled={disabled}
              onLabel=""
              offLabel=""
              checked={action.body.params.value}
              onChange={() =>
                onChange({
                  ...event,
                  actions: event.actions.map((a, idx) => {
                    return idx == index
                      ? {
                          ...a,
                          body: { ...a.body, params: { ...a.body.params, value: !a.body.params.value } },
                        }
                      : a;
                  }),
                })
              }
              className={`mr-2 ${disabled ? 'opacity-50' : ''}`}
            />
            <div style={{ fontSize: '13px' }}>
              {action.body.params.value ? 'Enable' : 'Disable'} {display_name}
              {phase.type == 'submission' && action.type != 'submission_creation' && (
                <p className="text-gray-500">(Only acts if submission edition is enabled)</p>
              )}
            </div>
          </div>

          {event.type == 'custom' && (
            <div
              className={`k-cursor-pointer trash-wrapper`}
              onClick={() => onChange({ ...event, actions: removeAction(index, {}, event.actions) })}
            >
              <TrashCan />
            </div>
          )}
        </StyledSwitchWrapper>,
      );
    }
  });

  return (
    <div>
      <div className="flex flex-row mb-8">
        <h2 className="w-40 text-base font-semibold title-form-element">Event name</h2>
        <div className="text-sm pt-0.5 form-element">
          {/* Show name input */}
          {event.type == 'custom' ? (
            <StyledInputText
              value={event.name}
              className="flex items-center w-full"
              onChange={(e) => onChange({ ...event, name: e.target.value })}
            />
          ) : (
            <h2 className="text-base font-semibold">{event.name}</h2>
          )}
        </div>
      </div>

      <div className="flex flex-row mb-6">
        <h2 className="w-40 text-base font-semibold title-form-element">Date and time</h2>
        <div className="text-sm pt-0.5 form-element">
          {/* Show date picker */}
          <DateTimePickerWithOffset
            utcOffset={utcOffset}
            format={dateFormat ?? undefined}
            defaultValue={event.starts_at ? new Date(event.starts_at) : undefined}
            width={225}
            onChange={(date) => onChange({ ...event, starts_at: date.toISOString() })}
          />
        </div>
      </div>

      {phase.type != 'author_notification' && phase.type != 'custom' && (
        <div className="flex flex-row mb-8">
          <h2 className="w-40 text-base font-semibold title-form-element">Actions</h2>
          <div className="text-sm pt-0.5 form-element">
            {/* Show current switch-like actions */}
            {switchActions}
            {/* Switch-like dropdown actions */}
            {computeActionOptions().length > 0 && (
              <div className={`flex flex-col`}>
                <DropDownList
                  style={{ width: '22.7rem', height: '35.2px' }}
                  value={addActionValue}
                  data={computeActionOptions()}
                  textField="text"
                  onChange={(e: DropDownListChangeEvent) => setAddActionValue(e.target.value)}
                  className="mb-3"
                />
                <StyledSquaredAddButtonWrapper>
                  <SquaredAddButton
                    isValid={!!addActionValue.id}
                    handleOnClick={() => {
                      if (addActionValue.id) {
                        onChange({
                          ...event,
                          actions: addAction(addActionValue.id, { value: true }, event.actions),
                        });
                        setAddActionValue(defaultAddActionValue);
                      }
                    }}
                    label="Add action"
                  />
                </StyledSquaredAddButtonWrapper>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex flex-row mb-8">
        <h2 className="w-40 text-base font-semibold title-form-element">Forms</h2>
        <FormPermissionPicker
          roleById={roleById}
          formById={formById}
          phaseType={phase.type}
          eventType={event.type}
          actions={event.actions}
          onChange={(actions) => onChange({ ...event, actions })}
        />
      </div>
    </div>
  );
};

export default EventForm;

const StyledInputText = styled(InputText)`
  flex-direction: row !important;
  label {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
  > div {
    width: 100% !important;
  }
`;

const StyledSwitchWrapper = styled.div`
  background-color: #eff3fa;
  border: 2px solid #eee;
  border-radius: 2px;

  .switch-wrapper {
    padding: 0.4rem 0.9rem;
  }
  .trash-wrapper {
    padding: 0.4rem 0.9rem;
  }
`;

const StyledSquaredAddButtonWrapper = styled.div`
  width: 100%;

  button {
    margin: 0;
  }

  svg {
    height: 2.2rem;
  }
`;
