import React, { useState } from 'react';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { selectPaperStatusState } from '../../store/selectors';
import { CategoryEnum, PaperStatus } from '../../store/paper-status/types.d';
import Loading from '../../components/Loading/Loading';
import { AddButton } from '../../components/ui/inputs/AddButton/AddButton';
import PaperStatusDialog from '../../components/dialogs/PaperStatusDialog/PaperStatusDialog';
import { capitalizeFirstLetter } from '../../helpers/string';
import TrashCan from '../../icons/TrashCan';
import EditIcon from '../../icons/Edit';
import styled from 'styled-components';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import paperStatusSlice from '../../store/paper-status/slice';
import useTheme from '@material-ui/core/styles/useTheme';

export const formatCategory = (category: string): string => {
  return capitalizeFirstLetter(category.split('_').join(' '));
};

interface Props {
  loading: boolean;
  paperStatusById: { [key: number]: PaperStatus };
  deletePaperStatusAction: (id: number) => void;
}

const SettingsPaperStatusPage: React.FC<Props> = ({ loading, paperStatusById, deletePaperStatusAction }) => {
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);
  if (loading) return <Loading />;

  const theme = useTheme();

  return (
    <div className="text-sm max-w-4xl">
      {Object.values(CategoryEnum).map((category, index) => {
        const formatted = formatCategory(category);
        return (
          <div key={index} className="text-sm w-full max-w-screen-2xl mb-14">
            <div className="flex mb-8 items-center">
              <h2 className="font-bold text-xl">{formatted}</h2>
              <AddButton
                label={`Add ${formatted.toLowerCase()} status`}
                onClick={() =>
                  setDialog(
                    <PaperStatusDialog
                      open={true}
                      handleClose={() => setDialog(undefined)}
                      initialValue={{
                        id: 0,
                        name: '',
                        category: category,
                        display_name: category === 'in_progress' ? 'In the review process' : '',
                      }}
                    />,
                  )
                }
              />
            </div>
            <ul>
              {Object.values(paperStatusById)
                .filter((paperStatus) => paperStatus.category == category)
                .map((paperStatus) => (
                  <StyledPaperStatus key={paperStatus.id}>
                    <div className="flex w-full justify-between bg-transparent mb-2">
                      <div className="flex w-full py-2 px-4">
                        <span>{paperStatus.name}</span>
                      </div>
                      {!['Withdrawn', 'Deleted'].includes(paperStatus.name) && (
                        <>
                          <button
                            className="bg-transparent p-2.5 border-l-2 border-white border-solid flex items-center"
                            onClick={() =>
                              setDialog(
                                <PaperStatusDialog
                                  open={true}
                                  handleClose={() => setDialog(undefined)}
                                  initialValue={paperStatus}
                                />,
                              )
                            }
                          >
                            <EditIcon color={theme.palette.text.primary} className="-mt-0.5 inline-block" />
                            <span className="ml-1">Edit</span>
                          </button>
                          {paperStatus.name !== 'Awaiting decision' ? (
                            <button
                              className="bg-transparent p-2.5 border-l-2 border-white border-solid flex items-center"
                              onClick={() => {
                                setDialog(
                                  <SimpleDialog
                                    open={true}
                                    handleClose={() => setDialog(undefined)}
                                    handleOk={() => deletePaperStatusAction(paperStatus.id)}
                                    title="Remove paper status?"
                                  >
                                    <p className="text-sm mb-2">
                                      {`Are you sure you want to remove paper status ${paperStatus.name}? Take into account that this action can't be undone.`}
                                    </p>
                                  </SimpleDialog>,
                                );
                              }}
                            >
                              <TrashCan /> <span className="ml-1">Delete</span>
                            </button>
                          ) : (
                            <div
                              className="bg-transparent border-l-2 border-white border-solid"
                              style={{ width: '6.1rem' }}
                            ></div>
                          )}
                        </>
                      )}
                    </div>
                  </StyledPaperStatus>
                ))}
            </ul>
          </div>
        );
      })}
      {dialog}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectPaperStatusState(state).loading,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deletePaperStatusAction: (id: number) => dispatch(paperStatusSlice.actions['DELETE:PAPER_STATUS'](id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPaperStatusPage);

const StyledPaperStatus = styled.li`
  > div {
    /*
    margin-bottom: 0.5rem;
    width: max-content;
    padding: 0.5rem 1rem;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
    border-radius: 18px;
    */
    background-color: #e3e5e6;
  }
`;
