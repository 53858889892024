import React, { useState } from 'react';
import MenuLeftIcon from '../icons/MenuLeft';
import { Menu, MenuItem, MenuSubItem } from '../components/ui/navigation/Menu';
import { MainItems, MenuItems } from './types';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Home from '../icons/Home';
import history from '../store/history';
import { getRouteByName, getRouteByPath } from '../router/routes';
import { Link } from '../components/ui/navigation/Link';
import { Conference, Track } from '../store/conference/types';
import { AddButton } from '../components/ui/inputs/AddButton/AddButton';

type Props = {
  main_items: MainItems;
  selectedOption: string | null;
  doClick: (item: MenuItems, isMenuSubitem: boolean) => void;
  logo: React.ReactNode;
  header: React.ReactNode;
  menuOpen: boolean;
  handleAvatarClick?: () => void;
  userName: string;
  children: React.ReactNode;
  isImpersonated?: boolean;
  currentConference?: Conference | null;
  leaveImpersonationAction?: () => void;
  avatarImageExists?: string | null;
  currentTrack: Track | null;
};

const MainLayout: React.FC<Props> = ({
  main_items,
  selectedOption,
  doClick,
  logo,
  header,
  menuOpen,
  userName,
  children,
  isImpersonated,
  leaveImpersonationAction,
  currentTrack,
}) => {
  const betaVersion = true;

  const parentName = main_items.find(
    (parent) => parent.subitems && parent.subitems.some((subitem) => subitem.name === selectedOption),
  )?.name;

  const [expandedItems, setExpandedItems] = useState<string[]>([parentName !== undefined ? parentName : '']);

  const theme = useTheme();
  const route = getRouteByPath(window.location.pathname);
  const menuItems = main_items.map((item) => {
    const selected =
      selectedOption === item.name || item.subitems?.find((value) => value.name === selectedOption) !== undefined;
    const iconProps = selected ? { stroke: theme.palette.primary.main } : {};

    return (
      <MenuItem
        key={item.name}
        // @ts-ignore
        icon={React.createElement(item.icon, iconProps)}
        label={item.name}
        selected={selected}
        color="primary"
        onClick={() => {
          setExpandedItems((itemsList) => {
            if (itemsList.includes(item.name)) {
              return itemsList.filter((i) => i !== item.name);
            } else {
              return [item.name];
            }
          });
          doClick(item, false);
        }}
        expandedItems={expandedItems}
      >
        {item.subitems?.map((subitem) => {
          return (
            <MenuSubItem
              key={subitem.name}
              label={subitem.name}
              selected={selectedOption === subitem.name}
              color="primary"
              onClick={() => {
                setExpandedItems((prevExpandedItems) => [item.name]);
                doClick(subitem, true);
              }}
            />
          );
        })}
      </MenuItem>
    );
  });

  const mainBackground =
    getRouteByPath(window.location.pathname).name === 'RouteConferences' ||
    getRouteByPath(window.location.pathname).name === 'RouteEditSubmission' ||
    getRouteByPath(window.location.pathname).name === 'RouteDetailSubmission' ||
    getRouteByPath(window.location.pathname).name === 'RouteNewSubmission' ||
    getRouteByPath(window.location.pathname).name === 'RouteEditReview'
      ? '#ffffff'
      : '#edf3ff';

  return (
    <>
      <StyledMainContent className="flex h-full flex-col">
        {/* IMPERSONAATED BAR */}
        {isImpersonated && (
          <StyledImpersonatedBar>
            You are impersonated as <strong>{userName}</strong>. To leave impersonation,
            <Link onClick={(e) => leaveImpersonationAction!()} className="cursor-pointer ml-1 underline">
              click here
            </Link>
            .
          </StyledImpersonatedBar>
        )}

        <div className={`flex ${isImpersonated ? 'mainContentImpersonation' : 'h-full'}`}>
          {currentTrack && getRouteByPath(window.location.pathname).name !== 'RouteConferences' && (
            <>
              {/* LATERAL MENU */}
              <StyledMenuBarWrapper className="flex flex-col" style={{ backgroundColor: theme.palette.secondary.main }}>
                {/* LOGO WRAPPER */}
                <div className="h-16 flex items-center px-6 border-b border-gray-200">
                  <div className="relative">{logo}</div>
                </div>

                {/* LATERAL MENU: OUT OF TRACK */}
                <div>
                  {menuItems.length === 0 && (
                    <Menu open={!menuOpen} className="pr-5">
                      <div>
                        <MenuItem
                          key="Hide Menu"
                          icon={<MenuLeftIcon />}
                          label="Hide Menu"
                          onClick={() => {
                            doClick(
                              {
                                name: 'Hide Menu',
                                route_name: 'RouteConferenceHome',
                                icon: MenuLeftIcon,
                              },
                              false,
                            );
                          }}
                        />
                        <StyledMenuItem
                          key={'Home'}
                          // @ts-ignore
                          icon={<Home color={route.name === 'RouteConferences' ? 'blue' : ''} />}
                          label={'Home'}
                          selected={route.name === 'RouteConferences'}
                          color="primary"
                          onClick={() => history.push(getRouteByName('RouteConferenceHome').path)}
                        />
                      </div>
                    </Menu>
                  )}
                </div>

                {/* LATERAL MENU: INSIDE OF TRACK */}
                <div className="flex-grow overflow-y-auto">
                  <Menu open={!menuOpen} className="pr-5">
                    {menuItems.length > 0 &&
                      [
                        <div key={0} className="text-gray-400">
                          <MenuItem
                            key="Hide Menu"
                            icon={<MenuLeftIcon />}
                            label="Hide Menu"
                            onClick={() => {
                              doClick(
                                {
                                  name: 'Hide Menu',
                                  route_name: 'RouteConferenceHome',
                                  icon: MenuLeftIcon,
                                },
                                false,
                              );
                            }}
                          />
                        </div>,
                      ].concat(menuItems)}
                  </Menu>
                </div>

                {/* REQUEST CONFERENCE BUTTON */}
                {!isImpersonated && (
                  <div
                    className="flex items-center pt-4 pb-4 border-t border-gray-50"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <AddButton
                      label="Request Conference"
                      menuOpen={menuOpen}
                      onClick={() => history.push(getRouteByName('RouteConferencesCreate').path)}
                    />
                  </div>
                )}
              </StyledMenuBarWrapper>
            </>
          )}

          {/* MAIN CONTENT */}
          <div
            className="flex-grow flex flex-col overflow-x-auto relative"
            style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}
          >
            {/* HEADER */}
            <div className="flex-shrink-0 flex justify-between items-center h-16 px-8 border-b border-gray-200">
              {header}
            </div>

            {/* CONTENT */}
            <StyledChildrenWrapper
              className={`flex-grow overflow-auto h-full px-8 pt-7 flex flex-col ${
                currentTrack ||
                getRouteByPath(window.location.pathname).name == 'RouteSettingsUser' ||
                getRouteByPath(window.location.pathname).name == 'RouteConferencesCreate'
                  ? ''
                  : 'homeBackground'
              }`}
              style={{ backgroundColor: mainBackground }}
            >
              {children}
            </StyledChildrenWrapper>

            {/* FOOTER */}
            <StyledFooter>
              {betaVersion ? (
                <p>
                  You are using a BETA VERSION of chairing tool. We are actively improving the user experience and the
                  functionalities. If you have some issue or some suggestion, feel free to contact us at
                  <a href="mailto:support@brainful-labs.com" className="ml-1">
                    support@brainful-labs.com
                  </a>
                </p>
              ) : (
                <p>
                  Copyright © Brainful labs | <span>Contact us at </span>
                  <a href="mailto:support@brainful-labs.com">support@brainful-labs.com</a>
                </p>
              )}
            </StyledFooter>
          </div>
        </div>
      </StyledMainContent>
    </>
  );
};

const StyledMenuBarWrapper = styled.div`
  background: rgb(244, 245, 250);
  background: linear-gradient(0deg, rgb(223, 232, 249) 0%, rgb(228, 235, 251) 13%, rgb(250, 250, 250) 55%);
`;

const StyledFooter = styled.div`
  position: absolute;
  bottom: 0;
  /*height: 2.4em;*/
  text-align: center;
  line-height: 2em;
  /*color: #666;*/
  /*font-size: 0.75rem;*/
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  width: 100%;
  background-color: rgb(223, 232, 249);

  height: 4rem;
  font-size: 0.875rem;
  /*border-top: 1px solid #e3e5e6;*/
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    padding: 0 2rem;
    line-height: 1.4;
    width: 100%;

    a {
      text-decoration: underline;
      color: rgb(0, 68, 240);
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 1rem 2.25rem 0 0;

  a {
    align-items: center;

    svg {
      color: blue;
    }

    .caret {
      visibility: hidden;
    }

    &.selected {
      .caret {
        visibility: visible;
      }
    }
  }
`;

const StyledImpersonatedBar = styled.div`
  z-index: 100;
  position: relative;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));

  a {
    color: #000 !important;
  }
`;

const StyledMainContent = styled.div`
  .mainContentImpersonation {
    height: calc(100% - 24px);
  }
`;

const StyledChildrenWrapper = styled.div`
  padding-bottom: 4rem;
`;

export default MainLayout;
