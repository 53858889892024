interface PaperStatusState {
  loading: boolean;
  paperStatusRole: { [key: number]: number[] };
  paperStatusById: { [key: number]: PaperStatus };
}

export const CategoryEnum = {
  In_Progress: 'in_progress',
  Rejection: 'rejection',
  Accepted: 'accepted',
};

type CategoryType = CategoryEnum.Rejection | CategoryEnum.In_Progress | CategoryEnum.Accepted;

interface PaperStatus {
  id: number;
  track_id: number;
  name: 'Withdrawn' | 'Deleted' | 'Awaiting decision' | string;
  category: CategoryType;
  display_name: string | null;
}

interface GetPaperStatusOk {
  data: { [key: number]: PaperStatus };
}

interface PaperStatusDTO extends Omit<PaperStatus, 'track_id'> {}

interface PostPaperStatusOk {
  data: PaperStatus;
}

interface PaperStatusRoleDTO {
  paper_status_id: number;
  role_id: number;
}

interface PostPaperStatusRoleResponse {
  data: any;
}

interface GetPaperStatusRoleResponse {
  data: { [key: number]: number[] };
}
