import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, Select } from '../../ui';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import tw from 'twin.macro';
import { InputText } from '../../ui';
import { Form, Question, QuestionType } from '../../../store/form/types';
import { _CustomColumn, columnOperator, CustomColumn } from '../../../store/table/types';
import EditIcon from '../../../icons/Edit';
import TrashCan from '../../../icons/TrashCan';

type filter = 'text' | 'numeric';

const operators: { [Property in filter]: { id: columnOperator; label: string }[] } = {
  text: [{ id: 'avg_word_count', label: 'Average word count' }],
  numeric: [
    { id: 'average', label: 'Average' },
    { id: 'aggregate', label: 'Aggregate' },
    { id: 'min', label: 'Min' },
    { id: 'max', label: 'Max' },
  ],
};

const typeToFilter = (type: QuestionType): filter => {
  switch (type) {
    case 'Numeric':
    case 'LinearScale':
      return 'numeric';
    default:
      return 'text';
  }
};

interface Props {
  forms: Form[];
  questions: Question[];
  onClose: () => void;
  initCustomColumns: CustomColumn[];
  handleOk: (customColumns: _CustomColumn[]) => void;
}

const AggregateColumnDialog: React.FC<Props> = ({ forms, questions, onClose, initCustomColumns, handleOk }) => {
  const theme = useTheme();

  const initColumnState: _CustomColumn = {
    id: 0,
    question_id: 0,
    operator: '' as columnOperator,
    name: '',
  };

  const [formId, setFormId] = React.useState<string>('');
  const [customColumnsId, setCustomColumnsId] = React.useState<number | null>(null);
  const [columnState, setColumnState] = React.useState<_CustomColumn>(initColumnState);
  const [customColumns, setCustomColumns] = React.useState<_CustomColumn[]>(initCustomColumns);

  const getOperators = (questionId: number) => {
    const question = questions.find((question) => question.id === questionId);
    if (!question) return [];
    const filter = typeToFilter(question.type);
    return operators[filter];
  };

  const clearInputs = () => {
    setFormId('');
    setCustomColumnsId(null);
    setColumnState(initColumnState);
  };

  const isDisabled = (): boolean => {
    return !(columnState.question_id && columnState.operator && columnState.name);
  };

  let formContent;

  const getQuestionById = (questionId: number) => {
    const question = questions.filter((question) => question.id === questionId);
    return question[0];
  };

  const getOperatorById = (questionId: number, operatorId: columnOperator) => {
    const operator = getOperators(questionId).filter((operator) => operator.id === operatorId);
    return operator[0];
  };

  if (customColumnsId !== null) {
    const selectedQuestionName = getQuestionById(customColumns[customColumnsId].question_id).title;
    const selectedOperatorName = getOperatorById(
      customColumns[customColumnsId].question_id,
      customColumns[customColumnsId].operator,
    ).label;

    const questionFormId = getQuestionById(customColumns[customColumnsId].question_id).form_id;

    const selectedFormName = forms.filter((form) => form.id === questionFormId)[0].name;

    formContent = (
      <div>
        <div className="mb-4">
          <div className="font-semibold mb-2 text-sm labelSelect text-black">Form</div>
          <span className="font-normal mb-2 text-sm">{selectedFormName}</span>
        </div>

        <div className="font-semibold text-sm mb-4 w-full">Question</div>

        <div className="flex justify-between mb-4">
          {/* FIELD */}
          <div className="flex-1  mr-2" style={{ maxWidth: '18rem' }}>
            <div className="font-normal mb-1 text-sm labelSelect text-black">Field</div>
            <span className="font-normal text-sm italic">
              {`${selectedQuestionName && selectedQuestionName.substring(0, 100)}${
                selectedQuestionName && selectedQuestionName.length > 100 ? '(...)' : ''
              }`}
            </span>
          </div>

          {/* OPERATOR */}
          <div className="flex-1" style={{ maxWidth: '13rem' }}>
            <div className="font-normal mb-1 text-sm labelSelect text-black">Operator</div>
            <span className="font-normal text-sm italic">{selectedOperatorName}</span>
          </div>
        </div>

        <div className="flex">
          {/* NAME */}
          <div className="flex-2">
            <div>
              <InputText
                label="Name"
                className="w-full"
                onChange={(event) => {
                  setColumnState((prevState) => ({
                    ...prevState,
                    name: event.target.value,
                  }));
                }}
                value={columnState.name}
                placeholder="Enter name..."
              ></InputText>
            </div>
          </div>

          <div className="grow-0 flex flex-col">
            <div style={{ marginBottom: '0.2rem' }}> &nbsp;</div>

            <div className="flex">
              {/* CANCEL BUTTON */}
              <StyledCancel
                onClick={() => {
                  clearInputs();
                }}
                variant="text"
                style={{ height: '2.5rem', paddingLeft: '12px', paddingRight: '12px' }}
              >
                Cancel
              </StyledCancel>

              {/* UPDATE BUTTON */}
              <Button
                variant="solid"
                roundedEdges="rounded"
                onClick={() => {
                  setCustomColumns((prevCustomColumns) =>
                    prevCustomColumns.map((column, index) =>
                      index === customColumnsId ? { ...column, name: columnState.name } : column,
                    ),
                  );
                  clearInputs();
                }}
                style={{ width: '5rem', height: '2.5rem' }}
                disabled={!columnState.name}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    formContent = (
      <div>
        {/* FORM */}
        <Select
          label="Form"
          dense={false}
          options={[{ id: '', label: 'Select form...' }].concat(
            forms.map((form) => ({ id: form.id.toString(), label: form.name ?? '' })),
          )}
          value={formId}
          onChange={(id) => {
            setFormId(id);
            setColumnState((prevState) => ({
              ...prevState,
              question_id: 0,
            }));
          }}
          className="select-form"
        />

        <div className="font-semibold text-sm mb-4 w-full">Question</div>

        <div className="flex justify-between">
          {/* FIELD */}
          <div className="flex-1  mr-2" style={{ maxWidth: '18rem' }}>
            <Select
              label="Field"
              dense={false}
              options={[{ id: '', label: 'Select field...' }].concat(
                questions
                  .filter((question) => question.form_id == parseInt(formId))
                  .map((question) => ({ id: question.id.toString(), label: question.title ?? '' })),
              )}
              value={columnState.question_id ? columnState.question_id.toString() : ''}
              onChange={(id) => {
                setColumnState((prevState) => ({
                  ...prevState,
                  question_id: parseInt(id),
                }));
              }}
            ></Select>
          </div>

          {/* OPERATOR */}
          <div className="flex-1" style={{ maxWidth: '13rem' }}>
            <Select
              label="Operator"
              value={columnState.operator ?? ''}
              dense={false}
              options={[{ id: '', label: 'Select operator...' }].concat(getOperators(columnState.question_id))}
              onChange={(value) => {
                const selectedQuestionName = getQuestionById(columnState.question_id).title;
                const selectedOperatorName = getOperatorById(columnState.question_id, value as columnOperator).label;

                const suggestedName =
                  selectedQuestionName && selectedOperatorName
                    ? `${selectedQuestionName.substring(0, 25)}${
                        selectedQuestionName && selectedQuestionName.length > 25 ? '(...)' : ''
                      } ${selectedOperatorName}`
                    : '';
                setColumnState((prevState) => ({
                  ...prevState,
                  operator: value as columnOperator,
                  name: suggestedName,
                }));
              }}
            ></Select>
          </div>
        </div>

        <div className="flex">
          {/* NAME */}
          <div className="flex-2 mr-2">
            <div>
              <InputText
                label="Name"
                className="w-full"
                onChange={(event) => {
                  setColumnState((prevState) => ({
                    ...prevState,
                    name: event.target.value,
                  }));
                }}
                value={columnState.name}
                placeholder="Enter name..."
              ></InputText>
            </div>
          </div>

          <div className="grow-0 flex flex-col">
            <div style={{ marginBottom: '0.2rem' }}> &nbsp;</div>

            {/* ADD BUTTON */}
            <Button
              variant="solid"
              roundedEdges="rounded"
              onClick={() => {
                setCustomColumns([
                  ...customColumns,
                  {
                    id: 0,
                    question_id: columnState.question_id,
                    operator: columnState.operator as columnOperator,
                    name: columnState.name,
                  },
                ]);
                clearInputs();
              }}
              style={{ width: '5rem', height: '2.5rem' }}
              disabled={isDisabled()}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        style={{ minWidth: 1024 }}
        className="h-5/6"
      >
        <StyledDiv>
          <div className="flex">
            <div className="title">Aggregate Columns</div>
          </div>

          <div className="flex flex-row mt-4 mb-8">
            <StyledCreateColumns className="mr-8" style={{ width: '32rem' }}>
              {formContent}
            </StyledCreateColumns>

            {/*List of custom columns*/}
            <div className="list" style={{ backgroundColor: '#E4EBFD' }}>
              {customColumns.map((customColumn, index) => (
                <div key={index} className="flex text-sm p-3 mx-2 border-b border-gray-400 border-dotted">
                  {/* LIST NAME */}
                  <div style={{ flexGrow: 1 }}>{customColumn.name}</div>

                  {/* LIST EDIT BUTTON */}
                  <Button
                    variant="text"
                    onClick={() => {
                      setCustomColumnsId(index);
                      setColumnState((prevState) => ({
                        ...prevState,
                        name: customColumn.name,
                      }));
                    }}
                    style={{
                      width: '4rem',
                      color: `${theme.palette.primary.main}`,
                      padding: '0',
                      height: 'max-content',
                    }}
                  >
                    <div className="flex items-center">
                      <EditIcon color={theme.palette.primary.main} className="mr-1" />
                      Edit
                    </div>
                  </Button>

                  {/* LIST REMOVE BUTTON */}
                  <Button
                    variant="text"
                    onClick={() => {
                      clearInputs();
                      setCustomColumns(customColumns.filter((a, i) => i !== index));
                    }}
                    style={{
                      width: '1.2rem',
                      padding: '0',
                      height: 'max-content',
                    }}
                  >
                    <TrashCan stroke={theme.palette.primary.main} />
                  </Button>
                </div>
              ))}
              {customColumns.length === 0 && (
                <div className="flex text-sm p-2">
                  <div style={{ flexGrow: 1 }}>No aggregate columns created</div>
                </div>
              )}
            </div>
          </div>
        </StyledDiv>

        <StyledButtonsWrapper>
          <DialogActions className="mb-3">
            {/* WINDOW CANCEL BUTTON */}
            <StyledCancel onClick={onClose} variant="text">
              Cancel
            </StyledCancel>

            {/* WINDOW SAVE BUTTON */}
            <Button
              onClick={() => {
                handleOk(customColumns);
                onClose();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </StyledButtonsWrapper>
      </Dialog>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  ${tw`px-10 pt-3 pb-6`}
  .title {
    ${tw`font-bold my-2 pt-5`}
    color: ${(props) => props.theme.palette.primary.main};
  }

  .settingscolhdr {
    ${tw`text-sm font-bold`}
  }

  .flex-2 {
    flex-grow: 2;
  }

  .list {
    flex-grow: 1;
    background-color: #f0eeee;
    overflow-y: scroll;
    height: 16.8rem;
  }

  .dropdown-wrapper {
    margin-bottom: 1rem;
  }
`;

const StyledCreateColumns = styled.div`
  > div > div:first-child {
    font-weight: 600;
  }

  .k-input {
    padding: 1.3em 0.5em;
  }

  label {
    margin-bottom: 0.3rem;
  }

  .k-dropdown {
    width: 100%;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 96%;
  bottom: -1.75rem;
  padding: 0.9rem 0;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-top: 1px dashed ${(props) => props.theme.palette.secondary.dark};
  margin-right: auto;
  margin-left: auto;
`;

const StyledCancel = styled(Button)`
  color: ${(props) => props.theme.palette.error.main};
  font-weight: 700;
`;

export default AggregateColumnDialog;
