import React, { useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Loading from '../components/Loading/Loading';
import conferenceSlice from '../store/conference/slice';
import { ConferenceSelectTrack, ConferenceState } from '../store/conference/types';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { getRouteByName } from '../router/routes';
import history from '../store/history';
import PlusIcon from '../icons/Plus';
import { Button, Card, Link, SearchText } from '../components/ui';
import { Tabs, TabsItem } from '../components/ui/navigation/Tabs';
import { useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { selectAuth } from '../store/selectors';
import DateTimeWithOffset from '../components/DateTimeWithOffset/DateTimeWithOffset';
import { ImpersonateRequest } from '../store/auth/types';
import authSlice from '../store/auth/slice';
import { determineRoleId } from '../store/auth/commons';
import { getUserRoles } from '../helpers/role';

interface ConferencesPageProps {
  conferenceState: ConferenceState;
  selectTrackAction: (data: ConferenceSelectTrack) => void;
  isSuperAdmin: boolean;
  impersonateAction: (data: ImpersonateRequest) => void;
  unselectTrackAction: () => void;
}

function ConferencesPage({
  conferenceState,
  selectTrackAction,
  isSuperAdmin,
  impersonateAction,
  unselectTrackAction,
}: ConferencesPageProps) {
  const { loading, userRolesByTrack, tracksById, conferencesById, roleById } = conferenceState;
  const myTracksIds = Object.keys(userRolesByTrack).map((value) => parseInt(value));
  const [section, setSection] = React.useState('my-conferences');
  const [searchText, setSearchText] = React.useState<string>('');
  const theme = useTheme();

  useEffect(() => {
    if (conferenceState.currentTrackId) {
      /* If we reach conferences list page and we have a selected track this means we exit track by a strange way and thus by not by clicking 'Exit conference' or so.
      We must clean redux state at this point.
       */
      unselectTrackAction();
    }
  }, []);

  if (loading) return <Loading />;

  let myTrackIds: number[] = [];
  let otherTrackIds: number[] = [];

  const today = new Date();

  // Testing dates
  //const today = new Date('2023-09-01T10:55:00.000000Z');
  //const today = new Date('2023-10-05T10:55:00.000000Z');
  //const today = new Date('1970-10-05T10:55:00.000000Z');

  const conferencesByDateAndOwner = (dateIntervalCondition: (value: any) => boolean, label?: string) => {
    const conditioWhenNull = section != 'past-conferences' ? true : false;

    return Object.values(conferencesById)
      .filter((value) => (value.end_date != null ? dateIntervalCondition(value) : conditioWhenNull))
      .map((value) => {
        return Object.values(tracksById)
          .filter((track) => track.conference_id == value.id)
          .map((track) => track.id);
      })
      .flat()
      .filter((id) => (label == 'own' ? myTracksIds.includes(id) : !myTracksIds.includes(id)));
  };

  switch (section) {
    case 'my-conferences':
      // My active conferences
      myTrackIds = conferencesByDateAndOwner((value) => new Date(value.end_date) >= today, 'own');
      break;
    case 'all-conferences':
      // My active conferences
      myTrackIds = conferencesByDateAndOwner((value) => new Date(value.end_date) >= today, 'own');

      // All active conferences
      otherTrackIds = conferencesByDateAndOwner((value) => new Date(value.end_date) >= today);
      break;
    case 'past-conferences':
      // My active conferences
      myTrackIds = conferencesByDateAndOwner((value) => new Date(value.end_date) < today, 'own');

      // All active conferences
      otherTrackIds = conferencesByDateAndOwner((value) => new Date(value.end_date) < today);
      break;
  }

  const tracks = (trackIds: number[]) => {
    return trackIds
      .filter((trackId) => {
        const track = tracksById[trackId];
        const conference = conferencesById[track.conference_id];

        const text = searchText.trim().toLowerCase();
        return (
          track.name.toLowerCase().includes(text) ||
          conference.name.toLowerCase().includes(text) ||
          conference.short_name.toLowerCase().includes(text) ||
          (isSuperAdmin && conference.slug.toLowerCase().includes(text))
        );
      })
      .sort(function (a, b) {
        const startA = conferencesById[tracksById[a].conference_id];
        const startB = conferencesById[tracksById[b].conference_id];
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        const valA = startA?.start_date ? new Date(startA.start_date) : Infinity;
        const valB = startB?.start_date ? new Date(startB.start_date) : Infinity;
        // @ts-ignore
        // Order: ASC
        return valA - valB;
      })
      .map((trackId, index) => {
        const track = tracksById[trackId];
        const conference = conferencesById[track.conference_id];
        const userRoles = getUserRoles(trackId, userRolesByTrack, roleById);
        const isChair = userRoles.map((r) => roleById[r.id]).filter((role) => role.type == 'chair').length > 0;

        return (
          <Card
            key={index}
            featured={false}
            className={`relative ${section != 'past-conferences' && myTracksIds.includes(trackId) ? 'my-tracks' : ''}`}
          >
            <div className="mb-2">
              <Link
                className="cursor-pointer"
                onClick={() => {
                  selectTrackAction({
                    trackId: trackId,
                    roleId: determineRoleId(trackId, conferenceState),
                    doRedirect: true,
                  });
                }}
              >
                <div className="font-bold">
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mr-2" />
                  {conference.name} ({conference.short_name}) - {track.name}
                </div>
              </Link>
            </div>
            <StyledUl className="mt-4 mb-2 list-disc list-inside">
              <li>
                <span className="font-bold">Start date:</span>
                <span className="ml-1">
                  <DateTimeWithOffset date={conference.start_date} />
                </span>
              </li>
              <li>
                <span className="font-bold">End date:</span>
                <span className="ml-1">
                  <DateTimeWithOffset date={conference.end_date} />
                </span>
              </li>
              {conference.location && (
                <li>
                  <span className="font-bold">Location:</span>
                  <span className="ml-1">{conference.location}</span>
                </li>
              )}
              {conference.url && (
                <li>
                  <span className="font-bold">URL:</span>
                  <span className="ml-1">
                    <Link href={conference.url} target="_blank">
                      {conference.url}
                    </Link>
                  </span>
                </li>
              )}
            </StyledUl>

            <div className="sticky top-full mt-8 text-sm border-t-2 border-dotted border-gray-400 pt-1">
              <p className="mr-1">Enter as... </p>
              <p>
                <span className="text-xs"> | </span>
                {userRoles.map((r) => {
                  return (
                    <>
                      <Link
                        onClick={() => {
                          selectTrackAction({ trackId: trackId, roleId: r.id, doRedirect: true });
                        }}
                        className="cursor-pointer"
                      >
                        {roleById[r.id].description}
                      </Link>
                      <span className="text-xs"> | </span>
                    </>
                  );
                })}
              </p>
              {isSuperAdmin && !isChair && (
                <div className="mt-1 text-center">
                  <hr className="border-t-2 border-dotted border-gray-400 mb-4" />
                  <Button
                    className="cursor-pointer"
                    onClick={() => impersonateAction({ track_id: trackId })}
                    style={{ backgroundColor: 'rgb(252, 211, 77)', borderColor: 'rgb(252, 211, 77)' }}
                  >
                    <span className="font-bold text-black">Impersonate chair</span>
                  </Button>

                  <div className="mt-4">
                    <span className="font-bold">Slug:</span>
                    <span className="ml-1">{conference.slug}</span>
                  </div>
                </div>
              )}
            </div>
          </Card>
        );
      });
  };

  return (
    <div className="max-w-screen-2xl mt-8 mx-auto w-full">
      <div className="flex flex-row justify-between">
        <div className="flex items-center mb-14">
          <h2 className="font-bold text-xl">Conference List</h2>
          <button
            className="flex items-center ml-3 font-bold text-base"
            onClick={() => history.push(getRouteByName('RouteConferencesCreate').path)}
            style={{ color: theme.palette.primary.main }}
          >
            <PlusIcon />
            <span className="ml-3">Request Conference</span>
          </button>
        </div>

        <SearchText
          className="h-9"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>

      <StyledSection className="pb-8 border-b">
        <header className="relative">
          <Tabs className="flex-grow">
            {/*
              My conferences: my active conferences. Marked with background blue
            */}
            <TabsItem
              label="My Conferences"
              selected={section == 'my-conferences'}
              onClick={() => {
                setSection('my-conferences');
              }}
            />
            {/*
              Explore conferences: all active, owned conferences and other conferences. Other marked with background white
            */}
            <TabsItem
              label="Explore conferences"
              selected={section == 'all-conferences'}
              onClick={() => {
                setSection('all-conferences');
              }}
            />
            {/*
              Past conferences: all past conferences are marked with background grey.
            */}
            <TabsItem
              label="Past conferences"
              selected={section == 'past-conferences'}
              onClick={() => {
                setSection('past-conferences');
              }}
            />
          </Tabs>
        </header>

        {section != 'past-conferences' ? (
          // There are active conferences to show in My conferences and Explore conferences
          <>
            {tracks(myTrackIds).length > 0 || tracks(otherTrackIds).length > 0 ? (
              <div className="grid xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
                {tracks(myTrackIds).length > 0 && tracks(myTrackIds)}
                {section == 'all-conferences' && tracks(otherTrackIds).length > 0 && tracks(otherTrackIds)}
              </div>
            ) : (
              <div>
                <div className="grid xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
                  You don't have active conferences yet.
                </div>
              </div>
            )}
          </>
        ) : (
          // There are past conferences
          <div>
            {tracks(myTrackIds).length > 0 && (
              <>
                <h2 className="mb-4 text-sm font-bold leading-none">My past conferences</h2>
                <div className="grid xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 past-conferences-wrapper">
                  {tracks(myTrackIds)}
                </div>
              </>
            )}

            {tracks(otherTrackIds).length > 0 && (
              <>
                <h2 className={`mb-4 text-sm font-bold ${tracks(myTrackIds).length > 0 && 'mt-8'}`}>
                  Other past conferences
                </h2>
                <div className="past-conferences-wrapper grid xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
                  {tracks(otherTrackIds)}
                </div>
              </>
            )}
          </div>
        )}
      </StyledSection>
    </div>
  );
}

const StyledSection = styled.div`
  ${tw`mb-6`}
  header {
    ${tw`mb-6`}
  }
  .past-conferences-wrapper {
    > div {
      background-color: #f4f1f1;
    }
  }
`;

const StyledUl = styled.ul`
  li {
    display: list-item;
    list-style-position: initial;
    margin-left: 15px;
    padding-left: 3px;

    a {
      word-break: break-all;
    }
  }
`;

const mapStateToProps = (state: AppState) => ({
  conferenceState: state.conference,
  isSuperAdmin: selectAuth(state).isSuperAdmin,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  selectTrackAction: (data: ConferenceSelectTrack) => dispatch(conferenceSlice.actions.SELECT_TRACK(data)),
  impersonateAction: (data: ImpersonateRequest) => dispatch(authSlice.actions.IMPERSONATE(data)),
  unselectTrackAction: () => dispatch(conferenceSlice.actions.UNSELECT_TRACK()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConferencesPage);
