import React from 'react';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '../ui';
import useStyles from './ManageLabels.styles';
import TableBody from '@material-ui/core/TableBody';
import { DialogMode, LabelDialogFields } from '../LabelDialog/LabelDialog';
import { Label } from '../../store/label/types';
import { LabelDefaultColors } from '../MyGridRedux/MyGridRedux';
import { useTheme } from '@material-ui/core/styles';
import tw from 'twin.macro';
import styled, { ThemeProvider } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

export interface ManageLabelsProps {
  labelsById: { [key: number]: Label };
  openLabelDialog: (data: LabelDialogFields, labelId: null | number, mode: DialogMode) => void;
  onBackClick: () => void;
}
const ManageLabels: React.FC<ManageLabelsProps> = ({ labelsById, openLabelDialog, onBackClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div>
      <ThemeProvider theme={theme}>
        <StyledDiv>
          <button onClick={onBackClick} className="bg-blue-700 text-white px-1.5 py-0.5 h-6 leading-none">
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>

          <StyledH2>Manage Labels</StyledH2>

          <Button
            onClick={() =>
              openLabelDialog(
                {
                  name: '',
                  backgroundColor: LabelDefaultColors.backgroundColor,
                  textColor: LabelDefaultColors.textColor,
                },
                null,
                'create',
              )
            }
          >
            Create new label
          </Button>
        </StyledDiv>

        <TableContainer className="mb-16">
          <Table className={classes.table} aria-label="simple table">
            <StyledTableHead>
              <TableRow>
                <TableCell>
                  <StyledTableHeadLeft>Label Name</StyledTableHeadLeft>
                </TableCell>
                <TableCell>
                  <StyledTableHeadRight>Actions</StyledTableHeadRight>
                </TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {Object.values(labelsById).map((label) => {
                return (
                  <TableRow hover key={label.key}>
                    <TableCell component="th" scope="row">
                      <span
                        style={{
                          padding: '8px 12px',
                          width: '200px',
                          backgroundColor: label.background_color,
                          color: label.text_color,
                          borderRadius: '16px',
                        }}
                      >
                        {label.label}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <StyledButtons>
                        <StyledEditButton
                          onClick={() =>
                            openLabelDialog(
                              {
                                name: label.label,
                                backgroundColor: label.background_color,
                                textColor: label.text_color,
                              },
                              label.key,
                              'update',
                            )
                          }
                        >
                          Edit
                        </StyledEditButton>
                        <StyledRemoveButton
                          onClick={() =>
                            openLabelDialog(
                              {
                                name: label.label,
                                backgroundColor: label.background_color,
                                textColor: label.text_color,
                              },
                              label.key,
                              'delete',
                            )
                          }
                        >
                          Remove
                        </StyledRemoveButton>
                      </StyledButtons>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  );
};

export default ManageLabels;

const StyledDiv = styled.div`
  ${tw`flex items-center mb-7`}
`;

const StyledH2 = styled.h2`
  ${tw`text-xl font-semibold my-1.5 pr-5 ml-8`}
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledTableHeadLeft = styled.div`
  ${tw`text-left pl-3 font-semibold py-1.5`}
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledTableHeadRight = styled.div`
  ${tw`text-right pr-20 font-semibold`}
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledButtons = styled.div`
  ${tw`flex justify-end mr-8 my-1.5`}
`;

const StyledEditButton = styled.div`
  ${tw`cursor-pointer pr-8`}
  color: ${(props) => props.theme.palette.primary.dark};
`;

const StyledRemoveButton = styled.div`
  ${tw`cursor-pointer text-red-600`}
`;

const StyledTableHead = styled.thead`
  background-color: ${(props) => props.theme.palette.secondary.dark};
`;
