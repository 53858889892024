import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputText } from '../ui';
import useTheme from '@material-ui/core/styles/useTheme';
import { ThemeProvider } from 'styled-components';
import { getUserByEmail } from '../../store/user/sagas';
import SimpleDialog from '../dialogs/SimpleDialog/SimpleDialog';
import { Author } from '../../store/submission/types';
import { InputValidationError } from '../types';
import SquaredAddButton from '../ui/inputs/SquaredAddButton/SquaredAddButton';

export interface AuthorFormFields {
  first_name: string;
  last_name: string;
  organization: string | null;
  email: string;
}

interface Props {
  authors: Author[];
  save_handler: (data: AuthorFormFields) => void;
}

const AuthorForm: React.FC<Props> = ({ authors, save_handler }) => {
  const [dialog, setDialog] = React.useState<JSX.Element | undefined>(undefined);
  const [validatedEmail, setValidatedEmail] = useState<boolean>(false);

  const { handleSubmit, errors, control, getValues, setError } = useForm<AuthorFormFields>();

  let firstNameProps: InputValidationError = {};
  let lastNameProps: InputValidationError = {};
  let organizationProps: InputValidationError = {};
  let emailProps: InputValidationError = {};

  const frontend_validation_rules = {
    first_name: { required: 'Enter first name' },
    last_name: { required: 'Enter last name' },
    organization: { required: false },
    email: {
      required: 'Enter email address',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  };

  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.first_name) {
      firstNameProps = {
        ...firstNameProps,
        error: true,
        helperText: errors.first_name.message,
      };
    }
    if (errors.last_name) {
      lastNameProps = {
        ...lastNameProps,
        error: true,
        helperText: errors.last_name.message,
      };
    }
    if (errors.organization) {
      organizationProps = {
        ...organizationProps,
        error: true,
        helperText: errors.organization.message,
      };
    }
    if (errors.email) {
      emailProps = {
        ...emailProps,
        error: true,
        helperText: errors.email.message,
      };
    }
  }
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <form
        onSubmit={handleSubmit(async (data) => {
          const res = await getUserByEmail(data.email);
          switch (res.type) {
            case 'ok':
              const { data } = res.value;
              if (data.length) {
                const foundAuthor = data[0];
                // Case user found
                if (
                  authors.map((author) => author.id).includes(foundAuthor.id) ||
                  authors.map((author) => author.email).includes(foundAuthor.email)
                ) {
                  setError('email', { type: 'custom', message: 'This person is already added.' });
                } else {
                  save_handler(data[0]);
                }
              } else {
                // Case user not found
                setDialog(
                  <SimpleDialog
                    handleOk={handleSubmit((data) => {
                      save_handler(data);
                      setDialog(undefined);
                    })}
                    handleClose={() => setDialog(undefined)}
                    title="Email not found"
                    closeOnOk={false}
                  >
                    <>
                      <p className="text-sm mb-2">We don't have {getValues('email')} in our records yet.</p>
                      <p className="text-sm mb-2">
                        Please, provide us with some information about the author so that we can add it.
                      </p>
                      <form className="mt-4">
                        <Controller
                          name="first_name"
                          defaultValue=""
                          control={control}
                          rules={frontend_validation_rules.first_name}
                          render={({ onChange, value }) => (
                            <InputText
                              label="First name"
                              value={value}
                              required
                              onChange={onChange}
                              className="mb-3 mr-3 w-80 flex-grow"
                              error={firstNameProps.error}
                              helperText={firstNameProps.helperText}
                            />
                          )}
                        />
                        <Controller
                          name="last_name"
                          defaultValue=""
                          control={control}
                          rules={frontend_validation_rules.last_name}
                          render={({ onChange, value }) => (
                            <InputText
                              label="Last name"
                              value={value}
                              required
                              onChange={onChange}
                              className="mb-3 mr-3 w-80 flex-grow"
                              error={lastNameProps.error}
                              helperText={lastNameProps.helperText}
                            />
                          )}
                        />
                        <Controller
                          name="organization"
                          defaultValue=""
                          control={control}
                          rules={frontend_validation_rules.organization}
                          render={({ onChange, value }) => (
                            <InputText
                              label="Organization"
                              value={value}
                              onChange={onChange}
                              className="mb-3 mr-3 w-80 flex-grow"
                              error={organizationProps.error}
                              helperText={organizationProps.helperText}
                            />
                          )}
                        />
                      </form>
                    </>
                  </SimpleDialog>,
                );
              }
              break;
            case 'validation-error':
              break;
            case 'error':
              break;
          }
        })}
        className="flex"
      >
        <Controller
          name="email"
          defaultValue=""
          control={control}
          rules={frontend_validation_rules.email}
          render={({ onChange, value }) => {
            const regexEmail = new RegExp(frontend_validation_rules.email.pattern.value);

            if (value != '' && regexEmail.test(value)) {
              setValidatedEmail(true);
            } else {
              setValidatedEmail(false);
            }

            return (
              <InputText
                label=""
                value={value}
                onChange={onChange}
                className="mb-3 mr-3 w-72 flex-grow"
                error={emailProps.error}
                helperText={emailProps.helperText}
                placeholder="Enter a valid email address..."
              />
            );
          }}
        />
        <SquaredAddButton isValid={validatedEmail} />
      </form>

      {dialog}
    </ThemeProvider>
  );
};

export default AuthorForm;
