import React from 'react';
import { Link } from '../ui';

export interface SendVerificationProps {
  verify_email_handler: () => void;
  logout_handler: () => void;
  email: string;
}

const SendEmailVerification: React.FC<SendVerificationProps> = (info) => {
  const { verify_email_handler, logout_handler, email } = info;

  return (
    <div>
      <h2 className="text-2xl mb-9 font-semibold text-gray-600">Email verification</h2>
      <p>
        You should have received an email to <b>{email} </b>with the verification link.
      </p>
      <div className="flex justify-between mt-3">
        <Link onClick={verify_email_handler} className="font-semibold text-xs cursor-pointer">
          Resend link
        </Link>
        <Link onClick={logout_handler} className="font-semibold text-xs cursor-pointer">
          Logout
        </Link>
      </div>
    </div>
  );
};

export default SendEmailVerification;
