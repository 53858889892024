import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SendEmailVerification from '../components/EmailVerification/SendEmailVerification';
import authSlice from '../store/auth/slice';
import { selectAuth, selectCurrentAlias } from '../store/selectors';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';

interface SendEmailVerificationPageProps {
  sendVerificationEmailAction: () => void;
  logoutAction: () => void;
  email: string;
  isImpersonated: boolean;
}

const SendEmailVerificationPage = ({
  sendVerificationEmailAction,
  logoutAction,
  email,
  isImpersonated,
}: SendEmailVerificationPageProps) => {
  useEffect(() => {
    !isImpersonated && sendVerificationEmailAction();
  }, []);

  return (
    <SendEmailVerification
      verify_email_handler={sendVerificationEmailAction}
      logout_handler={logoutAction}
      email={email}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  email: selectCurrentAlias(state).email,
  isImpersonated: !!selectAuth(state).impersonatedTrackId,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendVerificationEmailAction: () => dispatch(authSlice.actions['SEND_VERIFICATION_EMAIL']()),
  logoutAction: () => dispatch(authSlice.actions['LOGOUT']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailVerificationPage);
