import React from 'react';
import { Kmenu } from '../ui/navigation/Kmenu/Kmenu';
import { MenuItemLink } from '@progress/kendo-react-layout';
import IconButton from '@material-ui/core/IconButton';
import { RowAction } from '../types';

export function renderMenuIconIfApplicable(props: any, key: string) {
  const icon = (
    <IconButton className="tooltip_icon" aria-label="actions" color="primary" style={{ fontSize: '1rem' }}>
      <span className={`k-icon k-i-${props.item.icon}`} role="presentation" key={key} />
    </IconButton>
  );
  return props.item.icon ? icon : null;
}

interface Props {
  actions: RowAction[];
  record: Record<string, unknown>;
  index: string;
}

const ActionsKmenu: React.FC<Props> = ({ actions, record, index }) => {
  return (
    <Kmenu
      openOnClick
      linkRender={(props: any) => {
        return (
          <MenuItemLink url={props.item.url} opened={props.opened}>
            {[renderMenuIconIfApplicable(props, index), props.item.text]}
          </MenuItemLink>
        );
      }}
      items={[
        {
          //text: 'Actions',
          icon: 'more-vertical',
          items: actions.map((x) => {
            return { text: x.label };
          }),
        },
      ]}
      onSelect={(event) => {
        const action = actions.find((x) => x.label == event.item.text);
        if (action && action.callback) {
          // @ts-ignore
          action.callback(record[action.rowIdKey || 'id']);
        }
      }}
    />
  );
};

export default ActionsKmenu;
