import React, { useState } from 'react';
import { Button, Link } from '../ui';
import { GridCellProps, GridColumnProps } from '@progress/kendo-react-grid';
import { UploadFeedbackResponse } from '../../store/submission/types';
import { APIValidationError } from '../../store/api';
import { Result } from '../../store/api.d';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { OpenSnackBarDTO } from '../../store/error/types';
import errorSlice from '../../store/error/slice';
import { getHref } from '../../helpers/download';
import { useTheme } from '@material-ui/core';
import MyGridCore from '../MyGridCore/MyGridCore';
import { DEFAULT_FONT_SIZE } from '../MyGridRedux/MyGridRedux';

const FeedbackCell: React.FC<GridCellProps> = (props) => {
  const field = props.field || '';
  const value = props.dataItem[field];

  const { has_error } = props.dataItem;

  return <td style={{ backgroundColor: has_error ? 'rgb(243, 23, 0, 0.32)' : 'rgb(55, 180, 0,0.32)' }}> {value}</td>;
};

interface Props {
  doPreview?: (file: File) => Promise<Result<UploadFeedbackResponse, APIValidationError>>;
  doSave?: (file: File) => Promise<Result<UploadFeedbackResponse, APIValidationError>>;
  onSaveSuccess?: () => void;
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
  templateName?: string;
  error?: string;
  onFeedbackHandler?: (data: UploadFeedbackResponse) => void;
}

const FileUploadUpdater: React.FC<Props> = ({
  doPreview,
  doSave,
  onSaveSuccess,
  openSnackBarAction,
  templateName,
  error,
  onFeedbackHandler,
}) => {
  const fileInput = React.createRef<HTMLInputElement>();

  const [feedback, setFeedback] = useState<UploadFeedbackResponse | undefined>(undefined);

  const theme = useTheme();

  /**
   * Reset input to its initial state.
   */
  const clearInput = () => {
    if (fileInput.current) {
      fileInput.current.value = '';
      setFeedback(undefined);
    }
  };

  let grid;
  if (feedback) {
    const gridColumnProps: GridColumnProps[] = feedback.data.columns.map((col) => ({
      field: col,
      title: col,
    }));
    gridColumnProps.push({
      field: 'Feedback',
      title: 'Feedback',
      cell: FeedbackCell,
    });

    const inputData: any[] = [];
    feedback.data.rows.forEach((row) => {
      const item: any = {};
      feedback.data.columns.forEach((col, index) => {
        item[col] = row.values[index];
      });
      item['Feedback'] = row.feedback;
      item['Feedback'] = row.feedback;
      item['has_error'] = row.has_error;
      inputData.push(item);
    });

    grid = (
      <MyGridCore
        allGridData={inputData}
        gridSettings={{
          gridColumnProps: gridColumnProps,
          arrayFields: [],
          advancedFilter: false,
          DATA_ITEM_KEY: '',
          SELECTED_FIELD: '',
          LABELABLE_ITEM_KEY: '',
          fontSize: DEFAULT_FONT_SIZE,
          dataState: {},
        }}
        selectedState={{}}
        dateFormat={null}
        expandedState={{}}
        selectable={false}
        showActionsColumn={false}
      />
    );
  }
  return (
    <div>
      <div>
        <div className="mb-6">
          {templateName && (
            <Link href={getHref(`/templates/${templateName}`)} className="mb-4 text-sm block w-max">
              Download template
            </Link>
          )}

          <input
            id="file"
            type="file"
            accept=".xlsx"
            ref={fileInput}
            onInput={async () => {
              if (fileInput.current?.files?.length && doPreview) {
                const file = fileInput.current.files[0];
                const result = await doPreview(file);
                switch (result.type) {
                  case 'ok':
                    setFeedback(result.value);
                    onFeedbackHandler?.(result.value);
                    break;
                  case 'validation-error':
                    openSnackBarAction({ message: result.value.validation[0].message, severity: 'error' });
                    break;
                }
              }
            }}
            onClick={() => {
              clearInput();
            }}
          />
        </div>
        {error && (
          <span className="ml-0.5 text-sm" style={{ color: theme.palette.error.main }}>
            {error}
          </span>
        )}
      </div>
      {grid}
      {feedback && doSave && (
        <div className="buttonWrapper text-center mt-4">
          <Button
            variant="text"
            onClick={() => {
              clearInput();
            }}
          >
            <span className="font-bold text-red-600 ">Cancel</span>
          </Button>

          <Button
            onClick={async () => {
              if (fileInput.current?.files?.length) {
                const file = fileInput.current.files[0];
                const result = await doSave(file);
                switch (result.type) {
                  case 'ok':
                    clearInput();
                    openSnackBarAction({ message: 'Data saved with success.', severity: 'success' });
                    onSaveSuccess?.();
                    break;
                  case 'validation-error':
                    openSnackBarAction({ message: result.value.validation[0].message, severity: 'error' });
                    break;
                }
              }
            }}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  openSnackBarAction: (data: OpenSnackBarDTO) => dispatch(errorSlice.actions['OPEN:SNACKBAR'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadUpdater);
