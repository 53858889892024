import React, { useState } from 'react';
import { InputText, Link } from '../ui';
import { OptionsData } from '../FormBuilder/FormBuilder';

interface Props {
  initialOption?: OptionsData;
  saveHandler: (option: OptionsData) => void;
  cancelHandler: () => void;
  withNumericalValue?: boolean;
}

const OptionInput: React.FC<Props> = ({ initialOption, saveHandler, cancelHandler, withNumericalValue }) => {
  const emptyOption: OptionsData = { id: '', label: '', value: undefined };
  const [option, setOption] = useState<OptionsData>(initialOption ?? emptyOption);

  const isValid = () => {
    let ok = !!option.label;
    if (withNumericalValue) {
      ok = ok && typeof option.value === 'number';
    }
    return ok;
  };

  return (
    <div className="w-full">
      <div className="flex items-center">
        <InputText
          className="option-input"
          label=""
          value={option.label}
          onChange={(e) => setOption({ ...option, label: e.target.value })}
          placeholder="Enter name"
        />
        {withNumericalValue && (
          <InputText
            type="number"
            label=""
            value={option.value}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setOption({ ...option, value: isNaN(value) ? undefined : value });
            }}
            placeholder="Enter number"
            className="ml-2 w-32"
          />
        )}
        {isValid() && (
          <Link className="ml-2 cursor-pointer" onClick={() => saveHandler(option)}>
            Save
          </Link>
        )}
        <Link className="ml-2 cursor-pointer" onClick={() => cancelHandler()}>
          Cancel
        </Link>
      </div>
    </div>
  );
};

export default OptionInput;
