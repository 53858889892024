import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../../inputs/Button';

interface Props {
  open: boolean;
  onClose?: () => void;
  onSave?: () => void;
  panelWidthMax?: string;
  panelWidthMin?: string;
  cancelLabel?: string;
  isValid?: boolean;
}

const SidePanel: React.FC<Props> = ({
  open,
  onClose,
  onSave,
  panelWidthMin = '0',
  panelWidthMax = '40%',
  children,
  cancelLabel = 'Cancel',
  isValid,
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(() => !open);

  useEffect(() => {
    setIsCollapsed(!open);
  }, [open]);

  return (
    <StyledSidePanel>
      <div
        className={`${isCollapsed ? 'panelCollapsed' : ''} shadow-lg`}
        style={{ width: isCollapsed ? panelWidthMin : panelWidthMax }}
      >
        <div className="panelContentWrapper h-full flex flex-col">
          <div className="panelContent">{children}</div>

          {/* FOOTER BUTTONS */}
          <StyledButtonsWrapper>
            <Button variant="text" className="cursor-pointer mx-2" onClick={() => onClose && onClose()}>
              <span className="font-bold text-red-600 ">{cancelLabel}</span>
            </Button>

            {onSave && (
              <Button className="w-48 mx-2" onClick={onSave} disabled={!isValid}>
                Save
              </Button>
            )}
          </StyledButtonsWrapper>
        </div>
      </div>
    </StyledSidePanel>
  );
};

export default SidePanel;

const StyledSidePanel = styled.div`
  > div {
    width: 37.5rem !important;
    max-width: 37.5rem;
    margin-left: 0px;
    position: absolute;
    top: 64px;
    right: 0px;
    height: calc(100% - 127px);
    background-color: #ffffff;
  }

  .panelContentWrapper {
    .panelContent {
      flex-grow: 1;
      overflow-y: scroll;

      > div {
        padding: 57px 38px 30px 38px;
      }
    }
  }

  .panelCollapsed {
    .panelContentWrapper {
      visibility: hidden;
      padding: 30px 0px 30px 0;
      display: none;
    }
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0 1.75rem 0;
  background-color: rgb(255, 255, 255);
  border-top: 1px dashed #b1b2b3;
  position: sticky;
  bottom: 0;
`;
