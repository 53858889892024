import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: PhaseState = {
  loading: false,
  byId: {},
  eventById: {},
  actionDefinitions: { submission: {}, review: {}, bidding: {}, discussion: {}, author_notification: {}, custom: {} },
};

const phaseSlice = createSlice({
  name: 'PHASE',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET:OK']: (state, action: PayloadAction<GetPhasesResponse>) => ({
      ...state,
      loading: false,
      byId: action.payload.data.byId,
      eventById: action.payload.data.eventById,
      actionDefinitions: action.payload.data.actionDefinitions,
    }),
    ['UPDATE']: (state, action: PayloadAction<PhaseUpdateDTO>) => ({
      ...state,
      //loading: true,
    }),
    ['UPDATE:OK']: (state, action: PayloadAction<Phase>) => ({
      ...state,
      loading: false,
      byId: {
        ...state.byId,
        [action.payload.id]: action.payload,
      },
    }),
    ['CREATE']: (state, action: PayloadAction<PhaseDTO>) => ({
      ...state,
      loading: true,
    }),
    ['CREATE:OK']: (state, action: PayloadAction<CreatePhasesResponse>) => ({
      ...state,
      loading: false,
      byId: {
        ...state.byId,
        ...action.payload.data.byId,
      },
      eventById: {
        ...state.eventById,
        ...action.payload.data.eventById,
      },
    }),
    ['CREATE:KO']: (state) => ({
      ...state,
      loading: false,
    }),
    ['DELETE']: (state, action: PayloadAction<number>) => ({
      ...state,
      loading: true,
    }),
    ['DELETE:OK']: (state, action: PayloadAction<number>) => {
      const { [action.payload]: phase, ...rest } = { ...state.byId };
      const eventById: { [key: number]: PhaseEvent } = {};
      Object.keys(state.eventById)
        .map((eventId) => state.eventById[parseInt(eventId)])
        .filter((event) => event.phase_id != action.payload)
        .forEach((event) => {
          eventById[event.id] = event;
        });
      return {
        ...state,
        loading: false,
        byId: rest,
        eventById,
      };
    },
    ['CREATE:EVENT']: (state, action: PayloadAction<PhaseEventDTO>) => ({
      ...state,
      loading: true,
    }),
    ['CREATE:EVENT:OK']: (state, action: PayloadAction<PhaseEvent>) => ({
      ...state,
      loading: false,
      eventById: {
        ...state.eventById,
        [action.payload.id]: action.payload,
      },
    }),
    ['CREATE:EVENT:KO']: (state) => ({
      ...state,
      loading: false,
    }),
    ['UPDATE:EVENT']: (state, action: PayloadAction<PhaseEventDTO>) => ({
      ...state,
      loading: true,
    }),
    ['UPDATE:EVENT:OK']: (state, action: PayloadAction<CreatePhasesResponse>) => {
      const { byId } = action.payload.data;
      const phase = Object.values(byId)[0];
      const newEventById: { [key: number]: PhaseEvent } = {};
      Object.values(state.eventById)
        .filter((event) => event.phase_id != phase.id)
        .forEach((event) => {
          newEventById[event.id] = event;
        });
      return {
        ...state,
        loading: false,
        eventById: { ...newEventById, ...action.payload.data.eventById },
      };
    },
    ['UPDATE:EVENT:KO']: (state) => ({
      ...state,
      loading: false,
    }),
    ['DELETE:EVENT']: (state, action: PayloadAction<number>) => ({
      ...state,
      loading: true,
    }),
  },
});

export default phaseSlice;
