import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import PhaseAddForm from '../../PhaseAddForm/PhaseAddForm';
import styled from 'styled-components';
import BidOptionsManager from '../../BidOptionsManager/BidOptionsManager';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import phaseSlice from '../../../store/phase/slice';
import { connect } from 'react-redux';
import { selectBidState, selectFormState, selectPaperStatusState, selectTrackRoles } from '../../../store/selectors';
import { BidOption } from '../../../store/bid/types';
import PaperStatusPicker from '../../PaperStatusPicker/PaperStatusPicker';
import { PaperStatus } from '../../../store/paper-status/types';
import { StepProps } from '@progress/kendo-react-layout/dist/npm/stepper/interfaces/StepProps';
import FormPermissionPicker from '../../FormPermissionPicker/FormPermissionPicker';
import { Role } from '../../../store/conference/types';
import { Form } from '../../../store/form/types';
import { initialFormPermissionActions } from '../../../helpers/phase';
import MyStepper from '../../MyStepper/MyStepper';

interface Props extends SimpleDialogProps {
  type: PhaseType;
  createPhase: (data: PhaseDTO) => void;
  paperStatusById: { [key: number]: PaperStatus };
  bidOptionsById: { [key: number]: BidOption };
  roleById: { [key: string]: Role };
  formById: { [key: number]: Form };
}

interface Step {
  item: StepProps;
  content: JSX.Element;
}

const PhaseAddDialog: React.FC<Props> = ({
  type,
  createPhase,
  paperStatusById,
  bidOptionsById,
  roleById,
  formById,
  ...rest
}) => {
  const [step, setStep] = React.useState<number>(0);
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [settings, setSettings] = useState<
    BidSettings | (DiscussionSettings & FormPermissionSettings) | FormPermissionSettings
  >(() => {
    switch (type) {
      case 'bidding':
        return { subset_bidding_enabled: false, paper_status_ids: [], bid_options: [] };
      case 'discussion':
        return { paper_status_ids: [], by_role_id: {}, start: [], end: [] };
      default:
        return { start: [], end: [] };
    }
  });
  const [phase, setPhase] = useState<PhaseDTO>({
    id: 0,
    type,
    name: '',
    role_id: null,
    start_date: '',
    end_date: '',
    settings,
  });

  const isLastStep = (): boolean => {
    return step == steps.length - 1;
  };

  const getFormStepContent = (eventType: keyof FormPermissionSettings) => {
    return (
      <div key={eventType}>
        <div className="text-sm mb-4 text-center" style={{ color: '#696969' }}>
          Here you have the forms visibility configuration for the {eventType} of the phase. Feel free to add or modify
          this configuration from below (can be changed later as well):
        </div>
        <div className="flex flex-row justify-center mb-8">
          <FormPermissionPicker
            roleById={roleById}
            formById={formById}
            phaseType={type}
            eventType={eventType}
            actions={(settings as FormPermissionSettings)[eventType]}
            onChange={(actions) => {
              setSettings({
                ...settings,
                [eventType]: actions,
              });
            }}
          />
        </div>
      </div>
    );
  };

  const getSteps = (): Step[] => {
    const steps = [
      {
        item: { label: 'Phase' },
        content: (
          <PhaseAddForm
            phase={phase}
            onChange={(data, isValid) => {
              setPhase(data);
              setIsValid(isValid);

              // Initialize bid options settings for bidding
              if (type === 'bidding') {
                setSettings({
                  ...settings,
                  bid_options: Object.values(bidOptionsById).filter((value) => value.role_id === data.role_id),
                });
              } else {
                setSettings({ ...settings, ...initialFormPermissionActions(data, roleById, formById) });
              }
            }}
          />
        ),
      },
    ];

    switch (type) {
      case 'submission':
      case 'review':
      case 'author_notification':
      case 'custom':
        steps.push({
          item: { label: 'Forms at start' },
          content: getFormStepContent('start'),
        });
        steps.push({
          item: { label: 'Forms at end' },
          content: getFormStepContent('end'),
        });
        break;
      case 'bidding':
        {
          const { bid_options, paper_status_ids } = settings as BidSettings;
          steps.push({
            item: { label: 'Bid options' },
            content: (
              <div>
                <div className="text-sm mx-4">
                  Here you can add, edit or remove bid options that will be used in this phase. This can be edit later
                  in the settings section later.
                </div>
                <BidOptionsManager
                  bidOptions={bid_options}
                  onChange={(bidOptions) => setSettings({ ...settings, bid_options: bidOptions })}
                />
              </div>
            ),
          });
          steps.push({
            item: { label: 'Paper statuses' },
            content: (
              <div>
                <div className="text-sm text-center">Which paper status do you want to use?</div>
                <StyledPaperStatusPickerWrapper>
                  <PaperStatusPicker
                    paperStatusIds={paper_status_ids}
                    paperStatusById={paperStatusById}
                    onChange={(paperStatusIds) => setSettings({ ...settings, paper_status_ids: paperStatusIds })}
                    phaseType={type}
                  />
                </StyledPaperStatusPickerWrapper>
              </div>
            ),
          });
        }
        break;
      case 'discussion':
        {
          const { paper_status_ids } = settings as DiscussionSettings;
          steps.push({
            item: { label: 'Forms at start' },
            content: getFormStepContent('start'),
          });
          steps.push({
            item: { label: 'Forms at end' },
            content: getFormStepContent('end'),
          });
          steps.push({
            item: { label: 'Paper statuses' },
            content: (
              <div>
                <div className="text-sm text-center">Which paper status do you want to use?</div>
                <StyledPaperStatusPickerWrapper>
                  <PaperStatusPicker
                    paperStatusIds={paper_status_ids}
                    paperStatusById={paperStatusById}
                    onChange={(paperStatusIds) => setSettings({ ...settings, paper_status_ids: paperStatusIds })}
                    phaseType={type}
                  />
                </StyledPaperStatusPickerWrapper>
              </div>
            ),
          });
        }
        break;
    }

    return steps;
  };

  const steps = getSteps();

  return (
    <SimpleDialog
      {...rest}
      title={`Add ${type.split('_').join(' ').toLowerCase()} phase`}
      closeOnOk={false}
      okButtonText={isLastStep() ? 'OK' : 'Next'}
      disableOkButton={!isValid}
      handleOk={() => {
        if (isLastStep()) {
          createPhase({ ...phase, settings });
          rest.handleClose();
        } else {
          setStep(step + 1);
        }
      }}
      handlePrevious={step > 0 ? () => setStep(step - 1) : undefined}
    >
      <>
        <MyStepper step={step} items={steps.map((step) => step.item)} className="pt-4 pb-8" />
        <div className="mb-4" style={{ width: type !== 'bidding' ? '47rem' : '36rem' }}>
          {steps[step].content}
        </div>
      </>
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  bidOptionsById: selectBidState(state).bidOptions.byId,
  roleById: selectTrackRoles(state),
  formById: selectFormState(state).formById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createPhase: (data: PhaseDTO) => dispatch(phaseSlice.actions.CREATE(data)),
});

const StyledPaperStatusPickerWrapper = styled.div`
  table {
    margin-left: auto;
    margin-right: auto;
    font-size: 0.875rem;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(PhaseAddDialog);
