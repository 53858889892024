import { DefaultQuestions } from '../store/form/types';
import QuestionHeader from '../components/QuestionComponent/QuestionHeader';
import FileUploaderS3 from '../components/FileUploaderS3/FileUploaderS3';
import React from 'react';
import { Submission } from '../store/submission/types';
import styled from 'styled-components';
import { Chip } from '../components/ui/display/Chip';
import { InputText, Link, TextArea } from '../components/ui';
import { can } from './permissions';
import AuthorsListForm from '../components/AuthorListForm/AuthorsListForm';
import { Role } from '../store/conference/types';
import EditIcon from '../icons/Edit';
import { OpenSnackBarDTO } from '../store/error/types';
import useTheme from '@material-ui/core/styles/useTheme';

export const getDefaultSubmissionForm = (
  submission: Submission,
  default_questions: DefaultQuestions,
  canViewAuthors: boolean,
  keywordById: { [key: number]: Keyword },
  setSubmission?: React.Dispatch<React.SetStateAction<Submission>>,
  errors?: { [key: string]: string },
  canViewFeedback?: boolean,
  userRole?: Role,
  personId?: number,
  onKeywordEditClick?: () => void,
  openSnackBarAction?: (data: OpenSnackBarDTO) => void,
): JSX.Element[] => {
  const theme = useTheme();

  const submissionGlobalSettings = {
    paperUploadRequired: default_questions.paper_upload.required,
    titleDescription: default_questions.title.description ?? '',
    abstractDescription: default_questions.abstract.description ?? '',
    authorsDescription: default_questions.authors.description ?? '',
    keywordsDescription: default_questions.keywords.description ?? '',
    paperUploadDescription: default_questions.paper_upload.description ?? '',
    paperUploadMaxSize: default_questions.paper_upload.max_size,
    paperUploadExtensions: default_questions.paper_upload.extensions ?? [],
    paperUploadMaxPages: default_questions.paper_upload.max_pages,
  };

  const defaultSubmissionContent: JSX.Element[] = [];
  let position = 1;

  const isEdit = !!setSubmission;

  // TITLE
  defaultSubmissionContent.push(
    <div id="title" className={`${isEdit ? 'mb-8' : ''}`}>
      {isEdit && (
        <p className="mt-4 mb-6">
          *This field is required to finalize the submission (you are free to save it without finalizing it)
        </p>
      )}
      <QuestionHeader
        position={position++}
        title="Title"
        description={submissionGlobalSettings.titleDescription}
        required={true}
        error={!!canViewFeedback && (!!errors?.title || !submission.title)}
      />
      {isEdit ? (
        <InputText
          value={submission.title}
          onChange={(e) => setSubmission?.({ ...submission, title: e.target.value })}
          className="mt-2"
          error={!!errors?.title}
          helperText={errors?.title}
          disabled={!can('CAN_EDIT_TITLE')}
          fullWidth={true}
          required={true}
        />
      ) : (
        <div className={`mt-2 whitespace-pre-line`}>{submission.title ? submission.title : 'Title not provided'}</div>
      )}

      {!isEdit && <StyledHr />}
    </div>,
  );

  // ABSTRACT
  defaultSubmissionContent.push(
    <div id="abstract" className={`${isEdit ? 'mb-8' : ''}`}>
      <QuestionHeader
        position={position++}
        title="Abstract"
        description={submissionGlobalSettings.abstractDescription}
        required={true}
        error={!!canViewFeedback && (!!errors?.abstract || !submission.abstract)}
      />

      {isEdit ? (
        <TextArea
          cols={40}
          rows={7}
          value={submission.abstract}
          onChange={(e) => setSubmission?.({ ...submission, abstract: e.target.value })}
          className="mt-2"
          error={!!errors?.abstract}
          helperText={errors?.abstract}
          disabled={!can('CAN_EDIT_ABSTRACT')}
          fullWidth={true}
          required={true}
        />
      ) : (
        <div className={`mt-2 text-sm w-full whitespace-pre-line`}>
          {submission.abstract ? submission.abstract : 'Abstract not provided'}
        </div>
      )}

      {!isEdit && <StyledHr />}
    </div>,
  );

  // AUTHORS
  if (canViewAuthors) {
    defaultSubmissionContent.push(
      <div id="authors" className={`${isEdit ? 'mb-8' : ''}`}>
        <QuestionHeader
          position={position++}
          title="Authors"
          description={submissionGlobalSettings.authorsDescription}
          required={true}
          error={!!canViewFeedback && !submission.authors.length}
        />

        {isEdit && setSubmission && userRole && personId ? (
          <AuthorsListForm
            submission={submission}
            setSubmission={setSubmission}
            userRole={userRole}
            personId={personId}
          />
        ) : (
          <StyledTable className="mt-2 w-full">
            <thead>
              <tr>
                <th className="w-40">First name</th>
                <th className="w-40">Last name</th>
                <th className="w-72">E-mail</th>
                <th>Affiliation</th>
                <th className="text-center">Primary</th>
              </tr>
            </thead>
            <tbody>
              {submission.authors.map((author, index) => {
                return (
                  <tr key={index}>
                    <td>{author.first_name}</td>
                    <td>{author.last_name}</td>
                    <td>{author.email}</td>
                    <td>{author.organization}</td>
                    <td style={{ textAlign: 'center' }}>{author.is_primary ? 'Yes' : 'No'}</td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        )}

        {!isEdit && <StyledHr />}
      </div>,
    );
  }

  // KEYWORDS
  if (Object.keys(keywordById).length > 0) {
    defaultSubmissionContent.push(
      <div id="keywords" className={`${isEdit ? 'mb-8' : ''}`}>
        <QuestionHeader
          position={position++}
          title="Keywords"
          description={submissionGlobalSettings.keywordsDescription}
          required={false}
          error={false}
        />

        {isEdit ? (
          <StyledKeywordsWrapper>
            {submission.keyword_ids.length > 0 ? (
              <StyledKeywordsContent>
                {submission.keyword_ids.map((keywordId, index) => {
                  const keyword = keywordById[keywordId];
                  const parentKeywordName = keyword.parent_id ? keywordById[keyword.parent_id].name : '';
                  return (
                    <Chip
                      className="keywordds-chip bg-gray-200 text-gray-500 mt-1 mb-2"
                      key={index}
                      onClose={
                        can('CAN_EDIT_KEYWORDS')
                          ? () => {
                              const selectedIds = submission.keyword_ids.filter(
                                (keywId, index) => keywId != keyword.id,
                              );

                              setSubmission?.({ ...submission, keyword_ids: selectedIds });
                            }
                          : undefined
                      }
                    >
                      <p className="font-bold">{parentKeywordName}</p>
                      <p>{keyword.name}</p>
                    </Chip>
                  );
                })}
              </StyledKeywordsContent>
            ) : (
              <StyledKeywordsContent>
                <div className="pt-2 text-gray-400 pb-2">No keywords selected</div>
              </StyledKeywordsContent>
            )}
            <StyledSelectKeywordsLabel>
              {
                <Link>
                  {can('CAN_EDIT_KEYWORDS') && (
                    <>
                      <div
                        className="font-normal cursor-pointer flex justify-end items-center"
                        onClick={(e) => can('CAN_EDIT_KEYWORDS') && onKeywordEditClick?.()}
                      >
                        <EditIcon color={theme.palette.primary.main} />
                        <p className="pl-2">Select keywords</p>
                      </div>
                    </>
                  )}
                </Link>
              }
            </StyledSelectKeywordsLabel>
          </StyledKeywordsWrapper>
        ) : submission.keyword_ids.length > 0 ? (
          <StyledKeywordsContent className="w-full mt-2">
            {submission.keyword_ids.map((keywordId, index) => {
              const keyword = keywordById[keywordId];
              const parentKeywordName = keyword.parent_id ? keywordById[keyword.parent_id].name : '';
              return (
                <Chip key={index} className="keywordds-chip bg-gray-200 text-gray-500 mr-2 mb-2">
                  <p className="font-bold">{parentKeywordName}</p>
                  <p>{keyword.name}</p>
                </Chip>
              );
            })}
          </StyledKeywordsContent>
        ) : (
          <div className="mt-2">No keywords selected</div>
        )}
        {!isEdit && <StyledHr />}
      </div>,
    );
  }

  // PAPER UPLOAD
  defaultSubmissionContent.push(
    <div id="uploaded-paper" className={`${isEdit ? 'mb-8' : ''}`}>
      <QuestionHeader
        position={position++}
        title="Paper upload"
        description={submissionGlobalSettings.paperUploadDescription}
        required={submissionGlobalSettings.paperUploadRequired}
        error={!!canViewFeedback && submissionGlobalSettings.paperUploadRequired && !submission.file_upload}
      />

      <StyledFileUploaderS3 className="mt-2">
        {isEdit ? (
          <FileUploaderS3
            initUpload={submission.file_upload}
            onSuccess={(upload) =>
              setSubmission?.({
                ...submission,
                file_upload: upload,
              })
            }
            onError={(message) => openSnackBarAction?.({ message, severity: 'error' })}
            openSnackBarAction={openSnackBarAction}
            extensions={submissionGlobalSettings.paperUploadExtensions}
            error={!!errors?.file_upload}
            disabled={!can('CAN_UPLOAD_PAPER')}
            maxSize={submissionGlobalSettings.paperUploadMaxSize}
            maxPages={submissionGlobalSettings.paperUploadMaxPages ?? undefined}
          />
        ) : (
          <FileUploaderS3 initUpload={submission.file_upload} extensions={['pdf']} disabled />
        )}
      </StyledFileUploaderS3>

      {!isEdit && <StyledHr />}
    </div>,
  );
  return defaultSubmissionContent;
};

const StyledTable = styled.table`
  thead {
    background-color: #e5e6ea;
  }

  th,
  td {
    text-align: left;
    padding: 1.25rem 1rem;
    font-size: 0.875rem;
    border-right: 1px solid ${(props) => props.theme.palette.secondary.dark};
    border-bottom: 1px solid ${(props) => props.theme.palette.secondary.dark};
  }

  th:last-child,
  td:last-child {
    border-right: none;
  }

  th:last-child {
    text-align: center;
  }
`;

const StyledFileUploaderS3 = styled.div`
  width: 100%;
  .fuploaderWrapper {
    span {
      color: ${(props) => props.theme.palette.primary.main};
    }
    > div > div {
      display: flex;
      align-items: center !important;
    }
  }

  .error {
    .fuploaderWrapper {
      span span {
        --tw-text-opacity: 1;
        color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
        font-size: 0.875rem;
      }
    }
  }
`;

const StyledKeywordsWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.25rem;
  padding: 9px;
  margin-top: 0.5rem;
  margin-bottom: 0.35rem;
  border: ${(props) => '1px solid rgba(0, 0, 0, 0.23);'}}
  background-color: #fff
`;

const StyledKeywordsContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  div {
    margin-right: 0.5rem;
  }

  .keywordds-chip {
    padding-top: 6px;
    padding-bottom: 6px;
    height: auto;
    padding-left: 18px;
    padding-right: 18px;

    span {
      margin-right: 10px;

      p:first-child {
        margin-bottom: 6px;
      }
    }
  }
`;

const StyledSelectKeywordsLabel = styled.div`
  width: 11rem;
  flex-grow: 0;
  padding-top: 0.5rem;
`;

const StyledHr = styled.hr`
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-style: dotted;
  border-bottom-width: 2px;
  border-top-width: 0px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;
