import React from 'react';
import { Card, Link } from '../../components/ui';
import { Tooltip } from '@material-ui/core';
import EditIcon from '../../icons/Edit';
import TrashCan from '../../icons/TrashCan';
import useTheme from '@material-ui/core/styles/useTheme';
import { Form, FormVisibilityOption, Question } from '../../store/form/types';
import { AppDispatch } from '../../store/store';
import { AppState } from '../../store/state';
import { selectFormState } from '../../store/selectors';
import { connect } from 'react-redux';

interface Props {
  form: Form;
  questionById: { [key: number]: Question };
  visibilityByFormId: {
    [key: string]: {
      [key: string]: { visibility: FormVisibilityOption; to_role_id: number | null; first_used_at: string | null }[];
    };
  };
  handleEdit: () => void;
  handleDeleteForm: () => void;
}

const FormCard: React.FC<Props> = ({ form, questionById, visibilityByFormId, handleEdit, handleDeleteForm }) => {
  // Check if form has is_draft property to false for some role
  const isDraft = (formId: number) => {
    let is_draft = true;
    Object.keys(visibilityByFormId[formId]).forEach((roleId) => {
      visibilityByFormId[formId][roleId].forEach((value) => {
        is_draft = is_draft && value.first_used_at == null;
      });
    });
    return is_draft;
  };

  const is_draft = isDraft(form.id);
  const formActiveQuestions = Object.values(questionById).filter((question) => question.form_id == form.id).length;

  let usersType = '';

  switch (form.type) {
    case 'Submission':
      usersType = 'authors';
      break;

    case 'Review':
      usersType = 'reviewers';
      break;
  }

  const theme = useTheme();

  return (
    <Card className={`${is_draft && 'draft-card'}`}>
      <div className="card-header">
        <h3 className="font-medium text-base">
          <Link
            className="flex items-center cursor-pointer"
            onClick={() => {
              // Tabs: On the onclick event, I pass the ID of the form to open, and I open the page using the setTab
              handleEdit();
            }}
          >
            {form.name}
          </Link>
        </h3>

        {/* DRAFT LABEL */}
        <div className={`draft-label ${is_draft ? 'visible w-[71.7px]' : 'invisible w-0'}`}>
          Draft
          <Tooltip
            placement="top-end"
            title={`This will remain a draft until presented to the ${usersType}. Use the planning section to attach the form to a phase event.`}
            className="cursor-default"
          >
            <span
              className="font-semibold px-1.5 rounded-full text-sm ml-1.5 text-white cursor-default"
              style={{ backgroundColor: '#2563eb', paddingTop: '2px' }}
            >
              ?
            </span>
          </Tooltip>
        </div>
      </div>
      <ul style={{ minHeight: '71px' }}>
        <li>
          <strong>Number of questions: </strong>
          {form.default_questions
            ? formActiveQuestions + Object.keys(form.default_questions).length
            : formActiveQuestions}
        </li>
      </ul>
      <div className="flex justify-between w-full left-0 px-5 text-sm absolute bottom-4">
        <Link
          onClick={() => {
            // Tabs: On the onclick event, I pass the ID of the form to open, and I open the page with the form using the setTab
            handleEdit();
          }}
          className="flex items-center cursor-pointer"
        >
          <EditIcon color={theme.palette.primary.main} className="mr-1" />
          Edit
        </Link>

        {!form.is_default && (
          <div className="cursor-pointer w-4" onClick={handleDeleteForm}>
            <TrashCan stroke={theme.palette.primary.main} />
          </div>
        )}
      </div>
    </Card>
  );
};

const mapStateToProps = (state: AppState) => ({
  questionById: selectFormState(state).questionById,
  visibilityByFormId: selectFormState(state).visibilityByFormId,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormCard);
