import React from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { Select, SelectRadioButton } from '../../ui';

export type columnsOption = 'current' | 'all';
export type rowsOption = 'selected' | 'all';

interface Props extends Omit<SimpleDialogProps, 'handleOk'> {
  numSelected: number;
  handleOk?: (columns: columnsOption, rows: rowsOption) => void;
}

const colsOptions = [
  { id: 'all', label: 'All' },
  { id: 'current', label: 'Current view' },
];

const rowsOptions = [
  { id: 'all', label: 'All' },
  { id: 'selected', label: 'Selected' },
];

const formatOptions = [{ id: '1', label: 'Excel file (.xlsx)' }];

const ExportTableDialog: React.FC<Props> = ({ numSelected, handleOk, ...props }) => {
  const [selectedColsOption, setSelectedColsOption] = React.useState<columnsOption | undefined>(
    colsOptions[0].id as columnsOption,
  );
  const [selectedRowsOption, setSelectedRowsOption] = React.useState<rowsOption | undefined>(
    rowsOptions[0].id as rowsOption,
  );

  return (
    <SimpleDialog
      {...props}
      handleOk={() => selectedColsOption && selectedRowsOption && handleOk?.(selectedColsOption, selectedRowsOption)}
    >
      <>
        <div className="mb-5">
          <p className="mb-2">Which columns?</p>
          <SelectRadioButton
            value={selectedColsOption}
            options={colsOptions}
            onChange={(value) => setSelectedColsOption(value as columnsOption)}
          />
        </div>
        <div className="mb-5">
          <p className="mb-2">Which rows?</p>
          <SelectRadioButton
            value={selectedRowsOption}
            options={rowsOptions}
            onChange={(value) => setSelectedRowsOption(value as rowsOption)}
            disabled={numSelected == 0}
          />
        </div>
        <Select label="File format" value={formatOptions[0].id} options={formatOptions} />
      </>
    </SimpleDialog>
  );
};

export default ExportTableDialog;
