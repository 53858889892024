import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import { InputText } from '../../ui';
import { PaperStatusDTO } from '../../../store/paper-status/types';
import paperStatusSlice from '../../../store/paper-status/slice';
import { formatCategory } from '../../../pages/chair/SettingsPaperStatusPage';

interface Props extends SimpleDialogProps {
  initialValue: PaperStatusDTO;
  postPaperStatusAction: (data: PaperStatusDTO) => void;
  putPaperStatusAction: (data: PaperStatusDTO) => void;
}

const PaperStatusDialog: React.FC<Props> = ({ initialValue, postPaperStatusAction, putPaperStatusAction, ...rest }) => {
  const [data, setData] = useState<PaperStatusDTO>(initialValue);

  let disableButtonCondition = !data.name;

  if (data.category === 'in_progress') {
    disableButtonCondition = disableButtonCondition || !data.display_name;
  }

  return (
    <SimpleDialog
      {...rest}
      title={`${data.id ? 'Edit' : 'Add'} ${formatCategory(initialValue.category).toLowerCase()} status`}
      handleOk={() => {
        if (data.id) {
          putPaperStatusAction(data);
        } else {
          postPaperStatusAction(data);
        }
      }}
      disableOkButton={disableButtonCondition}
    >
      <div className="flex flex-col">
        <InputText
          label="Name"
          required
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          className="mb-5"
          disabled={data.name === 'Awaiting decision'}
        />
        {data.category === 'in_progress' && (
          <InputText
            label="Display name for authors"
            required
            value={data.display_name}
            onChange={(e) => setData({ ...data, display_name: e.target.value })}
            className="mb-5"
          />
        )}
      </div>
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  postPaperStatusAction: (data: PaperStatusDTO) => dispatch(paperStatusSlice.actions['POST:PAPER_STATUS'](data)),
  putPaperStatusAction: (data: PaperStatusDTO) => dispatch(paperStatusSlice.actions['PUT:PAPER_STATUS'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaperStatusDialog);
