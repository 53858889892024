import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { AppState } from '../../store/state';
import { Card, Link } from '../../components/ui';
import PhaseAddDialog from '../../components/dialogs/PhaseAddDialog/PhaseAddDialog';
import phaseSlice from '../../store/phase/slice';
import { selectCurrentUser, selectPhaseState, selectTrackRoles } from '../../store/selectors';
import Loading from '../../components/Loading/Loading';
import Phase from '../../components/Phase';
import { capitalizeFirstLetter } from '../../helpers/string';
import { AddButton } from '../../components/ui/inputs/AddButton/AddButton';
import styled from 'styled-components';
import EditIcon from '../../icons/Edit';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import useTheme from '@material-ui/core/styles/useTheme';
import TrashCan from '../../icons/TrashCan';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import { Role } from '../../store/conference/types';
import DateTimeWithOffset from '../../components/DateTimeWithOffset/DateTimeWithOffset';
import Tooltip from '@material-ui/core/Tooltip';
import { getUserOffset } from '../../helpers/timezone';
import { isPastDate, isInProgress } from '../../helpers/date';

interface Props {
  loading: boolean;
  phaseById: { [key: number]: Phase };
  eventById: { [key: number]: PhaseEvent };
  roleById: { [key: string]: Role };
  timezone: string | null;
  deletePhaseAction: (phaseId: number) => void;
}

const SettingsPlanningPage: React.FC<Props> = ({
  loading,
  phaseById,
  eventById,
  roleById,
  timezone,
  deletePhaseAction,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [phaseType, setPhaseType] = useState<PhaseType | undefined>(undefined);
  const [selectedPhaseId, setSelectedPhaseId] = useState<number | undefined>(undefined);
  const [dialog, setDialog] = React.useState<JSX.Element | undefined>(undefined);

  if (loading) {
    return <Loading />;
  } else if (selectedPhaseId) {
    return (
      <Phase
        phase={phaseById[selectedPhaseId]}
        onBack={() => setSelectedPhaseId(undefined)}
        getPhaseName={(phaseId: number) => phaseById[phaseId].name}
      />
    );
  } else {
    const theme = useTheme();
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
        partialVisibilityGutter: 40,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        partialVisibilityGutter: -46,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        partialVisibilityGutter: 85,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 85,
      },
    };

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
      const {
        carouselState: { currentSlide },
      } = rest;

      return (
        <div className="carousel-button-group absolute top-0 left-0 flex justify-between w-full">
          <div
            className={
              currentSlide === 0
                ? 'left-arrow-wrapper disable-caroussel-buttons'
                : 'left-arrow-wrapper caroussel-arrow-wrapper'
            }
          >
            <button
              className={
                currentSlide === 0
                  ? 'disable-caroussel-buttons'
                  : 'react-multiple-carousel__arrow react-multiple-carousel__arrow--left '
              }
              onClick={() => previous()}
            />
          </div>

          <div
            className={
              currentSlide === rest.carouselState.totalItems - rest.carouselState.slidesToShow
                ? 'right-arrow-wrapper disable-caroussel-buttons'
                : 'right-arrow-wrapper caroussel-arrow-wrapper'
            }
          >
            <button
              className={
                currentSlide === rest.carouselState.totalItems - rest.carouselState.slidesToShow
                  ? 'disable-caroussel-buttons'
                  : 'react-multiple-carousel__arrow react-multiple-carousel__arrow--right'
              }
              onClick={() => next()}
            />
          </div>
        </div>
      );
    };

    const utcOffset = getUserOffset(timezone);

    return (
      <StyledContent>
        <h2 className="font-bold text-xl mb-8">Planning phases</h2>

        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={false}
          keyBoardControl={false}
          containerClass="carousel-container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          deviceType={'desktop'}
          partialVisible={true}
          renderArrowsWhenDisabled={false}
          arrows={false}
          customButtonGroup={<ButtonGroup />}
        >
          {(['submission', 'bidding', 'review', 'discussion', 'author_notification', 'custom'] as PhaseType[]).map(
            (phaseType, index) => {
              let phaseShortDescription;
              switch (phaseType) {
                case 'submission':
                  phaseShortDescription =
                    'Authors submit their papers for review during this phase, hoping for acceptance.';
                  break;
                case 'bidding':
                  phaseShortDescription =
                    'In the bidding phase, reviewers place bids and express their interest in reviewing different papers.';
                  break;
                case 'review':
                  phaseShortDescription =
                    'During the review phase, submitted content is carefully examined and evaluated by reviewers.';
                  break;
                case 'discussion':
                  phaseShortDescription = 'This phase involves open discussion among reviewers.';
                  break;
                case 'author_notification':
                  phaseShortDescription =
                    'Authors receive notifications regarding the status of their submissions in this phase.';
                  break;
                case 'custom':
                  phaseShortDescription = 'This is a personalized phase where custom actions are established.';
                  break;

                default:
                  phaseShortDescription =
                    "Here give users a bit of an explanation about what this is. Let's say it simple terms.";
                  break;
              }

              return (
                <div key={index} style={{ maxWidth: '19rem' }}>
                  <StyledCardTemplate>
                    <h3 className="font-medium text-base">
                      <Link
                        className="cursor-pointer"
                        onClick={() => {
                          setPhaseType(phaseType);
                          setIsOpen(true);
                        }}
                      >
                        {capitalizeFirstLetter(phaseType.split('_').join(' '))}
                      </Link>
                    </h3>
                    <p style={{ height: '4rem' }}>{phaseShortDescription}</p>

                    <AddButton
                      label="Add"
                      onClick={() => {
                        setPhaseType(phaseType);
                        setIsOpen(true);
                      }}
                    />
                  </StyledCardTemplate>
                </div>
              );
            },
          )}
        </Carousel>

        <div className="mb-8 flex items-center">
          <h2 className="font-bold text-xl">My conference</h2>

          <Tooltip title="The UTC offset can be adjusted from the user settings screen." placement="top">
            <StyledDateInfo className="text-sm ml-4 cursor-default">Dates in UTC{utcOffset}</StyledDateInfo>
          </Tooltip>
        </div>

        <div className="grid gap-5 xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 mb-8">
          {Object.values(phaseById)
            .sort(function (a, b) {
              const startA = Object.values(eventById).find((event) => event.phase_id == a.id && event.type == 'start');
              const startB = Object.values(eventById).find((event) => event.phase_id == b.id && event.type == 'start');
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              const valA = startA?.starts_at ? new Date(startA.starts_at) : Infinity;
              const valB = startB?.starts_at ? new Date(startB.starts_at) : Infinity;
              // @ts-ignore
              return valA - valB;
            })
            .map((phase) => {
              const events = Object.values(eventById).filter((event) => event.phase_id == phase.id);
              const startEvent = events.find((event) => event.type == 'start') as PhaseEvent;
              const endEvent = events.find((event) => event.type == 'end') as PhaseEvent;
              endEvent.starts_at;
              const isPast = isPastDate(new Date(endEvent.starts_at ?? ''));
              const inProgress = isInProgress(new Date(startEvent.starts_at ?? ''), new Date(endEvent.starts_at ?? ''));

              return (
                <StyledCard key={phase.id} className="templates">
                  <div className={`in-progress-label text-sm ${endEvent.starts_at && isPast ? 'opacity-40' : ''}`}>
                    <div className="card-header">
                      <h3 className="font-medium text-base">
                        <Link className="flex items-center cursor-pointer" onClick={() => setSelectedPhaseId(phase.id)}>
                          {phase.name}
                        </Link>
                      </h3>

                      {/* IN PROGRESS LABEL */}
                      <div className={`text-sm ${inProgress ? 'in-progress-label' : 'invisible-in-progress-label'}`}>
                        In progress
                      </div>
                    </div>

                    <ul style={{ minHeight: '71px' }}>
                      <li>
                        <strong>Type</strong>:<span className="ml-1">{phase.type}</span>
                      </li>
                      {phase.role_id && (
                        <li>
                          <strong>Role</strong>:
                          <span className="ml-1">
                            {Object.values(roleById).find((role) => role.id == phase.role_id)?.description}
                          </span>
                        </li>
                      )}
                      {startEvent && (
                        <li>
                          <strong>Starting</strong>:
                          <span className="ml-1">
                            <DateTimeWithOffset date={startEvent.starts_at} showTime={true} showUtc={false} />
                          </span>
                        </li>
                      )}
                      {endEvent && (
                        <li>
                          <strong>Ending</strong>:
                          <span className="ml-1">
                            <DateTimeWithOffset date={endEvent.starts_at} showTime={true} showUtc={false} />
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>

                  <div className="flex justify-between w-full left-0 px-5 text-sm absolute bottom-4">
                    <Link className="flex items-center cursor-pointer" onClick={() => setSelectedPhaseId(phase.id)}>
                      <EditIcon color={theme.palette.primary.main} className="mr-1" />
                      Edit
                    </Link>

                    <div
                      className="cursor-pointer w-4"
                      onClick={() => {
                        setDialog(
                          <SimpleDialog
                            open={true}
                            handleClose={() => setDialog(undefined)}
                            handleOk={() => {
                              deletePhaseAction(phase.id);
                            }}
                            title="Remove phase?"
                          >
                            <p className="text-sm mb-2">
                              {`Are you sure you want to remove ${phase.name} phase? Take into account that this action can't be undone.`}
                            </p>
                          </SimpleDialog>,
                        );
                      }}
                    >
                      <TrashCan stroke={theme.palette.primary.main} />
                    </div>
                  </div>
                </StyledCard>
              );
            })}
        </div>
        {isOpen && phaseType && <PhaseAddDialog type={phaseType} open={isOpen} handleClose={() => setIsOpen(false)} />}
        {dialog}
      </StyledContent>
    );
  }
};

const mapStateToProps = (state: AppState) => ({
  loading: selectPhaseState(state).loading,
  phaseById: selectPhaseState(state).byId,
  eventById: selectPhaseState(state).eventById,
  roleById: selectTrackRoles(state),
  timezone: selectCurrentUser(state).person.timezone,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deletePhaseAction: (phaseId: number) => dispatch(phaseSlice.actions.DELETE(phaseId)),
});

const StyledCard = styled(Card)`
  h3 {
    border-color: rgb(200, 203, 204);
  }

  p,
  ul li {
    font-size: 0.875rem;
  }

  button {
    margin-left: 0;
    margin-top: 1rem;
  }

  .card-header {
    border-bottom: 1px dashed rgb(214, 216, 217);
    margin-bottom: 0.75rem;
    display: flex;
    justify-content: space-between;

    h3 {
      border: none;
      margin-bottom: 0;
      align-self: end;
    }

    .in-progress-label {
      height: max-content;
      background-color: #fbeb02;
      padding: 2px 8px;
      border-radius: 12px;
      margin-bottom: 0.2rem;
      visibility: visible;
    }
    .invisible-in-progress-label {
      margin-bottom: 0.2rem;
      visibility: hidden;
      width: 0;
      height: 0;
      padding: 0;
    }
  }
`;

const StyledCardTemplate = styled(Card)`
  background-color: transparent;

  h3 {
    border-color: rgb(200, 203, 204);
  }

  p,
  ul li {
    font-size: 0.875rem;
  }

  button {
    margin-left: 0;
    margin-top: 1rem;
  }
`;

const StyledContent = styled.div`
  .carousel-container {
    max-width: 70rem;
    margin-bottom: 3rem;

    .react-multiple-carousel__arrow {
      background: rgba(0, 68, 240, 1);
    }
    .react-multiple-carousel__arrow--left {
      left: calc(1% + 1px);
    }
    .react-multiple-carousel__arrow--right {
      right: calc(1% + 1px);
    }
  }
  .caroussel-arrow-wrapper {
    height: 12rem;
    width: 3rem;
    position: absolute;
    background: rgba(237, 243, 255, 0.8);
    opacity: 1;
    display: flex;
    align-items: center;
  }
  .left-arrow-wrapper {
    left: 0;
    justify-content: end;
  }
  .right-arrow-wrapper {
    right: 0;
    justify-content: end;
  }
  .disable-caroussel-buttons {
    display: none;
  }
`;

const StyledDateInfo = styled.div`
  background-color: transparent;
  padding: 0 10px;
  color: #000;
  border-radius: 13px;
  font-size: 0.87rem;
  font-weight: 500;
  border: 1px solid #000;
`;

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPlanningPage);
