import React, { useState } from 'react';
import { OptionsData } from '../FormBuilder/FormBuilder';
import { Button } from '../ui';
import OptionInput from './OptionInput';
import TrashCan from '../../icons/TrashCan';
import Edit from '../../icons/Edit';
import { Tooltip } from '@material-ui/core';

interface OptionData {
  asSelect?: boolean;
  asRadioButton?: boolean;
}
interface Props {
  editOption: (index: number, option: OptionsData) => void;
  option: OptionsData[];
  addOption: (index: number, option: OptionsData) => void;
  deleteOptionHandler: (OptionIndex: number, index: number | undefined) => void;
  index: number;
  withNumericalValue?: boolean;
  disabled?: boolean;
}

const SelectOptions: React.FC<Props> = ({
  editOption,
  option,
  index,
  addOption,
  deleteOptionHandler,
  withNumericalValue,
  disabled,
}) => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [editIdx, setEditIdx] = useState<number | undefined>(undefined);

  return (
    <>
      <div className="flex flex-col mb-4">
        {option?.map((x, i) => (
          <div key={x.id} className="flex flex-row items-center justify-start py-1.5 px-3 bg-gray-200 rounded-lg mb-2">
            {i == editIdx ? (
              <OptionInput
                initialOption={x}
                saveHandler={(value) => {
                  editOption(index, value);
                  setEditIdx(undefined);
                }}
                cancelHandler={() => setEditIdx(undefined)}
                withNumericalValue={withNumericalValue}
              />
            ) : (
              <div style={{ flexGrow: 1 }}>
                <span>{x.label}</span>
                {withNumericalValue && <span>:{x.value}</span>}
              </div>
            )}
            {editIdx == undefined && !disabled && (
              <>
                <div className="cursor-pointer ml-2" onClick={() => setEditIdx(i)}>
                  <Edit />
                </div>
                <div className="cursor-pointer ml-2" onClick={() => deleteOptionHandler(i, index)}>
                  <TrashCan />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      {showInput ? (
        <OptionInput
          saveHandler={(value) => {
            addOption(index, value);
            setShowInput(false);
          }}
          cancelHandler={() => setShowInput(false)}
          withNumericalValue={withNumericalValue}
        />
      ) : (
        <Tooltip
          title={`${disabled ? 'You cannot add options because the form is presented to authors.' : ''}`}
          placement="top-start"
          className="cursor-default"
        >
          <div>
            <Button className="w-32" onClick={() => setShowInput(true)} disabled={disabled}>
              Add Option
            </Button>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default SelectOptions;
