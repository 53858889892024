import { Switch, Tooltip } from '@material-ui/core';
import React from 'react';
import ChevronDownIcon from '../../icons/ChevronDown';
import ChevronUpIcon from '../../icons/ChevronUp';
import CopyIcon from '../../icons/Copy';
import { FTypeEnum, OptionsData } from '../FormBuilder/FormBuilder';
import InputLinearScaleComponent from '../FormBuilderInput/InputLinearScaleComponent';
import InputMultiSelectComponent from '../FormBuilderInput/InputMultiSelectComponent';
import InputNumericComponent from '../FormBuilderInput/InputNumericComponent';
import InputSelectComponent from '../FormBuilderInput/InputSelectComponent';
import InputShortTextComponent from '../FormBuilderInput/InputShortTextComponent';
import InputTextComponent from '../FormBuilderInput/InputTextComponent';
import UploadFileComponent from '../FormBuilderInput/UploadFileComponent';
import { InputText, MultiSelect, Select, TextArea } from '../ui';
import styled from 'styled-components';
import TrashCan from '../../icons/TrashCan';

interface IData {
  id?: string;
  label?: string;
}

interface Props {
  index: number;
  values: any;
  valueslength: number;
  typeOptionsData: OptionsData[];
  visibilityOptionsData: OptionsData[];
  isFocus: boolean;
  defaultQuestionsLength: number;
  is_editable?: boolean;
  deleteHandler: (id: number) => void;
  duplicateHandler: (id: number) => void;
  handleChange: (event: any, id: number) => any;
  descriptionHandler: (id: number, text: string) => void;
  changeHandler: (event: any, id: number) => any;
  visibilityHandler: (visible: any, index: number, visibilityOptions: IData[]) => void;
  swapPosition: (from: number, to: number) => void;
  selectHandler: (event: any, index: number) => void;
  editOption: (index: number, option: OptionsData) => void;
  addOption: (index: number | undefined, option: OptionsData) => void;
  deleteOptionHandler: (OptionIndex: number | undefined, index: number | undefined) => void;
  textHandler: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number | undefined) => void;
  checkboxHandler: (event: React.ChangeEvent<HTMLInputElement>, index: number | undefined) => void;
  fileHandler: (file: any, index: number | undefined, fileOptions: any) => void;
  selectNumberHandler: (event: string, index: number | undefined, optionName: string) => void;
  focusHandler: (questionId: number) => void;
}
const FormBuilderCard: React.FC<Props> = ({
  index,
  values,
  valueslength,
  typeOptionsData,
  visibilityOptionsData,
  isFocus,
  defaultQuestionsLength,
  is_editable,
  deleteHandler,
  duplicateHandler,
  handleChange,
  descriptionHandler,
  changeHandler,
  visibilityHandler,
  swapPosition,
  selectHandler,
  editOption,
  addOption,
  deleteOptionHandler,
  textHandler,
  checkboxHandler,
  fileHandler,
  selectNumberHandler,
  focusHandler,
}) => {
  const notEditableMessage = "You can't do this action because this form has already stored answers";

  /* QUESTION TYPES COMPONENTS */
  const getComponent = (data: string) => {
    switch (data) {
      case FTypeEnum.Text:
        return (
          <InputTextComponent
            placeholder="Enter maximum text length"
            textHandler={textHandler}
            checkboxHandler={checkboxHandler}
            index={index}
            value={values.Option}
            name="textLength"
            className="text-sm"
          />
        );
      case FTypeEnum.ShortText:
        return (
          <InputShortTextComponent
            placeholder="Enter maximum text length"
            textHandler={textHandler}
            index={index}
            value={values.Option}
            name="textLength"
            className="text-sm"
          />
        );
      case FTypeEnum.MultipleSelect:
        return (
          <StyledTInputMultiSelectComponent
            index={index}
            editOption={editOption}
            addOption={addOption}
            deleteOptionHandler={deleteOptionHandler}
            option={values.Options}
            disabled={!is_editable}
          />
        );
      case FTypeEnum.Select:
        return (
          <InputSelectComponent
            index={index}
            editOption={editOption}
            addOption={addOption}
            checkboxHandler={checkboxHandler}
            value={values.Option}
            deleteOptionHandler={deleteOptionHandler}
            option={values.Options}
            disabled={!is_editable}
          />
        );
      case FTypeEnum.File:
        return (
          <UploadFileComponent
            fileHandler={fileHandler}
            name="fileSize"
            textHandler={textHandler}
            index={index}
            value={values.Option}
            className="text-sm"
          />
        );
      case FTypeEnum.Numeric:
        return (
          <InputNumericComponent
            index={index}
            textHandler={textHandler}
            selectNumberHandler={selectNumberHandler}
            value={values.Option}
            values={values.Options}
            disabled={!is_editable}
            tootltipMessage={notEditableMessage}
          />
        );
      case FTypeEnum.LinearScale:
        return <InputLinearScaleComponent index={index} textHandler={textHandler} value={values.Option} />;
      case FTypeEnum.LikertScale:
        return (
          <InputSelectComponent
            index={index}
            editOption={editOption}
            addOption={addOption}
            checkboxHandler={checkboxHandler}
            value={values.Option}
            deleteOptionHandler={deleteOptionHandler}
            option={values.Options}
            withNumericalValue={true}
            disabled={!is_editable}
          />
        );
      default:
        return null;
    }
  };

  const style: React.CSSProperties | undefined = {};
  if (isFocus) {
    style.borderLeftWidth = '4px';
    style.borderColor = '#0044F0';
  }

  return (
    <StyledFormBuilderCard
      className={`p-4 h-auto bg-white rounded-lg border border-solid border-gray-300 w-full mb-4`}
      style={style}
    >
      <div className="relative">
        <h2 className={`text-lg font-semibold pb-4`}>Question {index + 1 + defaultQuestionsLength}</h2>
        {!is_editable && (
          <Tooltip title={notEditableMessage} placement="top-start" className="cursor-default">
            <StyledPreviewLabel className="absolute">Not entirely editable</StyledPreviewLabel>
          </Tooltip>
        )}
      </div>

      <div onClick={() => focusHandler(index)}>
        <div className="flex flex-col">
          {/* COMMON OPTIONS */}

          {/* TITLE */}
          <div className="flex flex-row">
            <InputText
              type="text"
              placeholder="Enter Title"
              name="questionTitle"
              defaultValue={values.questionTitle}
              onBlur={(e) => changeHandler(e, index)}
              className="text-sm h-10 mb-1 w-full rounded-md questionName"
              label="Question name"
              inputProps={{ maxLength: 80, autoComplete: 'off' }}
            />
          </div>

          {/* DESCRIPTION */}
          <StyledTextAreaWrapper>
            <TextArea
              className={`text-sm mb-2`}
              defaultValue={values.questionDescription}
              name="questionDescription"
              onBlur={(e) => changeHandler(e, index)}
              placeholder="Enter Description"
              rows={3}
              fullWidth
              label="Description"
            />
          </StyledTextAreaWrapper>

          {/* TYPE */}
          <Tooltip title={`${!is_editable ? notEditableMessage : ''}`} placement="top-start">
            <StyledTypeWrapper className={`mb-2`}>
              <div className={`flex flex-row`}>
                <Select
                  className="w-52 text-lg"
                  fullWidth
                  label="Type"
                  value={values?.questionType.id}
                  options={typeOptionsData.map((value) => ({ id: value.id ?? '', label: value.label ?? '' }))}
                  onChange={(e) => selectHandler(e, index)}
                  disabled={!is_editable}
                />
              </div>
              {!is_editable && (
                <div className="text-red-600 text-xs" style={{ marginLeft: '9rem', paddingTop: '5px' }}>
                  Not editable
                </div>
              )}
            </StyledTypeWrapper>
          </Tooltip>

          <hr className="mt-2 border-dashed border-gray-400 mb-2" />

          {values?.visibilityOption == true && (
            <MultiSelect
              className="text-sm mb-2"
              fullWidth
              placeholder="Choose Visibility"
              value={values?.visibility.map((x: any) => x?.id)}
              options={visibilityOptionsData}
              onChange={(e) => visibilityHandler(e, index, visibilityOptionsData)}
              label="Hide this question to the following roles"
            />
          )}
        </div>

        {/* QUESTION TYPES */}
        <div style={{ maxWidth: '32rem' }}>{getComponent(values.questionType.label)}</div>
      </div>

      {/* TOOLTIP */}
      <hr className="m-2" />
      <div className="flex justify-between items-center h-6">
        <div className="flex w-full">
          {/* REQUIRED */}
          <Tooltip title={`${!is_editable ? notEditableMessage : ''}`} placement="right">
            <div className={`flex items-center mr-4 ${!is_editable ? 'opacity-60 cursor-default' : 'cursor-pointer'}`}>
              <Switch
                onChange={(e) => (is_editable ? handleChange(e, index) : undefined)}
                checked={values?.required}
                color="primary"
                style={!is_editable ? { cursor: 'default' } : { cursor: 'pointer' }}
              />
              <h3>Required</h3>
            </div>
          </Tooltip>

          {/*VISIBILITY */}
          <div className="flex flex-row py-3 mr-4">
            <div
              className="flex p-2  cursor-pointer items-center text-sm"
              //onClick={() => descriptionHandler(index, 'visibility')}
            >
              <Switch
                onChange={() => descriptionHandler(index, 'visibility')}
                checked={values?.visibilityOption}
                color="primary"
              />
              <span className="text-sm">Visibility</span>
            </div>
          </div>

          {/* MOVE UP */}
          <Tooltip title="" className="mr-4">
            <button
              type="button"
              className={`flex items-center cursor-pointer ${index == 0 && 'text-gray-400'} text-sm`}
              disabled={index == 0}
              onClick={() => swapPosition(index, index - 1)}
            >
              <ChevronUpIcon /> <span className="ml-2">Move up</span>
            </button>
          </Tooltip>

          {/* MOVE DOWN */}
          <Tooltip title="" className="mr-4">
            <button
              type="button"
              className={`flex items-center cursor-pointer ${valueslength == index + 1 && 'text-gray-400'} text-sm`}
              disabled={valueslength == index + 1}
              onClick={() => swapPosition(index, index + 1)}
            >
              <ChevronDownIcon /> <span className="ml-2">Move down</span>
            </button>
          </Tooltip>

          {/* DUPLICATE */}
          <Tooltip title={`${!is_editable ? notEditableMessage : ''}`} placement="right" className="mr-4">
            <div
              className={`flex items-center ${!is_editable ? 'opacity-60 cursor-default' : 'cursor-pointer'}`}
              onClick={() => (is_editable ? duplicateHandler(index) : undefined)}
            >
              <CopyIcon /> <span className="ml-2">Duplicate</span>
            </div>
          </Tooltip>
        </div>

        {/* TRASH */}
        <Tooltip title={`${!is_editable ? notEditableMessage : ''}`} placement="right">
          <div
            className={`w-4 ${!is_editable ? 'opacity-50 cursor-default' : 'cursor-pointer'}`}
            onClick={() => (is_editable ? deleteHandler(index) : undefined)}
          >
            <TrashCan stroke={'#000000'} />
          </div>
        </Tooltip>
      </div>
      {/* <TextAreaInput /> */}
    </StyledFormBuilderCard>
  );
};

export default FormBuilderCard;

const StyledFormBuilderCard = styled.div`
  .labelSelect {
    width: 10rem;
    padding-top: 0.3rem;
    margin-left: 0;
  }
  .questionName {
    display: flex;
    flex-direction: row;

    label {
      width: 10rem;
      padding-top: 0.3rem;
      margin-left: 0;
    }
    > div {
      width: 98%;
    }
  }
  .multiSelect-wrapper {
    > div {
      width: 100%;

      > div {
        > div {
          /*width: 40%;*/
        }
        .cursor-pointer {
          width: auto;
        }
      }
    }
  }
  .select-wrapper {
    > div {
      width: 100%;
      justify-content: flex-start;

      > div {
        > div {
          /*width: 19%;*/
        }
        > div:first-child {
          /*width: 31%;*/
        }
        .cursor-pointer {
          width: auto;
        }
      }
    }
  }
  .input-numeric,
  .linear-scale {
    margin-right: 0;
    > div {
      display: flex;
      flex-direction: row;
      margin-right: 2rem;

      label {
        width: 8.6rem;
        margin-top: 0.5rem;
      }

      > div {
        width: 17rem;
        margin-bottom: 0.5rem;
      }
    }
    .labelSelect {
      width: 8.8rem;
      align-self: center;
      padding-top: 0;
    }
    .selectType.k-dropdown {
      width: 17rem;
      margin-bottom: 0.5rem;
    }
  }
  .input-text,
  .short-text {
    > div {
      margin-bottom: 1rem;

      label {
        width: 14rem;
      }
    }
  }
  .upload-file {
    > div {
      justify-content: flex-start;
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;

      label {
        width: 8.8rem;
        padding-top: 0.375rem;
      }
      > div {
        width: 20.8rem;
      }
    }
  }
  .option-input {
    flex-grow: 1;
  }
`;

const StyledTypeWrapper = styled.div`
  .dropdown-wrapper,
  .k-dropdown {
    width: 100%;
  }
  .k-dropdown {
    background-color: #fff;
  }
`;

const StyledTInputMultiSelectComponent = styled(InputMultiSelectComponent)`
  margin-bottom: 1rem;
`;

const StyledTextAreaWrapper = styled.div`
  textarea::placeholder {
    color: #adadad !important;
  }
`;

const StyledPreviewLabel = styled.div`
  width: fit-content;
  padding: 0.1rem 1rem;
  border-radius: 10px;
  right: 0;
  position: absolute;
  top: 0;
  background-color: #fbeb02;
`;
