import SidePanel from '../ui/utils/SidePanel/SidePanel';
import EventForm from '../EventForm/EventForm';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Role } from '../../store/conference/types';
import { Form } from '../../store/form/types';
import TrashCan from '../../icons/TrashCan';

interface Props {
  title: string;
  onClose: () => void;
  onSave?: (event: PhaseEvent) => void;
  onDelete?: () => void;
  roleById: { [key: string]: Role };
  formById: { [key: number]: Form };
  initEvent?: PhaseEvent;
  actionDefinitions: { [key: string]: ActionDefinition };
  utcOffset: string;
  dateFormat: string;
  phase: Phase;
}

const EventSidePanel: React.FC<Props> = ({ title, initEvent, phase, onClose, onSave, onDelete, ...rest }) => {
  const [event, setEvent] = useState<PhaseEvent>(
    initEvent ?? { id: 0, type: 'custom', name: '', phase_id: phase.id, starts_at: '', actions: [] },
  );

  return (
    <SidePanel
      open={true}
      onClose={onClose}
      onSave={onSave && (() => onSave(event))}
      key={event.id}
      isValid={!!event.name && !!event.starts_at}
    >
      <header
        className="w-full py-3.5 px-8 flex justify-between sticky top-0 z-50"
        style={{ backgroundColor: '#EFF3FA' }}
      >
        <h2 className="font-bold text-xl">
          {title} {event.name}
        </h2>
        {event.id > 0 && event.type == 'custom' && (
          <div className="cursor-pointer ml-10 text-red-600 flex items-center" onClick={onDelete}>
            <TrashCan />
            <span className="ml-1">Delete event</span>
          </div>
        )}
      </header>

      <StyledEventFormWrapper className="flex flex-col">
        <EventForm event={event} phase={phase} onChange={(event) => setEvent(event)} {...rest} />
      </StyledEventFormWrapper>
    </SidePanel>
  );
};

export default EventSidePanel;

const StyledEventFormWrapper = styled.div`
  .title-form-element {
    min-width: 10rem;
  }
  .form-element {
    max-width: 364px;
    flex-grow: 1;
  }
  .k-datetimepicker {
    width: 100% !important;
  }
`;
