import { InputText, MultiSelect } from '../ui';
import React from 'react';

export const FUploadOption = [
  {
    id: '0',
    label: 'pdf',
  },
  {
    id: '1',
    label: 'doc',
  },
  {
    id: '2',
    label: 'zip',
  },
];

interface Props {
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: OptionDatas;
  textHandler: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number | undefined) => void;
  fileHandler: (file: any, index: number | undefined, fileOptions: any) => void;
  name?: string;
  index?: number;
  max?: number;
}

interface OptionDatas {
  fileSize?: number;
  fileType?: OptionsData[];
  pdfFile?: number;
}
interface OptionsData {
  id?: string;
  label?: string;
}
const UploadFileComponent: React.FC<Props> = ({ index, value, textHandler, fileHandler, name }) => {
  return (
    <div className="py-2 upload-file">
      <InputText
        label="Size limit (MB)"
        type="number"
        defaultValue={value?.fileSize}
        name={name}
        fullWidth
        onChange={(e) => textHandler(e, index)}
        inputProps={{ min: 1 }}
      />
      <MultiSelect
        fullWidth
        label="File Type"
        placeholder="Choose File Type"
        value={value?.fileType?.map((x: any) => x?.id)}
        onChange={(e) => fileHandler(e, index, FUploadOption)}
        options={FUploadOption}
      />
      {value?.fileType?.map(
        (x) =>
          x.label == 'pdf' && (
            <InputText
              key={x.id}
              type="number"
              placeholder=""
              label="Max pages (PDF)"
              name="pdfFile"
              defaultValue={value?.pdfFile}
              onChange={(e) => textHandler(e, index)}
              inputProps={{ min: 1 }}
            />
          ),
      )}
    </div>
  );
};

export default UploadFileComponent;
