import React from 'react';
import SimpleDialog, { SimpleDialogProps } from '../dialogs/SimpleDialog/SimpleDialog';
import { InputText } from '../ui';
import { EmailTemplate } from '../../store/email/types';

interface Props extends Omit<SimpleDialogProps, 'handleOk'> {
  handleOk: (emailTemplate: EmailTemplate) => void;
}

const AddEmailTemplateDialog: React.FC<Props> = ({ handleOk, ...rest }) => {
  const [emailTemplate, setEmailTemplate] = React.useState<EmailTemplate>({
    id: 0,
    name: '',
    subject: '',
    body: '',
  });

  return (
    <SimpleDialog {...rest} handleOk={() => handleOk(emailTemplate)} disableOkButton={!emailTemplate.name}>
      <InputText
        placeholder="Enter name"
        label="Name"
        value={emailTemplate.name}
        onChange={(e) => setEmailTemplate({ ...emailTemplate, name: e.target.value })}
        className="text-sm mb-4"
      />
    </SimpleDialog>
  );
};

export default AddEmailTemplateDialog;
