import React from 'react';
import { Button, Checkbox } from '../ui';
import { EmailTemplate, Placeholder } from '../../store/email/types';
import '../SendEmailForm/SendEmailForm.css';
import DateTimePickerWithOffset from '../DateTimePickerWithOffset/DateTimePickerWithOffset';
import { getUserOffset } from '../../helpers/timezone';
import { AppState } from '../../store/state';
import { selectCurrentUser } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { validationErrorTransformer } from '../../store/api';
import { ValidatedField } from '../../store/api.d';
import styled from 'styled-components';
import EmailForm from '../EmailForm/EmailForm';

interface FormFields {
  subject: string;
  body: string;
  expiration_date: Date;
  concat: boolean;
  template_id?: number;
}

interface InvitationEmailFormFields {
  subject: string;
  body: string;
  expiration_date: string;
  concat: boolean;
  template_id?: number;
}

interface Props {
  onSendHandler: (data: InvitationEmailFormFields) => void;
  placeholders: Placeholder[];
  value: InvitationEmailFormFields;
  timezone: string | null;
  dateFormat: string | null;
  validationErrors?: ValidatedField[];
  onClose?: () => void;
  back?: () => void;
  isResend?: boolean;
  templatesById: { [key: number]: EmailTemplate };
}

const InvitationEmailForm: React.FC<Props> = ({
  onSendHandler,
  placeholders,
  value,
  timezone,
  dateFormat,
  validationErrors,
  onClose,
  back,
  isResend,
  templatesById,
}) => {
  const [FData, setFData] = React.useState<FormFields>({ ...value, expiration_date: new Date(value.expiration_date) });

  console.log(FData);

  const isValid = () => {
    return !!FData.expiration_date && !!FData.subject && !!FData.body;
  };

  const errors = validationErrors ? validationErrorTransformer(validationErrors) : {};
  return (
    <div>
      <div className="flex flex-col mt-10">
        <div style={{ maxWidth: '40rem' }}>
          <p className="text-xs mb-3" style={{ color: '#696969' }}>
            The invited participants will receive an email notification informing them that they have been invited to
            join your conference with a designated role. You don't need to provide links for acceptance or rejection. A
            preview of the invitation is available before sending, in the next screen
          </p>
        </div>
        <StyledDateTimePickerWrapper>
          <DateTimePickerWithOffset
            utcOffset={getUserOffset(timezone)}
            format={dateFormat ?? undefined}
            defaultValue={FData.expiration_date}
            width="40rem"
            onChange={(date) => setFData({ ...FData, expiration_date: date })}
            className="ml-2 text-sm test"
            error={!!errors.expiration_date}
            helperText={errors.expiration_date}
            required
            label="Expiration date"
            display="vertical"
          />
        </StyledDateTimePickerWrapper>

        <EmailForm
          onChange={(data) =>
            setFData({ ...FData, subject: data.subject, body: data.body, template_id: data.template_id })
          }
          placeholders={placeholders}
          value={FData}
          templatesById={templatesById}
          subjectDisabled={false}
        >
          {isResend && (
            <StyledCheckbox>
              <Checkbox
                label="This message will be concatenated with the previous one."
                className="ml-1 mt-2 mb-1"
                checked={FData.concat}
                onChange={(e) => setFData({ ...FData, concat: e.target.checked })}
              />
            </StyledCheckbox>
          )}
        </EmailForm>

        <StyledButtons>
          <>
            <StyledCancel onClick={onClose} autoFocus variant="text">
              Cancel
            </StyledCancel>

            <Button
              onClick={() => {
                back && back();
              }}
              variant="text"
              className="font-semibold"
            >
              Previous
            </Button>
          </>
          <Button
            disabled={!isValid()}
            onClick={() =>
              onSendHandler({
                ...FData,
                expiration_date: FData.expiration_date.toISOString(),
              })
            }
          >
            Next
          </Button>
        </StyledButtons>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  timezone: selectCurrentUser(state).person.timezone,
  dateFormat: selectCurrentUser(state).person.date_format,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

const StyledButtons = styled.div`
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 2px dotted #e3e5e6;
  padding: 1rem 0 0 0;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
  padding-bottom: 1.5rem;
`;

const StyledCancel = styled(Button)`
  color: rgb(220 38 38);
  font-weight: 700;
`;

const StyledCheckbox = styled.div`
  label {
    font-size: 0.775rem;
  }
`;

const StyledDateTimePickerWrapper = styled.div`
  margin-top: 1rem;
`;

export default connect(mapStateToProps, mapDispatchToProps)(InvitationEmailForm);
