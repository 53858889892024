import SimpleDialog from '../SimpleDialog/SimpleDialog';
import React, { useEffect } from 'react';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  selectBidState,
  selectKeywordState,
  selectLabelState,
  selectReviewState,
  selectSubmissionState,
  selectTable,
  selectTrackRoles,
} from '../../../store/selectors';
import {
  AssignmentsAdditionalInfo,
  EditAssignmentsForReviewerRequest,
  Review,
  ReviewerTableRow,
  ReviewStats,
} from '../../../store/review/types';
import { TableFriendlyName } from '../../../store/info/types';
import { GridCellProps, GridFilterCellProps } from '@progress/kendo-react-grid';
import AssignedCell from '../../AssignedCell/AssignedCell';
import reviewSlice from '../../../store/review/slice';
import { getAssignmentsSubmissions } from '../../../store/review/sagas';
import Loading from '../../Loading/Loading';
import { BidOption } from '../../../store/bid/types';
import { Label } from '../../../store/label/types';
import LabelsCell from '../../MyGridCore/LabelsCell';
import KeywordsCell from '../../KeywordsCell/KeywordsCell';
import { getSubmissionsRequest, Submission } from '../../../store/submission/types';
import { TableSettings } from '../../MyGridRedux/types';
import { formatKeywordColumn } from '../../../helpers/table';
import submissionSlice from '../../../store/submission/slice';
import MyGridCore from '../../MyGridCore/MyGridCore';
import { MultiSelectFilterCell } from '../../MultiSelectFilterCell/MultiSelectFilterCell';
import { DEFAULT_FONT_SIZE } from '../../MyGridRedux/MyGridRedux';

interface Props {
  reviewer: ReviewerTableRow;
  handleClose: () => void;
  reviewsById: { [key: string]: Review };
  submissionStats: { [key: string]: { [key: string]: ReviewStats } };
  editAssignmentsAction: (data: EditAssignmentsForReviewerRequest) => void;
  bidOptionsById: { [key: number]: BidOption };
  labelsById: { [key: number]: Label };
  labelables: { [key: string]: { [key: number]: number[] } };
  tablesSettings: { [key: string]: TableSettings };
  keywordById: { [key: number]: Keyword };
  submissionsById: { [key: string]: Submission };
  getSubmissionsAction: (data: getSubmissionsRequest) => void;
  outerFriendlyName: TableFriendlyName;
}

const EditAssignmentReviewerDialog: React.FC<Props> = ({
  reviewer,
  handleClose,
  reviewsById,
  submissionStats,
  editAssignmentsAction,
  bidOptionsById,
  labelsById,
  labelables,
  tablesSettings,
  keywordById,
  submissionsById,
  getSubmissionsAction,
  outerFriendlyName,
}) => {
  /* Table info */
  const friendlyName: TableFriendlyName = 'submissions';
  const tableSettings = tablesSettings[friendlyName];
  const LABELABLE_ITEM_KEY = tableSettings.gridSettings.LABELABLE_ITEM_KEY;
  const DATA_ITEM_KEY = tableSettings.gridSettings.DATA_ITEM_KEY;
  const modelClass = tableSettings.model;

  const [additionalInfo, setAdditionalInfo] = React.useState<AssignmentsAdditionalInfo[]>([]);

  const initSelection = () => {
    const selection: { [key: number]: boolean } = {};
    Object.values(reviewsById)
      .filter((review) => review.person_role_id === reviewer.person_role_id)
      .forEach((review) => {
        selection[review.submission_id] = true;
      });
    return selection;
  };
  const [selection, setSelection] = React.useState<{ [key: number]: boolean }>(initSelection);

  useEffect(() => {
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction({ friendlyName });
    }
    getAssignmentsSubmissions({ person_role_id: reviewer.person_role_id }).then((result) => {
      if (result.type === 'ok') {
        setAdditionalInfo(result.value.data);
      }
    });
  }, []);

  const isLoading = !additionalInfo.length || Object.keys(submissionsById).length === 0;

  /* Build input data */
  let inputData: Record<string, unknown>[] = [];
  Object.values(submissionsById).forEach((submission, index) => {
    const additional = additionalInfo.find((value) => value.submission_id == submission.id);

    // Get labels
    // @ts-ignore
    const label_ids = labelables[modelClass][submission[LABELABLE_ITEM_KEY]] ?? [];
    const labels = label_ids.map((id) => labelsById[id].label).join(';');

    let total_reviews;
    if (submission.id in submissionStats) {
      total_reviews = Object.values(submissionStats[submission.id])
        .map((stats) => stats.total_reviews)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    } else {
      total_reviews = 0;
    }

    const newRegister = {
      person_role_id: reviewer.person_role_id,
      ...submission,
      authors_str: submission.authors.map((author) => author.first_name + ' ' + author.last_name).join(';'),
      keyword_ids: submission.keyword_ids,
      total_reviews: total_reviews,
      affinity: additional?.affinity,
      bid: additional?.bid_option_id ? bidOptionsById[additional.bid_option_id].name : undefined,
      conflict: additional?.conflict,
      assigned: submission.id in selection && selection[submission.id],
      label_ids,
      labels,
    };

    formatKeywordColumn(newRegister, keywordById);

    inputData.push(newRegister);
  });
  if (outerFriendlyName == 'reviewers_bids') {
    inputData = inputData.filter((record: any) => !!record.bid);
  }

  /* Decide which columns to show */
  const extraGridColumns: any[] =
    outerFriendlyName == 'reviews_to_papers'
      ? [
          { field: 'conflict', title: 'Conflict', filter: 'boolean' },
          { field: 'total_reviews', title: '# Assignments' },
          {
            field: 'assigned',
            title: 'Assigned',
            filter: 'boolean',
            cell: (props: GridCellProps) => (
              <AssignedCell
                {...props}
                onChangeHandler={() => setSelection({ ...selection, [props.dataItem.id]: !props.dataItem.assigned })}
              />
            ),
          },
        ]
      : [];

  let gridColumnProps: any[] = [
    { field: 'external_id', title: 'ID', width: '100px', filter: 'numeric', locked: true },
    { field: 'title', title: 'Title' },
    { field: 'authors_str', title: 'Authors' },
    {
      field: 'keywords',
      title: 'Keywords',
      cell: (props: GridCellProps) => <KeywordsCell {...props} keywordById={keywordById} />,
      filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
    },
    {
      field: 'main_area',
      title: 'Main area',
      filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
    },
    {
      field: 'bid',
      title: 'Bid',
      filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
    },
  ];
  gridColumnProps = gridColumnProps.concat(extraGridColumns);
  gridColumnProps.push({
    field: 'labels',
    title: 'Labels',
    cell: (props: GridCellProps) => (
      <LabelsCell
        {...props}
        labelsById={labelsById}
        tableFriendlyName={friendlyName}
        labelableIdKey={LABELABLE_ITEM_KEY}
      />
    ),
    filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
  });

  return (
    <SimpleDialog
      title={outerFriendlyName == 'reviews_to_papers' ? 'Edit assignments' : 'View bids'}
      open={true}
      handleClose={handleClose}
      handleOk={
        outerFriendlyName == 'reviews_to_papers'
          ? () => editAssignmentsAction({ values: selection, person_role_id: reviewer.person_role_id })
          : undefined
      }
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="text-sm mb-6">
            <span className="mr-3">
              Reviewer: <strong>{reviewer.full_name}</strong> ({reviewer.role})
            </span>
            <span className="mr-2">{reviewer.email}</span>
          </div>
          <MyGridCore
            allGridData={inputData}
            gridSettings={{
              gridColumnProps: gridColumnProps,
              arrayFields: [],
              advancedFilter: false,
              DATA_ITEM_KEY: DATA_ITEM_KEY,
              SELECTED_FIELD: '',
              LABELABLE_ITEM_KEY: LABELABLE_ITEM_KEY,
              fontSize: DEFAULT_FONT_SIZE,
              dataState: {},
            }}
            selectedState={{}}
            dateFormat={null}
            expandedState={{}}
            selectable={false}
            showActionsColumn={false}
          />
        </>
      )}
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  info: state.info.info,
  reviewsById: selectReviewState(state).reviewsById,
  submissionStats: selectReviewState(state).submissionStats ?? {},
  roleById: selectTrackRoles(state),
  bidOptionsById: selectBidState(state).bidOptions.byId,
  labelsById: selectLabelState(state).labelsById,
  labelables: selectLabelState(state).labelables,
  tablesSettings: selectTable(state).tablesSettings,
  keywordById: selectKeywordState(state).keywordById,
  submissionsById: selectSubmissionState(state).submissionsById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  editAssignmentsAction: (data: EditAssignmentsForReviewerRequest) =>
    dispatch(reviewSlice.actions['ASSIGNMENTS:REVIEWER:EDIT'](data)),
  getSubmissionsAction: (data: getSubmissionsRequest) => dispatch(submissionSlice.actions.GET_SUBMISSIONS(data)),
});

const StyledDialogContent = styled.div`
  flex-direction: column;
  display: flex;

  .MuiInputBase-input {
    width: 25rem;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(EditAssignmentReviewerDialog);
