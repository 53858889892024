import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../images/logo.svg';

type Props = {
  children?: React.ReactNode;
};

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <StyledDiv>
        <div className="-left-col">
          <span className="-text">
            "The art and science of asking questions is the source of all knowledge." — <span>Thomas Berger</span>
          </span>
        </div>
        <div className="flex-1 flex flex-col px-7 py-9">
          <div className="relative">
            <StyledBeta>BETA</StyledBeta>
            <Logo />
          </div>
          <div className="flex-grow flex items-center justify-center">
            <div className="w-full max-w-xs flex flex-col mt-4">{children}</div>
          </div>
        </div>
      </StyledDiv>
      <StyledFooter>
        Copyright © Brainful labs | <span>Contact us at </span>
        <a href="mailto:support@brainful-labs.com">support@brainful-labs.com</a>
      </StyledFooter>
    </>
  );
};

const StyledDiv = styled.div`
  ${tw`flex h-screen`}

  .-left-col {
    ${tw`flex-1 bg-blue-700 relative`}
    background: url(/images/login.jpg) right bottom / cover no-repeat;

    .-text {
      ${tw`absolute bottom-0 text-gray-600 text-5xl leading-tight cursor-default pl-16 pr-28 pb-14`}
      background-color: rgba(255, 255, 255, 0.5);
      margin-left: 4rem;
      margin-right: 7rem;
      margin-bottom: 3.5rem;
      padding: 2rem;
      border-radius: 10px;

      & > span {
        ${tw`text-green-500 font-bold`}
      }
    }
  }
`;

const StyledBeta = styled.div`
  font-weight: bold;
  background-color: rgb(0, 68, 240);
  width: max-content;
  padding: 1px 11px;
  color: #fff;
  font-size: 0.6rem;
  position: absolute;
  top: 32px;
  left: 148px;
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.4) -2px 2px 4px 0px;
  border-radius: 11px;
`;

const StyledFooter = styled.div`
  position: fixed;
  bottom: 1px;
  width: 100%;
  height: 2em;
  border-top: 1px solid #ddd;
  text-align: center;
  line-height: 2em;
  background: #eee;
  color: #666;
  font-size: 0.75rem;
`;

export default AuthLayout;
