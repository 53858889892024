import React from 'react';
import EmailHistory from '../../components/EmailHistory/EmailHistory';
import MyTableGridWrapper from '../../components/MyTableGridWrapper/MyTableGridWrapper';
import { AppState } from '../../store/state';
import { selectCurrentUser } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import styled from 'styled-components';

interface Props {
  dateFormat: string | null;
}

const EmailHistoryPage: React.FC<Props> = ({ dateFormat }) => {
  return (
    <div className="flex flex-col h-full">
      <h2 className="text-xl mb-8 font-bold text-gray-700">Email history</h2>
      <StyledWrapper className="mb-2">
        <MyTableGridWrapper>
          <EmailHistory dateFormat={dateFormat} />
        </MyTableGridWrapper>
      </StyledWrapper>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  dateFormat: selectCurrentUser(state).person.date_format,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmailHistoryPage);

const StyledWrapper = styled.div`
  min-height: 50vh;
  flex-grow: 1;

  .k-grid {
    height: 100%;
  }
  .k-grid-header,
  .k-header,
  th.k-header,
  .k-grid-header-wrap,
  .k-grouping-header,
  .k-grouping-header .k-group-indicator,
  .k-grid td,
  .k-grid-footer,
  .k-grid-footer-wrap,
  .k-grid-content-locked,
  .k-grid-footer-locked,
  .k-grid-header-locked,
  .k-filter-row > td,
  .k-filter-row > th {
    border-color: #f4f5f9;
  }

  .k-grid tr.k-alt {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .k-widget.k-grid {
    border-width: 0;

    .k-grid-header {
      border-width: 0;
    }
  }
`;
