import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InfoGetDTO,
  InfoGetOkDTO,
  InfoState,
  UpdateOkDTO,
  updateUserRolesBulkDTO,
  updateUserRolesBulkResponse,
} from './types';
import { Invitation, RespondInvitationDTO } from '../recruitment/types';
import { AddPersonFormFields } from '../../components/dialogs/AddPersonDialog/AddPersonDialog';

export const initialState: InfoState = {
  info: {
    users: {
      byId: {},
    },
    invitations: {
      byId: {},
    },
    reviewers_bids: {
      byId: {},
    },
  },
  loading: false,
};

const infoSlice = createSlice({
  name: 'INFO',
  initialState,
  reducers: {
    reset: () => initialState,
    ['LOADING:ON']: (state) => ({
      ...state,
      loading: true,
    }),
    ['LOADING:OFF']: (state) => ({
      ...state,
      loading: false,
    }),
    ['GET']: (state, _action: PayloadAction<InfoGetDTO>) => ({
      ...state,
      loading: true,
    }),
    ['GET:OK']: (state, action: PayloadAction<InfoGetOkDTO>) => ({
      ...state,
      loading: false,
      info: {
        ...state.info,
        [action.payload.friendlyName]: {
          byId: action.payload.byId,
        },
      },
    }),
    ['GET:KO']: (state) => ({
      ...state,
      loading: false,
    }),
    ['UPDATE:OK']: (state, action: PayloadAction<UpdateOkDTO>) => {
      const { tableFriendlyName, byId } = action.payload;
      return {
        ...state,
        info: {
          ...state.info,
          [tableFriendlyName]: {
            byId,
          },
        },
      };
    },
    ['USER:UPDATE:ROLES:BULK']: (state, action: PayloadAction<updateUserRolesBulkDTO>) => ({
      ...state,
    }),
    ['USER:UPDATE:ROLES:BULK:OK']: (state, action: PayloadAction<updateUserRolesBulkResponse>) => {
      const newById = { ...state.info.users.byId };

      Object.entries(action.payload.data).forEach(([idStr, value]) => {
        const personId = parseInt(idStr);
        newById[personId] = { ...newById[personId], role_ids: value.role_ids, person_role_ids: value.person_role_ids };
      });

      return {
        ...state,
        info: {
          ...state.info,
          users: {
            ...state.info.users,
            byId: newById,
          },
        },
      };
    },
    ['DELETE_INVITATIONS:OK']: (state, action: PayloadAction<number[]>) => {
      const newById: { [key: number]: Invitation } = {};
      Object.keys(state.info.invitations.byId)
        .filter((key) => !action.payload.includes(parseInt(key)))
        .forEach((key) => {
          const idx = parseInt(key);
          newById[idx] = state.info.invitations.byId[idx];
        });
      return {
        ...state,
        loading: false,
        info: { ...state.info, invitations: { ...state.info.invitations, byId: newById } },
      };
    },
    ['RESPOND_INVITATION:OK']: (state, action: PayloadAction<RespondInvitationDTO>) => {
      return {
        ...state,
        loading: false,
        info: {
          ...state.info,
          invitations: {
            ...state.info.invitations,
            byId: {
              ...state.info.invitations.byId,
              [action.payload.id]: {
                ...state.info.invitations.byId[action.payload.id],
                ...action.payload,
              },
            },
          },
        },
      };
    },
    ['USER:ADD']: (state, action: PayloadAction<AddPersonFormFields>) => ({
      ...state,
    }),
  },
});

export default infoSlice;
