import { Button } from '../../ui';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { Dialog } from '@progress/kendo-react-dialogs';
import { StyledGridWrapper } from '../../MyGridCore/MyGridCore';

export interface SimpleDialogProps {
  open?: boolean;
  handleClose: () => void;
  handleOk?: () => void;
  handlePrevious?: () => void;
  title?: string;
  hideCancel?: boolean;
  closeOnOk?: boolean;
  okButtonText?: string;
  previousButtonText?: string;
  cancelButtonText?: string;
  disableOkButton?: boolean;
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  handleClose,
  handleOk,
  handlePrevious,
  title,
  children,
  hideCancel = false,
  closeOnOk = true,
  okButtonText,
  previousButtonText,
  cancelButtonText,
  disableOkButton = false,
}) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledDialog onClose={handleClose} className="custom-dialog h-full flex flex-col">
        {title && (
          <StyledTitle id="alert-dialog-title">
            <h2>{title}</h2>
          </StyledTitle>
        )}
        <StyledChildrenContent className="dialog-content">{children}</StyledChildrenContent>

        {(!hideCancel || handlePrevious || handleOk) && (
          <StyledButtons>
            <div>
              {!hideCancel && (
                <StyledCancel onClick={handleClose} autoFocus variant="text">
                  {cancelButtonText ?? 'Cancel'}
                </StyledCancel>
              )}
              {handlePrevious && (
                <Button
                  onClick={() => {
                    handlePrevious();
                  }}
                  className="ml-8 font-semibold"
                  variant="text"
                >
                  {previousButtonText ?? 'Previous'}
                </Button>
              )}
              {handleOk && (
                <Button
                  onClick={() => {
                    handleOk();
                    closeOnOk && handleClose();
                  }}
                  className="ml-8"
                  disabled={disableOkButton}
                >
                  {okButtonText ?? 'OK'}
                </Button>
              )}
            </div>
          </StyledButtons>
        )}
      </StyledDialog>
    </ThemeProvider>
  );
};

export default SimpleDialog;

const StyledChildrenContent = StyledGridWrapper;

const StyledCancel = styled(Button)`
  color: rgb(220 38 38);
  margin-left: 0 !important;
  font-weight: 700;
`;
const StyledTitle = styled.div`
  border-bottom: 2px dotted #e3e5e6;
  padding: 16px 24px;

  h2 {
    font-size: 1.25rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }
`;
const StyledButtons = styled.div`
  justify-content: center;
  margin-top: 1rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  border-top: 2px dotted #e3e5e6;
  display: flex;
  align-items: center;
  padding: 1rem 0 0 0;
`;

const StyledDialog = styled(Dialog)`
  .dialog-content {
    padding: 26px 24px 0 24px;
  }
  .k-grid-header-wrap {
    border-width: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .k-grid td.k-state-selected,
  .k-grid tr.k-state-selected > td.gcgrid {
    background-color: yellow;
  }

  .k-grid th {
    font-weight: bold;
  }

  .k-menu-item {
    margin: auto;
  }

  .k-grid th:first-child,
  .k-grid td:first-child {
    border-left-width: 0;
  }

  .k-grid-header,
  .k-header,
  th.k-header,
  .k-grid-header-wrap,
  .k-grouping-header,
  .k-grouping-header .k-group-indicator,
  .k-grid td,
  .k-grid-footer,
  .k-grid-footer-wrap,
  .k-grid-content-locked,
  .k-grid-footer-locked,
  .k-grid-header-locked,
  .k-filter-row > td,
  .k-filter-row > th {
    border-color: ${(props) => props.theme.palette.secondary.light};
  }

  .k-grid {
    min-height: 22rem;
  }

  .k-window.k-dialog {
    padding: 0;
  }
  .k-icon {
    color: inherit;
  }
`;
